import styled from 'styled-components';

export const Container = styled.div.attrs({
    className: 'containerAutocomplete'
})`
    position: relative;

    &:focus{
        outline: none !important;
    }
`;

export const SearchDiv = styled.div.attrs({
    className: 'searchDiv'
})`
    display: flex; 
    flex-wrap: nowrap; 
    align-items: space-between; 
    justifyContent: center; 
    min-width: 240px; 
    max-width: 100%;
    height: 30px;
    z-index: 995;
    position: relative;

    input {
        border: 1px solid #d5dade;
        padding: 5.5px;
        width: 100%;
    }

    input:focus {
        border: 1px solid #9B59B6;
    }

    input[type=text] {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        font-size: 12px;
    }

    .inputLimpo {
        border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
		padding-left: 18px;
    }
`;

export const TextoFiltro = styled.div.attrs({
    className: 'textoFiltro'
})`
    border: 1px;
    border-style: solid hidden solid solid;
    border-color: #d5dade;
    padding: 4px 13px;
    background-color: #e9ecef;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    white-space: nowrap;
`;

export const Sugestoes = styled.div.attrs({
    className: 'sugestoes'
})`
    border-top-width: 0;
    border: 1px solid #999;
    list-style: none;
    max-height: 195px;
    overflow-y: auto;
    padding-left: 0;
    position: absolute;
    overflow-y: auto;
    background-color: white;
    z-index: 990;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    font-size: 12px;
    margin-top: -2px;

    & li {
        padding: 6px;
    }

    &::-webkit-scrollbar {
        width: 10px !important;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1 !important;
    }
      
    &::-webkit-scrollbar-thumb {
        background: #888 !important;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555 !important;
    }

    & li:hover {
        cursor: pointer;
    }

    .suggestion-active {
        background-color: rgba(0, 0, 0, 0.08);
    }

    .no-sugestoes {
        color: #999;
        padding: 0.5rem;
    }
`;