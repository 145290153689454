import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { processoService } from '../../services/processoService';
import { dossieService } from '../../services/dossieService';
import { withStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import { DossiePage } from '../../pages/DossiePage/DossiePage';
import { renderToStaticMarkup } from 'react-dom/server';
import FasesLoader from '../FaseLoader/FasesLoader';
import _ from "lodash";
import { FaseViewerCss } from '../../components/FaseViewer/FaseViewerCss';
import { ModalDossieCss } from './ModalDossieCss';
import { MovimentacaoProcessoViewerCss } from '../../components/MovimentacaoProcessoViewer/MovimentacaoProcessoViewerCss';
import { pushWarning } from '../../services/notifierService';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import { Backdrop, CircularProgress } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
/**
 * Objeto style para ser consumido pelo Dialog
 */
const styles = theme => ({
    rootStyle: {
        borderRadius: 15,
        zIndex: 1200
    },
    paper: {
        padding: 5,
        textAlign: 'left',
        flex: '1 0 auto',
        marginBottom: 5,
        width: 400
    },
    label: {
        fontSize: "10px",
        fontFamily: "Montserrat-Medium"
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonExportar: {
        backgroundColor: "#0091C7",
        color: "white",
        fontSize: "10px",
        width: "90px"
    },
    modalContent: {
        backgroundColor: "#F5F5F5",
        padding: theme.spacing("15px", "20px", "20px", "20px"),
        borderRadius: "10px",
        fontSize: "13px",
        outline: 'none'
    },
    header: {
        marginLeft: 24
    },
    backdrop: {
        color: '#fff',
        zIndex: 99999,
    },
});

/**
 * Componente que realiza a abertura das informações do proceso dentro de um Modal responsivo
 * @param {int} processoId
 * @since 29/04/2020
 */
class ModalDossie extends Component {

    /**
     * Construtor do componente
     */
    constructor(props) {
        super(props);
        this.state = {
            collapses: [],
            open: false,
            checkFases: [],
            checkboxes: {
                movimentacoes: true,
                partesProcesso: true,
                dadosProcesso: true
            },
            partes: '',
            detalhes: '',
            dadosCampos: [],
            documento: '',
            gerarDossie: false,
            fases: null,
            loading: false
        };
        this.gerarDossie = this.gerarDossie.bind(this);
    }

    gerarDossie = () => {

        this.setState({ loading: true });
        let dados = this.state.checkboxes;
        dados.fases = [];
        let collapses = this.state.collapses;
        let checkFases = this.state.checkFases;
        let fases = [];
        checkFases.forEach((value, index) => {
            if (checkFases[index]) {
                dados.fases.push(collapses[index].idFase);
                fases.push(collapses[index]);
            }
        });
        if (!this.state.checkboxes.partesProcesso && !this.state.checkboxes.movimentacoes && !this.state.checkboxes.dadosProcesso && dados.fases.length === 0) {
            pushWarning("Escolha pelo menos uma opção para gerar o Dossiê")
            return;
        }
        dados.idCarteiraProcessoHistorico = this.props.carteiraProcessoHistorico;
        let thisNow = this;
        processoService.getProcessoPartes(thisNow.props.processoId).then(response => {
            let rowProc = [];
            let rowPart = [];
            let iPartes = 0;
            let iProces = 0;

            let chaveAgrupador = '';
            if (response.result.length > 0) {
                rowProc.push(<div key={iProces} className="col-xs-12 pad-botton"><span className="expansion-panel-inner-title">Processo: </span><span>{response.result[iProces].processoNumero}</span></div>);
            }
            for (const result of response.result) {
                let innerRow = [];
                if (result.sessao === "PARTES") {
                    let colWidth = (result.agrupadorRelacionado !== "" ? 6 : 12);
                    if (colWidth === 12) {
                        innerRow.push(<div key={"col1" + iPartes} className={"col-xs-" + colWidth + " pad-botton"}><span className="expansion-panel-inner-title">{result.agrupador}: </span><span>{result.valor}</span></div>);

                    } else if (chaveAgrupador !== result.agrupador) { //Novo agrupamento
                        chaveAgrupador = result.agrupador;
                        innerRow.push(<div key={"col1" + iPartes} className={"col-xs-" + colWidth + " pad-botton"}><span className="expansion-panel-inner-title">{result.agrupador}: </span><span>{result.valor}</span></div>);
                        innerRow.push(<div key={"col2" + iPartes} className={"col-xs-" + colWidth + " pad-botton"}><span className="expansion-panel-inner-title">{result.agrupadorRelacionado}: </span><span>{result.valorRelacionado}</span></div>);
                    } else { // Atualizando agrupamento
                        innerRow.push(<div key={"col1" + iPartes} className={"col-xs-" + colWidth + " pad-botton"}></div>);
                        innerRow.push(<div key={"col2" + iPartes} className={"col-xs-" + colWidth + " pad-botton"}><span className="expansion-panel-inner-title">{result.agrupadorRelacionado}: </span><span>{result.valorRelacionado}</span></div>);

                    }
                    iPartes++; //tratando o parametro Key do react
                    rowPart.push(innerRow);
                } else if (result.sessao === "PROCESSO") {
                    iProces++; //tratando o parametr Key do react
                    rowProc.push(<div key={iProces} className="col-xs-6 pad-botton"><span className="expansion-panel-inner-title">{result.agrupador}: </span><span>{result.valor}</span></div>);
                }
            }

            if (rowProc.length < 1) {
                rowProc.push(<div key={iProces} className="col-xs-6 pad-botton"><div className="">Sem informações do processo</div></div>);
            }

            if (rowPart.length < 1) {
                rowPart.push(<div key={iProces} className="col-xs-6 pad-botton"><div className="">Sem informações das partes do processo</div></div>);
            }

            thisNow.setState({ partes: rowPart });
            thisNow.setState({ detalhes: rowProc });

            processoService.getProcessoMovimentacoes(this.props.processoId).then(response => {
                let row = [];
                let index = 0;

                for (const result of response.result || []) {
                    row.push(<div key={"0_" + index} className="col-xs-12"><hr /></div>);
                    row.push(<div key={"1_" + index} className="col-xs-4 pad-botton-flex"><span className="expansion-panel-inner-title">Nº</span>: {result.codigo}</div>);
                    row.push(<div key={"2_" + index} className="col-xs-4 pad-botton-flex"><span className="expansion-panel-inner-title">Disponibilização</span>: {result.dtDiarioOficial}</div>);
                    row.push(<div key={"3_" + index} className="col-xs-4 pad-botton-flex"><span className="expansion-panel-inner-title">Tribunal</span>: {result.regiao}</div>);
                    row.push(<div key={"4_" + index}><br /><br /></div>);
                    row.push(<div key={"5_" + index} className="col-xs-12 pad-botton detalhamento-ln-break">{result.detalhamento}</div>);

                    if (response.result.length !== (index + 1)) {
                        row.push(<div key={"6_" + index}><br /><br /><br /></div>);
                    }
                    index++;
                }

                if (row.length < 1) {
                    row.push(<div key={index} className="col-xs-6 pad-botton"><div className="">Sem informações movimentações no processo</div></div>);
                }
                this.setState({ conteudo: row });

                dados.fases.forEach((result, index) => {
                    let loader = new FasesLoader(result, thisNow.props.carteiraProcessoHistorico);
                    loader.getData().then((retorno) => {
                        if (retorno.dataCampos == null) {
                            thisNow.state.dadosCampos[index] = [];
                        }
                        else {
                            thisNow.state.dadosCampos[index] = retorno.dadosCampos;
                        }
                        thisNow.setState({ dadosCampos: thisNow.state.dadosCampos });
                    });
                });
                thisNow.setState({ gerarDossie: true });
                thisNow.setState({ fases });
            });
        }).finally(() => {
            setTimeout(() => {
                this.setState({ loading: false });
            }, 2000)
        });

    }

    /**
     * Método responsável por carregar as informações iniciais
     * do processo
    */
    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if ((prevProps.processoId === undefined && prevProps.processoId !== this.props.processoId) && !prevState.open) {
            //deixando o modal visível
            this.setState({ open: true });

            //Pegando as Fases que o processo já percorreu
            processoService.getProcessoFases(this.props.carteiraProcessoHistorico).then(response => {
                let customCollapses = [];
                let chkFases = [];
                for (const result of response.result) {
                    if (result.idFase !== 0) {
                        customCollapses.push(result);
                        chkFases.push(true);
                    }
                }
                this.setState({ collapses: customCollapses, checkFases: chkFases });
            });
        }
        if (this.state.gerarDossie && this.state.dadosCampos && this.state.fases && this.state.dadosCampos.length === this.state.fases.length) {
            let i = 0;
            _.each(this.state.dadosCampos, function (value) {
                if (value !== undefined) {
                    i++;
                }
            });
            if (i === this.state.fases.length) {
                let dados = this.state.checkboxes;
                this.setState({ gerarDossie: false });
                let collapses = this.state.collapses;
                let checkFases = this.state.checkFases;
                let fases = [];
                let thisNow = this;
                checkFases.forEach((value, index) => {
                    if (checkFases[index]) {
                        dados.fases.push(collapses[index].idFase);
                        fases.push(collapses[index]);
                    }
                });
                dados.idCarteiraProcessoHistorico = this.props.carteiraProcessoHistorico
                dados.html = "<style>@import 'https://fonts.googleapis.com/css?family=Montserrat';</style>";
                dados.html += "<style>" + FaseViewerCss + "</style>";
                dados.html += "<style>" + MovimentacaoProcessoViewerCss + "</style>";
                dados.html += "<style>" + ModalDossieCss + "</style>";
                dados.html += renderToStaticMarkup(
                    <DossiePage
                        checkboxes={this.state.checkboxes}
                        dadosCampos={this.state.dadosCampos}
                        fases={this.state.fases}
                        processoId={this.props.processoId}
                        carteiraProcessoHistorico={this.props.carteiraProcessoHistorico}
                        detalhes={this.state.detalhes}
                        partes={this.state.partes}
                        movimentacoes={this.state.conteudo}
                    />);
                dossieService.gerarDossie(dados, this.props.processoId).then((response) => {
                    const base64 = "data:application/zip;base64," + response.result; //data:image/png;base64,
                    const url = base64;
                    // const url = window.URL.createObjectURL(new Blob([response.result[0].binAnexo],{type: response.result[0].tipoAnexo}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', this.props.processoNumero + ".zip");
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }).finally(() => {
                    thisNow.setState({ partes: '', detalhes: '', fases: null, dadosCampos: [] });
                });
            }
        }
    }

    render() {
        const { classes, onClose } = this.props;

        const handleChange = (event) => {
            this.setState({ ...this.state, checkboxes: { ...this.state.checkboxes, [event.target.name]: event.target.checked } });
        };

        const handleChangeFase = (event) => {
            let checkFases = this.state.checkFases;
            checkFases[event.target.name] = event.target.checked;
            this.setState({ checkFases });
        };

        /**
         * Finaliza o modal, liberando os objetos instanciados
         */
        const handleClose = () => {
            this.setState({
                collapses: [],
                open: false
            }, () => onClose());
        };

        return (
            <>

                <Backdrop className={classes.backdrop} open={this.state.loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={this.state.open}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.open}>
                        <div className={classes.modalContent}>
                            <React.Fragment>
                                <div className="modal-title">
                                    <FontAwesomeIcon icon={faChevronLeft} onClick={() => { handleClose() }} style={{ cursor: "pointer" }} />
                                    <label className={classes.header}>Dossiê</label>
                                </div>
                                <div className={classes.contentModal} >

                                    <Paper className={classes.paper}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.checkboxes.dadosProcesso}
                                                    onChange={handleChange}
                                                    name="dadosProcesso"
                                                    color="primary"
                                                />
                                            }
                                            label={<span className={classes.label}>{'Dados do Processo'}</span>}
                                        />
                                    </Paper>
                                    <Paper className={classes.paper}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.checkboxes.partesProcesso}
                                                    onChange={handleChange}
                                                    name="partesProcesso"
                                                    color="primary"
                                                />
                                            }
                                            label={<span className={classes.label}>{'Partes do Processo'}</span>}
                                        />
                                    </Paper>
                                    <Paper className={classes.paper}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.checkboxes.movimentacoes}
                                                    onChange={handleChange}
                                                    name="movimentacoes"
                                                    color="primary"
                                                />
                                            }
                                            label={<span className={classes.label}>{'Movimentações'}</span>}
                                        />
                                    </Paper>
                                    {
                                        //Inject dos collapses customizados (conforme as fases executadas do Processo)
                                        this.state.collapses.map((result, index) => {
                                            return (
                                                <Paper key={index} className={classes.paper}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={this.state.checkFases[index]}
                                                                onChange={handleChangeFase}
                                                                name={index}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={<span className={classes.label}>{result.fase}</span>}
                                                    />
                                                </Paper>
                                            )
                                        }
                                        )
                                    }
                                    <div className="text-right mt-2">
                                        <Button
                                            variant="contained"
                                            className={classes.buttonExportar}
                                            onClick={() => { this.gerarDossie() }}
                                        >
                                            Exportar
                                    </Button>
                                    </div>
                                </div>
                            </React.Fragment>
                        </div>
                    </Fade>
                </Modal>
            </>
        );
    }
}

/**
 * Export do Componente construído utilizando o style customizado
 */
export default withStyles(styles)(ModalDossie);
