import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import InputBase from '@material-ui/core/InputBase';
import { carteiraService } from '../../services';
import Select from 'react-select';
import { pushWarning } from '../../services/notifierService';

const useStyles = theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: "#F5F5F5",
        padding: theme.spacing("15px", "20px", "20px", "20px"),
        borderRadius: "10px",
        fontSize: "13px",
        outline: 'none'
    },
    buttonSim: {
        backgroundColor: "#0091C7",
        color: "white",
        fontSize: "10px",
        width: "90px"
    },
    buttonCancelar: {
        backgroundColor: "#FEFEFE",
        color: "#0091C7",
        fontSize: "10px",
        marginRight: "16px",
        width: "90px"
    },
    contentModal: {
        width: 312,
        backgroundColor: "white",
        padding: 16
    },
    header: {
        marginLeft: 24
    },
    label: {
        color: "#0091C7"
    },
    input: {
        backgroundColor: "#F5F5F5",
        flex: 1,
        width: "100%",
        paddingLeft: 10
    },
    iconButton: {
        padding: 10,
    },
    searchDiv: {
        backgroundColor: "#F5F5F5",
        borderRadius: 10
    }
});


class AdicionarCarteira extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nome: "",
            fluxo: "",
            tipo: "",
            idParametro: 0,
            parametroDescricao: "",
            tipos: [],
            fluxos: []
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.criarCarteira = this.criarCarteira.bind(this);
    }

    componentDidMount() {
        carteiraService.getFluxos().then(response => {
            var fluxos = [];
            response.result.forEach(fluxo => {
                fluxos.push({ ...fluxo, value: fluxo.idFluxo, label: fluxo.descricao })
            });
            this.setState({ fluxos });
        });
        carteiraService.getCarteiraTipo().then(response => {
            var tipos = [];
            if (response.result) {
                response.result.forEach(tipo => {
                    tipos.push({ value: tipo.idCarteiraTipo, label: tipo.descricao })
                });
                this.setState({ tipos });
            }
        });
    }

    handleInputChange(e) {
        const target = e.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        });
    }

    criarCarteira() {
        var novaCarteira = {
            nome: this.state.nome,
            IDCarteiraParametro: this.state.idParametro,
            idFluxo: this.state.fluxo,
            IDCarteiraTipo: this.state.tipo
        };
        if (this.state.nome === "") pushWarning("Preencha o campo nome")
        else if (this.state.fluxo === "") pushWarning("Preencha o campo fluxo")
        else if (this.state.parametroDescricao === "") pushWarning("Preencha o campo parâmetro")
        else if (this.state.tipo === "") pushWarning("Preencha o campo tipo")
        else carteiraService.insCarteira(novaCarteira).then((response) => {
            this.props.closeModal();
            this.props.criouCarteira(response.result.IDCarteira);
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={this.props.open}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.props.open}>
                        <div className={classes.paper}>
                            <React.Fragment>
                                <FontAwesomeIcon icon={faChevronLeft} onClick={() => { this.props.closeModal() }} style={{ cursor: "pointer" }} />
                                <label className={classes.header}>Criar Carteira</label>
                                <div className={classes.contentModal} >
                                    <label className={classes.label}>Nome:</label>
                                    <InputBase
                                        name="nome"
                                        className={classes.input}
                                        placeholder=""
                                        value={this.state.pesquisaProcesso}
                                        onChange={this.handleInputChange}
                                    />
                                    <label className={classes.label}>Fluxo:</label>
                                    <Select
                                        options={this.state.fluxos}
                                        onChange={(val) => {
                                            this.setState({ ...this.state, parametroDescricao: val.parametroDescricao, idParametro: val.idCarteiraParametro },
                                                () => { this.handleInputChange({ target: { name: 'fluxo', value: val.value } }) });
                                        }}
                                        noOptionsMessage={() => { return "Não encontrado"; }}
                                        placeholder="Selecionar"
                                    />
                                    <label className={classes.label}>Parâmetro:</label>
                                    <InputBase
                                        name="parametro"
                                        className={classes.input}
                                        placeholder=""
                                        value={this.state.parametroDescricao}
                                        disabled
                                    />
                                    <label className={classes.label}>Tipo de Carteira:</label>
                                    <Select
                                        options={this.state.tipos}
                                        onChange={(val) => { this.handleInputChange({ target: { name: 'tipo', value: val.value } }) }}
                                        noOptionsMessage={() => { return "Não encontrado"; }}
                                        placeholder="Selecionar"
                                    />
                                    <div className="text-right mt-2">
                                        <Button
                                            style={{ marginLeft: 10 }}
                                            variant="contained"
                                            className={classes.buttonCancelar}
                                            onClick={() => { this.props.closeModal() }}
                                        >
                                            Cancelar
                                    </Button>
                                        <Button
                                            variant="contained"
                                            className={classes.buttonSim}
                                            onClick={(e) => { this.criarCarteira() }}
                                        >
                                            Criar
                                    </Button>
                                    </div>
                                </div>
                            </React.Fragment>
                        </div>
                    </Fade>
                </Modal>
            </>
        );
    }
}

export default withStyles(useStyles)(AdicionarCarteira)
