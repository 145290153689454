import { workFlowConstants } from '../redux/constants';
import { workFlowService } from '../services';
import _ from 'lodash';

export const workFlowActions = {
    getPassos
};

function getPassos(idCarteira) {
    return dispatch => {
        dispatch(request());

        workFlowService.getWorkFlowPassos(idCarteira)
            .then(
                response => {
                    let data = response.result;
                    if (data && data.length > 0) {
                        dispatch(success(data));
                    }
                }
            );
    };
    
    function request() { return { type: workFlowConstants.LISTAPASSOS } }
    function success(passos) { return { type: workFlowConstants.SUCESSO_PASSOS, passos } }
}