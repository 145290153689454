import React, { Component } from 'react';
import { BackGroundLogin } from '../../components/BackGroundLogin/BackGroundLogin';
import './LoginPage.css';
import LoginImg from '../../assets/id_legalflow_login_com_logo.png';
import InputMask from 'react-input-mask';
import ToggleableLink  from '../../components/ToggleableLink/ToggleableLink';
import { ContentLogin }  from '../../components/ContentLogin/ContentLogin';
import { connect } from 'react-redux';

import { usuarioActions } from '../../actions';

class LoginPage extends Component {
    constructor(props) {
        super(props);

        //Sempre que voltar para login sai do sistema
        this.props.logout();
        
        this.state = { cpf: '', senha: '', submitted: false };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ submitted: true });
        const { cpf, senha } = this.state;
        if (cpf && senha) {
            this.props.login(cpf.replace(/[^0-9.-]/g, ''), senha);
        }
    }

    render() {
        const { loading } = this.props;
        const { cpf, senha, submitted } = this.state;

        return (
            <BackGroundLogin>
                <ContentLogin white>
                    <div className="row align-items-center">
                        <div className="col-6 p-0">
                            <img src={LoginImg} className="img-responsive login-img" alt="LoginImg"/>
                        </div>
                        <div className="col-6 body-login">
                            <form onSubmit={this.handleSubmit}>
                                <div className={'form-group text-left' + (submitted && !cpf ? ' has-error' : '')}>
                                    <label htmlFor="cpf">CPF</label>
                                    <InputMask id="cpf" mask="999.999.999-99" name="cpf" className="form-control text-center" disabled={loading} value={cpf} onChange={this.handleChange}></InputMask>
                                </div>
                                <div className="form-group text-left">
                                    <label htmlFor="senha">Senha</label>
                                    <input id="senha" name="senha" type="password" className="form-control text-center" disabled={loading} value={senha} onChange={this.handleChange} />
                                </div>
                                <div className="row">
                                    <div className="col-7 text-left">
                                        <ToggleableLink id="lnkEsqueceuSenha" to="/EsqueceuSenha" disabled={loading}>Esqueceu a senha?</ToggleableLink >
                                    </div>
                                    <div className="col-5 text-right pl-0">
                                        <button id="btnLogin" className="btn btn-primary" type="submit" disabled={loading} >
                                            <span role="status" aria-hidden="true" className={loading ? "spinner-border spinner-border-sm" : "d-none"}></span>
                                            Enviar
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </ContentLogin>
            </BackGroundLogin>
        );
    }
}

function mapState(state) {
    const { loading } = state.authentication;
    return { loading };
}

const actionCreators = {
    login: usuarioActions.login,
    logout: usuarioActions.logout
};

const connectedLoginPage = connect(mapState, actionCreators)(LoginPage);
export { connectedLoginPage as LoginPage };