import styled from 'styled-components';

export const Container = styled.div.attrs({
    className: 'containerChipFilter'
})`
    display: flex;
    flex-wrap: wrap;

    .filterChip{
        color: #2c304d;
        border: none;
        cursor: default;
        height: 30px;
        display: inline-flex;
        padding-left: 8px;
        padding-right: 4px;
        font-size: 0.8125rem;
        align-items: center;
        white-space: nowrap;
        border-radius: 30px;
        vertical-align: middle;
        justify-content: center;
        background-color:#f0f0f0;
        font-weight: 500;
        line-height: 1.1876em;
        letter-spacing: 0.00938em;
        margin-right: 5px;
        font-family: 'Roboto', Arial, Helvetica, sans-serif;
    }

    .filterChave{
        padding-right:3px;
    }
    
    .filterChipRemove {
        color: rgba(0, 0, 0, 0.87);
        margin-left: 5px;
        cursor: pointer;
        padding-top: 2px;
    }

    .filterChipRemove path {
        fill: #737e8a;
    }
    
    .filterChipRemove:hover path{
        fill: #495057;
    }
`;