import React, { useEffect, useState } from 'react';
import PivotGrid, {
    Scrolling
} from 'devextreme-react/pivot-grid';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { locale, loadMessages } from 'devextreme/localization';
import ptMessages from 'devextreme/localization/messages/pt.json';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import ModalConfirm from '../ModalConfirm/ModalConfirm';
import history from '../../services/history';

const useStyles = makeStyles((theme) => ({
    input: {
        fontSize: 10,
        fontFamily: 'Montserrat-Medium',
        marginBottom: 10
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: "#F5F5F5",
        padding: theme.spacing("25px", "25px"),
        borderRadius: "10px",
        fontSize: "13px",
        outline: 'none',
        minWidth: '300px'
    },
    buttonSim: {
        backgroundColor: "#0091C7",
        color: "white",
        fontSize: "10px"
    },
    buttonCancelar: {
        backgroundColor: "rgba(0, 145, 199, 0.65)",
        color: "white",
        fontSize: "10px",
        marginRight: "16px"
    },
    contentModal: {
        backgroundColor: "white",
        padding: 16
    },
    formControl: {
        width: '100%',
        fontSize: 10,
        fontFamily: 'Montserrat-Medium'
    },
    label: {
        fontSize: 10,
        fontFamily: 'Montserrat-Medium'
    },
    grid: {
        fontSize: 10,
        fontFamily: 'Montserrat-Medium'
    }
}));

export const RelatorioPivot = (props) => {
    const classes = useStyles();
    const { data, configurar, pivotGrid, layoutJson, name, campos, options } = props;
    const [dataSource, setDataSource] = useState(null);
    const [lastLoadOptions, setLastLoadOptions] = useState({ columns: [], rows: [], values: [] })
    const [confirmVisualizarProcesso, setConfirmVisualizarProcesso] = useState(false);
    const [modalProps, setModalProps] = useState({tittle: 'Visualizar Processo', Message : ''})
    locale('pt');
    loadMessages(ptMessages);

    useEffect(() => {
        let extraFields = [];
        let fields = _.map(campos, (obj) => {
            let campo = {
                displayFolder: obj.Agrupamento,
                dataField: obj.Campo,
                dataType: obj.Tipo,
                format: obj.Formato,
                // allowSortingBySummary:true,
                // allowSorting:true,
                expanded: true
            };
            if (obj.Tipo === 'date') {
                campo.groupInterval = 'day';
                campo.caption = obj.Campo + ' Dia';

                extraFields.push({
                    displayFolder: obj.Agrupamento,
                    dataField: obj.Campo,
                    expanded: true,
                });
                extraFields.push({
                    groupName: obj.Campo,
                    caption: obj.Campo + 'Mês',
                    groupInterval: 'month',
                    visible: true
                });
                extraFields.push({
                    groupName: obj.Campo,
                    caption: obj.Campo + 'Ano',
                    groupInterval: 'year',
                    visible: true
                });
                extraFields.push({
                    groupName: obj.Campo,
                    groupInterval: 'quarter',
                    visible: false
                });
                extraFields.push({
                    groupName: obj.Campo,
                    groupInterval: 'quarter',
                    visible: false
                });
            }

            return campo;
        });
        fields = _.concat(fields, extraFields);
        fields.push({
            type: 'buttons',
            buttons:
                [{
                    hint: 'Visualizar',
                    visible: true,
                    icon: 'info',
                }]
        })
        setDataSource(new PivotGridDataSource({
            fields: (layoutJson && layoutJson.fields) ? layoutJson.fields : fields,
            store: data
        }));
    }, [data, layoutJson, campos]);


    useEffect(() => {
        if (pivotGrid && pivotGrid.current && pivotGrid.current.instance) {
            if (configurar) {
                pivotGrid.current.instance.getFieldChooserPopup().show()
            }

            _.forEach(options, (opt) => {
                switch (opt.option) {
                    case 'showTotalsPrior':
                        pivotGrid.current.instance.option(opt.option, opt.value ? 'both' : 'none');
                        break;
                    case 'dataFieldArea':
                        pivotGrid.current.instance.option(opt.option, opt.value ? 'column' : 'row');
                        break;
                    case 'rowHeaderLayout':
                        pivotGrid.current.instance.option(opt.option, opt.value ? 'tree' : 'standard');
                        break;
                    case 'fieldPanel.visible':
                        let optionFieldPanel = pivotGrid.current.instance.option('fieldPanel');
                        optionFieldPanel.visible = opt.value;
                        pivotGrid.current.instance.option('fieldPanel', optionFieldPanel);
                        break;
                    default:
                        if (opt.option.indexOf('fieldPanel.') !== -1) {
                            let optionFieldPanel = pivotGrid.current.instance.option('fieldPanel');
                            optionFieldPanel[opt.option.replace('fieldPanel.', '')] = opt.value;
                            pivotGrid.current.instance.option('fieldPanel', optionFieldPanel);
                        } else {
                            pivotGrid.current.instance.option(opt.option, opt.value);
                        }
                        break;
                }
            });

            setTimeout(() => {
                pivotGrid.current.instance.on('contentReady', firstLoad);
            }, 1000);
        }

        function firstLoad() {
            let _lastLoadOptions = pivotGrid.current.instance.getDataSource()._lastLoadOptions;
            lastLoadOptions.values = _lastLoadOptions.values;
            setLastLoadOptions(lastLoadOptions);
            pivotGrid.current.instance.off('contentReady');
            pivotGrid.current.instance.on('contentReady', contentReady);
        }


    }, [dataSource, configurar]);


    function contentReady(e) {
        let _lastLoadOptions = dataSource._lastLoadOptions;
        let opt = null;
        if (lastLoadOptions.values.length !== _lastLoadOptions.values.length) {
            opt = getModified(_lastLoadOptions.values, lastLoadOptions.values);
            if (opt.length === 0) {
                let optCaption = getModified(lastLoadOptions.values, _lastLoadOptions.values);
                let chkField = _.find(campos, { 'Campo': optCaption[0].dataField });
                optCaption[0].summaryType = null;
                optCaption[0].dataType = chkField.Tipo;
                optCaption[0].format = chkField.Formato;
                optCaption[0].caption = chkField.Campo;
            } else if (!opt[0].summaryType) {
                opt[0].summaryType = 'count';
                opt[0].dataType = opt[0].dataType === 'currency' ? 'number' : opt[0].dataType;
                opt[0].format = null;
                opt[0].caption = opt[0].dataField + ' (' + returnTypeSummary('count') + ')';
            }
            lastLoadOptions.values = _lastLoadOptions.values
            setLastLoadOptions(lastLoadOptions)
            dataSource.load();
        }
    }

    function getModified(lastOpts, opts) {
        return _.differenceBy(lastOpts, opts, 'dataField');
    }

    function returnTypeSummary(symmaryType) {
        let retorno = '';
        switch (symmaryType) {
            case 'sum':
                retorno = 'Soma';
                break;
            case 'min':
                retorno = 'Mín';
                break;
            case 'max':
                retorno = 'Max';
                break;
            case 'avg':
                retorno = 'Méd';
                break;
            case 'count':
                retorno = 'Contagem';
                break;
            default:
                retorno = 'Contagem';
                break;
        }
        return retorno;
    }

    function onContextMenuPreparing(e) {
        var dataSource = e.component.getDataSource(),
            sourceField = e.field;
        if (e.field && e.field.area === 'data') {
            if (sourceField) {
                if (sourceField.dataType === 'number' || sourceField.dataType === 'currency') {
                    var menuItems = [];

                    e.items.push({ text: 'Tipo de Agregação', items: menuItems });

                    ['sum', 'avg', 'min', 'max', 'count'].forEach(summaryType => {
                        menuItems.push({
                            text: returnTypeSummary(summaryType),
                            value: summaryType,
                            onItemClick: function (args) {
                                setSummaryType(args, sourceField);
                            },
                            selected: (e.field.summaryType ?? 'count') === summaryType
                        });
                    });
                }
            }
        }
    }

    function setSummaryType(args, sourceField) {
        let chkField = _.find(campos, { 'Campo': sourceField.dataField });
        let formato = {};
        if (args.itemData.value === 'count') {
            formato = {};
        } else if (args.itemData.value === 'avg' && sourceField.dataType === 'number') {
            formato = { "style": "decimal", "maximumFractionDigits": 2, "minimumFractionDigits": 2 };
        } else {
            formato = chkField ? chkField.Formato : sourceField.format;
        }
        dataSource.field(sourceField.index, {
            summaryType: args.itemData.value,
            dataType: args.itemData.value === 'count' ? 'number' : (chkField ? chkField.Tipo : sourceField.dataType),
            format: formato,
            caption: chkField.Campo + ' (' + returnTypeSummary(args.itemData.value) + ')'
        });
        dataSource.load();
    }

    function visualizarProcesso(e) {
        let regex = /\d*\-\d*\.\d*\.\d*.\d*.\d*/;
        if (regex.test(e.cell.text)) {
            setConfirmVisualizarProcesso(true);
            let modal = _.cloneDeep(modalProps);
            modal.message = 'Deseja visualizar o processo ' + e.cell.text + '?';
            
            modal.buttonSimClick = () => { setConfirmVisualizarProcesso(false); window.open('WorkFlow/' + e.cell.text, "_blank"); }
            setModalProps(modal)
        }
    }

    function verificaProcesso(e){
        let regex = /\d*\-\d*\.\d*\.\d*.\d*.\d*/;
        if (e.cell && e.cell.text && regex.test(e.cell.text)) {
            // console.log(e)
            return;
        }
    }

    return (
        <>
            <ModalConfirm
                open={confirmVisualizarProcesso}
                modalProps={modalProps}
                buttonCancelarClick={()=>{setConfirmVisualizarProcesso(false);}}
            />
            <PivotGrid
                id="pivotgrid"
                height='100%'
                dataSource={dataSource}
                ref={pivotGrid}
                export={{
                    enabled: true,
                    fileName: name ?? 'Relatório'
                }}
                fieldChooser={{
                    enabled: configurar,
                    allowSearch: true,
                    allowExpandAll: true
                }}
                showFieldChooser={configurar}
                wordWrapEnabled={false}
                fieldPanel={{
                    allowFieldDragging: configurar,
                }
                }
                className={classes.grid}
                allowExpandAll={true}
                onContextMenuPreparing={onContextMenuPreparing}
                allowSorting={true}
                allowSortingBySummary={true}
                allowFiltering={true}
                onCellClick={visualizarProcesso}
                onCellPrepared={verificaProcesso}
            >
                <Scrolling mode="virtual" />
            </PivotGrid>
        </>);
}