import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: "#F5F5F5",
        padding: theme.spacing("15px", "25px"),
        borderRadius: "10px",
        fontSize:"13px",
        outline: 'none'
    },
    buttonSim: {
        backgroundColor: "#0091C7",
        color: "white",
        fontSize:"10px"
    },
    buttonCancelar:{
        backgroundColor: "rgba(0, 145, 199, 0.65)",
        color: "white",
        fontSize:"10px",
        marginRight:"10px"
    },
    contentModal:{
        backgroundColor: "white",
        padding:16
    }
}));

export default function ModalConfirm(props) {
    const classes = useStyles();
    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={props.open}
                // onClose={props.OnClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classes.paper}>
                        <React.Fragment>
                            <label>{props.modalProps.tittle}</label>
                            <div className={classes.contentModal} >
                                {props.modalProps.message}
                                <div style={{ textAlign: "center", marginTop: "5px" }}>
                                    {props.buttonCancelarClick && 
                                    <Button
                                    style={{ marginLeft: 10 }}
                                    variant="contained"
                                    className={classes.buttonCancelar}
                                    onClick={(e) => { props.buttonCancelarClick() }}
                                    >
                                        Cancelar
                                    </Button>
                                    }                                    
                                    {props.btnNaoClick && 
                                    <Button
                                    style={{ marginRight: 10 }}
                                    variant="contained"
                                    className={classes.buttonSim}
                                    onClick={(e) => { props.modalProps.buttonNaoClick() }}
                                    >
                                        Não
                                    </Button>}
                                    
                                    <Button
                                        variant="contained"
                                        className={classes.buttonSim}
                                        onClick={(e) => { props.modalProps.buttonSimClick() }}
                                    >
                                        Sim
                                    </Button>
                                </div>
                            </div>
                        </React.Fragment>
                    </div>
                </Fade>
            </Modal>
        </>
    );
}
