import { processosConstants } from '../constants';

const inicialState = { loading: false, processos: [], processoFundo: [], count: 0, filtro: { FlagTodos: 2, inativoChecked: false }, selecionados: [], acoes: [], atualizacao: new Date(), reload: new Date() };

const processos = (state = inicialState, action) => {
    switch (action.type) {
        case processosConstants.SUCESSO:
            return {
                ...state,
                loading: false,
                processos: action.processos,
                count: action.count,
                filtro: action.filtro,
                selecionados: [],
                acoes: []
            };
        case processosConstants.FALHA:
            return {
                ...state,
                loading: false,
                processos: []
            };
        case processosConstants.LISTA:
            return {
                ...state,
                loading: true
            };
        case processosConstants.PROXIMA_PAGINA:
            return {
                ...state,
                loading: false,
                processos: [...state.processos, ...action.processos]
            };
        case processosConstants.INICIA_STATE:
            return {
                ...inicialState,
                filtro: action.filtro ?? inicialState.filtro
            };
        case processosConstants.ATUALIZA_PROCESSOS_SELECIONADOS:
            return {
                ...state,
                selecionados: action.selecionados,
                processos: action.processos
            };
        case processosConstants.SUCESSO_ACOES_PROCESSO:
            return {
                ...state,
                acoes: action.acoes,
            }
        case processosConstants.FALHA_ACOES_PROCESSO:
            return {
                ...state,
                acoes: [],
                selecionados: []
            }
        case processosConstants.ATUALIZA_PROCESSOS:
            return {
                ...state,
                atualizacao: new Date()
            }
        case processosConstants.SUCESSO_PROCESSO_FUNDO:
            return {
                ...state,
                loading: false,
                processoFundo: action.processoFundo,
                count: action.count
            };
        case processosConstants.PROXIMA_PAGINA_FUNDO:
            return {
                ...state,
                loading: false,
                processoFundo: [...state.processoFundo, ...action.processoFundo]
            };
        case processosConstants.RECARREGA_PROCESSOS:
            return {
                ...state,
                reload: new Date()
            }
        default:
            return state
    }
}

export default processos;