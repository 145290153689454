import React, { Component } from 'react';
import { Header } from '../../components/Header/Header';
import { SideBar } from '../../components/SideBar/SideBar';

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div className="page" id="wrapper" >
        <div id="page-content-wrapper" className="container-fluid pl-0">
          <Header />
          <hr className="mb-0 mt-0" />
          <div className="content-right">
            <div>
              {this.props.children}
            </div>
          </div>
          <div className="sidebar-left">
            <SideBar />
          </div>
        </div>
      </div>
    );
  }
}
