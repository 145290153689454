import React, { Component } from 'react';
import './Search.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChipFilter } from "../ChipFilter/index";
import { Autocomplete } from "../Autocomplete/index";
import { connect } from 'react-redux';
import { filtroActions } from '../../actions';
import _ from 'lodash';

class Search extends Component {

    constructor(props) {
        super(props);
        this.state = {
            objValor: {},
            buttonClicked: false,
            camposPesquisados: []
        };
    }

    componentWillUnmount() {
        this.props.limparTudo();
    }

    //retorna valor digitado no input, neste exemplo é utilizado para setar as sugestões 
    handleInputReturn = (obj) => {
        let campoPesquisado = _.find(this.props.filtros.campos, (campo) => { return campo.cod === obj.cod });
        let chave = '';
        let objValor = {};
        if (campoPesquisado) {
            objValor = {
                id: campoPesquisado.cod,
                chave: campoPesquisado.desc,
                valor: obj.textoEntrada,
                nomeCampo: campoPesquisado.desc,
                sp: campoPesquisado.sp,
                tipo: campoPesquisado.tipo,
                dsc: campoPesquisado.dsc
            };
        }
        this.setState({ objValor });
    }

    handleResult = obj => {
        let campos = _.cloneDeep(this.props.filtros.campos);
        let filtros = _.cloneDeep(this.props.filtros.filtros);
        let camposPesquisados = _.cloneDeep(this.state.camposPesquisados);
        let campoPesquisado = _.remove(campos, (item) => { return item.cod === obj.chave_id });
        camposPesquisados = _.concat(camposPesquisados, campoPesquisado);
        filtros.push({
            id: obj.chave_id,
            chave: obj.chave_desc,
            valor: obj.valor_desc,
            nomeCampo: campoPesquisado[0].desc,
            sp: campoPesquisado[0].sp,
            tipo: campoPesquisado[0].tipo,
            dsc: campoPesquisado[0].dsc
        });

        this.setState({ objValor: {}, camposPesquisados });
        this.props.alterarFiltros(filtros);
        this.props.alterarCampos(campos);
    }

    handleRemoveItem = filtros => {
        let campos = _.cloneDeep(this.props.filtros.campos);
        let camposPesquisados = _.cloneDeep(this.state.camposPesquisados);
        let filtroRemovido = _.remove(camposPesquisados, (value) => { return _.findIndex(filtros, (f) => { return f.id === value.cod }) === -1 });
        campos = [...campos, ...filtroRemovido];
        this.props.alterarFiltros(filtros);
        this.props.alterarCampos(campos);
        this.setState({ objValor: {}, camposPesquisados });
    }

    btnPesquisa = () => {
        if (this.state.objValor.hasOwnProperty('chave')) {
            let campos = _.cloneDeep(this.props.filtros.campos);
            let filtros = _.cloneDeep(this.props.filtros.filtros);
            let camposPesquisados = _.cloneDeep(this.state.camposPesquisados);
            let campoPesquisado = _.remove(campos, (item) => { return item.cod === this.state.objValor.id });
            camposPesquisados = _.concat(camposPesquisados, campoPesquisado);
            filtros.push({
                id: this.state.objValor.id,
                chave: this.state.objValor.chave,
                valor: this.state.objValor.valor,
                nomeCampo: campoPesquisado[0].desc,
                sp: campoPesquisado[0].sp,
                tipo: campoPesquisado[0].tipo,
                dsc: campoPesquisado[0].dsc
            });

            this.setState({ objValor: {}, camposPesquisados });
            this.props.alterarFiltros(filtros);
            this.props.alterarCampos(campos);
        } else {
            this.props.botaoPesquisarClique();
        }
        this.setState({ buttonClicked: true });
        this.setState({ objValor: {} });
    }

    ClickButtonFalse = () => {
        this.setState({ buttonClicked: false });
    }

    render() {
        const campos = _.cloneDeep(this.props.filtros.campos);
        _.remove(campos, (campo) => {
            return _.findIndex(this.props.filtros.filtros, (filtro) => { return filtro.id === campo.cod }) !== -1;
        });
        return (
            <>
                {campos && campos.length > 0 && 
                    <div className="form-inline vertical-center SearchDiv">
                        <label className="mr-1 flex-div-1">Pesquisar: </label>
                        <Autocomplete
                            listaFiltro={campos}
                            listaSugestoes={this.state.listaSugestoes}
                            onRetornoBusca={e => this.handleResult(e)}
                            limpaFiltroAoBuscar={true}
                            LimpaClickButton={this.state.buttonClicked}
                            limpaClickButtonRetorno={this.ClickButtonFalse}
                            onRetornoInput={this.handleInputReturn}
                        />
                        <label className="icon-search flex-div-3" onClick={e => { this.btnPesquisa() }}><FontAwesomeIcon icon={['fa', 'search']} style={{ marginLeft: 2, cursor: "pointer" }} /></label>
                        <div style={{ display: 'flex' }}>
                            {this.props.filtros.filtros.length > 0 ? <ChipFilter datalist={[{ chave: 'Limpar', valor: 'Todos' }]} onReturnList={e => this.handleRemoveItem(e)} /> : ''}
                            <ChipFilter datalist={this.props.filtros.filtros} onReturnList={e => this.handleRemoveItem(e)} />
                        </div>
                    </div>
                }
            </>
        );
    }
}

function mapState(state) {
    const { carteiras, idCarteira } = state.carteiras;
    const filtros = state.filtros;
    return { carteiras, idCarteira, filtros };
}

const actionCreators = {
    alterarFiltros: filtroActions.alterarFiltros,
    alterarCampos: filtroActions.alterarCampos,
    limparTudo: filtroActions.limparTudo,
    botaoPesquisarClique: filtroActions.botaoPesquisarClique
};


const connectedSearch = connect(mapState, actionCreators)(Search);
export { connectedSearch as Search };