import axiosInstance from '../core/httpInterceptor';

export const usuarioService = {
    login,
    esqueceuSenha,
    logout,
    novaSenha,
    getUsuariosAtivos,
    alterarUsuario,
    getUsuariosPorProcessoFase,
    getUsuarios,
    atualizarUsuarios,
    InsUsuario
};

function login(login, password) {
    let data = JSON.stringify({ login: login, senha: password });
    return axiosInstance.post('/Login', data);
}

function esqueceuSenha(login) {
    let data = JSON.stringify({ cpf: login });
    return axiosInstance.post('/Login/EsqueceuSenha', data);       
}

function novaSenha(usuario) {
    return axiosInstance.post('/Login/NovaSenha', usuario);
}

function logout() {
    localStorage.removeItem('authentication');
}

function getUsuariosAtivos(){
    return axiosInstance.get('/Usuario/GetUsuariosAtivos');
}

function alterarUsuario(usuario){    
    return axiosInstance.post('/Usuario/atualizarUsuario', usuario);
}

function getUsuariosPorProcessoFase(processos){
    return axiosInstance.get('/Usuario/GetUsuariosPorProcessoFase/'+ processos);
}

function getUsuarios(paginacao, filtroPesquisa){
    return axiosInstance.post('/Usuario/GetUsuarios',{paginacao, filtroPesquisa});       
};

function atualizarUsuarios(usuario){    
    return axiosInstance.post('/Usuario/atualizarUsuarios', usuario);
}

function InsUsuario(usuario){
    return axiosInstance.post('/Usuario/atualizarUsuario', usuario);       
};
