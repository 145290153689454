import axiosInstance from '../core/httpInterceptor';

export const carteiraService = {
    getCarteiras,
    insProcessosCarteira,
    getFluxos,
    getCarteiraTipo,
    insCarteira,
    insProcessosTrabalhista
};

function getCarteiras(paginacao, filtro, idCarteiraProcesso = null, filtroPesquisa = []) {
    let flagTodos = filtro.FlagTodos ? filtro.FlagTodos : filtro.flagTodos;
    let data = { FlagTodos: parseInt(flagTodos), idCarteiraProcesso: (idCarteiraProcesso == null ? idCarteiraProcesso : parseInt(idCarteiraProcesso)), paginacao, filtroPesquisa, chkInativa: filtro.inativaChecked };
    return axiosInstance.post('/Carteira/GetCarteiras', data);
};

function insProcessosCarteira(idCarteira, processos) {
    return axiosInstance.post('/Carteira/InsProcessosCarteira', { idCarteira, processos });
}

function getFluxos() {
    return axiosInstance.get('/Carteira/GetFluxos');
}

function getCarteiraTipo() {
    return axiosInstance.get('/Carteira/GetCarteiraTipo');
}

function insCarteira(carteira) {
    return axiosInstance.post('/Carteira/InsCarteira', carteira);
}

function insProcessosTrabalhista(processo) {
    return axiosInstance.post('/Carteira/InsProcessoTrabalhista', { processo });
}