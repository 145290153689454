import { usuariosConstants } from '../constants';

let auth = JSON.parse(localStorage.getItem('authentication'));
const initialState = auth ? { loggedIn: true, usuario: auth.usuario, permissoes: auth.permissoes, loading: false } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case usuariosConstants.LOGIN:
      return {
        ...state,
        loading: true,
        usuario: action.usuario
      };
    case usuariosConstants.LOGIN_SUCESSO:
      return {
        ...state,
        loading: false,
        usuario: action.usuario,
        permissoes: action.permissoes
      };
    case usuariosConstants.LOGIN_FALHA:
      return {
        loading: false,
      };
    case usuariosConstants.LOGOUT:
      return {
        loading: false,
      };
    case usuariosConstants.ATUALIZAR_USUARIO_SUCESSO:
      return {
        ...state,
        loading: false,
        usuario: action.usuario
      };
    default:
      return state
  }
}