import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import MaskedInput from 'react-text-mask';
import Grid from '../Grid/Grid';
import { columnsDefinition } from './Columns';
import { processoService, carteiraService } from '../../services';
import ModalConfirm from '../../components/ModalConfirm/ModalConfirm';
import { pushWarning } from '../../services/notifierService';

const useStyles = theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: "#F5F5F5",
        padding: theme.spacing("15px", "20px", "20px", "20px"),
        borderRadius: "10px",
        fontSize: "13px",
        outline: "0px",
        '&$focused': {
            outline: "0px"
        },
    },
    buttonSim: {
        backgroundColor: "#0091C7",
        color: "white",
        fontSize: "10px",
        width: "90px"
    },
    buttonCancelar: {
        backgroundColor: "#FEFEFE",
        color: "#0091C7",
        fontSize: "10px",
        marginRight: "16px",
        width: "90px"
    },
    contentModal: {
        width: 312,
        backgroundColor: "white",
        padding: 16
    },
    header: {
        marginLeft: 24
    },
    label: {
        color: "#0091C7"
    },
    input: {
        backgroundColor: "#F5F5F5",
        flex: 1,
        width: "236px",
        marginLeft: 5,
        paddingLeft: 20
    },
    iconButton: {
        padding: 10,
    },
    searchDiv: {
        backgroundColor: "#F5F5F5",
        borderRadius: 10
    }
});

class AdicionarProcessos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            processos: [],
            processoTrabalhista: "",
            pesquisaProcesso: "",
            modalConfirm: {
                abrirModal: false,
                tittle: "Inclusão de Processos",
                message: "",
            },
            modalConfirmTrabalhista: {
                abrirModal: false,
                tittle: "Inclusão de Processos",
                message: "",
                buttonNaoClick: () => { }
            }
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.buscaProcesso = this.buscaProcesso.bind(this);
        this.incluirProcessos = this.incluirProcessos.bind(this);
        this.incluirProcessoTrabalhista = this.incluirProcessoTrabalhista.bind(this);
        this.deleteClick = this.deleteClick.bind(this);
        this.adicionarProcessos = this.adicionarProcessos.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.closeModalTrabalhista = this.closeModalTrabalhista.bind(this);
    }

    componentDidMount() {
        columnsDefinition[1].props.onClick = this.deleteClick;
    }

    deleteClick(props) {
        if (props.index !== undefined) {
            let processos = this.state.processos ?? [];
            processos.splice(props.index, 1);
            this.setState({
                processos
            });
        }
    }

    adicionarProcessos() {
        this.setState({
            modalConfirm: {
                abrirModal: true,
                tittle: "Incluir Processos",
                message: `Você tem certeza que deseja incluir ${this.state.processos.length} processos?`,
                buttonSimClick: this.incluirProcessos
            }
        });
    }

    incluirProcessos() {
        let processos = [];
        this.state.processos.forEach((processo) => {
            processos.push(processo.idProcesso);
        });
        carteiraService.insProcessosCarteira(this.props.idCarteira, processos).then(
            () => {
                this.closeModal();
                this.props.adicionouProcessos();
            }
        )
    }

    incluirProcessoTrabalhista() {
        carteiraService.insProcessosTrabalhista(this.state.processoTrabalhista).then(
            response => {
                var processos = this.state.processos;
                processos.push({processoNumero: this.state.processoTrabalhista, idProcesso: response.result.IDProcesso});
                this.setState({
                    processos,
                    pesquisaProcesso: "",
                    modalConfirmTrabalhista: {
                        abrirModal: false,
                        tittle: "",
                        message: ""
                    }
                });
            }            
        )
    }

    handleInputChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    maskedProcesso(props) {
        const { inputRef, ...other } = props;
        return (<>
            <MaskedInput
                {...other}
                ref={(ref) => {
                    inputRef(ref ? ref.inputElement : null);
                }}
                placeholder="0000023-50.2013.5.02.0311"
                mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, '.', /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
            />
        </>);
    }

    closeModal() {
        this.setState({
            processos: [],
            pesquisaProcesso: "",
            modalConfirm: {
                abrirModal: false,
                tittle: "",
                message: ""
            },

        });
        this.props.closeModal();
    }

    closeModalTrabalhista() {
        this.setState({
            modalConfirmTrabalhista: {
                abrirModal: false,
                tittle: "",
                message: ""
            },
        });
    }

    buscaProcesso() {
        let jaExiste = false;
        this.state.processos.forEach((processo) => {
            if (processo.processoNumero === this.state.pesquisaProcesso) {
                jaExiste = true;
                pushWarning("Este processo já esta na lista de inclusão")
            }
        });
        if (!jaExiste) {
            processoService.getProcesso(this.state.pesquisaProcesso).then(
                response => {
                    if (response.result && response.result.length > 0) {
                        var processos = this.state.processos;
                        processos.push(response.result[0]);
                        this.setState({ processos, pesquisaProcesso: "" });
                    } else if (this.state.pesquisaProcesso.replace('_','').length === 25 && response.messages.length === 0) {
                        this.setState({
                            processoTrabalhista: this.state.pesquisaProcesso,
                            modalConfirmTrabalhista: {
                                abrirModal: true,
                                tittle: "",
                                message: `Processo não encontrado. Deseja incluir mesmo assim?`,
                                buttonSimClick: this.incluirProcessoTrabalhista,
                                buttonNaoClick: this.closeModalTrabalhista
                            }
                        });
                    } else if (response.result.length === 0 && response.messages.length === 0) {
                        pushWarning('Nenhum PROCESSO encontrado.');
                    }
                });
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={this.props.open}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.props.open}>
                        <div className={classes.paper}>
                            <React.Fragment>
                                <FontAwesomeIcon icon={faChevronLeft} onClick={() => { this.props.closeModal() }} style={{ cursor: "pointer" }} />
                                <label className={classes.header}>Inclusão de Processos</label>
                                <div className={classes.contentModal} >
                                    <label className={classes.label}>Número do Processo:</label>
                                    <div className={classes.searchDiv}>
                                        <InputBase
                                            name="pesquisaProcesso"
                                            className={classes.input}
                                            placeholder=""
                                            inputComponent={this.maskedProcesso}
                                            value={this.state.pesquisaProcesso}
                                            onChange={this.handleInputChange}
                                        />
                                        <IconButton onClick={this.buscaProcesso} className={classes.iconButton} aria-label="search">
                                            <FontAwesomeIcon icon={["fal", "search"]} />
                                        </IconButton>
                                    </div>
                                    <div className="text-center mt-1" style={{ height: 272, overflowY: "auto" }}>
                                        <label>Processos</label>
                                        <Grid data={this.state.processos}
                                            style={{ width: '100%', fontSize: 10 }}
                                            columns={columnsDefinition}
                                            minPage={1}
                                            pageSize={this.state.processos.length}
                                            getTrProps={(state, rowInfo) => ({
                                                style: {
                                                    background: '#FFFFFF',
                                                },
                                            })}

                                        />
                                    </div>
                                    <div className="text-right mt-2">
                                        <Button
                                            style={{ marginLeft: 10 }}
                                            variant="contained"
                                            className={classes.buttonCancelar}
                                            onClick={() => { this.closeModal() }}
                                        >
                                            Cancelar
                                        </Button>
                                        <Button
                                            variant="contained"
                                            className={classes.buttonSim}
                                            onClick={this.adicionarProcessos}
                                            disabled={this.state.processos.length === 0}
                                        >
                                            Incluir
                                        </Button>
                                    </div>
                                </div>
                            </React.Fragment>
                        </div>
                    </Fade>
                </Modal>
                {this.state.modalConfirm.abrirModal &&
                    <ModalConfirm open={this.state.modalConfirm.abrirModal} modalProps={this.state.modalConfirm} buttonCancelarClick={() => { this.closeModal() }} ></ModalConfirm>
                }
                {this.state.modalConfirmTrabalhista.abrirModal &&
                    <ModalConfirm open={this.state.modalConfirmTrabalhista.abrirModal} modalProps={this.state.modalConfirmTrabalhista} btnNaoClick={true} ></ModalConfirm>
                }
            </>
        );
    }
}

export default withStyles(useStyles)(AdicionarProcessos)