import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Grid, Paper, Tooltip } from "@material-ui/core";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { notificacaoService } from '../../services/notificacaoService';

const useStyles = makeStyles((theme) => ({
    paper: {
        paddingRight: 15,
        paddingBottom:15,
        paddingTop:15,
        width: "100%",
        boxShadow: "0px 0px 0px 0px",
        cursor:"pointer"
    },
    headerName: {
        fontSize: "10px"
    },
    headerDate: {
        fontSize: "10px",
        color: "rgba(60, 60, 67, 0.6)",
        whiteSpace: "nowrap"
    },

}));

export const NotificacaoBox = (props) => {
    const { data, mensagem, icone, onClick, cor, titulo, dtLeitura, idNotificacao, idNotificacaoUsuario, idUsuario } = props;
    const classes = useStyles();

    const MarcarComoLido = (idNotificacao, idNotificacaoUsuario, idUsuario, dtLeitura = null) => {
        let obj = {
            dtLeitura: null,
            idNotificacao, 
            idNotificacaoUsuario, 
            idUsuario
        }
        if(!dtLeitura){
            notificacaoService.updateDtLeitura([obj]).then(() => {
                props.atualizaNotificacao();
                props.recarregaNotificacoes();
            });
        }        
    }

    return (
        <>
            <Paper className={classes.paper} >

                <Grid container wrap="nowrap" spacing={2}  alignItems="center" alignContent="center"
  justify="center">
                    <Grid item>
                        {icone &&
                            <FontAwesomeIcon icon={['fa', icone]} style={{fontSize:20, color: cor}} onClick={function(event){onClick();MarcarComoLido(idNotificacao, idNotificacaoUsuario, idUsuario, dtLeitura)}}/>
                        }
                    </Grid>
                    <Grid container >
                        <Grid item xs={6}>
                            <label className={classes.headerName}>{titulo}</label>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                            <label className={classes.headerDate}>{moment(data).format('DD/MM/YY HH:mm')}</label>
                        </Grid>
                        <p style={{ textAlign: "justify", fontSize: 10, color: "rgba(60, 60, 67, 0.6)" }} dangerouslySetInnerHTML={{ __html: mensagem }} onClick={function(event){onClick();MarcarComoLido(idNotificacao, idNotificacaoUsuario, idUsuario, dtLeitura)}}>
                        </p>
                        <Grid item xs={12} style={{ textAlign: "right" }}>
                        <Tooltip title={dtLeitura ? 'Alerta Lido' : 'Marcar como lido'} aria-label={dtLeitura ? 'Alerta Lido' : 'Marcar como lido'} >
                        <span><FontAwesomeIcon icon={['fa', 'check']} style={{fontSize:20, color: dtLeitura ? '#33ccff' : ''}} onClick={dtLeitura ? null: () => MarcarComoLido(idNotificacao, idNotificacaoUsuario, idUsuario)}/></span>
                        </Tooltip>
                        </Grid>
                        
                    </Grid>
                    
                </Grid>
                <Divider variant="fullWidth" style={{ marginTop: 15 }} />
            </Paper>
        </>
    )
}