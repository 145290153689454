import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import FaseViewer from '../../components/FaseViewer/FaseViewer';

export class DossiePage extends Component {
    render() {
        /**
          * Função que verifica se os dados da fase estão preenchidos
          * @param {*} dadosCampos 
          */
        function validateCollapseData(dadosCampos, index) {
            for (const dados of dadosCampos[index]) {
                if (dados.valor !== null) {
                    return true;
                }
            }
            return false;
        }

        return (
            <div>
                <div className="modal-grey-background">
                    <div>
                        {this.props.checkboxes.dadosProcesso &&
                            <div>
                                <Typography className="expansion-panel-title">
                                    {'Dados do Processo'}
                                </Typography>
                                <div className="row">
                                    {this.props.detalhes}
                                </div>
                            </div>
                        }
                        {this.props.checkboxes.partesProcesso &&
                            <div>
                                <Typography className="expansion-panel-title">
                                    {'Partes do Processo'}
                                </Typography>
                                <div className="row">
                                    {this.props.partes}
                                </div>
                            </div>
                        }
                        {this.props.checkboxes.movimentacoes &&
                            <div style={{pageBreakBefore:'always'}}>
                                <Typography className="expansion-panel-title">
                                    {'Movimentações'}
                                </Typography>
                                <div className="row">
                                    {this.props.movimentacoes}
                                </div>
                            </div>
                        }
                        <div style={{pageBreakBefore:'always'}}>
                        {
                            //Inject dos fases customizados (conforme as fases executadas do Processo)
                            this.props.fases.map((result, index) =>

                                <div key={index}>
                                    <Typography className="expansion-panel-title-enriquecido">
                                        {result.fase}
                                    </Typography>
                                    {
                                        (this.props.dadosCampos !== undefined && this.props.dadosCampos[index] !== undefined && this.props.dadosCampos[index].length > 0) ?
                                            (validateCollapseData(this.props.dadosCampos, index)) ?
                                                <FaseViewer data={this.props.dadosCampos[index]} style={{ fontFamily: 'Montserrat', color: '#5d5d5d' }} pdfViewer={true} />
                                                : result.fase + " não preenchido"
                                            :
                                            "Carregando..."
                                    }
                                </div>
                            )
                        }
                        </div>

                    </div>
                </div >
            </div >
        );
    }
}