import { Center } from "devextreme-react/map";

export const columnsDefinition= 
[
    {
        "column": "processoCorHexadecimal",
        "title": "Número do Processo",
        "render": "ColumnWithColorAndCheckbox",
        "props": {
            "color": "rgb(245, 114, 114)",
            "text_column":  "processoNumero",
            "check_column": "idProcesso",
            "selected":0,
            "onupdate": (props)=>{
            },
        },
        "style": {
            "padding": "0 8px 0 0",
            "height": "100%",
            "minHeight": "40px",
        },
        "height":20,
        "width": 175,
        "headerClassName": "text-left pl-5",
    },
    {
        "column": "statusProcesso",
        "title": "Fase",
        "headerClassName": "text-left",
        "render": "Div",
    },
    {
        "column": "observacao",
        "title": "Observação",
        "render": "ToolTip",
        "headerClassName": "text-left",
        "style": {
            "minWidth": "200px",   
            "width" : 200,
        },
        "width" : 200,
    },
    {
        "column": "nomeUsuarioCompra",
        "title": "Resp.",
        "render": "Avatar",
        "width":60,
        "height":40,
        "className": "text-center",
        "headerClassName": "text-left",
        "props": {
            "tooltip_empty":"Sem Responsável",
            "imagem_usuario_compra": "imagemUsuarioCompra"
        },
    },
    {
        "column": "valorSentenca",
        "title": "Valor",
        "render": "Money",
        "headerClassName": "text-left",
    },
    {
        "column": "parcelas",
        "title": "Parcelas",
        "render": "Div",
        "headerClassName": "text-left",
        "width":60,
        "style": {
            "textAlign": "center",
        },
    },
    // {
    //     "column": "varaAbreviacao",
    //     "title": "Vara",
    //     "headerClassName": "text-left"
    // },
    // {
    //     "column": "tribunalAbreviacao",
    //     "title": "Tribunal",
    //     "headerClassName": "text-left"
    // },
    {
        "column": "dtEvento",
        "title": "Data Atualização",
        "render": "DateTime",
        "headerClassName": "text-left",
        "style": {
            "width": "20px",
        },
    },
    {
        "column": "imagem",
        "title": "Pend.",
        "headerClassName": "text-left",
        "width": 40,
        "render":"IconToolTip",     
        "props": {
            "tooltip":"nomeUsuarioPendencia",
            "color": "#CCCC00"
        }
    },
    {
        "column": "qtdComentario",
        "title": "#",
        "className": "text-center",
        "width": 40,
        "render": "IconCount",
        "props": {
            "icon" : "comment",
            "tooltip": "Qtd. Comentário(s) não lido(s)"
        }
    },
    {
        "column": "reclamada",
        "title": "Reclamada",
        "headerClassName": "text-left",
        "render": "Div",
    },
    {
        "column": "reclamante",
        "title": "Reclamante",
        "headerClassName": "text-left",
        "render": "Div",
    },
    {
        "column": "advogados",
        "title": "Advogados",
        "headerClassName": "text-left",
        "render": "Div",
    },
];
