import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Button from '@material-ui/core/Button';
import SelectWithImage from '../SelectWithImage/SelectWithImage';
import { usuarioService } from '../../services/usuarioService';
import { processoService } from '../../services/processoService';
import { withStyles } from "@material-ui/core/styles";
import { pushWarning } from '../../services/notifierService';
import './ModalMudarResponsavel.css';

/**
 * Objeto style para ser consumido pelo Dialog
 */
const styles = {
    rootStyle: {
        borderRadius: 15
    },
    overflow:{
        overflow: 'visible'
    } 
};

const selectStyles = {
    valueContainer: base => ({
        ...base,
        height: 35,
        minHeight: 35
      })
}

/**
 * Componente que realiza a abertura das informações do proceso dentro de um Modal responsivo
 * @param {int} processoId
 * @since 27/05/2020
 */
class ModalMudarResponsavel extends Component {

    /**
     * Construtor do componente
     */
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            responsavel:"",
            responsaveis: []
        };
        this.handleOpen = this.handleOpen.bind(this);
    }

    /**
     * Realiza a abertura do Modal e chama o load para as informações básicas
     */
    handleOpen = () => {
        this.setState({ open: true });
    }

    /**
     * Função que trata a alteração de valor do Select com Avatar
     * @param {*} e 
     */
    handleInputChange = (value) => {
        this.setState({
            responsavel: value
        });
    }

    /**
     * 
     */
    componentDidMount = () => {
        // VAZIO
    }

    /**
     * Método responsável por carregar as informações iniciais
     * do processo
     */
    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if((prevProps.processosId === undefined && prevProps.processosId !== this.props.processosId) && !prevState.open){
            
            //deixando o modal visível
            this.setState({open:true});

            //Pegando e preenchendo o cabeçalho do processo
            usuarioService.getUsuariosPorProcessoFase(this.props.faseId).then(response => {
                var responsaveis = [];
                
                response.result.forEach(responsavel => {
                    responsaveis.push({ value: responsavel.iD_Usuario, label: responsavel.nome, image: responsavel.imagem })
                });
                this.setState({ responsaveis });
            });
        }
    }

    componentWillUnmount = () => {
        //VAZIO
    }

    /**
     * Função responsável por atualizar o responsável do processo
     */
    trocaResponsavel(){
        if(this.state.responsavel === ""){
            pushWarning("Preencha o campo Novo Responsável");
        } else {
            //Enviando requisição de atualização do responsável
            processoService.updateProcessoResponsavel(this.props.processosId, this.state.responsavel).then(response => {
                this.props.adicionouProcessos();
                this.handleClose(true);
            });
        }
    }

    /**
     * Finaliza o modal, liberando os objetos instanciados
     */
    handleClose(){
        this.setState({open:false},()=>this.props.onClose());
    }

    render() {
        const { classes} = this.props;

        return (
            <Dialog
                fullWidth={true}
                maxWidth={"xs"}
                open={this.state.open}
                onClose={e => this.handleClose()}
                aria-labelledby="responsive-dialog-title"
                classes={{
                    paper: classes.rootStyle,
                    paperScrollPaper: classes.overflow
                }}
                
            >
                <div className="modal-grey-background">
                    <DialogTitle id="responsive-dialog-title" className="modal-title" onClick={e => this.handleClose()}>
                        <ArrowBackIosIcon fontSize="small" />
                        Mudar Responsável
                    </DialogTitle>
                    <div className="teste">
                        <DialogContent className={classes.overflow}>
                            <label className={classes.label}>Novo Responsável:</label>
                            <SelectWithImage 
                                options={this.state.responsaveis} 
                                onChange={this.handleInputChange} 
                                noOptionsMessage= "Não encontrado"
                                placeholder="Selecionar"
                            />
                            <div className="btn-container">
                                <Button
                                    className="btn-cancel"
                                    variant="contained"
                                    onClick={() => {this.handleClose() }}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    variant="contained"
                                    className="btn-confirma"
                                    onClick={() => {this.trocaResponsavel() }}
                                >
                                    Mudar
                                </Button>
                                </div>
                        </DialogContent>
                    </div>
                </div>
            </Dialog>
        );
    }
}

/**
 * Export do Componente construído utilizando o style customizado
 */
export default withStyles(styles)(ModalMudarResponsavel);
