import React, { useState, useEffect, useRef } from "react";

// Components
import { Modifier, EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import DraftPasteProcessor from 'draft-js/lib/DraftPasteProcessor';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./WYSIWYG.css";

const WYSIWYGEditor = props => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorStateClick, setEditorStateClick] = useState(EditorState.createEmpty());
  const { dataUsuarios, submitBtn, editorStateClicked } = props;
  const editor = useRef();

  const onEditorStateChange = editorState => {
    setEditorState(editorState);    
    // console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    return props.onChange(
        
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };

  useEffect(() => {
    if(submitBtn){
          EditorState.push(editorState, ContentState.createFromText(''))
          setEditorState(EditorState.createEmpty())
      }
  },[submitBtn]);

  useEffect(() => {
    editor.current.focusEditor();
    if(editorStateClicked){
        let contentState = editorState.getCurrentContent();
        let targetRange = editorState.getSelection();
        let newContentState = Modifier.insertText(
          contentState, targetRange, '@'
        );
        setEditorState(EditorState.push(
          editorState,
          newContentState
        ));
        setTimeout(() => {
          editor.current.focusEditor();
        }, 200);
      }
  },[editorStateClicked]);

  function clickEditor() {
    editor.current.focusEditor();
  }

  return (
    <React.Fragment>
      <div className="editor" onClick={clickEditor}>
        <Editor
          editorState={editorState}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          onEditorStateChange={onEditorStateChange}
          toolbar={
                            {
                                options: ['inline','colorPicker','list'],
                                inline: {
                                inDropdown: false,
                                className: undefined,
                                component: undefined,
                                dropdownClassName: undefined,
                                options: ['bold', 'italic', 'underline'],                                                            
                            },
                            }
                        }
                        mention={{
                        separator: ' ',
                        trigger: '@',
                        suggestions: dataUsuarios,
                        }}
                        ref={editor}
        />
      </div>
    </React.Fragment>
  );
};

export default WYSIWYGEditor;
