export const columnsDefinition= 
[
    {
        "column": "processoNumero",
        "title": "",
        "headerClassName": "text-center",
        "width": 250
    },
    {
        "column": "IDProcesso",
        "title": "",
        "render": "Icon",
        "props": {
            "icon" : "trash-alt",
            onClick: (props)=>{
            },
        },
        "width": 20
    }
];
