import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";

import { LoginPage } from "../pages/LoginPage/LoginPage";
import Dashboard from "../pages/Dashboard/Dashboard";
import { Home } from "../pages/Home/Home";
import { EsqueceuSenha } from "../pages/EsqueceuSenha/EsqueceuSenha";
import { NovaSenhaPage } from "../pages/NovaSenhaPage/NovaSenhaPage";
import { WorkFlowPage } from "../pages/WorkFlowPage/WorkFlowPage";
import { Enriquecimento } from "../pages/Enriquecimento/Enriquecimento";
import { Layout } from '../pages/Layout/Layout';
import ConfigCarteiraAutomatica from '../pages/ConfigCarteiraAutomatica/ConfigCarteiraAutomatica';
import ConfigurarRelatorio from '../pages/Relatorio/ConfigurarRelatorio';
import VisualizarRelatorio from '../pages/Relatorio/VisualizarRelatorio';
import Processo from '../pages/Processo/Processo';
import Usuario from '../pages/Usuario/Usuario';
import { PainelProcesso } from '../pages/PainelProcesso/PainelProcesso';
import { Lista } from '../pages/Lista/Lista';

export default function Routes() {
  return (
    <Switch>
      <Route path="/Login" component={props => <LoginPage {...props} />} />
      {/* <Route path="/Dashboard" component={props => <Layout {...props}><Dashboard {...props} /></Layout>} isPrivate={true} /> */}
      <Route path="/Dashboard" component={props => <Layout {...props}><WorkFlowPage {...props} /></Layout>} isPrivate={true} />
      <Route path="/Home" component={props => <Layout {...props}><WorkFlowPage {...props} /></Layout>} isPrivate={true} />
      {/* <Route path="/Home" component={props => <Layout {...props}><Home {...props} /></Layout>} isPrivate={true} /> */}
      <Route path="/EsqueceuSenha" component={EsqueceuSenha} />
      <Route path="/NovaSenha" isPrivate component={props => <NovaSenhaPage {...props} />} />
      <Route path="/WorkFlow/:processoNumero?/:isFilterSearch?" component={props => <Layout {...props}><WorkFlowPage {...props} /></Layout>} isPrivate={true} />
      <Route path="/Enriquecimento" component={props => <Layout {...props}><Enriquecimento {...props} /></Layout>} />
      <Route path="/Processo/:data" component={props => <Processo {...props} />} />
      <Route path="/ConfigCarteiraAutomatica" component={props => <Layout {...props}><ConfigCarteiraAutomatica {...props} idChave="RÉU" /></Layout>} />
      <Route path="/ConfigBlackListAdvogados" component={props => <Layout {...props}><ConfigCarteiraAutomatica {...props} idChave="ADVOGADO" categoria="BLACKLIST" regexMsg="O campo descrição deve conter o seguinte formato: Nome do Advogado 9999999/UF" regexDescricao=".*?\d*?\/(?:RO|AC|AM|RR|PA|AP|TO|MA|PI|CE|RN|PB|PE|AL|SE|BA|MG|ES|RJ|SP|PR|SC|RS|MS|MT|GO|DF)$" /></Layout>} />
      <Route path="/ConfigurarRelatorio" component={props => <Layout {...props}><ConfigurarRelatorio {...props} /></Layout>} />
      <Route path="/VisualizarRelatorio" component={props => <Layout {...props}><VisualizarRelatorio {...props} /></Layout>} />
      <Route path="/Usuario" component={props => <Layout {...props}><Usuario {...props} /></Layout>} />
      <Route path="/PainelProcesso" component={props => <Layout {...props}><PainelProcesso {...props} /></Layout>} />
      <Route path="/Lista" component={props => <Layout {...props}><Lista {...props} /></Layout>} />
      {/* redirect user to Login page if route does not exist and user is not authenticated */}
      <Route component={props => <LoginPage {...props} />} />
    </Switch>
  );
}
