import React, { useEffect, useState, useRef } from 'react';
import Chart from 'react-google-charts';
import { Grid as GridTable } from '../Grid/Grid';
import { Card, CardContent, Typography, Button, Grid, Backdrop, Modal, Fade, Paper, InputBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from "lodash";
import ReactExport from "react-export-excel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Grafico.css';
import ReactDOM from 'react-dom';
import { pushWarning } from '../../services/notifierService';
import history from '../../services/history';
import ModalConfirm from '../ModalConfirm/ModalConfirm';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles((theme) => ({
    titulo: {
        fontFamily: 'Montserrat-Medium',
        fontSize: 10,
        fontWeight: 'bold',
        marginBottom: 5,
    }, root: {
        height: '100%'
    }, content: {
        height: '100%',
    }, buttonExport: {
        fontSize: 10,
        marginLeft: 5,
        fontFamily: 'Montserrat-Medium',
        textTransform: 'none'
    }, textRight: {
        textAlign: 'right'
    }, noData: {
        fontFamily: 'Montserrat-Medium',
        fontSize: 10,
        marginBottom: 5,
        textAlign: 'center',
        position: 'initial !important',
        transform: 'translate(0%,0%)',
        padding: '12px !important',
        color: 'rgba(0,0,0,0.5)'
    },
    editStyle: {
        position: "absolute",
        right: "16px",
        top: 0,
        cursor: "pointer",
        zIndex: 2
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: "#F5F5F5",
        padding: theme.spacing("15px", "15px", "15px", "15px"),
        borderRadius: "10px",
        fontSize: "13px",
        outline: 'none'
    },
    buttonSim: {
        backgroundColor: "#0091C7",
        color: "white",
        fontSize: "10px",
        width: "90px"
    },
    buttonCancelar: {
        backgroundColor: "#FEFEFE",
        color: "#0091C7",
        fontSize: "10px",
        marginRight: "16px",
        width: "90px"
    },
    contentModal: {
        width: 312,
        backgroundColor: "white",
        padding: 16
    },
    label: {
        color: "#0091C7"
    },
    input: {
        backgroundColor: "#F5F5F5",
        flex: 1,
        width: "100%",
        paddingLeft: 10
    },
}));

export const Grafico = (props) => {
    const { data, grafico, reload, onChangeProps, editing } = props;
    const [columnsDefinition, setColumnsDefinition] = useState([]);
    const [options, setOptions] = useState([]);
    const classes = useStyles();
    const [dataGrafico, setDataGrafico] = useState([]);
    const [altura, setAltura] = useState(0);
    const [google, setGoogle] = useState();
    const [chartEditor, setChartEditor] = useState();
    const [chartWrapper, setChartWrapper] = useState();
    const [tempoAtualizacaoSegundos, setTempoAtualizacaoSegundos] = useState(0);
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [openPropriedadesGrafico, setOpenPropriedadesGrafico] = useState(false);
    const inputTempoAtualizacao = useRef(null);
    const [confirmVisualizarProcesso, setConfirmVisualizarProcesso] = useState(false);
    const [modalProps, setModalProps] = useState({tittle: 'Visualizar Processo', Message : ''})

    useEffect(() => {
        let dataG = _.cloneDeep(data);
        if (dataG && dataG.length > 0) {
            let columns = [];
            let header = [];
            let regex = /\d*\-\d*\.\d*\.\d*.\d*.\d*/;
            Object.keys(data[0]).forEach((value, index) => {
                let onClickFunction = null;
                let column = {
                    "column": value,
                    "title": value,
                    "headerClassName": "text-left mb-0",
                    "style": {
                        "backgroundColor": "white",
                        "paddingBottom": "0px",
                        "paddingTop": "0px",
                        "height": "25px"
                    },
                    "render": "Div"
                }
                if(data.length > 0 && regex.test(data[0][value])){
                    column.props = {
                        onClick: (row) => {
                            onClickvisualizarProcesso(row);
                        }
                    }
                    column.style = {...column.style, "cursor" : "pointer"}
                }
                columns.push(column);
                header.push(value);
            });
            setColumnsDefinition(columns);
            if (grafico.tipoGrafico !== "TOTAL" && grafico.tipoGrafico !== "GRID") {
                dataG.unshift(Object.assign({}, header));
            }
        }
        let opt = {};
        if (grafico.dashboardUsuario.propriedadesGrafico || grafico.propriedadesGrafico) {
            opt = JSON.parse(grafico.dashboardUsuario.propriedadesGrafico ?? grafico.propriedadesGrafico);
        }
        if (!opt.title) {
            opt.title = grafico.titulo;
        }
        opt.titleTextStyle = {
            fontName: 'Montserrat-Medium',
            fontSize: 10,
            bold: true
        };

        setDataGrafico(dataG);
        setOptions(opt);
        setAltura(grafico.dashboardUsuario.altura);

        function onClickvisualizarProcesso(row){
            if(row.value){
                let regex = /\d*\-\d*\.\d*\.\d*.\d*.\d*/;
                let processo = row.value.match(regex);
                if(processo.length > 0){
                    setConfirmVisualizarProcesso(true);
                    let modal = _.cloneDeep(modalProps);
                    modal.message = 'Deseja visualizar o processo ' + processo[0] + '?';                
                    modal.buttonSimClick = () => { history.push('WorkFlow/' + processo[0])}
                    setModalProps(modal)
                }                
            }
        }
    }, [data, grafico, reload]);

    useEffect(() => {
        setTempoAtualizacaoSegundos(grafico.dashboardUsuario.tempoAtualizacaoSegundos);
    }, [grafico])
    function calcHeightGrid() {
        if (altura === 1) {
            return (58 > dataGrafico.length * 26 ? (dataGrafico.length * 26 + 23) : 58);
        } else {
            let alt = altura * 100 + (altura - 1) * 10 - 42;
            return (alt > dataGrafico.length * 26 ? (dataGrafico.length * 26 + 23) : alt);
        }
    }

    function onOpenPropriedadesGrafico() {
        setOpenBackDrop(true);
        let divDadosAdicionais = document.createElement("DIV");
        let label = document.createElement("LABEL");
        label.innerHTML = 'Tempo de Atualização:'
        divDadosAdicionais.appendChild(label)
        let dadosAdicionais = document.createElement("INPUT");
        dadosAdicionais.setAttribute("type", "number");
        dadosAdicionais.value = tempoAtualizacaoSegundos;
        dadosAdicionais.onkeyup = (e) => {
            setTempoAtualizacaoSegundos(e.target.value);
        };
        dadosAdicionais.placeholder = "Tempo de Atualização";
        dadosAdicionais.setAttribute('style', 'flex: 1;width: 100%;padding-left: 10px;background-color: #F5F5F5;');
        divDadosAdicionais.appendChild(dadosAdicionais)
        chartEditor.openDialog(chartWrapper, { dataSourceInput: divDadosAdicionais });
        chartWrapper.setOption('width', '100%');
        chartWrapper.setOption('height', '100%');
        chartWrapper.draw();
        google.visualization.events.addListener(chartEditor, 'ok', () => {
            const newChartWrapper = chartEditor.getChartWrapper();
            let tempo = parseInt(inputTempoAtualizacao.current.value);
            if (parseInt(inputTempoAtualizacao.current.value) < 30 || parseInt(inputTempoAtualizacao.current.value) > 9999) {
                pushWarning("O tempo de atualização deve ser um número maior que 30 e menor que 9999");
                tempo = tempoAtualizacaoSegundos;
            }
            onChangeProps(grafico, newChartWrapper.getChartType(), newChartWrapper.getOptions(), tempo);
        });
    }

    function onClosePropriedadesGrafico() {
        if (tempoAtualizacaoSegundos >= 30 && tempoAtualizacaoSegundos < 9999) {
            setOpenPropriedadesGrafico(false);
            onChangeProps(grafico, grafico.tipoGrafico, options, tempoAtualizacaoSegundos);
        } else {
            pushWarning("O tempo de atualização deve ser um número maior que 30 e menor que 9999");
        }
    }

    return (
        <>
            <ModalConfirm
                open={confirmVisualizarProcesso}
                modalProps={modalProps}
                buttonCancelarClick={()=>{setConfirmVisualizarProcesso(false);}}
            />
            {dataGrafico.length === 0 &&
                <Card className={classes.root}>
                    <CardContent>
                        <Typography className={classes.titulo}>
                            {grafico.titulo}
                        </Typography>
                        <Typography className={classes.noData}>
                            Nenhum registro encontrado
                        </Typography>
                    </CardContent>
                </Card>
            }
            {grafico.tipoGrafico === "TOTAL" && dataGrafico.length > 0 &&
                <>
                    <FontAwesomeIcon
                        className={classes.editStyle}
                        icon={['fa', 'cog']}
                        tyle={{ cursor: "pointer" }}
                        onClick={() => { setOpenPropriedadesGrafico(true) }}
                        style={{ display: editing ? '' : 'none' }} />
                    <Card className={classes.root}>
                        <CardContent className={classes.content}>
                            <Typography className={classes.titulo}>
                                {grafico.titulo}
                            </Typography>
                            <Typography variant="h5" component="h2">
                                {columnsDefinition.map((value, index) => {
                                    return (
                                        <span key={index}>
                                            {index !== 0 ? ' - ' : ''}
                                            {dataGrafico[0][value.title]}
                                        </span>
                                    )
                                })}
                            </Typography>
                        </CardContent>
                    </Card>
                </>
            }
            {grafico.tipoGrafico === "GRID" && dataGrafico.length > 0 &&
                <>
                    <FontAwesomeIcon
                        className={classes.editStyle}
                        icon={['fa', 'cog']}
                        tyle={{ cursor: "pointer" }}
                        onClick={() => { setOpenPropriedadesGrafico(true) }}
                        style={{ display: editing ? '' : 'none' }} />
                    <Card className={classes.root}>
                        <CardContent className={classes.content}>
                            <Grid container alignItems="center">
                                <Grid item xs={6}>
                                    <Typography className={classes.titulo}>
                                        {grafico.titulo}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} className={classes.textRight}>

                                    <ExcelFile
                                        filename={grafico.titulo}
                                        element={
                                            <Button
                                                startIcon={<FontAwesomeIcon icon={['fa', 'file-excel']} />}
                                                size="small"
                                                className={classes.buttonExport}
                                            >
                                                Exportar Dados
                                    </Button>
                                        }>
                                        <ExcelSheet data={dataGrafico} name={grafico.titulo}>
                                            {_.map(columnsDefinition, (column) => {
                                                return (<ExcelColumn label={column.title} value={column.title} />);
                                            })}
                                        </ExcelSheet>
                                    </ExcelFile>
                                </Grid>
                            </Grid>
                            <div className={classes.table}>
                                <GridTable data={dataGrafico}
                                    style={{ width: '100%', fontSize: 10, backgroundColor: '#f5f5f5', height: calcHeightGrid() }}
                                    columns={columnsDefinition}
                                    minPage={1}
                                    pageSize={dataGrafico.length}
                                />
                            </div>
                        </CardContent>
                    </Card>
                </>
            }
            {grafico.tipoGrafico !== "GRID" && grafico.tipoGrafico !== "TOTAL" && dataGrafico.length > 0 &&
                <>
                    <FontAwesomeIcon
                        className={classes.editStyle}
                        icon={['fa', 'cog']}
                        tyle={{ cursor: "pointer" }}
                        onClick={onOpenPropriedadesGrafico}
                        style={{ display: editing ? '' : 'none' }} />
                    <Chart
                        width="100%"
                        height="100%"
                        chartType={options.tipoGrafico ?? grafico.tipoGrafico}
                        loader={<div>Carregando os dados</div>}
                        data={dataGrafico.map(Object.values)}
                        options={options}
                        legendToggle
                        chartLanguage={'pt-BR'}
                        getChartEditor={({ chartEditor, chartWrapper, google }) => {
                            setChartEditor(chartEditor);
                            setChartWrapper(chartWrapper);
                            setGoogle(google);
                        }}
                        chartPackages={['corechart', 'controls', 'charteditor']}
                    />
                      <InputBase
                                type="number"
                                inputRef={inputTempoAtualizacao}
                                style={{display:'none'}}
                                value={tempoAtualizacaoSegundos}
                            />
                </>
            }
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openPropriedadesGrafico}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openPropriedadesGrafico}>
                    <Paper className={classes.paper}>
                        <Paper className={classes.contentModal}>
                            <label className={classes.label}>Tempo de Atualização:</label>
                            <InputBase
                                type="number"
                                className={classes.input}
                                value={tempoAtualizacaoSegundos}
                                onChange={(e) => setTempoAtualizacaoSegundos(e.target.value)}
                            />
                            <div className="text-right mt-2">
                                <Button
                                    style={{ marginLeft: 10 }}
                                    variant="contained"
                                    className={classes.buttonCancelar}
                                    onClick={() => { setOpenPropriedadesGrafico(false) }}
                                >
                                    Cancelar
                                    </Button>
                                <Button
                                    variant="contained"
                                    className={classes.buttonSim}
                                    onClick={() => { onClosePropriedadesGrafico() }}
                                >
                                    Ok
                                    </Button>
                            </div>
                        </Paper>
                    </Paper>
                </Fade>
            </Modal>
        </>
    )
}