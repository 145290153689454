import axiosInstance from '../core/httpInterceptor';
import {objectToQueryString} from '../core/util';

export const workFlowService = {
    getWorkFlowProcessos,
    getWorkFlowPassos,
};

function getWorkFlowProcessos(carteiraId, paginacao, idProcessoStatus, filtro) {
    return axiosInstance.get('/WorkFlow/GetWorkFlowProcesso/' + carteiraId + '/'  + idProcessoStatus + '/' + filtro.FlagTodos  + '?' + objectToQueryString({page:paginacao}));
}

function getWorkFlowPassos(carteiraId) {
    return axiosInstance.get('/WorkFlow/GetPassos/' + carteiraId);       
}
