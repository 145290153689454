import { carteirasConstants } from '../redux/constants';
import { carteiraService } from '../services';
import {filtroActions} from './index';

export const carteiraActions = {
    getCarteiras,
    proximaPagina,
    getCarteiraProcesso,
    idCarteiraSelecionado
}

function getCarteiras(paginacao, filtro, idCarteiraProcesso = null, filtroPesquisa = [], primeiraConsulta = false) {
    return dispatch => {
        dispatch(request());
        carteiraService.getCarteiras(paginacao, filtro, idCarteiraProcesso, filtroPesquisa)
            .then(
                response => {
                    var data = response.result;
                    dispatch(success(data ?? [], response.paginacao ? response.paginacao.records.count : null, response.filtro));
                    if(response.filtro && response.filtro.campos){
                        dispatch(filtroActions.adicionarCampos(response.filtro.campos));
                    }
                    if (data && data.length > 0) {
                        this.getPassos(data[0].idCarteira);
                        if(primeiraConsulta){
                            this.idCarteiraSelecionado(data[0].idCarteira, data[0].idCarteira);
                        }
                    }
                }
            );
    };

    function request() { return { type: carteirasConstants.LISTA } }
    function success(carteiras, count, filtro) { return { type: carteirasConstants.SUCESSO, carteiras, count, filtro } }
}

function proximaPagina(paginacao, filtro, idCarteiraProcesso = null, filtroPesquisa = []) {
    return dispatch => {
        dispatch(request());
        carteiraService.getCarteiras(paginacao, filtro, idCarteiraProcesso, filtroPesquisa)
            .then(
                response => {
                    var data = response.result;
                    if (data && data.length > 0) {
                        dispatch(success(data, response.paginacao.records.count, paginacao, response.filtro));
                    }
                }
            );
    };

    function request() { return { type: carteirasConstants.LISTA } }
    function success(carteiras, count, paginacao, filtro) { return { type: carteirasConstants.PROXIMA_PAGINA, carteiras, count, paginacao, filtro } }
}

function getCarteiraProcesso(props) {
    return dispatch => {
        dispatch(request());
        carteiraService.getCarteiras(props)
            .then(
                response => {
                    var data = response.result;
                    dispatch(success(data ?? [], response.paginacao ? response.paginacao.records.count : null, response.filtro));
                    if (data && data.length > 0) {
                        this.getPassos(data[0].idCarteira);
                    }
                }
            );
    };

    function request() { return { type: carteirasConstants.LISTA } }
    function success(carteiras, count, filtro) { return { type: carteirasConstants.SUCESSO, carteiras, count, filtro } }
}

function idCarteiraSelecionado(idCarteira, selecionado) {
    return dispatch => {
        dispatch(success(idCarteira, selecionado));
    }
    function success(idCarteira, selecionado) { return { type: carteirasConstants.SUCESSO_SELECIONADO, idCarteira, selecionado } }
}