import axiosInstance from '../core/httpInterceptor';

export const anexoService = {
    insAnexo,
    getAnexo
};

function insAnexo(formData){
    return axiosInstance.post('/Anexo/InsAnexo', formData);       
}

function getAnexo(idAnexo){
    return axiosInstance.get('/Anexo/GetAnexo/' + idAnexo);       
}