import { usuariosConstants } from '../constants';

export function usuarios(state = {loading:false}, action) {
  switch (action.type) {
    case usuariosConstants.NOVA_SENHA:
      return {
        loading:true
      };
    case usuariosConstants.NOVA_SENHA_SUCESSO:
      return {
        loading: false,
      };
    case usuariosConstants.NOVA_SENHA_FALHA:
      return {
        loading: false,
      };
    
    default:
      return state
  }
}