import React, { useState, useEffect, useRef } from 'react';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faTimes } from '@fortawesome/free-solid-svg-icons';
import { notificacaoService } from '../../services/notificacaoService';
import { NotificacaoBox } from '../NotificacaoBox/NotificacaoBox';
import InfiniteScroll from "react-infinite-scroll-component";
import history from '../../services/history';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: "#FFFFFF",
        padding: theme.spacing("0px", "0px", "0px", "0px"),
        borderRadius: "10px",
        fontSize: "13px",
        position: "absolute",
        right: "72px",
        top: "30px",
        outline: 'none',
        width:375,
        height: 500
    },
    header: {
        fontSize: "12px",
        marginLeft: "26px"
    },
    notificacaoBox: {
        height: 500,
        width: "100%"
    },
    notificacoesVazio: {
        margin: "auto",
        padding: 10
    },
    backDrop: {
        background: 'rgba(255,255,255,0.2)',
    },
    body:{
        padding:"25px"
    }
}));

export const Notificacao = (props) => {
    const classes = useStyles();
    const [paginacao, setPaginacao] = useState(
        {
            number: 1,
            size: 25
        });
    const [notificacoes, setNotificacoes] = useState([]);
    const [loading, setLoading] = useState(false);
    const notificacaoDiv = useRef();
    const [count, setCount] = useState(0);

    useEffect(() => {
        recarregaNotificacoes();

        function handleClick(e) {
            if (notificacaoDiv.current.contains(e.target)) {
                return;
            }
            onClose();
        }

        window.addEventListener('mousedown', handleClick, false);

        // returned function will be called on component unmount 
        return () => {
            window.removeEventListener('mousedown', handleClick, false)
        }
     // eslint-disable-next-line   
    },[]);

    function onClose() {
        props.close();
    }

    function recarregaNotificacoes() {
        setLoading(true);
        notificacaoService.getNotificacoes(paginacao)
            .then((response) => {
                if (response.paginacao && response.paginacao.records) {
                    setCount(response.paginacao.records.count)
                }
                setNotificacoes(response.result);
                setLoading(false);
                if (response.result) {                    
                    let notificacoesLeitura = [];
                    response.result.forEach((notificacao) => {
                        if (notificacao.notificacaoUsuario) {
                            if (!notificacao.notificacaoUsuario.dtLeitura) {
                                notificacao.notificacaoUsuario.idNotificacao = notificacao.idNotificacao;
                                notificacoesLeitura.push(notificacao.notificacaoUsuario)
                            }
                        }
                    });
                }
            }
            );
    }

    function proximaPagina() {
        paginacao.number += 1;
        setPaginacao(paginacao);
        setLoading(true);
        notificacaoService.getNotificacoes(paginacao)
            .then((response) => {
                setLoading(false);
                setNotificacoes([...notificacoes, ...response.result]);                
                if (response.result) {                    
                    let notificacoesLeitura = [];
                    response.result.forEach((notificacao) => {
                        if (notificacao.notificacaoUsuario) {
                            if (notificacao.notificacaoUsuario.dtLeitura === '0001-01-01T00:00:00') {
                                notificacao.notificacaoUsuario.idNotificacao = notificacao.idNotificacao;
                                notificacoesLeitura.push(notificacao.notificacaoUsuario)
                            }
                        }
                    });
                }
            });
    }

    function redirect(url) {
        if (url !== "") {
            history.push(url);
        }
    }

    return (<>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={true}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
                classes: {
                    root: classes.backDrop
                }
            }}
        >
            <Fade in={true}>
                <Paper ref={notificacaoDiv} className={classes.paper}>
                    <div className={classes.body}>
                    <div>
                        <FontAwesomeIcon icon={faBell} color="#0091C7" />
                        <label className={classes.header}>Notificações</label>
                        <label className="float-right">
                            <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={onClose} />
                        </label>
                    </div>
                    <div className={classes.notificacaoBox}>
                        <InfiniteScroll
                            dataLength={notificacoes.length}
                            next={() => { proximaPagina() }}
                            hasMore={notificacoes.length !== count && !loading}
                            height={425}
                            width="100%"
                        >
                            {notificacoes.map((notificacao, index) => {
                                return (
                                    <NotificacaoBox
                                        key={index}
                                        titulo={notificacao.titulo}
                                        icone={(notificacao.notificacaoTipo !== null ? notificacao.notificacaoTipo.icone : "")}
                                        data={notificacao.dtExibicao}
                                        mensagem={notificacao.dsNotificacao}
                                        cor={(notificacao.notificacaoTipo !== null ? notificacao.notificacaoTipo.cor_Icone : "")}
                                        onClick={() => { redirect((notificacao.notificacaoTipo !== null ? notificacao.notificacaoTipo.urL_Rota + "/" + notificacao.titulo : "")) }}
                                        dtLeitura={notificacao.notificacaoUsuario.dtLeitura}
                                        idNotificacao={notificacao.idNotificacao}
                                        idNotificacaoUsuario={notificacao.notificacaoUsuario.idNotificacaoUsuario}
                                        idUsuario={notificacao.notificacaoUsuario.idUsuario}
                                        atualizaNotificacao={props.atualizaNotificacao}
                                        recarregaNotificacoes={() => { recarregaNotificacoes() }}
                                    />
                                )
                            })}
                            {notificacoes.length === 0 &&
                                <div className={classes.notificacoesVazio}>
                                    Nenhuma notificação encontrada
                            </div>
                            }
                        </InfiniteScroll>
                        </div>
                    </div>
                </Paper>
            </Fade>
        </Modal>
    </>)
}