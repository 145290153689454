import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './WorkFlowPassos.css';
import ReprovarProcesso from '../../components/AcoesProcesso/ReprovarProcesso';
import BpmnDiagrama from '../BpmnDiagrama/BpmnDiagram';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import _ from 'lodash';
import { Fade, Tooltip } from '@material-ui/core';
import Switch from "react-switch";

const useStyles = theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: "#F5F5F5",
        padding: theme.spacing("15px", "20px", "20px", "20px"),
        borderRadius: "10px",
        fontSize: "13px",
        outline: 'none'
    },
    contentModal: {
        width: 1020,
        backgroundColor: "white",
        padding: 16
    },
    header: {
        marginLeft: 24
    },
    label: {
        color: "#0091C7"
    },
    searchDiv: {
        backgroundColor: "#F5F5F5",
        borderRadius: 10
    },
    reactSwitch: {
        marginTop: '2px',
        float: 'left'
    }
});

class WorkFlowPassos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idCarteira: null,
            modalConfirm: {
                abrirModal: false,
                tittle: "",
                message: "",
            },
            idProcessoAcao: 0,
            observacao: '',
            idMensagem: null,
            motivos: [],
            adicionaProcessos: false,
            faseModal: {
                open: false
            },
            openModal: false,
            passosDiagrama: [],
            openMenuOptions: false,
            statusFluxo: '',
            inativoChecked: false
        };
        this.handleClose = this.handleClose.bind(this);
        this.showMenuOptions = this.showMenuOptions.bind(this);
        this.closeMenuOptions = this.closeMenuOptions.bind(this);
        this.showModal = this.showModal.bind(this);
        this.checkInativo = this.checkInativo.bind(this);
    }

    handleClose() {
        this.setState({ openModal: false });
    };


    showModal(fluxo) {
        let passos = _.cloneDeep(this.props.passos);
        let statusFluxo = this.props.stateFluxo;
        if (fluxo) {
            _.remove(passos, (passo) => { return passo.idFluxo !== fluxo.idFluxo });
            statusFluxo = passos[0].dsFluxo;
        }
        this.setState({ passosDiagrama: passos, openModal: true, openMenuOptions: false, statusFluxo })
    }

    showMenuOptions() {
        this.setState({ openMenuOptions: !this.state.openMenuOptions });
    }

    closeMenuOptions() {
        this.setState({ openMenuOptions: false });
    }

    componentDidUpdate(prevProps){
        if(this.props.filtros.flagTodos !== prevProps.filtros.flagTodos && this.props.filtros.flagTodos === -1){
            this.props.alteraFiltroFlagTodos(-1)
        }
    }

    checkInativo(inativoChecked) {
        this.props.alteraFiltroInativo(inativoChecked);
        this.setState({ inativoChecked });
      }

    render() {
        const { classes } = this.props;
        let passos = [];
        let fluxos = [];
        _.forEach(this.props.passos, (passo) => {
            if (_.findIndex(passos, { 'idProcessoStatus': passo.idProcessoStatus }) === -1) {
                passos.push(passo);
            }
            if (_.findIndex(fluxos, { 'idFluxo': passo.idFluxo }) === -1) {
                fluxos.push({ idFluxo: passo.idFluxo, dsFluxo: passo.dsFluxo });
            }
        });
        return (
            <>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={this.state.openModal}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.openModal}>
                        <div className={classes.paper}>
                            <React.Fragment>
                                <FontAwesomeIcon icon={faChevronLeft} onClick={this.handleClose} style={{ cursor: "pointer" }} />
                                <label className={classes.header}>{this.state.statusFluxo}</label>
                                <div className={classes.contentModal} >
                                    <BpmnDiagrama passos={this.state.passosDiagrama} />
                                </div>
                            </React.Fragment>
                        </div>
                    </Fade>
                </Modal>
                <div className="float-left" style={{ width: "190px", padding: "8px" }}>
                    <Switch onChange={this.checkInativo} height={10} width={20} uncheckedIcon={false} className={classes.reactSwitch} checkedIcon={false} checked={this.state.inativoChecked} />
                    <Tooltip title="Exibir Processos Inativos" aria-label="Exibir Processos Inativos" >
                        <label style={{ marginLeft: "0.15rem" }} className={"mr-1 cursor-pointer "} >Inativo</label>    
                    </Tooltip>                    
                    {this.props.filtros.flagTodos !== -1 && <label className={"tab ml-1 mr-1 cursor-pointer " + (this.props.filtro ? (this.props.filtro.flagTodos === 2 ? "active" : "") : "")} onClick={e => { this.props.alteraFiltroFlagTodos(2) }}>Recentes</label>}
                    {this.props.filtros.flagTodos !== -1 && <label className={"tab ml-1 mr-1 cursor-pointer " + (this.props.filtro ? (this.props.filtro.flagTodos === 1 ? "active" : "") : "")} onClick={e => { this.props.alteraFiltroFlagTodos(1) }}>Todas</label>}
                    {this.props.idCarteira !== 0 &&
                        <FontAwesomeIcon icon={['fa', 'plus']} style={{ marginLeft: 2, cursor: "pointer" }} onClick={() => { this.props.adicionarProcesso({ idProcessoAcao: 0, urL_Rota: '/IncluirProcesso' }) }} />
                    }
                </div>
                <div className="workflow-passos-div text-right">
                    {passos && passos.length > 0 &&
                        <span className="workflow-passos text-nowrap text-right">
                            <span>
                                {passos && passos.map((passo) => {
                                    return (
                                        <span key={passo.idProcessoStatus} className="fluxos-span" onClick={e => { this.props.alteraProcessoStatus(passo.idProcessoStatus) }}>
                                            <FontAwesomeIcon icon={['fa', 'filter']} style={{ fontSize: 9, marginRight: 2, marginLeft: 2, visibility: (this.props.idProcessoStatus === passo.idProcessoStatus ? "visible" : "hidden") }} />
                                            <span className="badge badge-passos mr-2" style={{ backgroundColor: passo.corHexadecimal }}>{passo.qtdRegistros}</span>
                                            <span>
                                                {passo.dsProcessoStatus}
                                            </span>
                                            <span style={{ marginLeft: 10 }}>
                                                |
                                                </span>
                                        </span>
                                    )
                                })}
                            </span>
                            <span className="ml-3">
                                <span>
                                    |
                                        <div className="card-acao dropleft" >
                                        <FontAwesomeIcon className="mr-2 ml-2 mb-1" icon={['fa', 'sitemap']} style={{ verticalAlign: "middle" }} onClick={() => fluxos.length === 1 ? this.showModal() : this.showMenuOptions()} />
                                        <div className={this.state.openMenuOptions ? "dropdown-menu show" : "dropdown-menu"} aria-labelledby="dropdownMenu1">
                                            {fluxos.map((fluxo, index) => {
                                                return (<div key={index} className="dropdown-item" style={{ fontSize: 10 }} onClick={() => { this.showModal(fluxo) }}>{fluxo.dsFluxo}</div>)
                                            })
                                            }
                                        </div>
                                    </div>
                                </span>
                            </span>
                        </span>
                    }
                </div>
            </>
        );
    }
}


function mapState(state) {
    const { passos, loading } = state.workFlows;
    const { filtro } = state.carteiras;

    return { filtros: filtro, passos, loading };
}

const connectedWorkFlowPassos = connect(mapState)(withStyles(useStyles)(WorkFlowPassos));
export { connectedWorkFlowPassos as WorkFlowPassos };