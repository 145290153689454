
import axiosInstance from '../core/httpInterceptor';

export const fonteRelatorioService = {
    getFonteRelatorio
};

function getFonteRelatorio(){
    return axiosInstance.get('/FonteRelatorio/GetFonteRelatorio');       
};

function GetRelatorioDados(data){
    return axiosInstance.post('/FonteRelatorio/GetRelatorioDados', data);       
}
