import React, { useEffect, useState, useRef } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { ComentarioBox } from '../ComentarioBox/ComentarioBox';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, CircularProgress, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfiniteScroll from "react-infinite-scroll-component";
import { usuarioService, comentarioService, anexoService } from '../../services/index';
import { faPaperPlane, faAt, faPaperclip, faBell, faTimes, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { pushWarning } from '../../services/notifierService';
import { Upload } from '../Upload/Upload';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
    KeyboardDatePicker,
    KeyboardTimePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { IconButton } from '@material-ui/core';
import ptBRLocale from "date-fns/locale/pt-BR";
import { useDispatch } from "react-redux";
import { processoActions } from '../../actions';
import "./Comentarios.css";
import WYSIWYGEditor from "../Wysiwyg/WYSIWYG";
import { useForm, Controller } from "react-hook-form";


const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: "1210 !important",
        color: '#fff',
    },
    paper: {
        padding: "25px",
        width: "450px",
        height: "100%",
        position: "absolute",
        top: 0,
        right: 0
    },
    header: {
        fontSize: "12px",
        marginLeft: "5px"
    },
    headerDate: {
        fontSize: "12px",
        color: "rgba(60, 60, 67, 0.6)"
    },
    comentariosBox: {
        height: "calc(100.5vh - 250px)",
        width: "100%"
    },
    controles: {
        backgroundColor: "rgba(249, 249, 249, 0.94)",
        display: "flex"
    },
    botoes: {
        display: "flex"
    },
    mentionInput: {
        background: "#FFFFFF",
        border: "1px solid #E5E5EA",
        borderRadius: "10px",
        width: 300,
        height: 25,
        display: "inline-block"
    },
    comentariosVazio: {
        margin: "auto",
        padding: 10
    },
    divPendencia: {
        textAlign: "center"
    },
    divChkPendencia: {
        display: "flex",
        marginLeft: "5px",
        textAlign: "left"
    },
    statusbar: {
        display: 'none !important',
    },
}));

export const Comentarios = (props) => {
    const [fileName, setFileName] = useState();
    const { idCarteiraProcesso, numeroProcesso, idUsuario, onClose, idCarteira, filtros, statusFlag } = props;
    const [usuarios, setUsuarios] = useState([]);
    const [comentarios, setComentarios] = useState([]);
    const [flPendencia, setflPendencia] = useState();
    const [isUsuarioNotificacao, setIsUsuarioNotificacao] = useState();
    const [chkPendencia, setChkPendencia] = useState(false);
    const [mencionouUsers, setMencionar] = useState(false);
    const [submitBtn, setSubmitBtn] = useState(false);
    const dispatch = useDispatch();
    const [paginacao, setPaginacao] = useState(
        {
            number: 1,
            size: 25
        });
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingInsert, setLoadingInsert] = useState(false);
    const classes = useStyles();
    const [reload, setReload] = useState(1);
    const comentarioDiv = useRef();
    const fileInput = useRef(null);
    const [usuariosMencionados, setUsuariosMencionados] = useState([]);
    const [anexo, setAnexo] = useState(null);
    const [notificacao, setNotificacao] = useState(false);
    const [pendencia, setPendencia] = useState(false);
    const localeMap = {
        ptBR: ptBRLocale
    };
    const [selectedDate, handleDateChange] = useState(new Date());
    const [locale] = useState("ptBR");
    const [erroData, setErroData] = useState(false);
    const [erroMensagem, setErroMensagem] = useState();
    const [visualizacoes, setVisualizacoes] = useState({});
    const { handleSubmit, control } = useForm({
        mode: "onChange"
    });

    useEffect(() => {
        usuarioService.getUsuariosAtivos()
            .then((response) => {
                if (response.result) {
                    let usuarios = [];
                    response.result.forEach((usuario) => {
                        usuarios.push({ id: usuario.iD_Usuario, display: usuario.nome, text: usuario.nome.toString(), value: usuario.nome.toString(), url: usuario.iD_Usuario })
                    });
                    setUsuarios(usuarios);
                }
            }
            );
        setLoading(true);
        comentarioService.getComentarios({ number: 1, size: 25 }, idCarteiraProcesso)
            .then((response) => {
                let pendencia = response.result.filter(c => { return c.flPendencia });
                setChkPendencia(false);
                let isUsuarioNotificacao = response.result.filter(c => { return c.idUsuarioNotificacao === idUsuario });
                if (isUsuarioNotificacao.length > 0) {
                    setIsUsuarioNotificacao(true);
                }
                if (pendencia.length > 0) {
                    setflPendencia(true);
                }
                if (response.paginacao && response.paginacao.records) {
                    setCount(response.paginacao.records.count)
                }
                setComentarios(response.result ?? []);
                if (response.result) {
                    comentarioService.insComentarioVisualizacao(response.result);
                }
            }).finally(() => {
                setLoading(false);
            });
    }, [idCarteiraProcesso, reload]);



    function proximaPagina() {
        paginacao.number += 1;
        setPaginacao(paginacao);
        setLoading(true);
        comentarioService.getComentarios(paginacao, idCarteiraProcesso)
            .then((response) => {
                setComentarios([...comentarios, ...response.result]);
                if (response.result) {
                    comentarioService.insComentarioVisualizacao(response.result);
                }
            }).finally(() => {
                setLoading(false);
            });
    }

    function handleChkChange() {
        setChkPendencia(!chkPendencia)
    }

    const enviarComentario = ({ value }) => {
        if (value.includes('a href')) {
            var mentioned = value.match(/data-value="([^"]*)/)[1];
            var idMentioned = value.match(/href="([^"]*)/)[1];
            value = value.replace('<a href="' + idMentioned + '" class="wysiwyg-mention" data-mention data-value="' + mentioned + '">@' + mentioned + '</a>', '<strong>@' + mentioned + '</strong>')
            usuariosMencionados.push({ idUsuario: parseInt(idMentioned), nome: mentioned });
        }
        if (value.trim() === '<p></p>') {
            pushWarning("Favor preencher o comentário");
        }
        else if (usuariosMencionados.length === 0 && pendencia) {
            pushWarning("É preciso marcar a pessoa responsável pela pendência");
        } else if ((notificacao & selectedDate < new Date())) {
            pushWarning("É necessário selecionar um horário futuro");
        } else if ((notificacao & selectedDate > new Date()) || !notificacao) {
            setLoadingInsert(true);
            let comentario = {};
            comentario.idCarteiraProcesso = idCarteiraProcesso;
            comentario.dsComentario = value;
            comentario.chkPendencia = chkPendencia;
            comentario.notificacoes = [];
            comentario.usuarios = [];
            setSubmitBtn(true);

            usuariosMencionados.forEach((usuario, index) => {
                if (value.indexOf(usuario.nome) !== -1) {
                    comentario.usuarios.push(usuario.idUsuario);
                }
            });

            if (comentario.usuarios.length > 0 && !pendencia) {
                comentario.notificacoes.push({
                    IDNotificacaoTipo: 1, //Tipo: Menção
                    IDGenericoRota: idCarteiraProcesso
                });
            }
            if (notificacao) {
                comentario.notificacoes.push({
                    IDNotificacaoTipo: 2, //Tipo: Menção
                    IDGenericoRota: idCarteiraProcesso,
                    dtExibicao: selectedDate
                });
            }
            if (pendencia) {
                comentario.notificacoes.push({
                    IDNotificacaoTipo: 4, //Tipo: Pendência
                    IDGenericoRota: idCarteiraProcesso
                });
            }

            //Adiciona Tipo 3 para todos novos comentários
            comentario.notificacoes.push({
                IDNotificacaoTipo: 3, //Tipo: Novo
                IDGenericoRota: idCarteiraProcesso
            });

            if (anexo !== null && anexo !== "") {
                const data = new FormData()
                data.append('file', anexo)
                anexoService.insAnexo(data).then((response) => {
                    if (response.result !== null && response.result.IDAnexo && response.result.IDAnexo > 0) {
                        comentario.anexo = response.result.IDAnexo;
                        comentarioService.insComentario(comentario).then((response) => {
                            setReload(reload * -1);
                            limparTela();
                        }).finally(() => {
                            setLoadingInsert(false);
                        });
                    }
                });
            } else {
                comentarioService.insComentario(comentario).then((response) => {
                    setReload(reload * -1);
                    limparTela();

                }).finally(() => {
                    setLoadingInsert(false);
                    setUsuariosMencionados([]);
                    setSubmitBtn(false);
                    if (chkPendencia) {
                        setIsUsuarioNotificacao(false);
                        setflPendencia(false);
                    }

                    dispatch(processoActions.recarregaProcessos)
                });

            }
        }
    }

    function limparTela() {
        setAnexo("");
        setNotificacao(false);
        setPendencia(false);
        handleDateChange("");
        fileInput.current.value = null;
        setFileName(null);
    }

    function mencionar() {
        setMencionar(!mencionouUsers);
    }


    function abrirAnexar() {
        fileInput.current.click();
    }

    function anexar(e) {
        setAnexo(e);
        setFileName(e.name);
    }

    function notificar() {
        if (notificacao === false) {
            handleDateChange(new Date());
        }
        if (pendencia) {
            setPendencia(!pendencia);
        }
        setNotificacao(!notificacao);
    }

    function pendenciar() {
        if (pendencia === false) {
            handleDateChange(new Date());
        }
        if (notificacao) {
            setNotificacao(!notificacao);
        }
        setPendencia(!pendencia);
    }

    function onCloseBackDrop(e) {
        if (comentarioDiv.current.contains(e.target) || e.target.id !== "bkdrp") {
            return;
        }
        if (!loading) {
            onClose();
        }
    }

    function carregaVisualizacoes(idCarteiraProcessoComentario) {
        comentarioService.getComentariosVisualizacoes({
            number: 1,
            size: 3
        }, idCarteiraProcessoComentario).then((response) => {
            if (response.result) {
                setVisualizacoes({ lista: response.result, total: response.paginacao.records.count });
            } else {
                setVisualizacoes({})
            }
        })
    }

    return (
        <>
            <Backdrop id="bkdrp" className={classes.backdrop} open={true} onClick={onCloseBackDrop}>
                <Paper ref={comentarioDiv} className={classes.paper}>
                    <Backdrop open={loadingInsert} className={classes.backdrop}>
                        <CircularProgress color="inherit" />
                    </Backdrop>

                    <div>
                        <FontAwesomeIcon icon={['fa', 'comment-dots']} color="#0091C7" />
                        <label className={classes.header}>Comentários</label>
                        {flPendencia && <FontAwesomeIcon icon={['fa', 'exclamation-triangle']} style={{ cursor: "pointer", color: "#ff9900", marginLeft: "35px" }} />}
                        {flPendencia && <label style={{ marginLeft: "3px" }}> Processo com pendência</label>}
                        <label className="float-right">
                            <FontAwesomeIcon icon={faTimes} style={{ cursor: "pointer" }} onClick={onClose} />
                        </label>
                    </div>
                    <div>
                        <label>
                            Número do Processo: {numeroProcesso}
                        </label>
                    </div>
                    <div className={classes.comentariosBox}>
                        <InfiniteScroll
                            dataLength={comentarios.length}
                            next={() => { proximaPagina() }}
                            hasMore={comentarios.length !== count && !loading}
                            height="calc(100.5vh - 260px)"
                            width="100%"
                        >
                            {comentarios.map((comentario, index) => {
                                return (
                                    <ComentarioBox
                                        key={index}
                                        id={comentario.idCarteiraProcessoComentario}
                                        nome={comentario.nomeUsuario}
                                        data={comentario.dtComentario}
                                        mensagem={comentario.dsComentario}
                                        direita={comentario.idUsuario === idUsuario}
                                        anexos={comentario.anexos}
                                        imagem={comentario.imagemUsuario}
                                        icone={comentario.icone}
                                        qtdvisualizacao={comentario.qtdVisualizacao + 1}
                                        notificacoes={comentario.notificacoes}
                                        onLoad={() => {
                                            setLoadingInsert(true)
                                        }
                                        }
                                        onFinishLoad={() => {
                                            setLoadingInsert(false)
                                        }
                                        }
                                        onCloseTooltip={() => { setVisualizacoes({}) }}
                                        onLoadTooltip={carregaVisualizacoes}
                                        visualizacoes={visualizacoes}
                                    />
                                )
                            })}
                            {comentarios.length === 0 &&
                                <div className={classes.comentariosVazio}>
                                    Nenhum comentário para este processo
                                </div>
                            }
                        </InfiniteScroll>
                    </div>

                    <form onSubmit={handleSubmit(enviarComentario)}>
                        <div className={classes.controles}>
                            <Controller
                                as={<WYSIWYGEditor
                                    dataUsuarios={usuarios}
                                    editorStateClicked={mencionouUsers}
                                    submitBtn={submitBtn}
                                />}
                                name="value"
                                control={control}
                            />

                            <IconButton type="submit" size="small">
                                <FontAwesomeIcon icon={faPaperPlane} />
                            </IconButton>
                        </div>
                    </form>

                    {fileName &&
                        <>
                            <FontAwesomeIcon icon={faPaperclip} style={{ marginRight: 8 }} />
                            {fileName}
                        </>
                    }
                    <Upload onChange={anexar} inputRef={fileInput} />
                    <div className="pt-2">
                        <Tooltip title="Mencionar usuário" aria-label="Mencionar usuário" >
                            <span><FontAwesomeIcon icon={faAt} onClick={mencionar} style={{ margin: 4, cursor: "pointer" }} /></span>
                        </Tooltip>
                        <Tooltip title="Anexar arquivo" aria-label="Anexar arquivo" >
                            <span><FontAwesomeIcon icon={faPaperclip} onClick={abrirAnexar} style={{ margin: 4, cursor: "pointer" }} /></span>
                        </Tooltip>
                        <Tooltip title="Adicionar notificação" aria-label="Adicionar notificação" >
                            <span><FontAwesomeIcon icon={faBell} onClick={notificar} style={{ margin: 4, cursor: "pointer", color: (notificacao ? "#0091C7" : "") }} /></span>
                        </Tooltip>
                        {!flPendencia &&
                            <Tooltip title="Adicionar pendência" aria-label="Adicionar pendência" >
                                <span><FontAwesomeIcon icon={faExclamationTriangle} onClick={pendenciar} style={{ margin: 4, cursor: "pointer", color: (pendencia ? "#0091C7" : "") }} /></span>
                            </Tooltip>}

                        {(notificacao || pendencia) &&
                            <div className="float-right">
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                                    <KeyboardDatePicker
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        format="dd/MM/yy"
                                        variant="inline"
                                        disablePast={true}
                                        style={{ width: 140, paddingRight: 10, zIndex: 1305 }}
                                        invalidDateMessage="Data inválida"
                                        minDateMessage="Data deve ser maior que atual"
                                        autoOk={true}
                                    />
                                    <KeyboardTimePicker
                                        value={selectedDate}
                                        variant="inline"
                                        style={{ width: 100, zIndex: 1305 }}
                                        autoOk={true}
                                        invalidDateMessage="Data inválida"
                                        ampm={false}
                                        onChange={(date, value) => {
                                            if (date < new Date()) {
                                                setErroData(true);
                                                setErroMensagem("O tempo deve ser maior que atual")
                                            } else {
                                                setErroData(false);
                                                setErroMensagem()
                                            }
                                            handleDateChange(date);
                                        }}
                                        error={erroData}
                                        helperText={erroMensagem}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        }
                        {isUsuarioNotificacao &&
                            <div className={classes.divChkPendencia}>
                                <input
                                    type="checkbox"
                                    onChange={handleChkChange}
                                    style={{ marginRight: 3 }}
                                />  Pendência resolvida
                        </div>
                        }



                    </div>
                </Paper>
            </Backdrop>
        </>
    )
}

