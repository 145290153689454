export const processosConstants = {
    SUCESSO: 'SUCESSO_PROCESSO',
    FALHA: 'FALHA_PROCESSO',
    LISTA: 'LISTA_PROCESSO',
    PROXIMA_PAGINA: 'PROXIMA_PAGINA_PROCESSO',
    INICIA_STATE: 'INICIA_STATE_PROCESSO',
    ATUALIZA_PROCESSOS_SELECIONADOS: 'ATUALIZA_PROCESSOS_SELECIONADOS',
    LISTA_ACOES_PROCESSO: 'LISTA_ACOES_PROCESSO',
    FALHA_ACOES_PROCESSO: 'FALHA_ACOES_PROCESSO',
    SUCESSO_ACOES_PROCESSO: 'SUCESSO_ACOES_PROCESSO',
    ATUALIZA_PROCESSOS: 'ATUALIZA_PROCESSOS',
    SUCESSO_PROCESSO_FUNDO: 'SUCESSO_PROCESSO_FUNDO',
    PROXIMA_PAGINA_FUNDO: 'PROXIMA_PAGINA_FUNDO',
    RECARREGA_PROCESSOS: 'RECARREGA_PROCESSOS',
};