import 'bootstrap/dist/css/bootstrap.css';
import './assets/fonts/Montserrat-Medium.ttf';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { Provider } from 'react-redux';
import { store } from './redux';
import { SnackbarProvider } from 'notistack';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <SnackbarProvider>
      <BrowserRouter basename={baseUrl}>
        <App />
      </BrowserRouter>
    </SnackbarProvider>
  </Provider>,
  rootElement);

registerServiceWorker();

