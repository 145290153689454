import axiosInstance from '../core/httpInterceptor';

export const faseService = {
    // API
    getFaseCampo,
    saveFaseSessaoCampo,
    getQualificador,
    updateReferenciaCampoChave,
    updateReferenciaCampoChaveFilho,
    getValoresCampoChaveFilho,
    getFase,
    updateCamposPendentes,
    listData,
    updateTempoPreenchimento,
    // Métodos comuns nos componentes de Fase
    CalculaFormula,
    PosicaoAtual,
    ignorarPorqueCampoPaiNaoPossuiValor,
    replaceAll,
    isDateValid,
    getTicks,
    ValidaCNPJ,
    ValidaCPF,
    removeAcentuacao,
    Len,
    Left,
    Right,
    UpperCase,
    LowerCase,
    ValidaCPFCNPJ,
    ValidaEmail
};

function getFaseCampo(faseId, carteiraProcessoHistoricoId) {
    return axiosInstance.get(`/Fase/GetFaseCampo/${faseId}/${carteiraProcessoHistoricoId}`);
}

function saveFaseSessaoCampo(data) {
    return axiosInstance.post('/Fase/SaveFaseSessaoCampo', data);
}

function getQualificador(faseSessaoCampoQualificadorId) {
    return axiosInstance.get(`/Fase/GetQualificador/${faseSessaoCampoQualificadorId}`);
}

function updateReferenciaCampoChave(data) {
    return axiosInstance.post('/Fase/UpdateReferenciaCampoChave', data);
}

function updateReferenciaCampoChaveFilho(data) {
    return axiosInstance.post('/Fase/UpdateReferenciaCampoChaveFilho', data);
}

function getValoresCampoChaveFilho(idFaseSessaoCampoChave, valor) {
    return axiosInstance.post('/Fase/ListValoresCampoChave', { idFaseSessaoCampoChave, valor });
}

function getFase(faseId) {
    return axiosInstance.get(`/Fase/GetFase/${faseId}`);
}

function updateCamposPendentes(data) {
    return axiosInstance.post('/Fase/UpdateCamposPendentes', data);
}

function listData(data) {
    return axiosInstance.post('/Fase/ListData', data);
}

function updateTempoPreenchimento(data) {
    return axiosInstance.post('/Fase/UpdateTempoPreenchimento', data);
}

function ValidaCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj === '') return false;

    if (cnpj.length !== 14)
        return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj === "00000000000000" ||
        cnpj === "11111111111111" ||
        cnpj === "22222222222222" ||
        cnpj === "33333333333333" ||
        cnpj === "44444444444444" ||
        cnpj === "55555555555555" ||
        cnpj === "66666666666666" ||
        cnpj === "77777777777777" ||
        cnpj === "88888888888888" ||
        cnpj === "99999999999999")
        return false;

    // Valida DVs
    var tamanho = cnpj.length - 2
    var numeros = cnpj.substring(0, tamanho);
    var digitos = cnpj.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;
    for (var i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt(digitos.charAt(0)))
        return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt(digitos.charAt(1)))
        return false;

    return true;
}

function ValidaCPF(CPF) {
    CPF = CPF.replace(/[^\d]+/g, '');
    var Soma;
    var Resto;
    Soma = 0;

    if (CPF === "00000000000") return false;

    for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(CPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(CPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(CPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(CPF.substring(10, 11))) return false;
    return true;
}

function Len(valor) {
    return (valor || '').length;
}

function Left(str, n) {
    if (n <= 0)
        return "";
    else if (n > String(str).length)
        return str;
    else
        return String(str).substring(0, n);
}

function Right(str, n) {
    if (n <= 0)
        return "";
    else if (n > String(str).length)
        return str;
    else {
        var iLen = String(str).length;
        return String(str).substring(iLen, iLen - n);
    }
}

function UpperCase(valor) {
    return (valor || '').toUpperCase();
}

function LowerCase(valor) {
    return (valor || '').toLowerCase();
}

function ValidaCPFCNPJ(valor) {
    valor = valor.replace(/[^\d]+/g, '');
    return (valor.length === 11) ? ValidaCPF(valor) : ValidaCNPJ(valor);
}

function removeAcentuacao(inStr) {
    return inStr.replace(
        /([àáâãäå])|([çčć])|([èéêë])|([ìíîï])|([ñ])|([òóôõöø])|([ß])|([ùúûü])|([ÿ])|([æ])/g,
        function (str, a, c, e, i, n, o, s, u, y, ae) {
            if (a) return 'a';
            if (c) return 'c';
            if (e) return 'e';
            if (i) return 'i';
            if (n) return 'n';
            if (o) return 'o';
            if (s) return 's';
            if (u) return 'u';
            if (y) return 'y';
            if (ae) return 'ae';
        }
    );
}

function CalculaFormula(formula, data) {
    let retorno = '';
    if (formula) //campo contém formula. Então deve ser feito o cálculo do valor
    {
        retorno = formula;

        let tokens = formula.split('$');

        if (tokens.length > 0) {
            for (let i = 0; i < tokens.length; i++) {
                if (!tokens[i] || !isFinite(tokens[i]))
                    continue;

                let idCampo = tokens[i];
                let campoCalculo = data.find(c => c.idFaseSessaoCampo && c.idFaseSessaoCampo.toString() === idCampo);
                if (campoCalculo) {
                    if (!campoCalculo.valor)
                        campoCalculo.valor = '';
                    if (isFinite(campoCalculo.valor.replace('.', '').replace(',', '.'))) {   //É Decimal ou inteiro
                        let val = campoCalculo.valor.replace('.', '').replace(',', '.');

                        formula = replaceAll(formula, '$' + idCampo + '$', val);
                    }
                    /*else if (isDateValid(campoCalculo.valor))
                    {
                        let ticks = 864000000000;//(24 * 60 * 60 * 10000000);
                        //É data - Converter para dias
                        var dias = getTicks(campoCalculo.valor) / ticks;
                        formula = replaceAll( formula,'$' + idCampo + '$', dias.ToString());
                    }*/
                    else {
                        formula = replaceAll(formula, '$' + idCampo + '$', campoCalculo.valor);
                    }
                }
                else {
                    retorno = "ERRO: ID Campo da Fórmula inválido [" + idCampo + "]";
                    break;
                }
            }
        }

        if (!retorno.startsWith("ERRO:")) {
            try {
                [{ original: '=', substituto: '===' },
                { original: '<>', substituto: '!==' },
                { original: '<=', substituto: '<==' },
                { original: '<', substituto: '<' },
                { original: '>=', substituto: '>==' },
                { original: '>', substituto: '>' },
                { original: ' and ', substituto: ' && ' },
                { original: ' or ', substituto: ' || ' },
                { original: 'not ', substituto: '! ' },
                ]
                    .forEach(operador => formula = replaceAll(formula, operador.original, operador.substituto));

                ['ValidaCPFCNPJ', 'ValidaCNPJ', 'ValidaCPF', 'Len', 'Left', 'Right', 'UpperCase', 'LowerCase']
                    .forEach(metodo => formula = replaceAll(formula, metodo, 'faseService.' + metodo));

                formula = replaceAll(formula, 'faseService.faseService.', 'faseService.');
                // eslint-disable-next-line
                retorno = eval(formula);
                if (typeof retorno !== "boolean")
                    if (isFinite(retorno)) {
                        //colocar 2 casas decimais
                        retorno = parseFloat(retorno).toLocaleString('pt-br', { minimumFractionDigits: 2 });
                    }
            }
            catch (erro) {
                console.log(erro);
            }
        }

    }
    else {
        retorno = "ERRO: Fórmula inválida";
    }

    return retorno;
}

function PosicaoAtual(data) {
    const SOMATORIA = 13;
    const FINALIZADOR = 16;
    let ret = 0;
    if (Array.isArray(data)) {
        if (data[data.length - 1].estaOK)
            return data.length - 1;
        for (let index = 0; index < data.length; index++) {
            let campo = data[index];
            if (campo) {
                if (ignorarPorqueCampoPaiNaoPossuiValor(campo, data)) {
                    ret++;
                    continue;
                }

                if (!campo.idFaseSessaoCampoQualificador && !campo.permiteEdicao) {
                    ret++;
                    continue;
                }

                if (campo.exibeCampoFormula) {
                    let resultado = CalculaFormula(campo.exibeCampoFormula, data);
                    if (!resultado) {
                        ret++;
                        continue;
                    }
                }
                if (campo.idFaseSessaoCampoTipo === SOMATORIA) {
                    ret++;
                    continue;
                }
                if (campo.statusCadastro && campo.statusCadastro.toUpperCase() === 'PENDENTE')
                    break;
                if (!campo.valor && !campo.formula) {
                    if (!campo.estaOK) {
                        break;
                    }
                    continue;
                }
                if (campo.idFaseSessaoCampoTipo === FINALIZADOR) {
                    if (campo.estaOK) {
                        break;
                    } else {
                        ret++;
                        continue;
                    }
                }
                ret++;
            };
        }
        if (ret >= data.length) {
            return data.length - 1;
        }
        let campo = data[ret];
        //console.log("->",campo.originalIndex, campo.campo, campo);

        return ret;
    }
}

function ignorarPorqueCampoPaiNaoPossuiValor(campo, data) {
    if (campo.idFaseSessaoCampoPai > 0) {
        let campoPai = data.filter(pai => {
            return pai.idFaseSessaoCampo === campo.idFaseSessaoCampoPai &&
                pai.originalIndex < campo.originalIndex
        });

        if (campoPai.length > 0)
            campoPai = campoPai[campoPai.length - 1];

        if (campoPai && ((campoPai.valor || 'Não possui') === 'Não possui') ||
            campoPai.valor === '') {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

function replaceAll(str, needle, replacement) {
    return str.split(needle).join(replacement);
}

function isDateValid(value) {
    if (value) {
        let parts = value.split('/');
        if (parts.length === 3)
            return (new Date(parts[2], parts[1], parts[0]) !== "Invalid Date" && !isNaN(new Date(parts[2], parts[1], parts[0]))) ? true : false;
        else
            return false;
    } else {
        return false;
    }
}

function getTicks(dateValue) {
    let from = dateValue.split('/');

    var dNew = new Date(from[2], from[1], from[0]);
    var dStart = new Date(1970, 1, 1);
    var seconds = dNew.getTime();
    var secondsStart = dStart.getTime();
    var dateDifference = seconds - secondsStart;
    // multiply by 10000 to reconcile to c#
    return dateDifference * 10000;
}

function ValidaEmail(email) {
    var emailPattern = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
    return emailPattern.test(email);
}