import axiosInstance from '../core/httpInterceptor';
import { objectToQueryString } from '../core/util';

export const processoService = {
    getProcessos,
    getAcoesProcessos,
    getProcessoFases,
    getProcessoPartes,
    getProcessoMovimentacoes,
    insAcoesProcessos,
    aprovarProcessos,
    reprovarProcessos,
    getMotivos,
    getProcesso,
    getPermissao,
    updateProcessoResponsavel,
    getProcessoFundo,
};

function getProcessos(carteiraId, idProcessoStatus, filtro, paginacao, filtroPesquisa) {
    let flagTodos = filtro.FlagTodos ? filtro.FlagTodos : filtro.flagTodos;
    let data = { carteiraId, FlagTodos: parseInt(flagTodos), idProcessoStatus, paginacao, filtroPesquisa, chkInativa: filtro.inativoChecked };
    return axiosInstance.post('/Processo/GetProcessos', data);
}

function getAcoesProcessos(idCarteira, tbListaProcessos) {
    return axiosInstance.post('/AcaoProcesso/GetAcaoProcesso', { idCarteira, tbListaProcessos });
}

function getProcessoFases(idCarteiraProcessoHistorico) {
    return axiosInstance.get('/Processo/GetProcessos/Fases/' + idCarteiraProcessoHistorico);
}

function getProcessoPartes(idCarteiraProcessoHistorico) {
    return axiosInstance.get('/Processo/GetProcessos/Partes/' + idCarteiraProcessoHistorico);
}

function getProcessoMovimentacoes(idCarteiraProcessoHistorico) {
    return axiosInstance.get('/Processo/GetProcessos/Movimentacoes/' + idCarteiraProcessoHistorico);
}

function insAcoesProcessos(processos, idCarteira, idProcessoAcao) {
    let data = { tbListaProcessos: processos, idCarteira, idProcessoAcao };
    return axiosInstance.post('/AcaoProcesso/InsAcaoProcesso', data);
}

function getPermissao(processos, idCarteira, idProcessoAcao) {
    let data = { tbListaProcessos: processos, idCarteira, idProcessoAcao };
    return axiosInstance.post('/AcaoProcesso/GetPermissao', data);
}

function aprovarProcessos(processos, idCarteira, idProcessoAcao) {
    let data = { tbListaProcessos: processos, idCarteira, idProcessoAcao };
    return axiosInstance.post('/AcaoProcesso/AprovarProcessos', data);
}

function reprovarProcessos(processos, idCarteira, idProcessoAcao, observacao, idMensagem) {
    let data = { tbListaProcessos: processos, idCarteira, idProcessoAcao, observacao, idMensagem };
    return axiosInstance.post('/AcaoProcesso/ReprovarProcessos', data);
}

function getMotivos(idProcessoStatus) {
    return axiosInstance.get('/AcaoProcesso/GetMotivos/' + idProcessoStatus);
}

function getProcesso(numeroProcesso) {
    return axiosInstance.get('/Processo/GetProcessoIncCarteira?numeroProcesso=' + numeroProcesso);
}

function updateProcessoResponsavel(idProcessos, idUsuario) {
    let data = { idProcessos, idUsuario };
    return axiosInstance.post('/Processo/AtualizarProcessoResponsavel', data);
}

function getProcessoFundo(carteiraId, idProcesso, paginacao) {
    return axiosInstance.get('/Processo/GetProcessoFundo/' + carteiraId + '/' + idProcesso + '?' + objectToQueryString({ page: paginacao }));
}