import axiosInstance from '../core/httpInterceptor';

export const listaService = {
    getListas,
    getListaValores,
    getListaGrupos,
    insListaValor,
    updListaValor,
    delListaValor,
    insListaCSV
}

function getListas(idLista = null){
    return axiosInstance.get(`/Lista/GetListas/${idLista}`);
}

function getListaValores(idLista, paginacao, filtroPesquisa){
    return axiosInstance.post(`/Lista/GetListaValores/${idLista}`, {paginacao, filtroPesquisa});
}

function getListaGrupos(idLista){
    return axiosInstance.post(`/Lista/GetListaGrupos/${idLista}`);
}

function insListaValor(lista){
    return axiosInstance.post(`/Lista/InsListaValor`, lista)
}

function updListaValor(lista){
    return axiosInstance.post(`/Lista/UpdListaValor`, lista)
}

function delListaValor(lista){
    return axiosInstance.post(`/Lista/DelListaValor`, lista)
}

function insListaCSV(fileCSV){
    return axiosInstance.post('/Lista/InsListaCSV', fileCSV);       
};