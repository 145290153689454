import React, { Component } from 'react';
import { connect } from 'react-redux';
import { processoActions, workFlowActions, filtroActions } from '../../actions';
import Grid from '../Grid/Grid';
import { columnsDefinition } from './Columns';
import InfiniteScroll from "react-infinite-scroll-component";
import { WorkFlowPassos } from '../../components/WorkFlowPassos/WorkFlowPassos';
import _ from 'lodash';
import {pushWarning} from '../../services/notifierService';
import { withStyles } from '@material-ui/core/styles';
import { Backdrop, CircularProgress } from '@material-ui/core';

const useStyles = theme => ({    
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});

class ProcessosTable extends Component {
    constructor(props) {
        super(props);
        this.state = {inativoChecked: false};
        this.checkboxClick = this.checkboxClick.bind(this);
        this.alteraProcessoStatus = this.alteraProcessoStatus.bind(this);
        this.alteraFiltroFlagTodos = this.alteraFiltroFlagTodos.bind(this);
        this.alteraFiltroInativo = this.alteraFiltroInativo.bind(this);
        this.handleCloseBackdrop = this.handleCloseBackdrop.bind(this);
        this.state = { paginacao: this.paginacaoInicial(), idProcessoStatus: null, selection: [], idCarteiraProcesso: null, idFlagTodos: null, loading: false };
    }

    handleCloseBackdrop() {
        this.setState({ loading: false });
    }

    componentDidMount(){
        columnsDefinition[0].props.onupdate = this.checkboxClick;
    }

    componentDidUpdate(prevProps) {
        var filtro = this.props.filtro;
            filtro.inativoChecked = this.state.inativoChecked;
        if (this.props.idCarteira !== prevProps.idCarteira) {
            let paginacao = this.paginacaoInicial();
            let props = {
                carteiraId: this.props.idCarteira,
                paginacao: paginacao,
                idProcessoStatus: null,
                filtro: { FlagTodos: this.props.flagCarteira === -1 ? -1 : 2, inativoChecked: this.state.inativoChecked },
                filtroPesquisa: this.props.filtros
            };
            if ((!prevProps.idCarteira && this.props.idCarteiraProcesso) || this.props.idCarteiraProcesso !== prevProps.idCarteiraProcesso) {
                props.filtro = { FlagTodos: 1 };
                this.setState({ idCarteiraProcesso: this.props.idCarteiraProcesso });
            }
            this.setState({ paginacao, idProcessoStatus: null });
            this.props.getProcessos(props)
        } else if (this.props.atualizacao !== prevProps.atualizacao) {
            let props = {
                carteiraId: this.props.idCarteira,
                paginacao: this.state.paginacao,
                idProcessoStatus: this.state.idProcessoStatus,
                filtro,
                filtroPesquisa: this.props.filtros
            };
            setTimeout(() => {
                this.props.getProcessos(props)
            }, 100);            
            this.props.getPassos(this.props.idCarteira);
        }else if (this.props.reload !== prevProps.reload){
            let paginacao = _.cloneDeep(this.state.paginacao);
            paginacao.page.size = paginacao.page.number * 25;
            paginacao.page.number = 1;
            let props = {
                carteiraId: this.props.idCarteira,
                paginacao: paginacao,
                idProcessoStatus: this.state.idProcessoStatus,
                filtro,
                filtroPesquisa: this.props.filtros
            };
            this.props.getProcessos(props)
            this.props.getPassos(this.props.idCarteira);
        }
    }

    checkboxClick(props, lineClick = false) {
        if(!lineClick && (this.props.selecionados.length === 0 || (this.props.selecionados.length === 1 && this.props.selecionados[0] === props.index))){
            return;
        }
        let checkeds = _.cloneDeep(this.props.selecionados) ?? [];
        if (checkeds.indexOf(props.index) === -1) {
            checkeds.push(props.index);
        } else {
            checkeds.splice(checkeds.indexOf(props.index), 1);
        }
        let processosSelecionados = [];
        let processos = this.props.processos;
        processos.forEach((processo, index) => {
            processo.countselecionados = checkeds.length;
            if(_.indexOf(checkeds, index) === -1){
                processo.selecionado = false;
            }else{
                processo.selecionado = true;
                processosSelecionados.push({ idProcesso: processo.idProcesso, idCarteira: processo.idCarteira })
            }
        });

        if (checkeds.length === 0) {
            this.setState({
                selection: []
            });
            this.props.limpaAcoes();
            this.props.atualizaSelecionados([], processos);
        } else {
            let carteiraDiferente =  _.some(processosSelecionados, (proc) =>{return proc.idCarteira !== processosSelecionados[0].idCarteira});
            if(carteiraDiferente){
                pushWarning('Não é possível definir ações para processos de carteiras distintas')
            }else{
                this.props.getAcoesProcessos(processosSelecionados[0].idCarteira, _.map(processosSelecionados, (processo) =>{return processo.idProcesso}));
                this.setState({
                    selection: checkeds
                });
                this.props.atualizaSelecionados(checkeds, processos);
            }
        }
    }

    proximaPagina() {
        let paginacao = this.state.paginacao;
        paginacao.page.number++;
        var filtro = this.props.filtro;
            filtro.inativoChecked = this.state.inativoChecked;
        this.setState({ paginacao });
        this.props.proximaPaginaProcessos({ carteiraId: this.props.idCarteira, paginacao, filtro, idProcessoStatus: this.state.idProcessoStatus });
    }

    alteraProcessoStatus(idProcessoStatus) {
        if (this.props.idCarteira != null) {
            if (idProcessoStatus === this.state.idProcessoStatus) {
                idProcessoStatus = null;
            }
            let paginacao = this.paginacaoInicial();
            let props = {
                carteiraId: this.props.idCarteira,
                paginacao: paginacao,
                idProcessoStatus: idProcessoStatus,
                filtro: { FlagTodos: 1, inativoChecked: this.state.inativoChecked },
                filtroPesquisa: this.props.filtros
            };
            this.setState({ paginacao, idProcessoStatus });
            this.props.getProcessos(props)
            this.props.adicionaFiltroStatusFlag(idProcessoStatus, this.state.idFlagTodos)
        }
    }

    alteraFiltroFlagTodos(idFlagTodos) {
        if (this.props.idCarteira != null) {
            let paginacao = this.paginacaoInicial();
            let props = {
                carteiraId: this.props.idCarteira,
                paginacao: paginacao,
                idProcessoStatus: this.state.idProcessoStatus,
                filtro: { FlagTodos: idFlagTodos, inativoChecked: this.state.inativoChecked },
                filtroPesquisa: this.props.filtros
            };
            this.setState({paginacao, idFlagTodos});
            this.props.getProcessos(props);
            this.props.adicionaFiltroStatusFlag(this.state.idProcessoStatus, idFlagTodos)
        }
    }

    alteraFiltroInativo(inativoChecked) {
        if (this.props.idCarteira != null) {
            let paginacao = this.paginacaoInicial();
            let props = {
                carteiraId: this.props.idCarteira,
                paginacao: paginacao,
                idProcessoStatus: this.state.idProcessoStatus,
                filtro: { FlagTodos: this.state.idFlagTodos ? this.state.idFlagTodos : 2, inativoChecked: inativoChecked },
                filtroPesquisa: this.props.filtros,                
            };
            this.props.getProcessos(props);
            this.setState({ inativoChecked });
            //this.props.adicionaFiltroStatusFlag(this.state.idProcessoStatus, idFlagTodos)
        }
    }

    paginacaoInicial() {
        return { page: { number: 1, size: 25 } };
    }

    render() {        
        const { classes } = this.props;
        const [data] = [this.props.processos];
        const load = this.props.loading;
        let columnFluxo = _.some(columnsDefinition, {'column' : 'imagemFluxo'});
        if(this.props.idCarteira === 0 && !columnFluxo){
            columnsDefinition.push( {
                "render": "ConcatColumns",
                "column": "idCarteira",
                "title": "Carteira",
                "headerClassName": "text-left",
                "props": {
                    "concat_columns":["nomeCarteira"],
                    "separator": "-"
                }
            });
            columnsDefinition.push( {
                "column": "imagemFluxo",
                "title": "Fluxo",
                "headerClassName": "text-left",
                "width": 38,
                "render":"IconToolTip",     
                "props": {
                    "tooltip":"dsFluxo"
                }
            });
        }else if (this.props.idCarteira !== 0 && columnFluxo){
            _.remove(columnsDefinition, {'column' : 'imagemFluxo'} )
            _.remove(columnsDefinition, {'column' : 'idCarteira'} )
        }
        return (
            <>
                <WorkFlowPassos
                    idCarteira={this.props.idCarteira}
                    filtro={this.props.filtro}
                    alteraProcessoStatus={this.alteraProcessoStatus}
                    alteraFiltroFlagTodos={this.alteraFiltroFlagTodos}
                    alteraFiltroInativo={this.alteraFiltroInativo}
                    idProcessoStatus={this.state.idProcessoStatus}
                    adicionarProcesso={this.props.adicionarProcesso}
                    statusFluxo={this.props.statusFluxo}
                />
                <InfiniteScroll
                    style={{ width: '100%', fontSize: 10 }}
                    dataLength={this.props.processos.length}
                    next={() => { this.proximaPagina() }}
                    hasMore={this.props.processos.length !== this.props.count}
                    height="100%"
                >
                     {load &&
                        <Backdrop className={classes.backdrop} open={load} onClick={this.handleCloseBackDrop}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    }                   
                    <Grid 
                        selecionados={this.props.selecionados}
                        data={data}
                        style={{ width: '100%', fontSize: 10, position: 'static' }}
                        columns={columnsDefinition}
                        loading={this.props.loading.processos}
                        minPage={1}
                        pageSize={this.props.processos.length}
                        getTrProps={(state, rowInfo, instance) => {
                            let colorRow = '#FFFFFF';
                            let colorFont = '#4b4b4d';
                            let cursor = '';
                            if (this.props.selecionados.length === 0 || (this.props.selecionados.length === 1 && this.props.selecionados[0] === rowInfo.index)) {
                                cursor = 'pointer';
                            }
                            if (rowInfo && rowInfo.original && rowInfo.original.idCarteiraProcesso.toString() === this.state.idCarteiraProcesso) {
                                colorRow = rowInfo.original.processoCorHexadecimal;
                                colorFont = "#FFFFFF";
                                setTimeout(function () {
                                    this.setState({ idCarteiraProcesso: null });
                                }.bind(this), 5000);
                            }
                            return {
                                style: {
                                    background: colorRow,
                                    color: colorFont,
                                    cursor: cursor
                                },
                                onClick: () => {
                                    if (this.props.selecionados.length === 0 || (this.props.selecionados.length === 1 && this.props.selecionados[0] === rowInfo.index)) {
                                        this.checkboxClick(rowInfo, true);
                                    }
                                }
                            };
                        }}
                    />
                </InfiniteScroll>
            </>
        );
    }
}

function mapState(state) {
    const { processos, loading, count, selecionados, filtro, atualizacao, reload } = state.processos;
    const {filtros, btnPesquisarClick} = state.filtros;
    const flagCarteira  = state.carteiras.filtro.flagTodos;
    return { flagCarteira, processos, loading, count, selecionados, filtro, atualizacao, filtros, btnPesquisarClick, reload };
}

const actionCreators = {
    getProcessos: processoActions.getProcessos,
    proximaPaginaProcessos: processoActions.proximaPaginaProcessos,
    atualizaSelecionados: processoActions.atualizaSelecionados,
    getAcoesProcessos: processoActions.getAcoesProcessos,
    getPassos: workFlowActions.getPassos,
    limpaAcoes: processoActions.limpaAcoes,
    adicionaFiltroStatusFlag: filtroActions.adicionaFiltroStatusFlag
};

const connectedProcessosTable = connect(mapState, actionCreators)(withStyles(useStyles)(ProcessosTable));
export { connectedProcessosTable as ProcessosTable };