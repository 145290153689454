import { filtrosConstants } from '../constants';
import _ from 'lodash';

const filtroState = { campos: [], filtros: [], btnPesquisarClick: 1 };

const filtros = (state = filtroState, action) => {
    switch (action.type) {
        case filtrosConstants.ADICIONAR_CAMPOS:
            return {
                ...state,
                campos: _.uniqBy(_.concat(state.campos, action.campos), 'cod')
            };
        case filtrosConstants.ATUALIZAR_CAMPOS:
            return {
                ...state,
                campos: action.campos
            };
        case filtrosConstants.ADICIONAR_FILTROS:
            return {
                ...state,
                filtros: _.uniqBy(_.concat(state.filtros, action.filtros), 'chave')
            };
        case filtrosConstants.ATUALIZAR_FILTROS:
            return {
                ...state,
                filtros: action.filtros
            };
        case filtrosConstants.LIMPAR:
            return {
                ...state,
                campos: [],
                filtros: []
            };
        case filtrosConstants.BOTAO_PESQUISA_CLIQUE:
            return {
                ...state,
                btnPesquisarClick: state.btnPesquisarClick * -1
            };
        case filtrosConstants.ADICIONAR_FILTRO:
            return {
                ...state,
                filtros: [...state.filtros, action.filtro]
            };
        case filtrosConstants.ADICIONAR_STATUS_FLAG:
            return {
                ...state,
                statusFlag: [action.statusFlag]
            };
        default:
            return state
    }
}

export default filtros;