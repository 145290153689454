import { usuariosConstants } from '../redux/constants';
import { usuarioService } from '../services';
import history from '../services/history';
import {trataCpf} from '../core/util';

export const usuarioActions = {
    login,
    logout,
    novaSenha,
    alterarUsuario
};

function login(cpf, senha) {
    return dispatch => {
        dispatch(request({ cpf, senha }));

        usuarioService.login(cpf, senha)
            .then(
                response => {
                    var data = response.result;
                    if (data.authenticated) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        data.usuario.cpf = trataCpf(data.usuario.cpf);
                        localStorage.setItem('authentication', JSON.stringify(data));
                        dispatch(success(data.usuario, data.permissoes));
                        if (data.usuario.fl_MudarSenha === true) {
                            history.push('/NovaSenha');
                        } else {
                            history.push('/Workflow');
                        }
                    }else{
                        dispatch(failure());
                    }
                },
            ).catch(() => {
                dispatch(failure());
            });
    };

    function request(usuario) { return { type: usuariosConstants.LOGIN, usuario, loggingIn: false } }
    function success(usuario, permissoes) { return { type: usuariosConstants.LOGIN_SUCESSO, usuario, loggingIn: false, permissoes} }
    function failure() { return { type: usuariosConstants.LOGIN_FALHA, loggingIn: false } }
}

function logout() {
    usuarioService.logout();
    return { type: usuariosConstants.LOGOUT };
}

function novaSenha(usuario) {
    return dispatch => {
        dispatch(request(usuario));
        usuarioService.novaSenha(usuario)
            .then(
                response => {
                    dispatch(success());
                    history.push('/Dashboard');
                }
        );
    };

    function request(usuario) { return { type: usuariosConstants.NOVA_SENHA, usuario } }
    function success() { return { type: usuariosConstants.NOVA_SENHA_SUCESSO } }
}

function alterarUsuario(usuario) {
    return dispatch => {
        dispatch(request(usuario));
        usuarioService.alterarUsuario(usuario)
            .then(
                response => {
                    if(response.result && response.result.usuario){
                        var data = response.result.usuario;
                        dispatch(success(data));
                        history.push('/Workflow');
                    }
                }
        );
    };

    function request(usuario) { return { type: usuariosConstants.ATUALIZAR_USUARIO, usuario } }
    function success(usuario) { return { type: usuariosConstants.ATUALIZAR_USUARIO_SUCESSO, usuario } }
}