import React from 'react';

export const Upload = (props) => {
    let hiddenInputStyle = {
        position: 'absolute',
        top: '-9999px'
    }

    function onChange(e){
        let files = e.target.files;
        if(files.length > 0){
            props.onChange(e.target.files[0]);
        }
    }

    return (
        <>
            <input 
                style={hiddenInputStyle}
                type="file" 
                name="file"
                onChange={(e) => onChange(e)} 
                ref={props.inputRef}
                accept={props.accept ?? "*"}
            />
        </>
    )
}