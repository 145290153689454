import React, { Component } from 'react';
import './AcoesProcesso.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export class AcoesProcesso extends Component {
    render() {
        return (
            <>
                {this.props.acoes && this.props.acoes.length > 0 &&
                    <div className="box-acoes">
                        <span className="lblAcoes">Ações</span>
                        <span className="box-divisor-vertical"></span>
                        <div className="box-card-acao">
                            {this.props.acoes && this.props.acoes.map((acao, index) => {
                                return (
                                    <div key={index} className="card-acao" onClick={e => {this.props.selecionarAcao(acao)}}>
                                        <span key={acao.idProcessoAcao}><FontAwesomeIcon icon={['fa', acao.icone]} /></span>
                                        <span>{acao.acaoDescricao}</span>
                                    </div>
                                )
                            })}
                        </div>
                        <span className="box-divisor-vertical"></span>
                        <span className="close-acoes" onClick={e => {this.props.closeAcoes()}}>X</span>
                    </div>
                }
            </>
        );
    }
}
