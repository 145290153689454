import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { filtroActions } from '../../actions';
import { listaService } from '../../services/listaService';
import SelectUI from 'react-select';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CsvDownloader from 'react-csv-downloader';
import { Upload } from '../../components/Upload/Upload';
import { Grid as GridTable } from '../../components/Grid/Grid';
import InfiniteScroll from "react-infinite-scroll-component";
import _ from 'lodash';
import { pushWarning } from '../../services/notifierService';
import ModalConfirm from '../../components/ModalConfirm/ModalConfirm';
import { Select, MenuItem, Input } from '@material-ui/core';

const columnsDefinition = [
    {
        "column": null,
        "title": "Ativo",
        "render": "ColumnWithColorAndCheckbox",
        "props": {
            "color": "rgb(245, 114, 114)",
            "colorsecondary": "#0091c7",
            "text_column": "",
            "check_column": "idListaValor",
            "onupdate": (props) => {
            }
        },
        "style": {
            "padding": "0px",
            "height": "100%",
            "minHeight": "40px"
        },
        "height": 20,
        "width": 220,
        "headerClassName": "text-left pl-3",
    },
    {
        "column": "valor",
        "title": "Valor",
        "headerClassName": "text-left",
        "render": "Input",
        "props": {
            editable: () => {
                return true;
            }
        },
        "filterable": true
    },
    {
        "column": "listaGrupos.idListaGrupo",
        "title": "Grupo",
        "headerClassName": "text-left",
        "render": "Select",
        "props": {
            "text_column": "listaGrupos.descricao",
            editable: () => {
                return true;
            },
            onchange: () => {

            },
            getoptions: () => {

            }
        },
        "filterable": true,
        Filter: () => {
            return null
        },
        "className": "rt-rd-select-custom"
    },
];

const useStyles = theme => ({
    header: {
        margin: 5,
        display: "-webkit-box",
        '-webkit-box-align': 'center'
    },
    paper: {
        padding: 10,
        height: 'calc(100vh - 110px)',
        overflow: 'auto'
    },
    lista: {
        width: '15%'
    },
    button: {
        fontSize: 10,
        marginLeft: 5,
        fontFamily: 'Montserrat-Medium',
        textTransform: 'none'
    },
    lastButton: {
        fontSize: 10,
        marginLeft: 5,
        fontFamily: 'Montserrat-Medium',
        textTransform: 'none',
    },
    disabled: {
        display: "none"
    },
    enabled: {
        display: "contents"
    }
});

class Lista extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lista: null,
            listas: [],
            selecionados: [],
            editing: false,
            listaConfig: [],
            lstCsvConfig: [],
            listaCsv: [{id:1}],
            filtered: [],
            totalLista: 0,
            listaGrupos: [],
            regExValidacao: "",
            filtros: {},
            msgRegExValidacao: "",
            modalConfirm: {
                abrirModal: false,
                tittle: "",
                message: "",
                buttonSimClick: () => { }
            },
        }

        this.proximaPagina = this.proximaPagina.bind(this);
        this.getEditable = this.getEditable.bind(this);
        this.selecionaListaValor = this.selecionaListaValor.bind(this);
        this.getGruposOptions = this.getGruposOptions.bind(this);
        this.adicionarClick = this.adicionarClick.bind(this);
        this.editarClick = this.editarClick.bind(this);
        this.changeGrupo = this.changeGrupo.bind(this);
        this.changeValor = this.changeValor.bind(this);
        this.carregaLista = this.carregaLista.bind(this);
        this.closeModalConfirm = this.closeModalConfirm.bind(this);
        this.editarLista = this.editarLista.bind(this);
        this.excluirSelecionados = this.excluirSelecionados.bind(this);
        this.confirmarExcluirSelecionados = this.confirmarExcluirSelecionados.bind(this);

        this.abrirImportarCSV = this.abrirImportarCSV.bind(this);
        this.importarCSV = this.importarCSV.bind(this);
        this.fileInput = React.createRef();
        this.enviarCSV = this.enviarCSV.bind(this);
        this.carregaListaCsv = this.carregaListaCsv.bind(this);
        this.filterChange = this.filterChange.bind(this);

        this.timer = null;
    }

    carregaListaCsv(idLista) {
        let paginacao = {
                page: {
                    number: 1,
                    size: 9999
                }
            };
        listaService.getListaValores(idLista, paginacao, [...this.state.filtered,...this.props.filtros])
            .then((response) => {
                if (response.result && response.paginacao && response.paginacao.records) {
                    var csvDados = [];
                    response.result.forEach(item => {
                        csvDados.push({lista: this.state.listas.find(v => v.idLista === idLista).descricao, valor: item.valor, grupo: item.grupoDescricao})
                    });       
                    this.setState({listaCsv: csvDados})        
                }
            }).finally(() => {
                this.setState({ loading: false });
            });
    }

    importarCSV(file) {
        this.setState({ loading: true, arquivoCSV: file });
        let reader = new FileReader();
        let thisAtual = this;
        this.fileInput.current.value = null;
        reader.onload = function (e) {
            let csvValido = true;
            let linhas = reader.result.split(/\r\n|\n/);
            _.each(linhas, (linha, index) => {
                if (!csvValido) {
                    return false;
                }
                
                let campos = linha.split(',');
                if (campos.length !== 3 && linha.includes(',')) {
                    pushWarning("Arquivo CSV esta no formato inválido. Favor revisar o formato: LISTA, Valor, GRUPO");
                    csvValido = false;
                    return false;
                }
                _.each(campos, (campo) => {
                    if (campo.trim() === '' && linha.includes(',')) {
                        pushWarning("Existem campos que não estão preenchidos na linha " + (index + 1) + ". Por favor revise o seu arquivo CSV.");
                        csvValido = false;
                        return false;
                    }
                });
                if(index > 0  && linha.includes(',') && thisAtual.state.regExValidacao && !campos[1].match(thisAtual.state.regExValidacao)){
                    pushWarning(thisAtual.state.msgRegExValidacao);
                    csvValido = false;
                }
                if(index > 0  && linha.includes(',') &&  !thisAtual.state.listas.find(v => v.descricao === campos[0])){
                    pushWarning("Existem valores inválidos para o campo LISTA.")
                    csvValido = false;
                }
                if(thisAtual.state.listaGrupos && index > 0 && linha.includes(',') &&  !thisAtual.state.listaGrupos.find(v => v.descricao === campos[2])){
                    pushWarning("Existem valores inválidos para o campo GRUPO.")
                    csvValido = false;
                }
            });
            if (csvValido) {
                let modalConfirm = thisAtual.state.modalConfirm;
                modalConfirm.abrirModal = true;
                modalConfirm.title = 'Confirmar importação de CSV'
                modalConfirm.message = 'Tem certeza que deseja importar ' + (linhas.length - 2) + ' valor(es)?'
                modalConfirm.buttonSimClick = thisAtual.enviarCSV;
                thisAtual.setState({ modalConfirm });
            }
            thisAtual.setState({ loading: false });
        }
        reader.readAsText(file);
    }

    abrirImportarCSV() {
        this.fileInput.current.click();
    }

    enviarCSV() {
        const data = new FormData();
        data.append('file', this.state.arquivoCSV);
        listaService.insListaCSV(data).then((response) => {
            this.fileInput.current.value = null;
            this.setState({ modalConfirm: { abrirModal: false }, arquivoCSV: null })
            this.carregaLista(this.state.lista);
            this.carregaListaCsv(this.state.lista);
        });
    }

    filterChange(chave, dsc, nomeCampo, sp, tipo, valor) {
        
        let filtered = this.state.filtered;
        let id = chave;
        let index = _.findIndex(filtered, (value) => { return value.chave === chave });
        
        if(valor !== undefined){
            if (index !== -1) {
                filtered[index].valor = valor.toString();
            } else {
                filtered.push({ id, chave, dsc, nomeCampo, sp, tipo, valor: valor.toString() })
            }
        }
        
        this.setState({ filtered }, () => {
            this.carregaLista(this.state.lista);
            this.carregaListaCsv(this.state.lista);
        });
    }

    closeModalConfirm() {
        this.setState({ modalConfirm: { ...this.state.modalConfirm, abrirModal: false } });
    }

    handleInputChange(e) {
        const target = e.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value,
            loading: true
        }, () => {
            this.carregaLista(value);
            this.carregaListaCsv(value);
        });
    }

    componentDidMount() {
        listaService.getListas().then(response => {
            var listas = [];
            if (response.result) {
                response.result.forEach(lista => {
                    listas.push({ ...lista, value: lista.idLista, label: lista.descricao });
                });
            }
            this.setState({ listas }, () => {
                columnsDefinition[1].Filter = () => {
                    return <Input
                        style={{ fontSize: "10px" }}
                        fullWidth={true}
                        onChange={(e) => {
                            clearTimeout(this.timer)
                            this.timer = setTimeout(this.filterChange('Valor', '@Valor', 'Valor', '', 'string', e.target.value), 3000)
                        }
                        }
                        placeholder="Pesquise por valor"
                    />
                }

                columnsDefinition[2].Filter = () => {
                    let options = this.getGruposOptions();
                    
                    return (
                        <Select
                            style={{ fontSize: "10px" }}
                            fullWidth={true}
                            inputProps={{ 'aria-label': 'Without label' }}
                            displayEmpty
                            onChange={(e) => { 
                                let grupo = this.state.listaGrupos.find(v => v.idListaGrupo === e.target.value);
                                let grupoDescricao = grupo? grupo.descricao:"";
                                this.filterChange('Grupo', '@Grupo', 'Grupo', '', 'string', grupoDescricao); }}
                        >
                            <MenuItem style={{ fontSize: "10px" }}>Pesquise por Grupo</MenuItem>
                            <MenuItem key='TODOS' value='' style={{ fontSize: "10px" }}>TODOS</MenuItem>
                            {options.map((value, index) => {
                                return (<MenuItem key={index} value={value.value}
                                    style={{ fontSize: "10px" }}
                                >{value.label}</MenuItem>);
                            })}
                        </Select>);
                }
            });
        })
    }

    componentDidUpdate() {
        if (JSON.stringify(this.props.filtros) !== JSON.stringify(this.state.filtros) && this.state.lista != null) {
            this.setState({ ...this.state, filtros: this.props.filtros }, () => {
                this.carregaLista(this.state.lista);
                this.carregaListaCsv(this.state.lista);
            });
        }
    }

    proximaPagina() {
        this.setState({ loading: true })
        let paginacao = this.state.paginacao;
        paginacao.page.number++;
        listaService.getListaValores(paginacao, [...this.state.filtered,...this.props.filtros], this.state.lista)
            .then((response) => {
                let listaConfigResponse = _.map(response.result, (value) => {
                    value.countselecionados = this.state.selecionados.length;
                    value.selecionado = false;
                    return value;
                });
                let listaConfig = _.union(this.state.listaConfig, listaConfigResponse);
                this.setState({
                    listaConfig,
                    totalLista: response.paginacao.records.count                    
                })
            }).finally(() => {
                this.setState({ loading: false });
            });
        this.setState({ paginacao });
    }

    carregaLista(idLista) {
        let paginacao = {
            page: {
                number: 1,
                size: 25
            }
        };
        let state = this.state;
        state.paginacao = paginacao;
        state.regExValidacao = this.state.lista ? this.state.listas.find(v => v.idLista === idLista).regExValidacao : "";
        state.msgRegExValidacao = this.state.lista ? this.state.listas.find(v => v.idLista === idLista).msgRegExValidacao: "";        
        

        listaService.getListaValores(idLista, paginacao, [...this.state.filtered, ...this.props.filtros])
            .then((response) => {
                if (response.result && response.paginacao && response.paginacao.records) {
                    state.listaConfig = response.result.map(row => {                        
                        return {...row, idLista, listaGrupos: {idListaGrupo: row.idListaGrupo, descricao: row.grupoDescricao}}
                    });
                    state.totalLista = response.paginacao.records.count;
                }
                if (response.filtro && response.filtro.campos) {
                    this.props.adicionarCampos(response.filtro.campos);
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                listaService.getListaGrupos(idLista).then(response => {
                    if (response.result) {
                        state.listaGrupos = response.result;
                    }
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    state.listaCsv = this.state.listaCsv;
                    state.loading = false;
                    this.setState(state, () => {
                        
                        columnsDefinition[0].props.onupdate = this.selecionaListaValor;
                        columnsDefinition[2].props.getoptions = this.getGruposOptions;
                        columnsDefinition[1].props.editable = this.getEditable;
                        columnsDefinition[2].props.editable = this.props.listaGrupos ? () => { return false; } : this.getEditable;
                        columnsDefinition[1].props.onchange = this.changeValor;
                        columnsDefinition[2].props.onchange = this.changeGrupo;

                        
                    })
                })
            });
    }

    getGruposOptions() {
        return _.map(this.state.listaGrupos, (value) => {
            return { id: value.idListaGrupo, name: value.descricao, value: value.idListaGrupo, label:value.descricao };
        }) || [];
    }

    changeGrupo(value, text, row) {
        let listaConfig = this.state.listaConfig;
        let index = _.findIndex(this.state.listaConfig, (value) => { return value.idListaValor === row.original.idListaValor });
        if (index !== -1) {
            listaConfig[index].idListaGrupo = value;
            listaConfig[index].listaGrupos = this.state.listaGrupos.find((grupo) => grupo.idListaGrupo ===value);
            this.setState({ listaConfig });
        }
    }

    changeValor(value, row) {
        let listaConfig = this.state.listaConfig;
        let index = _.findIndex(this.state.listaConfig, (value) => { return value.idListaValor === row.original.idListaValor });
        if (index !== -1) {
            listaConfig[index].valor = value;
            this.setState({ listaConfig });
        }
    }

    getEditable(row) {
        let retorno = false;
        if (this.state.editing && _.findIndex(this.state.selecionados, (value) => { return value.idListaValor === row.original.idListaValor }) !== -1) {
            retorno = true;
        }
        return retorno;
    }

    selecionaListaValor(row, lineClick = false) {
        if (!this.state.editing && !lineClick && (this.state.selecionados.length === 0 || (this.state.selecionados.length === 1 && this.state.selecionados[0].idListaValor === row.original.idListaValor))) {
            return;
        }
        let checkeds = this.state.selecionados;
        let listaValor = row.original;
        let indexSelecionados = _.findIndex(checkeds, function (o) { return o.idListaValor === listaValor.idListaValor; })
        let cancelarSelecao = indexSelecionados === -1 ? null : _.clone(checkeds[indexSelecionados]);
        if (indexSelecionados === -1) {
            if (this.state.editing) {
                pushWarning("Encerre a edição antes de selecionar novos registros.");
                return;
            }
            let checked = _.clone(listaValor);
            checked.grupo = _.clone(listaValor.idListaGrupo);
            checkeds.push(checked);
        } else {
            _.remove(checkeds, (o) => { return o.idListaValor === listaValor.idListaValor });
        }
        let listaConfig = _.map(this.state.listaConfig, (value) => {
            if (indexSelecionados !== -1 && value.idListaValor === cancelarSelecao.idListaValor) {
                value = cancelarSelecao;
            }
            value.countselecionados = checkeds.length;
            value.selecionado = _.findIndex(checkeds, function (o) { return o.idListaValor === value.idListaValor; }) !== -1;
            return value;
        });
        if (this.state.editing && checkeds.length === 0) {
            this.setState({ editing: false });
        }
        this.setState({ selecionados: checkeds, listaConfig });
    }

    adicionarClick() {
        let { editing, listaConfig, selecionados } = this.state;
        var newObj = {
            idListaValor: 0,
            listaGrupos: {idListaGrupo: 0, descricao: ''},
            valor: "",
            idLista: this.state.lista,
            regExValidacao: "",
            selecionado: true,
            countselecionados: 1
        }
        this.state.selecionados.unshift(newObj);
        this.state.listaConfig.unshift(newObj);
        this.setState({
            editing: !editing,
            selecionados: selecionados,
            listaConfig: listaConfig
        });
    }

    editarLista() {
        this.setState({ modalConfirm: { abrirModal: false }, loading: true, editing: false });
        let { listaConfig, selecionados } = this.state;
        let editLista = _.filter(listaConfig, (value) => { return (_.findIndex(selecionados, (v) => { return v.idListaValor === value.idListaValor }) !== -1) })
                        .map((v) => {return {...v, Lista: this.state.listas.find(l => l.idLista === this.state.lista).descricao, 
                                            GrupoDescricao: v.listaGrupos.descricao}});
        if(editLista[0].idListaValor === 0){
            listaService.insListaValor(editLista).finally(() => {
                this.setState({ loading: false, selecionados: [] });
                this.carregaLista(this.state.lista);
                this.carregaListaCsv(this.state.lista);
            });
        }else{
            listaService.updListaValor(editLista).finally(() => {
                this.setState({ loading: false, selecionados: [] });
                this.carregaLista(this.state.lista);
                this.carregaListaCsv(this.state.lista);
            });
        }
    }

    editarClick() {
        let editing = this.state.editing;
        let lstDescNOk = [];
        if (editing && this.state.selecionados.length > 0) {
            let { listaConfig, selecionados } = this.state;
            lstDescNOk = _.filter(listaConfig, (value) => { return (_.findIndex(selecionados, (v) => { return v.idListaValor === value.idListaValor && value.valor !== '' && value.idGrupoLista !== 0}) !== -1) });
                    
            if (lstDescNOk.length === 0) {
                pushWarning("Por favor, preencher todos os campos.");
            } else {
                let lstDescRegex = false;
                if(this.state.regExValidacao){
                    _.forEach(selecionados, (value) => {
                        let config = _.find(listaConfig, {idListaValor : value.idListaValor});
                        if(!config.valor.match(this.state.regExValidacao)){
                            lstDescRegex = true;
                        }    
                    });
                }
                if(lstDescRegex){                   
                    pushWarning(this.state.msgRegExValidacao);
                }else{
                    this.setState({
                        modalConfirm: {
                            abrirModal: true,
                            tittle: "Confirmação de edição",
                            message: "Tem certeza que deseja incluir " + this.state.selecionados.length + " valor?",
                            buttonSimClick: this.editarLista
                        }
                    })
                }
            }
        } else {
            this.setState({ editing: !editing });
        }
    }

    confirmarExcluirSelecionados() {
        let modalConfirm = this.state.modalConfirm;
        modalConfirm.abrirModal = true;
        modalConfirm.title = 'Confirmar Exclusão'
        modalConfirm.message = 'Tem certeza que deseja excluir ' + this.state.selecionados.length + ' Valor(es)?'
        modalConfirm.buttonSimClick = this.excluirSelecionados;
        this.setState({ modalConfirm });
    }

    excluirSelecionados() {
        let listaConfigExcluir = _.map(this.state.selecionados, (value) => {
            return { idListaValor: value.idListaValor };
        });
        let thisAtual = this;
        listaService.delListaValor(listaConfigExcluir).then((response) => {
            if (response.result) {
                let listaConfig = thisAtual.state.listaConfig;
                _.remove(listaConfig, function (n) {
                    return _.findIndex(thisAtual.state.selecionados, function (o) { return o.idListaValor === n.idListaValor; }) !== -1;
                });

                thisAtual.setState({ listaConfig })
            }
        }
        ).finally(() => {
            thisAtual.setState({ modalConfirm: { abrirModal: false }, selecionados: [] });
        })
    }

    render() {
        const { classes } = this.props;
        const headers = [
            { displayName: "LISTA", id: "lista" },
            { displayName: "VALOR", id: "valor" },
            { displayName: "GRUPO", id: "grupo" },
        ];

        return (
            <>
                <Grid container spacing={1}>
                    <Grid item lg={12} md={12} sm={12}>
                        <div className={classes.paper} >
                            <div className={classes.header}>
                                <SelectUI
                                    options={this.state.listas}
                                    onChange={(val) => { this.handleInputChange({ target: { name: 'lista', value: val.value } }) }}
                                    noOptionsMessage={() => { return "Não encontrado"; }}
                                    placeholder="Selecionar Lista"
                                    className={classes.lista}
                                    value={this.state.listas.filter(option => option.value === this.state.lista)}
                                />
                                <div className={!this.state.lista ? classes.disabled : classes.enabled}>
                                    <Button
                                        size="small"
                                        className={classes.button}
                                        startIcon={<FontAwesomeIcon icon={['fa', 'plus']} />}
                                        onClick={this.adicionarClick}
                                        disabled={this.state.selecionados.length !== 0}
                                    >
                                        Adicionar
                                </Button>
                                    <Button
                                        size="small"
                                        className={classes.button}
                                        startIcon={<FontAwesomeIcon icon={['fa', (this.state.editing ? 'save' : 'pencil')]} />}
                                        onClick={this.editarClick}
                                        disabled={this.state.selecionados.length === 0}
                                    >
                                        {this.state.editing ? "Encerrar Edição" : "Editar"}
                                    </Button>
                                    <Button
                                        size="small"
                                        className={classes.button}
                                        startIcon={<FontAwesomeIcon icon={['fa', 'trash-alt']} />}
                                        onClick={this.confirmarExcluirSelecionados}
                                        disabled={this.state.selecionados.length === 0 || this.state.editing}
                                    >Excluir
                                </Button>
                                    <CsvDownloader
                                        filename="Padrao Lista"
                                        separator=","
                                        wrapColumnChar=""
                                        columns={headers}
                                        datas={this.state.csvPadrao}
                                    >
                                        <Button
                                            size="small"
                                            className={classes.button}
                                            startIcon={<FontAwesomeIcon icon={['fa', 'file-download']} />}
                                        >
                                            Download Padrão
                                    </Button>
                                    </CsvDownloader>
                                    <Button
                                        size="small"
                                        className={classes.lastButton}
                                        startIcon={<FontAwesomeIcon icon={['fa', 'file-csv']} />}
                                        onClick={this.abrirImportarCSV}
                                        disabled={this.state.selecionados.length !== 0 || this.state.editing}
                                    >
                                        Importar CSV
                                </Button>
                                    <CsvDownloader
                                        filename="Lista Exportada"
                                        separator=","
                                        wrapColumnChar=""
                                        columns={headers}
                                        datas={this.state.listaCsv}>
                                        <Button
                                            size="small"
                                            className={classes.button}
                                            startIcon={<FontAwesomeIcon icon={['fa', 'file-export']} />}
                                        >
                                            Exportar
                                        </Button>
                                    </CsvDownloader>
                                    <Upload onChange={this.importarCSV} accept=".csv" inputRef={this.fileInput}></Upload>
                                    </div>
                            </div>
                            <div className={!this.state.lista ? classes.disabled : classes.enabled}>
                            <InfiniteScroll
                                style={{ width: '100%', fontSize: 10 }}
                                dataLength={this.state.listaConfig.length}
                                next={() => { this.proximaPagina() }}
                                hasMore={this.state.listaConfig.length !== this.state.totalLista}
                                height="100%"
                            >
                                <GridTable
                                    selecionados={this.state.selecionados}
                                    data={this.state.lista != null ? this.state.listaConfig : []}
                                    style={{ width: '100%', fontSize: 10, position: 'static' }}
                                    columns={this.state.lista != null ? columnsDefinition : [] }
                                    loading={this.state.loading}
                                    minPage={1}
                                    pageSize={this.state.listaConfig.length}
                                    getTrProps={(state, rowInfo, instance) => {
                                        let colorRow = '#FFFFFF';
                                        let colorFont = '#4b4b4d';
                                        let cursor = '';
                                        let onClick = () => { };

                                        if (rowInfo && (this.state.selecionados.length === 0 || (this.state.selecionados.length === 1 && this.state.selecionados[0].idListaValor === rowInfo.original.idListaValor)) && this.state.editing === false) {
                                            cursor = 'pointer';
                                            onClick = () => { this.selecionaListaValor(rowInfo, true) };
                                        }

                                        return {
                                            style: {
                                                background: colorRow,
                                                color: colorFont,
                                                cursor: cursor,
                                            },
                                            onClick: onClick
                                        };
                                    }}
                                    getTdProps={(state, rowInfo, instance) => {

                                        return {
                                            style: {
                                                overflow: 'visible'
                                            },
                                        };
                                    }}
                                    onFilteredChange={this.filterChange}
                                >
                                </GridTable>
                            </InfiniteScroll>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                {this.state.modalConfirm.abrirModal &&
                    <ModalConfirm open={this.state.modalConfirm.abrirModal} modalProps={this.state.modalConfirm} buttonCancelarClick={() => { this.closeModalConfirm() }} ></ModalConfirm>
                }
            </>
        )
    }
}

function mapState(state) {
    const { filtros, btnPesquisarClick } = state.filtros;
    return { filtros, btnPesquisarClick };
}

const actionCreators = {
    adicionarCampos: filtroActions.adicionarCampos,
};

const connectedLista = connect(mapState, actionCreators)(withStyles(useStyles)(Lista));
export { connectedLista as Lista };