import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Divider } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: "25px",
        width: "100%",
        height: "100%",
        overflow:"auto"
    },
    header: {
        fontSize: "12px",
        marginLeft: "26px"
    },
    grid: {
        paddingTop: 15,
        cursor: "pointer"
    },
    titulo: {
        fontWeight: "bold"
    },
    graficosVazio: {
        margin: "auto",
        padding: 10
    }
}));

export const AdicionarGrafico = (props) => {
    const classes = useStyles();
    const { data, onClose, adicionarGrafico } = props;

    return (
        <>
            <Paper className={classes.paper}>
                <div>
                    <FontAwesomeIcon icon={['fa', 'chart-line']} color="#0091C7" />
                    <label className={classes.header}>Adicionar Gráficos</label>
                    <label className="float-right">
                        <FontAwesomeIcon icon={['fa', 'times']} style={{ cursor: "pointer" }} onClick={onClose} />
                    </label>
                </div>
                <div>
                    {data.length === 0 &&
                        <div className={classes.f}>
                            Nenhum dashboard encontrado para ser adicionado
                        </div>
                    }
                    {data.map((grafico, index) => {
                        return (
                            <div key={index}>

                                <Grid container wrap="nowrap" spacing={2} alignItems="center" className={classes.grid} onClick={() => { adicionarGrafico(grafico) }}>
                                    <Grid item>
                                        <FontAwesomeIcon icon={['fa', grafico.icone]} style={{ fontSize: 20, color: "#0091C7" }} />
                                    </Grid>
                                    <Grid container>
                                        <Grid item>
                                            <label className={classes.titulo}>
                                                {grafico.titulo}
                                            </label>
                                        </Grid>
                                        <Grid item>
                                            <label className={classes.descricao}>
                                                {grafico.descricao}
                                            </label>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider variant="fullWidth" style={{ marginTop: 15 }} />
                            </div>
                        );
                    })
                    }
                </div>
            </Paper>
        </>
    )
}