import { filtrosConstants } from '../redux/constants';
import _ from 'lodash';
export const filtroActions = {
    adicionarCampos,
    adicionarFiltros,
    alterarFiltros,
    alterarCampos,
    limparTudo,
    botaoPesquisarClique,
    adicionaFiltro,
    adicionaFiltroStatusFlag
}

function adicionarFiltros(filtros) {
    return dispatch => {
        dispatch({ type: filtrosConstants.ADICIONAR_FILTROS, filtros });
    }
}

function alterarFiltros(filtros) {
    return dispatch => {
        dispatch({ type: filtrosConstants.ATUALIZAR_FILTROS, filtros });
    }
}

function adicionarCampos(campos) {
    let filtros = [];
    let camposFiltros = _.map(campos, (campo) => {
        if (campo.defaultValue) {
            let filtro = {
                id: campo.id,
                chave: campo.dsc.replace('@', ''),
                valor: campo.defaultValue,
                nomeCampo: campo.dsc.replace('@', ''),
                sp: campo.sprauto,
                tipo: campo.tipo,
                dsc: campo.dsc
            }
            filtros.push(filtro);
        } 

        return {
            cod: campo.id,
            desc: campo.dsc.replace('@', ''),
            autocomplete: true,
            contDeCaracter: 3,
            contDeTempo: 0,
            sp: campo.sprauto,
            tipo: campo.tipo,
            dsc: campo.dsc,
            defaultValue: campo.defaultValue
        };
    });


    return dispatch => {
        dispatch({ type: filtrosConstants.ADICIONAR_CAMPOS, campos: camposFiltros});
        dispatch({ type: filtrosConstants.ADICIONAR_FILTROS, filtros });
    }
}

function alterarCampos(campos) {
    return dispatch => {
        dispatch({ type: filtrosConstants.ATUALIZAR_CAMPOS, campos });
    }
}

function limparTudo() {
    return dispatch => {
        dispatch({ type: filtrosConstants.LIMPAR });
    }
}

function botaoPesquisarClique() {
    return dispatch => {
        dispatch({ type: filtrosConstants.BOTAO_PESQUISA_CLIQUE });
    }
}

function adicionaFiltro(campo, valor) {
    let filtro = {
        id: campo.cod,
        chave: campo.desc,
        valor: valor,
        nomeCampo: campo.desc,
        sp: campo.sp,
        tipo: campo.tipo,
        dsc: campo.dsc
    };
    return dispatch => {
        dispatch({ type: filtrosConstants.ADICIONAR_FILTRO, filtro });
    }
}

function adicionaFiltroStatusFlag(status, flag) {
    let statusFlag = {
        status,
        flag
    };
    return dispatch => {
        dispatch({ type: filtrosConstants.ADICIONAR_STATUS_FLAG, statusFlag });
    }
}