import axiosInstance from '../core/httpInterceptor';
import {objectToQueryString} from '../core/util';

export const relatorioService = {
    getRelatorioDados,
    insRelatorio,
    updRelatorio,
    delRelatorio,
    getRelatorios,
    getRelatorioPerfil
};

function getRelatorioDados(data){
    return axiosInstance.post('/Relatorio/GetRelatorioDados', data);       
};

function insRelatorio(relatorio){
    return axiosInstance.post('/Relatorio/InsRelatorio', relatorio);       
};

function updRelatorio(relatorio){
    return axiosInstance.post('/Relatorio/UpdRelatorio', relatorio);       
};

function delRelatorio(idRelatorio){
    return axiosInstance.post('/Relatorio/DelRelatorio/' + idRelatorio );       
};

function getRelatorios(paginacao){
    return axiosInstance.get('/Relatorio/GetRelatorio?' + objectToQueryString(paginacao));       
}

function getRelatorioPerfil(idRelatorio){
    return axiosInstance.get('/Relatorio/GetRelatorioPerfil/' + idRelatorio);       
}