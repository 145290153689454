import { faseService } from '../../services/faseService';
import { processoService } from '../../services/processoService';

export default class EnriquecimentoData {
     constructor(faseId,carteiraProcessoHistoricoId){
        this.faseId=faseId;
        this.carteiraProcessoHistoricoId=carteiraProcessoHistoricoId;
     }

     getData=()=>{
        return new Promise((resolve,reject)=>{
            var customCollapses = []; 
            processoService.getProcessoFases(this.carteiraProcessoHistoricoId).then(response => {                           
                for (const result of response.result) {
                    if (result.idFase !== 0) {
                        customCollapses.push(result);
                    }
                }                
            }).finally(() => {
                let carteira = customCollapses.filter(c=>{return c.idFase === this.faseId })
                faseService.getFase(this.faseId).then(response=>{
                    this.fase=response.result[0];                    
                    faseService.getFaseCampo(this.faseId,carteira[0].idCarteiraProcessoHistorico).then(response=>{
                        this.dataCampos = response.result;
                        this.dadosCampos = response.result;
                        this.indiceEdicao=0;
                        resolve(this);
                    });
                    
                });
            });           
            
        });

    }
}
