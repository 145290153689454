import React, { Component } from 'react';
import {  FaseModalContainer,
          FaseModalBreadcrumb,
          FaseModalCloseIcon,          
          FaseModalVisualizacao,
          FaseModalArrowBack,
          FaseModalTitulo,
          FaseModalEtapa,
          FaseModalAreaEdicao,
          FaseModalDocumento } from './FaseModalStyled.js';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FasesLoader from '../FaseLoader/FasesLoader';

import MovimentacaoProcessoViewer from '../MovimentacaoProcessoViewer/MovimentacaoProcessoViewer';
import FaseProgresso from '../FaseProgresso/FaseProgresso';
import FaseViewer from '../FaseViewer/FaseViewer';
import FaseEditor from  '../FaseEditor/FaseEditor';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { withStyles } from "@material-ui/core/styles";

const useStyles = ((theme) => ({
  root:{
    "& .MuiPaper-root": {
      zIndex: "1210 !important",
    }
  },
  modal: {    
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius:'20px',
    zIndex:1200,
  },
  paper: {
    position: 'absolute',
    alignContent: 'center',
    left: 'calc(2.5%)',
    top: '10px',
    width: '95%',
    height: '94%',
    backgroundColor: theme.palette.background.paper,
    border: '0px solid black',
    borderRadius:'20px',
    outline: 'none',    
    zIndex:1200,
  },
}));

class FaseModal extends Component {
  constructor(props){
    super(props);
    const {faseId,carteiraProcessoHistoricoId,idUsuario,opened,titulo,processoId,carteiraId,resposta,indiceEdicao,idCarteiraProcesso,processoNumero,idProcessoStatus} = props;
    
    this.state = {
                  loaded:false,
                  aberto:false,
                  dadosCampos:[],
                  indiceEdicao,
                  resposta,
                  faseId,
                  carteiraProcessoHistoricoId,
                  opened,                
                  titulo,
                  processoId,
                  carteiraId,
                  idCarteiraProcesso,
                  processoNumero,
                  idProcessoStatus,
                  dadosConsulta:undefined,
                  idUsuario,
                  forcaIndiceEdicao:false
                }

      this.handleClose = this.handleClose.bind(this);
      this.loadState = this.loadState.bind(this);
  }
  
  componentDidMount() {
    this.loadState(); 
  }
  
  componentDidUpdate(){
    this.loadState();
  }

  loadState(){
    const {faseId,idUsuario,carteiraProcessoHistoricoId,opened,titulo,processoId,carteiraId,resposta,indiceEdicao,idCarteiraProcesso,processoNumero,idProcessoStatus} = this.props;
    
    if (opened &&
        carteiraProcessoHistoricoId && 
        faseId &&
        (carteiraProcessoHistoricoId !== this.state.carteiraProcessoHistoricoId 
      || faseId !== this.state.faseId)) {
      let loader=new FasesLoader(faseId,carteiraProcessoHistoricoId);
      loader.getData().then((data)=>{
        this.setState({loaded       : true,
                       dadosCampos  : data.dadosCampos,
                       fase         : data.fase,
                       exibeMovimentacao : data.fase.exibeMovimentacao,
                       aberto       : true,
                       faseId,
                       carteiraProcessoHistoricoId,
                       opened,
                       titulo,
                       processoId,
                       carteiraId,
                       resposta,
                       indiceEdicao,
                       idCarteiraProcesso,
                       processoNumero,
                       idUsuario,
                       mostrarPainel : 1,
                       idProcessoStatus
                      });
      });  
    }
  }
  
  handleClose(){
    if (this.props.onClose)
      this.props.onClose();
    this.setState({aberto:false,opened:false, carteiraProcessoHistoricoId: undefined, faseId: undefined});
  };

  render() {
    const { classes,openComentarios } = this.props;
    const showComentarios = () =>{
      openComentarios(this.state.idCarteiraProcesso,this.state.processoNumero);
  }


    const body = (
    <div className={classes.root}>
        <div className={classes.paper}>
          <FaseModalContainer exibeMovimentacao={this.state.exibeMovimentacao}>
            <FaseModalBreadcrumb>
              <FaseModalArrowBack>
                <ArrowBackIosIcon style={{fontSize:"small"}} onClick={this.handleClose}/>  
              </FaseModalArrowBack>
              
              <FaseModalTitulo>{this.state.titulo || ''} - {this.state.processoNumero}</FaseModalTitulo>
              <FaseModalCloseIcon>
                <CloseIcon style={{fontSize:"small"}} onClick={this.handleClose} />          
              </FaseModalCloseIcon>
            </FaseModalBreadcrumb>
            <FaseModalVisualizacao>
              <FaseViewer data={this.state.dadosCampos}
                              style={{  width: '98%',
                                        height: '98%',
                                        overflowX: 'hidden', 
                                        overflowY: 'auto'}}
                              onSelectField={(field)=>{
                                  this.setState({indiceEdicao:field.originalIndex,resposta:'',forcaIndiceEdicao:true});
                                }}
              />
            </FaseModalVisualizacao>
            <FaseModalEtapa>
              <FaseProgresso  data={this.state.dadosCampos}
                              heigth='98%'
                              width='190px' 
                              fontSize='11px'/>
            </FaseModalEtapa>
            <FaseModalAreaEdicao>
              <FaseEditor data={this.state.dadosCampos}
                            indiceEdicao={this.state.indiceEdicao} 
                            carteiraProcessoHistoricoId={this.state.carteiraProcessoHistoricoId}
                            processoId={this.state.processoId}
                            idUsuario={this.state.idUsuario}
                            carteiraId={this.state.carteiraId}
                            resposta={this.state.resposta}
                            forcaIndiceEdicao={this.state.forcaIndiceEdicao}
                            idProcessoStatus={this.state.idProcessoStatus}
                            close={()=>{ 
                              this.props.close();
                              this.handleClose(); 
                              this.setState({ aberto:false,
                                              carteiraProcessoHistoricoId: undefined,
                                              processoId : undefined,
                                              faseId: undefined,
                                              resposta:'',
                                              indiceEdicao:0,
                                              forcaIndiceEdicao:false,
                                            });
                            }}                          
                            onChange={(data)=>{     
                              this.setState({dadosCampos:data,resposta:'',indiceEdicao:0,forcaIndiceEdicao:false});
                            }}
                            onSearchChange={(termoPesquisa)=>{
                              this.setState({termoPesquisa,forcaIndiceEdicao:false})
                            }}
                            onAbortChangeField={()=>{
                              this.setState({resposta:'',indiceEdicao:0,forcaIndiceEdicao:false});
                            }}
                            onChangeDadosConsulta={(dadosConsulta,mostrarPainel)=>{
                              this.setState({dadosConsulta,mostrarPainel,forcaIndiceEdicao:false});
                            }}
                            onRequestRefresh={()=>{
                              const {faseId,carteiraProcessoHistoricoId}=this.props;
                              let loader=new FasesLoader(faseId,carteiraProcessoHistoricoId);
                              loader.getData()
                                    .then((data)=>{
                                        this.setState({dadosCampos  : data.dadosCampos, forcaIndiceEdicao:false});
                                    });
                            }}
              />
            </FaseModalAreaEdicao>
            {this.state.exibeMovimentacao &&         
            <FaseModalDocumento>            
              <MovimentacaoProcessoViewer processoId={this.state.processoId} 
                                          usarPesquisa={true}
                                          termoPesquisa={this.state.termoPesquisa}
                                          dadosConsulta={this.state.dadosConsulta}
                                          mostrarPainel={this.state.mostrarPainel}
                                          onResponse={(respostaRecebida)=>{
                                              this.setState({indiceEdicao:0,resposta:respostaRecebida,forcaIndiceEdicao:false});
                                          }} 
              /> 
            </FaseModalDocumento> 
            }        
        </FaseModalContainer>
        <div className="btn-float-comment" style={{bottom:4,right:4}} onClick={() => { showComentarios() }}>                                            
            <FontAwesomeIcon icon={['fa', 'comment-dots']} color="#0091C7" />            
        </div>
      </div>
      </div>);

      return (
        
          <Modal
              disableEnforceFocus
              className={classes.modal}
              open={this.state.aberto}
              onClose={this.handleClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
              style={{zIndex:1200}}
          >
            {body}
          </Modal>
      );
  }
}

export default withStyles(useStyles, { withTheme: true })(FaseModal);
