import React, { useEffect, useState } from 'react';
import { Divider, Input, MenuItem, Checkbox, ListItemText, FormGroup, FormControlLabel, Select } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    input: {
        fontSize: 10,
        fontFamily: 'Montserrat-Medium',
        marginBottom: 10
    },
    formControlLabel: {
        fontSize: 10,
        fontFamily: 'Montserrat-Medium',
    },
    FormControl:{
        fontSize: 10,
        fontFamily: 'Montserrat-Medium',
        width:'100%'
    }
}));

export const PropriedadesRelatorio = (props) => {
    const classes = useStyles();
    const { listaPerfil, options, handleChangeOptions, tituloRef, descricaoRef, perfilRef, relatorioEdit, perfilSelecionadoEdit, changeGridType, gridType, listaFonteRelatorio, setFonteRelatorioSelecionada, fonteRelatorioSelecionada } = props;
    const [relatorio, setRelatorio] = useState({ titulo: '', descricao: '' });
    const [perfilSelecionado, setPerfilSelecionado] = useState([]);

    useEffect(() => {
        setPerfilSelecionado([_.find(listaPerfil, function (o) { return o.iD_Perfil === 1; })]);
    }, [listaPerfil])

    useEffect(() => {
        if(relatorioEdit && relatorioEdit.idRelatorio && relatorioEdit.idRelatorio !== 0){
            setRelatorio(relatorioEdit);
            setPerfilSelecionado(perfilSelecionadoEdit);
        }
    }, [relatorioEdit, perfilSelecionadoEdit])

    function handleChange(e) {
        let rel = {};
        rel[e.target.name] = e.target.value;
        setRelatorio({ ...relatorio, ...rel });
    }

    function handleChangeMultiple(e) {
        if (_.findIndex(e.target.value, function (o) { return o.iD_Perfil === 1; }) > -1) {
            setPerfilSelecionado(e.target.value);
        }
    }

    return (<>
        <div>
            <label className={classes.header}>
                <FontAwesomeIcon icon={['fa', 'chart-bar']} color="#0091C7" />
                <span style={{ marginLeft: '26px', fontSize: '12px' }}>Propriedades do Relatório</span>
            </label>
        </div>
        <form noValidate autoComplete="off">
                <Select
                labelId="demo-mutiple-checkbox-label"
                fullWidth
                id="demo-mutiple-checkbox"
                value={fonteRelatorioSelecionada}
                input={<Input />}
                onChange={(e) => { 
                    setFonteRelatorioSelecionada(e.target.value);
                }}
                style={{
                    height: 33
                }}
                className={classes.input}
            >
                {listaFonteRelatorio.map((fonte) => (
                    <MenuItem key={fonte.idFonteRelatorio} value={fonte.idFonteRelatorio} className={classes.formControlLabel}>
                        <ListItemText primary={fonte.descricao} classes={{primary:classes.formControlLabel}} />
                    </MenuItem>
                ))}
            </Select>
            <Select
                fullWidth
                className={classes.input}
                onChange={(e) => {changeGridType(e.target.value)}}
                value={gridType}
            >
                <MenuItem className={classes.formControlLabel} value='pivot' >Tabela Pivot</MenuItem>
                <MenuItem className={classes.formControlLabel} value='grid'>Tabela Colunar</MenuItem>
            </Select>
            <Input
                name="titulo"
                value={relatorio.titulo}
                helperText="Campo obrigatório"
                placeholder="Titulo"
                inputProps={{ 'aria-label': 'titulo' }}
                onChange={handleChange}
                className={classes.input}
                fullWidth
                inputRef={tituloRef}
            />
            <Input
                name="descricao"
                value={relatorio.descricao}
                helperText="Campo obrigatório"
                placeholder="Descrição"
                inputProps={{ 'aria-label': 'descrição' }}
                onChange={handleChange}
                className={classes.input}
                fullWidth
                inputRef={descricaoRef}
            />
            <Select
                labelId="demo-mutiple-checkbox-label"
                fullWidth
                id="demo-mutiple-checkbox"
                value={perfilSelecionado}
                input={<Input />}
                onChange={handleChangeMultiple}
                renderValue={(selected) => _.map(selected, 'descricao').join(', ')}
                multiple
                style={{
                    height: 33
                }}
                className={classes.input}
                inputRef={perfilRef}
            >
                {listaPerfil.map((perfil) => (
                    <MenuItem key={perfil.iD_Perfil} value={perfil} className={classes.formControlLabel}>
                        <Checkbox
                            checked={_.findIndex(perfilSelecionado, function (o) { return o.iD_Perfil === perfil.iD_Perfil; }) > -1}
                            disabled={perfil.iD_Perfil === 1}
                        />
                        <ListItemText primary={perfil.descricao} classes={{primary:classes.formControlLabel}} />
                    </MenuItem>
                ))}
            </Select>
            <Divider orientation="horizontal" />
            {options.map((option) => {
                return (<FormGroup row>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={option.value}
                                onChange={handleChangeOptions}
                                name={option.option}
                                color="primary"
                            />
                        }
                        label={<span className={classes.formControlLabel}>{option.titulo}</span>}
                    />
                </FormGroup>);
            })}
        </form>
    </>)
}