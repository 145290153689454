export const getOptionsPivot = (optionSalvo) => {
    return [
        {
            titulo: 'Mostrar Totais Antes',
            option: 'showTotalsPrior',
            value: (optionSalvo === undefined ? false : optionSalvo['showTotalsPrior'])
        },
        {
            titulo: 'Exibir agrupamento como arvore',
            option: 'rowHeaderLayout',
            value: (optionSalvo === undefined ? false : optionSalvo['rowHeaderLayout'])
        },
        {
            titulo: 'Mostrar Totais por Linha',
            option: 'showRowTotals',
            value: (optionSalvo === undefined ? false : optionSalvo['showRowTotals'])
        },
        {
            titulo: 'Mostrar Totais Gerais da Linha',
            option: 'showRowGrandTotals',
            value: (optionSalvo === undefined ? true : optionSalvo['showRowGrandTotals'])
        },
        // {
        //     titulo: 'Permitir Classificação',
        //     option: 'allowSorting',
        //     value: (optionSalvo === undefined ? true : optionSalvo['allowSorting'])
        // },
        // {
        //     titulo: 'Permitir Classificação pelo Sumário',
        //     option: 'allowSortingBySummary',
        //     value: (optionSalvo === undefined ? true : optionSalvo['allowSortingBySummary'])
        // },
        // {
        //     titulo: 'Permitir Filtro',
        //     option: 'allowFiltering',
        //     value: (optionSalvo === undefined ? true : optionSalvo['allowFiltering'])
        // },
        {
            titulo: 'Mostrar Bordas',
            option: 'showBorders',
            value: (optionSalvo === undefined ? true : optionSalvo['showBorders'])
        },
        {
            titulo: 'Mostrar Totais Por Coluna',
            option: 'showColumnTotals',
            value: (optionSalvo === undefined ? false : optionSalvo['showColumnTotals'])
        },
        {
            titulo: 'Mostrar Totais Gerais das Colunas',
            option: 'showColumnGrandTotals',
            value: (optionSalvo === undefined ? false : optionSalvo['showColumnGrandTotals'])
        },
        {
            titulo: 'Mostrar Colunas no Cabeçalho da Grid',
            option: 'fieldPanel.showColumnFields',
            value:  (optionSalvo === undefined ? true : (optionSalvo.fieldPanel ? optionSalvo.fieldPanel.showColumnFields : optionSalvo['fieldPanel.showColumnFields']))
        },
        {
            titulo: 'Mostrar Dados no Cabeçalho da Grid',
            option: 'fieldPanel.showDataFields',
            value:  (optionSalvo === undefined ? true : (optionSalvo.fieldPanel ? optionSalvo.fieldPanel.showDataFields : optionSalvo['fieldPanel.showDataFields']))
        },
        {
            titulo: 'Mostrar Filtros no Cabeçalho da Grid',
            option: 'fieldPanel.showFilterFields',
            value:  (optionSalvo === undefined ? true : (optionSalvo.fieldPanel ? optionSalvo.fieldPanel.showFilterFields : optionSalvo['fieldPanel.showFilterFields']))
        },
        {
            titulo: 'Mostrar Linhas no Cabeçalho da Grid',
            option: 'fieldPanel.showRowFields',
            value:  (optionSalvo === undefined ? true : (optionSalvo.fieldPanel ? optionSalvo.fieldPanel.showRowFields : optionSalvo['fieldPanel.showRowFields']))
        },
        {
            titulo: 'Mostrar Painel de campos',
            option: 'fieldPanel.visible',
            value: (optionSalvo === undefined ? true : (optionSalvo.fieldPanel ? optionSalvo.fieldPanel.visible : optionSalvo['fieldPanel.visible']))
        }
    ];
}

export const getOptionsGrid = (optionSalvo) => {
    return [
        {
            titulo: 'Mostrar borda de Colunas',
            option: 'showColumnLines',
            value: (optionSalvo === undefined ? true : optionSalvo['showColumnLines'])
        },
        {
            titulo: 'Mostrar borda de Linhas',
            option: 'showRowLines',
            value: (optionSalvo === undefined ? true : optionSalvo['showRowLines'])
        },
        {
            titulo: 'Mostrar bordas da tabela',
            option: 'showBorders',
            value: (optionSalvo === undefined ? true : optionSalvo['showBorders'])
        },
        {
            titulo: 'Mostrar linha zebrada',
            option: 'rowAlternationEnabled',
            value: (optionSalvo === undefined ? true : optionSalvo['rowAlternationEnabled'])
        }
    ];
}