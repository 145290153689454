import React, { Component } from 'react';
import Board, { moveCard } from "@lourenci/react-kanban";
import '@lourenci/react-kanban/dist/styles.css';
import './Kanban.css';
import '../../../src/custom.css'
import InfiniteScroll from "react-infinite-scroll-component";

class Kanban extends Component {
    constructor(props) {
        super(props);
        this.state = {
            board: props.data,
        };
    }

    render() {
        return <>
            {
                this.state.board.columns.map(column => {
                    let board = {
                        columns: [column]
                    };

                    return <div key={column.id}>
                        <h4 className="titulo">{column.nome}</h4>

                        <InfiniteScroll

                            dataLength={column.cards.length}
                            next={() => { this.props.proximaPagina(column) }}
                            hasMore={1 == 1}
                            height="100%"

                        >
                            <Board disableColumnDrag disableCardDrag initialBoard={board} />
                        </InfiniteScroll >
                    </div>
                })
            }
        </>
    }
}

export default Kanban;