import { ENQUEUE_SNACKBAR, CLOSE_SNACKBAR, REMOVE_SNACKBAR } from '../constants/notifier.constants';

let notifierData = JSON.parse(localStorage.getItem('notifier'));
const defaultState = {
    notifications: notifierData ? notifierData.notifications || [] : []
};

const notifier =(state = defaultState, action) => {
    switch (action.type) {
        case ENQUEUE_SNACKBAR:{
            localStorage.setItem('notifier',JSON.stringify({
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        key: action.notification.key,
                        ...action.notification,
                    },
                ]
            }));
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        key: action.notification.key,
                        ...action.notification,
                    },
                ]
            };
        }
        case CLOSE_SNACKBAR:
            localStorage.setItem('notifier',JSON.stringify(
                {
                    ...state,
                    notifications: state.notifications.map(notification => (
                        (action.dismissAll || notification.key === action.key)
                            ? { ...notification, dismissed: true }
                            : { ...notification }
                    )),
                }
            ));
            return {
                ...state,
                notifications: state.notifications.map(notification => (
                    (action.dismissAll || notification.key === action.key)
                        ? { ...notification, dismissed: true }
                        : { ...notification }
                )),
            };

        case REMOVE_SNACKBAR:
            localStorage.setItem('notifier',JSON.stringify(
                {
                    ...state,
                    notifications: state.notifications.filter(
                        notification => notification.key !== action.key,
                    ),
                }
            ));
            return {
                ...state,
                notifications: state.notifications.filter(
                    notification => notification.key !== action.key,
                ),
            };

        default:
            return state;
    }
};

export default notifier;