import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DataGrid, { HeaderFilter, Export, Scrolling, ColumnChooser, ColumnFixing, GroupPanel, Sorting } from 'devextreme-react/data-grid';
import { locale, loadMessages } from 'devextreme/localization';
import ptMessages from 'devextreme/localization/messages/pt.json';
import _ from 'lodash';
import { pushWarning } from '../../services/notifierService';
import { ConfiguracaoDataGrid } from './ConfiguracaoDataGrid';
import history from '../../services/history';

const useStyles = makeStyles((theme) => ({
    input: {
        fontSize: 10,
        fontFamily: 'Montserrat-Medium',
        marginBottom: 10
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: "#F5F5F5",
        padding: theme.spacing("25px", "25px"),
        borderRadius: "10px",
        fontSize: "13px",
        outline: 'none',
        minWidth: '300px'
    },
    buttonSim: {
        backgroundColor: "#0091C7",
        color: "white",
        fontSize: "10px"
    },
    buttonCancelar: {
        backgroundColor: "rgba(0, 145, 199, 0.65)",
        color: "white",
        fontSize: "10px",
        marginRight: "16px"
    },
    contentModal: {
        backgroundColor: "white",
        padding: 16
    },
    formControl: {
        width: '120px',
        fontSize: 10,
        fontFamily: 'Montserrat-Medium',
        margin: 5
    },
    label: {
        fontSize: 10,
        fontFamily: 'Montserrat-Medium'
    },
    formGroup: {
        marginTop: 15
    },
    grid: {
        fontSize: 10,
        fontFamily: 'Montserrat-Medium'
    },
    lista: {
        maxHeight: '400px',
        overflow: 'auto'
    }
}));

export const RelatorioDataGrid = (props) => {
    const { data, gridRef, configurar, layoutJson, name, campos } = props;
    const classes = useStyles();
    const [modalSummary, setModalSummary] = useState(false);
    let columnsVisible = []
    locale('pt');
    loadMessages(ptMessages);

    useEffect(() => {
        let visualizarProcessoCampo = '';
        if (gridRef && gridRef.current && gridRef.current.instance) {
            gridRef.current.instance.beginCustomLoading("Carregando...");
            gridRef.current.instance.option('dataSource', { store: data });
            gridRef.current.instance.on('contentReady', firstContentReady);
        }

        function firstContentReady() {
            let stateColumns = [];
            let visualizarProcesso = false;
            if (data.length > 0) {
                _.forEach(campos, function (campo) {
                    let columnsLayoutJson = layoutJson.columns ?? [];
                    let campoLayoutJson = _.find(columnsLayoutJson, (column) => { return column.dataField === campo.Campo });
                  
                    if (campoLayoutJson) {
                        stateColumns.push(campoLayoutJson)
                        let regex = /\d*\-\d*\.\d*\.\d*.\d*.\d*/;
                        if(campoLayoutJson.visible === true  && typeof(data[0][campoLayoutJson.dataField]) === 'string' && regex.test(data[0][campoLayoutJson.dataField])){
                            visualizarProcesso = true;
                            visualizarProcessoCampo = campoLayoutJson.dataField;
                        }
                    } else {
                        stateColumns.push({
                            displayFolder: campo.Agrupamento,
                            allowHeaderFiltering: true,
                            allowFiltering: true,
                            allowSearch: true,
                            dataField: campo.Campo,
                            dataType: campo.Tipo === 'date' ? 'datetime' : campo.Tipo,
                            format: campo.Formato,
                            visible: false
                        });
                    }
                });
            }
            if(visualizarProcesso){
                // Coluna de visualizar processo
                stateColumns.push({
                    type:'buttons',
                    buttons:
                        [{
                            hint: 'Visualizar',
                            visible:true,
                            icon: 'info',
                            onClick: onClickvisualizarProcesso
                        }]
                });
            }
            gridRef.current.instance.option('columns', stateColumns);
            if (!layoutJson.options) {
                gridRef.current.instance.option('showColumnLines', true);
                gridRef.current.instance.option('showRowLines', true);
                gridRef.current.instance.option('showBorders', true);
                gridRef.current.instance.option('rowAlternationEnabled', true);
            } else {
                _.forOwn(layoutJson.options, function (value, key) {
                    gridRef.current.instance.option(key, value);
                });
            }
            if (layoutJson && layoutJson.summary) {
                gridRef.current.instance.option('summary', layoutJson.summary);
            }
            gridRef.current.instance.endCustomLoading();
            gridRef.current.instance.on('contentReady', centerColumnChooser);
        }

        function centerColumnChooser(e) {
            var columnChooserView = e.component.getView("columnChooserView");
            if (!columnChooserView._popupContainer) {
                columnChooserView._initializePopupContainer();
                columnChooserView.render();
                columnChooserView._popupContainer.option("position", { of: e.element, my: "center", at: "center", offset: "0 50" });
            } else {
                columnChooserView._popupContainer.option("position", { of: e.element, my: "center", at: "center", offset: "0 50" });
            }
            if (configurar) {
                gridRef.current.instance.showColumnChooser();
            }
            gridRef.current.instance.off('contentReady');
            columnsVisible = _.cloneDeep(gridRef.current.instance.getVisibleColumns());
            gridRef.current.instance.on('contentReady', reorderColumns);
        }

        function reorderColumns(){
            let columns = _.cloneDeep(gridRef.current.instance.getVisibleColumns());
            if(columnsVisible.length < columns.length && columns.length > 1){
                let lastColumn = _.last(gridRef.current.instance.getVisibleColumns());
                let columnReorder = _.first(_.differenceBy(columns, columnsVisible, 'dataField'));
                gridRef.current.instance.columnOption(columnReorder.index, 'visibleIndex', lastColumn.visibleIndex+1);
                gridRef.current.instance.columnOption(columnReorder.index, 'sortOrder', null);
                columnsVisible = columns
            }else if (columnsVisible.length > columns.length){
                columnsVisible = columns
            }
        }

        function onClickvisualizarProcesso(e){
            // e.row.data
            // e.row.rowIndex
            let regex = /\d*\-\d*\.\d*\.\d*.\d*.\d*/;
            let processo = e.row.data[visualizarProcessoCampo].match(regex);
            if(processo.length > 0){
                history.push('/WorkFlow/' + processo[0]);
            }
            e.event.preventDefault();
        }
    }, [gridRef, data, layoutJson, configurar, campos])

    function onToolbarPreparing(e) {
        if (configurar) {
            e.toolbarOptions.items.unshift({
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'datafield',
                },
                onClick: () => { setModalSummary(true) }
            });
        }
    }

    function buttonOkClick() {
        let valido = true;
        let summary = gridRef.current.instance.option('summary');
        if (summary) {
            _.forEach(summary.totalItems, (value) => {
                if (!value.summaryType) {
                    pushWarning("O campo " + value.dataField + " esta sem nenhum tipo de agregação.")
                    valido = false;
                    return valido;
                }
            });
        }
        if (valido) { setModalSummary(false); }
    }

    return (
        <React.Fragment>
            <DataGrid
                className={classes.grid}
                allowFiltering={true}
                allowHeaderFiltering={true}
                allowSearch={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                height='100%'
                ref={gridRef}
                defaultColumns={[]}
                onToolbarPreparing={onToolbarPreparing}>
                <Sorting mode="multiple" />
                <HeaderFilter visible={true} />
                <GroupPanel visible={true} />
                <ColumnChooser
                    height={500}
                    width={500}
                    className={classes.grid}
                    mode="select"
                    enabled={configurar}
                    allowSearch={true} />
                <ColumnFixing enabled={true} />
                <Scrolling mode="virtual" />
                <Export enabled={true} allowExportSelectedData={false} fileName={name} />
            </DataGrid>
            {modalSummary &&
                <ConfiguracaoDataGrid gridRef={gridRef} modalOpen={modalSummary} buttonOkClick={buttonOkClick} />
            }
        </React.Fragment>);
}