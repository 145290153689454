import axiosInstance from '../core/httpInterceptor';

export const PainelProcessoService = {
    getPainelProcessoColumns,
    listPainelProcessos,
    getTimeAtualizacao,
    postUpdProcessoPrioridade,
};

function getPainelProcessoColumns() {
    return axiosInstance.get(`/PainelProcesso/GetPainelProcessoColumns`);
}

function listPainelProcessos(carteiraId, idProcessoStatus, filtro, paginacao, filtroPesquisa) {
    let flagTodos = filtro.FlagTodos ? filtro.FlagTodos : filtro.flagTodos;
    let data = { carteiraId, FlagTodos: parseInt(flagTodos), idProcessoStatus, paginacao, filtroPesquisa, ignoreMessages: true };
    return axiosInstance.post(`/PainelProcesso/GetPainelProcessos`, data);
}
function getTimeAtualizacao() {
    return axiosInstance.get(`/PainelProcesso/GetTimeAtualizacao`);
}
function postUpdProcessoPrioridade(idProcesso, prioridade) {
    return axiosInstance.post('/PainelProcesso/PostUpdProcessoPrioridade/' + idProcesso + '/' + prioridade);
}

export default PainelProcessoService;