import React, { useEffect, useState } from 'react';
import './FaseViewer.css';
import { faseService, anexoService, pushError } from '../../services/index';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { Backdrop,CircularProgress } from '@material-ui/core';
import {faLink}from '@fortawesome/free-solid-svg-icons';

export default function FaseViewer(props) {
  let [loading,setLoading]=useState(false);
  const { data, onSelectField, pdfViewer } = props;

  const useStyles = makeStyles((theme) => ({
    root: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(1),
        width: theme.spacing(65),
        height: theme.spacing(55),
      },
    },
    backdrop: {        
      color: '#fff',
      zIndex : 1500,
    },
  }));
  const NAO_POSSUI = 'Não possui'
  const classes = useStyles();
  let linhaOcupada = { 1: false, 2: false, 3: false, 4: false };
  let linha = 1;
  let linhaOcupadaClass = { 1: false, 2: false, 3: false, 4: false };
  let linhaClass = 1;

  function getStyleCampo(campo, imprimeGrupo) {
    if (campo.valor && campo.colunaExibicao > 0) {
      if (linhaOcupada[campo.colunaExibicao]) {
        linha++;
        linhaOcupada = { 1: false, 2: false, 3: false, 4: false }
      }
      linhaOcupada[campo.colunaExibicao] = true;
      imprimeGrupo = false;
      return {
        gridArea: `r${linha}c${campo.colunaExibicao}`,
        marginLeft: `${(campo.colunaExibicao > 1 ? 1 : campo.nivel - 1) * 20}px`,
      }
    } else {
      return {};
    }
  }

  function getClassGrupo(campo) {
    if (pdfViewer) {
      linhaClass++;
      return "row col-xs-12"
    }
  }

  function getClassCampo(campo, imprimeGrupo) {
    if (pdfViewer) {
      if (campo.valor && campo.colunaExibicao > 0) {
        if (linhaOcupadaClass[campo.colunaExibicao]) {
          linhaClass++;
          linhaOcupadaClass = { 1: false, 2: false, 3: false, 4: false }
        }
        linhaOcupadaClass[campo.colunaExibicao] = true;
        return 'col-xs-3'
      } else {
        return 'col-xs-12';
      }
    }
  }

    function clickField(campo){
      if (onSelectField && !campo.formula &&  (campo.permiteEdicao || campo.idFaseSessaoCampoQualificador)){ 
        if ( onSelectField ) 
          onSelectField(campo)
      }
    }

  function getImprimeRow(campo) {
      if (campo.valor && campo.colunaExibicao > 0) {
        if (linhaOcupadaClass[campo.colunaExibicao]) {
          return true;
        }else{
          return false;
        }
      }
  }

  function getStyleGrupo(campo) {
    let linhaGrupo = linha++;
    return {
      gridArea: `r${linhaGrupo}c1`,
      width: '100%',
      marginLeft: `${(campo.nivel - 1) * 20}px`,
      color: '#E82E59',
      fontSize: '11px',
      fontWeight: 'bold'
    }
  }

  function DownloadArquivo(campo) {
    if (campo.idAnexo > 0) {
      setLoading(true);
      anexoService.getAnexo(campo.idAnexo).then((response) => {
        if (response.success && response.result[0].binAnexo){
          try{
            const base64 = "data:" + response.result[0].tipoAnexo + ";base64," + response.result[0].binAnexo;
            const url = base64;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response.result[0].nmAnexo);
            document.body.appendChild(link);
            link.click();
            setLoading(false);
          } catch(e){
            setLoading(false);
            pushError('Falha ao recuperar arquivo para download!');  
          }
        } else {
          setLoading(false);
          pushError('Falha ao recuperar arquivo para download!');  
        }
      }).catch(e=>{
        setLoading(false);
        pushError('Falha ao recuperar arquivo para download!');
      })
      
    }
  }

  function LinkZaig(campo) {
    if (campo.url) {
          try{
            const url = campo.url;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('target', '_blank');
            document.body.appendChild(link);
            link.click();
          } catch(e){
            pushError('Erro ao tentar abrir o link');  
          }
        } else {
          setLoading(false);
          pushError('Erro ao tentar abrir o link');  
        }    
    }
  

  let ultimoGrupo = '';
  let imprimeGrupo;
  
  let posicaoAtual = faseService.PosicaoAtual(data || []) || 0;

  let dadosExibir = (data || []).map((c, i) => { return { ...c, originalIndex: i } }).filter(campo => {
    return ( campo.valor && campo.colunaExibicao > 0
              && campo.originalIndex <= posicaoAtual && campo.estaOK 
              && campo.valor !== NAO_POSSUI 
           )
          ||   
          ( campo.valor && campo.colunaExibicao > 0
            && campo.originalIndex <= posicaoAtual 
            && campo.valor=== NAO_POSSUI && campo.campoMultiplo
          )
  });
  let element = document.getElementById('container');
  if (element)
    element.scrollTop = element.scrollHeight - element.clientHeight;

  let elementoAcao = document.getElementsByClassName("MuiTypography-root MuiTypography-h6");
  let action = false;
  //console.log('DadosExibir:', dadosExibir, 'posicaoAtual:',posicaoAtual);

  if(elementoAcao.length > 0)
    action = elementoAcao[0].innerText.toString().includes("Visualização");

  return (
    <>
        <Backdrop 
            open={loading} 
            className={classes.backdrop}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    <div className={classes.root} >      
      <Paper elevation={3} style={props.style} id='container'>
        <div className='FaseViewer-container'>
            {dadosExibir.map((campo, index) => {
              if (ultimoGrupo !== campo.grupo && campo.grupo) {
                ultimoGrupo = campo.grupo;
                imprimeGrupo = true;
                linha++;
              } else {
                imprimeGrupo = false;
              }

              return (
                <React.Fragment key={index}>
                  {imprimeGrupo ?
                    <div key={ultimoGrupo} className={getClassGrupo(campo)} style={getStyleGrupo(campo)} >{ultimoGrupo}</div>
                    : <></>
                  }
                  {imprimeGrupo && pdfViewer &&
                    <br />
                  }
                  {getImprimeRow(campo) && pdfViewer &&
                    <div className="row"></div>
                  }
                  <div className={'FaseViewer-tooltip ' + getClassCampo(campo)} style={getStyleCampo(campo, imprimeGrupo)} >
                      { action === true &&
                        <label style={{ cursor: "Default" }}>
                            {campo.campo}<br></br>                  
                            <div className="FaseViewer-container-value" style={{ cursor: "Default" }}>{campo.valor}</div>
                        </label>
                      }
                      { action === false &&
                        <label onClick={() => clickField(campo)}>
                            {campo.campo}<br></br>                  
                            <div className="FaseViewer-container-value">{campo.valor}</div>
                      </label>
                      }
                    {campo.idAnexo > 0 && !pdfViewer &&
                        <FontAwesomeIcon icon={faPaperclip} onClick={() => DownloadArquivo(campo)} style={{ marginRight: 3, cursor: "pointer" }} />
                      }
                    {campo.url &&
                        <FontAwesomeIcon icon={faLink} onClick={() => LinkZaig(campo)} style={{ marginRight: 3, cursor: "pointer" }} />
                      }
                    <span className='FaseViewer-tooltiptext'>{campo.campo}<br></br>{campo.valor}</span>
                  </div>
                </React.Fragment>);
            })}
        </div>
      </Paper>
    </div>
    </>
  );
}
