import React from 'react';

import { Tooltip, Select, MenuItem, Input, Badge, Checkbox, ListItemText } from '@material-ui/core';
// Import React Table
import ReactTable from "react-table";
import "react-table/react-table.css";

// Import React Table HOC Fixed columns
import withFixedColumns from "react-table-hoc-fixed-columns";
// import "react-table-hoc-fixed-columns/lib/styles.css";

// Import Component For Render specific Data Types
import SelectWithColor from '../SelectWithColor/SelectWithColor';
import { ColumnWithColor } from '../ColumnWithColor/ColumnWithColor';
import { Avatar } from '../Avatar/Avatar';
import MoneyFormat from '../MoneyFormat/MoneyFormat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import _ from 'lodash';
import CreatableSelect from 'react-select/creatable';

import './Grid.css';

function isBase64(str) {
  try {
    return ((str || '').length > 500) && btoa(atob(str)) == str;
  } catch (err) {
    return false;
  }
}

function createUUID() {
  // http://www.ietf.org/rfc/rfc4122.txt
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  var uuid = s.join("");
  return uuid;
}

function SpanOrImg(str) {
  if (!isBase64(str))
    return (<Tooltip title={str} aria-label={str} ><span>{str}</span></Tooltip>)
  else {
    let typeImg = str.startsWith("/9j") ? "jpeg" : "png";
    let identifier = createUUID();
    let modalId = "modal" + identifier;
    return (<div>
      <img width="100%"
        src={"data:image/" + typeImg + ";charset=utf-8;base64," + str}
        onClick={() => { document.getElementById(modalId).style.display = "block"; }} />
      <div className="modal" id={modalId} onClick={() => { document.getElementById(modalId).style.display = "none"; }}>
        <span className="close" onClick={() => { document.getElementById(modalId).style.display = "none"; }}>&times;</span>
        <img className="modal-content" src={"data:image/" + typeImg + ";charset=utf-8;base64," + str} id={'imgModal' + identifier} />
      </div>
    </div>)
  }
}

export const Renders = {
  ListWithColor: () => {
    return (
      <div className="rt-td-custom">
        <SelectWithColor />
      </div>);
  },
  ColumnWithColor: (props) => {
    return (
      <div className="rt-td-custom">
        <ColumnWithColor color={props.value} checked={props.original.selecionado} countselecionados={props.original.countselecionados} showCheckBox={props.columnProps.rest.showCheckBox} toolTip={props.original.status} />
      </div>);
  },
  Avatar: (props) => {
    return (
      <div className="rt-td-custom">
        <Avatar nome={props.value}
          imagem_usuario_compra={props.original[props.columnProps.rest.imagem_usuario_compra]}
          style={{ backgroundColor: "#CDD3E1", color: "#000" }}
          tooltip={props.value || props.columnProps.rest.tooltip_empty}
        />
      </div>
    )
  },
  Money: (props) => {
    return (<div className="rt-td-custom">
      <MoneyFormat value={props.value} style={{ backgroundColor: "#CDD3E1", color: "#000" }} />
    </div>)
  },
  Icon: (props) => {
    if (props.columnProps.rest.icon) {
      return <FontAwesomeIcon icon={['fa', props.columnProps.rest.icon]} onClick={e => { props.columnProps.rest.onClick(props) }} style={{ cursor: "pointer" }} />
    } else {
      return <></>
    }
  },
  IconToolTip: (props) => {

    var fabFal = (props.value || '').split("-");
    var extraFabFal = props.columnProps.rest.extraColumn ? (props.original[props.columnProps.rest.extraColumn] || '').split("-") : null;
    var toolTip = <> {props.original[props.columnProps.rest.tooltip]} <br></br> {props.columnProps.rest.extratooltip ? (props.original[props.columnProps.rest.extratooltip] || '') : ""} </>;
    return (
      <div className="rt-td-custom">
        {props.value &&
          <Tooltip title={toolTip} aria-label={toolTip} >
            <span>
              {fabFal && fabFal.length > 0 &&
                <FontAwesomeIcon
                  icon={[fabFal[0], fabFal[2] ? fabFal[1] + '-' + fabFal[2] : fabFal[1]]}
                  style={{ cursor: "pointer", color: props.columnProps.rest.color }}
                />
              }
              {extraFabFal && extraFabFal.length > 2 ? <FontAwesomeIcon icon={[extraFabFal[0], extraFabFal[2] ? extraFabFal[1] + '-' + extraFabFal[2] : extraFabFal[1]]} style={{ cursor: "pointer", color: props.columnProps.rest.color, marginLeft: "5px" }} /> : <></>}
            </span>
          </Tooltip>
        }
      </div >

    );
  },
  IconCount: (props) => {
    return <Tooltip title={props.columnProps.rest.tooltip} aria-label={props.columnProps.rest.tooltip} >
      <Badge
        badgeContent={props.value}
        color={props.value > 0 ? 'error' : 'primary'}
        showZero
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        style={{ fontSize: '10px', fontFamily: 'Montserrat-Medium' }}
      >
        {props.columnProps.rest.icon &&
          <FontAwesomeIcon icon={['fa', props.columnProps.rest.icon]} />
        }
      </Badge>
    </Tooltip>
  },
  ColumnWithColorAndCheckbox: (props) => {
    return (
      <div className="rt-td-custom">
        <ColumnWithColor color={props.value} colorsecondary={props.columnProps.rest.colorsecondary} onUpdate={e => { props.columnProps.rest.onupdate(props) }} index={props.index} checked={props.original.selecionado} countselecionados={props.original.countselecionados} showCheckBox="true">
        </ColumnWithColor>
        <div className="rt-td-center">
          <span className="ml-3">{props.original[props.columnProps.rest.text_column]}</span>
        </div>
      </div>
    )
  },
  ColumnWithColorAndCheckboxOrText: (props) => {
    let editable = props.columnProps.rest.editable(props);
    return (
      <div className="rt-td-custom">
        <ColumnWithColor color={props.value} colorsecondary={props.columnProps.rest.colorsecondary} onUpdate={e => { props.columnProps.rest.onupdate(props) }} index={props.index} checked={props.original.selecionado} countselecionados={props.original.countselecionados} showCheckBox="true">
        </ColumnWithColor>
        <div className="rt-td-center">
          {props.original[props.columnProps.rest.text_column] && !editable && <span className="ml-3">{props.original[props.columnProps.rest.text_column]}</span>}
          {editable &&
            <Input
              style={{ fontSize: "10px" }}
              fullWidth={true}
              onChange={(e) => { props.columnProps.rest.onchange(e.target.value, props) }}
              value={props.original[props.columnProps.rest.text_column]}
              autoFocus
            />}
        </div>
      </div>
    )
  },
  Date: (props) => {
    let date = props.value ? moment(props.value).format('DD/MM/YYYY') : '';
    return (
      <div className="rt-td-custom">
        {date}
      </div>);
  },
  DateTime: (props) => {
    let date = props.value ? moment(props.value).format('DD/MM/YYYY HH:mm') : '';
    return (
      <div className="rt-td-custom">
        {date}
      </div>
    );
  },
  ToolTip: (props) => {
    return (
      <div className="rt-td-custom">
        {props.value &&
          <Tooltip title={props.value} aria-label={props.value} >
            <span>{props.value}</span>
          </Tooltip>
        }
      </div>);
  },
  Div: (props) => {
    return (
      <div className="rt-td-custom" onClick={e => { if (props.columnProps.rest.onClick) { props.columnProps.rest.onClick(props); } }}>
        {props.value &&
          <Tooltip title={props.value} aria-label={props.value} >
            <span>{props.value}</span>
          </Tooltip>
        }
      </div>);
  },
  Dynamic: (props) => {
    return (
      <div className="rt-td-dynamic">
        {props.value &&
          SpanOrImg(props.value)
        }
      </div>);
  },
  Input: (props) => {
    let editable = props.columnProps.rest.editable(props);
    return (
      <div className="rt-td-custom">
        {editable &&
          <Input
            style={{ fontSize: "10px" }}
            fullWidth={true}
            onChange={(e) => { props.columnProps.rest.onchange(e.target.value, props) }}
            value={props.value}
            autoFocus
          />
        }
        {props.value && !editable &&
          <Tooltip title={props.value} aria-label={props.value} >
            <span>{props.value}</span>
          </Tooltip>
        }
      </div>);
  },
  InputWithNoFocus: (props) => {
    let editable = props.columnProps.rest.editable(props);
    return (
      <div className="rt-td-custom">
        {editable &&
          <Input
            style={{ fontSize: "10px" }}
            fullWidth={true}
            onChange={(e) => { props.columnProps.rest.onchange(e.target.value, props) }}
            value={props.value}
          />
        }
        {props.value && !editable &&
          <Tooltip title={props.value} aria-label={props.value} >
            <span>{props.value}</span>
          </Tooltip>
        }
      </div>);
  },
  Select: (props) => {
    let editable = props.columnProps.rest.editable(props);
    let options = props.columnProps.rest.getoptions();
    let text_column = _.map([props.original], _.property(props.columnProps.rest.text_column));

    return (
      <div className="rt-td-custom">
        {editable &&
          <Select
            style={{ fontSize: "10px" }}
            fullWidth={true}
            value={props.value}
            onChange={(e) => { props.columnProps.rest.onchange(e.target.value, e.target.textContent, props) }}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {(options || []).map((value, index) => {
              return (<MenuItem key={index} value={value.id}
                style={{ fontSize: "10px" }}
              >{value.name}</MenuItem>);
            })}
          </Select>
        }
        {props.value && !editable && text_column &&
          <Tooltip title={text_column[0]} aria-label={text_column[0]} >
            <span>{text_column[0]}</span>
          </Tooltip>
        }
      </div>);
  },
  SelectInput: (props) => {
    let editable = props.columnProps.rest.editable(props);
    let options = props.columnProps.rest.getoptions();
    let text_column = _.map([props.original], _.property(props.columnProps.rest.text_column));


    return (
      <div className="rt-td-custom">
        {editable &&
          <CreatableSelect
            isClearable
            onChange={(e) => { props.columnProps.rest.onchange(e, props) }}
            options={options}
            defaultInputValue={props.value}
            hideSelectedOptions={false}
            placeholder="Selecione ou digite"
            formatCreateLabel={(newValue) => "Adicionar " + newValue}
            clearable={false}
            menuPortalTarget={document.querySelector('body')}
          />
        }
        {props.value && !editable && text_column &&
          <Tooltip title={text_column[0]} aria-label={text_column[0]} >
            <span>{text_column[0]}</span>
          </Tooltip>
        }
      </div>);
  },
  SelectCheck: (props) => {
    let editable = props.columnProps.rest.editable(props);
    let options = props.columnProps.rest.getoptions();
    let text_column = _.map([props.original], _.property(props.columnProps.rest.text_column));
    let value_column = _.map([props.original], _.property(props.columnProps.rest.value_column));

    return (
      <div className="rt-td-custom">
        {editable && text_column &&
          <Select
            labelId="demo-mutiple-checkbox-label"
            fullWidth
            id="demo-mutiple-checkbox"
            value={value_column}
            input={<Input />}
            onChange={(e) => { props.columnProps.rest.onchange(e.target.value, props) }}
            renderValue={(selected) => _.map(selected[0], 'descricao').join(', ')}
            multiple
            style={{
              height: 33
            }}
            className="inputSelect"
          >
            {options.map((value) => (
              <MenuItem key={value.id} value={value} className="formControlLabel">
                <Checkbox
                  checked={_.findIndex(_.head(value_column), function (o) { return o.iD_Perfil === value.id; }) > -1}
                  disabled={value.id === 1}
                />
                <ListItemText primary={value.label} classes={{ primary: "formControlLabel" }} />
              </MenuItem>
            ))}
          </Select>
        }
        {props.value && !editable && text_column &&
          <Tooltip title={text_column[0]} aria-label={text_column[0]} >
            <span>{text_column[0]}</span>
          </Tooltip>
        }
      </div>);
  },
  ConcatColumns: (props) => {
    let concat_columns = '';
    let separator = props.columnProps.rest.separator;
    _.forEach(props.columnProps.rest.concat_columns, (obj) => {
      let text_column = props.original[obj];
      concat_columns += (' ' + separator + ' ' + text_column);
    });
    return (
      <div className="rt-td-custom">
        {props.value &&
          <Tooltip title={props.value + concat_columns} aria-label={props.value + concat_columns} >
            <span>{props.value}{concat_columns}</span>
          </Tooltip>
        }
      </div>);
  },
}

const ReactTableFixedColumns = withFixedColumns(ReactTable);

export function Grid(props) {
  let columnsGrouped = props.columns.reduce((r, a) => {
    r[a.fixed] = r[a.fixed] || [];
    const { fixed, ...rest } = a;
    r[a.fixed].push(rest);
    return r;
  }, Object.create(null));
  let columnsTable = [];
  Object.keys(columnsGrouped).forEach(fixed =>
    columnsTable.push({
      ...(fixed !== "undefined") && { fixed }, columns: columnsGrouped[fixed]
        .map(({ column, title, render, props, ...rest }) => {
          return { Header: title, accessor: column, ...(render) && { Cell: Renders[render] }, ...(props) && { getProps: () => ({ ...props }) }, ...rest }
        })
    })
  );
  const { columns, ...properties } = props;
  return (
    <ReactTableFixedColumns
      columns={columnsTable}
      showPagination={false}
      {...properties}
      noDataText="Nenhum registro encontrado"
      loadingText="Carregando..."
      selected={props.selected}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              {
                const data = this.state.data;
                data.push(newData);
                this.setState({ data }, () => resolve());
              }
              resolve()
            }, 1000)
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              {
                const data = this.state.data;
                const index = data.indexOf(oldData);
                data[index] = newData;
                this.setState({ data }, () => resolve());
              }
              resolve()
            }, 1000)
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              {
                let data = this.state.data;
                const index = data.indexOf(oldData);
                data.splice(index, 1);
                this.setState({ data }, () => resolve());
              }
              resolve()
            }, 1000)
          }),
      }}
    />
  );
}

export default Grid;