import React, { Component } from 'react';
import { Tooltip } from '@material-ui/core';

export class ColumnWithColor extends Component {
  render() {
    const style =
    {
      divColor: {
        backgroundColor: (this.props.color ?? (this.props.colorsecondary ?? '')),
        minHeight: '40px',
        alignItems: 'center',
        display: 'flex'
      }
    };
    return (
      <>
        {this.props.toolTip &&
          <Tooltip title={this.props.toolTip} aria-label={this.props.toolTip} >
            <div style={style.divColor} className={"columnWithColor2 float-left" + (this.props.countselecionados > 0 ? ' hover' : '')} >
              {this.props.showCheckBox === "true" &&
                <input type="checkbox" className={"check-table m-2" + (this.props.countselecionados > 0 ? " moreMarked" : "")} defaultValue={false} checked={this.props.checked} onChange={e => this.props.onUpdate(this.props)} />
              }
            </div>
          </Tooltip>
        }

        {!this.props.toolTip && <div style={style.divColor} className={"columnWithColor float-left" + (this.props.countselecionados > 0 ? ' hover' : '')} >
          {this.props.showCheckBox === "true" &&
            <input type="checkbox" className={"check-table m-2" + (this.props.countselecionados > 0 ? " moreMarked" : "")} defaultValue={false} checked={this.props.checked} onChange={e => this.props.onUpdate(this.props)} />
          }
        </div>
        }
      </>

    );
  }
}