import React, { Component } from 'react';
import { connect } from 'react-redux';
import './SideBar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ToggleableLink from '../ToggleableLink/ToggleableLink';
import ArchImg from '../../assets/elementos_legalflow.png';
import _ from 'lodash';
import { LocationDisabledSharp } from '@material-ui/icons';
class SideBar extends Component {
    constructor() {
        super();
        this.state = { isToggled: true };

        this.handleToggle = this.handleToggle.bind(this);
    }

    handleToggle() {
        this.setState({ isToggled: !this.state.isToggled });
    }

    currentPage() {
        return window.location.pathname;
    }

    render() {
        let idsMenu = [];
        return (
            <div className={this.state.isToggled ? "bg-light border-right toggled" : "bg-light border-right"} id="sidebar-wrapper">
                <div className="list-group list-group-flush">
                    {this.props.permissoes && this.props.permissoes.map((permissao) => {
                        if (_.findIndex(idsMenu, (o) => { return o === permissao.iD_Menu }) === -1) {
                            idsMenu.push(permissao.iD_Menu);
                            return (
                                <ToggleableLink id={"lnkMenu_" + permissao.iD_Menu} to={permissao.url_Rota} className={"list-group-item-action bg-light text-center " + (this.currentPage() === permissao.url_Rota ? "sidebar-active" : "")} key={permissao.iD_Menu}>
                                    <span className="text-center">
                                        <FontAwesomeIcon icon={['fa', permissao.icone]} size="2x" color={(this.currentPage() === permissao.url_Rota ? "" : "#0091C7")} className="mt-2 mb-2" />
                                        <label>{permissao.dsMenu}</label>
                                    </span>
                                </ToggleableLink>)
                        }
                    })}
                </div>
                <div className="sidebar-footer">
                    <img src={ArchImg} className="img-responsive" alt='ArchImg' />
                </div>
            </div>
        );
    }
}

function mapState(state) {
    const { permissoes } = state.authentication;
    return { permissoes };
}

const connectedSideBar = connect(mapState)(SideBar);
export { connectedSideBar as SideBar };