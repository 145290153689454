import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import ProfilePicture from "profile-picture";
import "profile-picture/build/ProfilePicture.css";
import { pushWarning } from '../../services/notifierService';
import history from '../../services/history';
import { usuarioActions } from '../../actions';
import { connect } from 'react-redux';
import { Paper } from '@material-ui/core';

const useStyles = theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: "#FFFFFF",
        padding: theme.spacing("0px", "0px", "0px", "0px"),
        borderRadius: "10px",
        fontSize: "13px",
        position: "absolute",
        right: "72px",
        top: "30px",
        outline: 'none'
    },
    buttonSalvar: {
        backgroundColor: "#0091C7",
        color: "white",
        fontSize: "9px",
        width:"75px"
    },
    buttonRedefinir: {
        backgroundColor: "#FEFEFE",
        color: "#0091C7",
        fontSize: "9px",
        marginRight: "16px",
        width:"118px"
    },
    contentModal: {
        width: 280,
        backgroundColor: "white",
        padding: "25px",
        borderRadius: "10px",
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    input: {
        backgroundColor: "#F5F5F5",
        flex: 1,
        width: "100%",
        borderRadius: "5px",
        fontSize: "13px",        
        marginBottom: "0.4rem"
    },
    iconButton: {
        padding: 10,
    },
    label:{
        color: "#696969",
        marginBottom: "0.1rem",
    },
    backDrop: {
        background: 'rgba(255,255,255,0.2)',
      },
});


class PerfilUsuario extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nome:'',
            cpf: '',
            email: '',
            telefone:'',
            imagem: '', 
            imagemCompleta: ''
        };
        this.profilePictureRef = React.createRef();
        //this.atualizarUsuario = this.atualizarUsuario.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleImageChanged = this.handleImageChanged.bind(this);
    }

    componentDidMount() {
        this.setState({id_usuario: this.props.usuario.iD_Usuario, 
                             nome: this.props.usuario.nome, 
                              cpf: this.props.usuario.cpf, 
                            email: this.props.usuario.email, 
                         telefone: this.props.usuario.telefone, 
                           imagem: this.props.usuario.imagem, 
                             foto: this.props.usuario.imagem});
    }

    esqueciSenha(){
        history.push('/EsqueceuSenha');
    }

    atualizarUsuario(){
        const PP = this.profilePictureRef.current;
        const imageAsDataURL = PP.getImageAsDataUrl();
        var perfilUsuario = {
            id_usuario: this.state.id_usuario,
            nome: this.state.nome,
            cpf: this.state.cpf,
            email: this.state.email,
            telefone: this.state.telefone,
            imagem: imageAsDataURL,
            foto: this.state.imagemCompleta
        };
        
        if(this.state.nome === "") pushWarning("Preencha o campo nome")
        else if(this.state.cpf === "")pushWarning("Preencha o campo cpf")
        else if(this.state.email === "")pushWarning("Preencha o campo email")
        else
        {
            this.props.alterarUsuario(perfilUsuario);
        };
    }

    handleImageChanged(e) {
        this.setState({imagemCompleta: e.data.imageSrc.src});
    }

    handleInputChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    render() {
        const { classes } = this.props;
        return (
            <>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={this.props.open}
                    onClose={this.props.close}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                        classes: {
                            root: classes.backDrop
                        }
                    }}
                >
                    <Fade in={this.props.open}>
                        <Paper className={classes.paper}>
                            <React.Fragment>
                                <div className={classes.contentModal} >
                                    <label className={classes.header}>Perfil</label>
                                    <ProfilePicture
                                        ref={this.profilePictureRef}
                                        useHelper={true}
                                        debug={true}
                                        frameFormat="circle"
                                        image={this.state.foto}
                                        minImageSize="100"
                                        onImageLoaded={this.handleImageChanged}
                                        />
                                    <label className={classes.label}>CPF</label>
                                    <InputBase
                                        name="cpf"
                                        className={classes.input}
                                        placeholder=""
                                        value={this.state.cpf}
                                        disabled
                                        onChange={this.handleInputChange}
                                        inputProps={{style: { textAlign: 'center' }}}
                                    />
                                    <label className={classes.label}>Nome</label>
                                    <InputBase
                                        name="nome"
                                        className={classes.input}
                                        placeholder=""
                                        value={this.state.nome}
                                        onChange={this.handleInputChange}
                                        inputProps={{style: { textAlign: 'center' }}}
                                    />
                                    <label className={classes.label}>E-mail</label>
                                    <InputBase
                                        name="email"
                                        className={classes.input}
                                        placeholder=""
                                        value={this.state.email}
                                        onChange={this.handleInputChange}
                                        inputProps={{style: { textAlign: 'center' }}}
                                    />
                                    <label className={classes.label}>Telefone (opcional)</label>
                                    <InputBase
                                        name="telefone"
                                        className={classes.input}
                                        placeholder=""
                                        value={this.state.telefone}
                                        onChange={this.handleInputChange}
                                        inputProps={{style: { textAlign: 'center' }}}
                                    />
                                    
                                    <div className="mt-1">    
                                    
                                        <Button style={{ marginLeft: 10 }}
                                            variant="contained"
                                            className={classes.buttonRedefinir} 
                                            onClick={(e) => { this.esqueciSenha() }}
                                        >
                                        Redefinir senha
                                        </Button>                                  
                                        
                                        <Button
                                            variant="contained"
                                            className={classes.buttonSalvar}
                                            onClick={this.atualizarUsuario.bind(this)}
                                        >
                                            Salvar
                                        </Button>
                                    </div>
                                </div>
                            </React.Fragment>
                        </Paper>
                    </Fade>
                </Modal>
            </>
        );
    }
}


const actionCreators = {
    alterarUsuario: usuarioActions.alterarUsuario
};
function mapState(state) {
    const { usuario } = state.authentication;

    return { usuario };
}

const connectedPerfilUsuario = connect(mapState,actionCreators)(withStyles(useStyles)(PerfilUsuario));
export { connectedPerfilUsuario as PerfilUsuario };
