import React from 'react';
import {  FaseProgressoContainer, 
          FaseProgressoList, 
          FaseProgressoListItem,
          FaseProgressoListItemLabel,
          FaseProgressoListItemLineBetween
 } from './FaseProgressoStyled';
import { faseService } from '../../services/faseService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function FaseProgresso(props) {
  var Campos = [];
  var indice=0;
  var sessao=undefined;
   (props.data || []).forEach((campo) => {
      if (campo.exibeStatus){
        let grupo = Campos.find(c=>{return c.Rotulo === (campo.grupo || campo.campo) });
        if (!Campos.find(c=>{return c.idFaseSessao===campo.idFaseSessao && campo.sessao}))
           sessao={idFaseSessao:campo.idFaseSessao, sessao:true, Rotulo: campo.sessao, Posicao:Campos.length,icone:campo.icone || 'folder-open', indice:indice-1}
        else
           sessao=undefined;
        if (grupo){
          grupo.indice = indice;
          Campos[grupo.Posicao]={...grupo};
        } else {
          if (sessao)
            Campos.push(sessao);
          Campos.push({Rotulo:campo.grupo || campo.campo, Posicao: Campos.length,indice,idFaseSessao:campo.idFaseSessao,ultimoCampo:campo.idFaseSessaoCampoTipo===16 })
        }
      }
      indice++;
   })

   Campos=Campos.map(item=>{
     let proximo = Campos.find(i=>{return i.Posicao===item.Posicao+1});

     return {...item, proximoEhSessao : !proximo ? false : proximo.sessao }
   })

   let data = props.data.map((item,originalIndex)=>{return {...item,originalIndex}});
   let finalizador=props.data.filter(f=>{return f.idFaseSessaoCampoTipo===16 && f.estaOK});
   let grupo={Posicao:1}
   let campoSelecionado = data[faseService.PosicaoAtual(data)]
   if (campoSelecionado)
      grupo = Campos.find(c=>{return c.indice >=campoSelecionado.originalIndex});
   if (!grupo)
      grupo = Campos[0];
   
  let Atual = grupo.Posicao;
  function renderRow(item){
      let {Rotulo,Posicao,icone,sessao,proximoEhSessao} = item;
      let ehPosicaoAtual = Atual===Posicao && finalizador.length===0 && !sessao;
      let finalizado=Posicao < Atual || finalizador.length===1;
      let iconeItem = sessao ? ( icone || 'folder-open' ) :
                              'circle';

      return (
              <FaseProgressoListItem key={'li_'+Posicao} sessao={sessao}>
                <FontAwesomeIcon  key={'liIcon_'+Posicao} icon={[sessao ? 'fa' : finalizado ? 'fas' : 'fa', iconeItem]} style={{color: finalizado || ehPosicaoAtual ? '#E82E59' : '#CCCCCC', fontSize: (sessao ? 18 : 14) }} />
                <FaseProgressoListItemLabel key={'liLabel_'+Posicao} sessao={sessao} color={finalizado || ehPosicaoAtual ? '#E82E59' : '#5d5d5d'}>{Rotulo}</FaseProgressoListItemLabel>
                {!item.ultimoCampo &&
                <FaseProgressoListItemLineBetween key={'liSeparator_'+Posicao} sessao={sessao} proximoEhSessao={proximoEhSessao} color={finalizado ? '#E82E59' : '#CCCCCC'}/>
                }
              </FaseProgressoListItem>
             )      
  }  

  return (    
    <FaseProgressoContainer heigth={props.heigth} 
                            width={props.width} 
                            fontSize={props.fontSize}>
        <FaseProgressoList>
        {     
             (Campos||[]).map(item => {
                            return renderRow( item );
                          })                   
        }
      </FaseProgressoList>          
    </FaseProgressoContainer>
  );
}
