import React, { Component } from 'react';
import './Header.css';
import LogoImg from '../../assets/id_legalflow_logo.png';
import { connect } from 'react-redux';
import { Search } from '../Search/Search';
import ToggleableLink from '../ToggleableLink/ToggleableLink';
import { AvatarPerfil } from '../../components/Avatar/AvatarPerfil';
import { PerfilUsuario } from '../PerfilUsuario/PerfilUsuario';
import {Notificacao} from '../Notificacao/Notificacao';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import {notificacaoService} from '../../services/notificacaoService';
import { Grid } from "@material-ui/core";

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = { 
            avatarClicked: false, 
            countNotificacoes: 0,
            openNotificacao : false,
            modalConfirm: {
                abrirModal: false,
                tittle: "",
                message: ""
            },
            modalAlterarPerfil: false
        }
        this.clickAvatar = this.clickAvatar.bind(this);
        this.closeNotificacao = this.closeNotificacao.bind(this);
        this.openNotificacao = this.openNotificacao.bind(this);
        this.buscaNotificacao = this.buscaNotificacao.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        this.buscaNotificacao();
        this.interval = setInterval(() => this.buscaNotificacao(), 60000);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        clearInterval(this.interval);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    buscaNotificacao(){
        notificacaoService.getNotificacoesSemLeitura().then(
            (response) =>
            {
                if(response.result){
                    this.setState({countNotificacoes: response.result.length});
                }
            }
        )
    }

    clickAvatar() {
        this.setState({ avatarClicked: !this.state.avatarClicked });
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.state.avatarClicked) {
            this.setState({ avatarClicked: !this.state.avatarClicked });
        }
    }

    openNotificacao(){
        this.setState({openNotificacao: true});
    }

    closeNotificacao(){
        this.setState({openNotificacao: false});
    }
    handleOpen() {
        this.setState({ modalAlterarPerfil: true });
        this.setState({ avatarClicked: !this.state.avatarClicked });
    };

    handleClose() {
        this.setState({ modalAlterarPerfil: false });
    };

    render() {
        const { usuario } = this.props;
        return (
            <>
                <PerfilUsuario open={this.state.modalAlterarPerfil} close={this.handleClose} />
                <div className="header-bar col-12">
                    <div className="row vertical-center">
                        <div className="col-4">
                            <ToggleableLink to="/Workflow">
                                <img src={LogoImg} className="img-responsive logo-img" alt='Logo'/>
                            </ToggleableLink>
                        </div>
                        <div className="col-5 center">
                            <Search/>
                        </div>
                        <div className="col-3" ref={this.setWrapperRef}>
                            <Grid
                                container
                                direction="row"
                                justify="flex-end"
                                alignItems="center"
                                style={{height: "100%"}}
                                >
                                        <div className="pr-3">
                                            <div className="icon-wrapper" onClick={this.openNotificacao} style={{cursor:"pointer"}}>
                                                <FontAwesomeIcon icon={faBell} style={{fontSize:18}} /> 
                                                {this.state.countNotificacoes > 0 && 
                                                    <span className="badge badge-notificacao">{this.state.countNotificacoes}</span>
                                                }
                                            </div>
                                            {this.state.openNotificacao &&
                                                <Notificacao close={() => {this.closeNotificacao();}} atualizaNotificacao={() => {this.buscaNotificacao();}} />
                                            }
                                        </div>
                                        <AvatarPerfil onClick={this.clickAvatar} nome={usuario.nome} className="dropleft cursor-pointer">
                                            <div className={this.state.avatarClicked ? "dropdown-menu show" : "dropdown-menu"} aria-labelledby="dropdownMenu1" ref={this.setWrapperRef}>
                                                <div className="dropdown-item" style={{ fontSize: 10 }} onClick={this.handleOpen}>Perfil</div>
                                                <ToggleableLink to="/" className="dropdown-item" style={{ fontSize: 10 }}>Sair</ToggleableLink>
                                            </div>
                                        </AvatarPerfil>
                            </Grid>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapState(state) {
    const { usuario } = state.authentication;
    return { usuario };
}

const connectedHeader = connect(mapState)(Header);
export { connectedHeader as Header };