import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFastBackward,faStepBackward,faStepForward,faFastForward } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Dvr from '@material-ui/icons/Dvr';
import List from '@material-ui/icons/List';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Grid from '../Grid/Grid';

import './MovimentacaoProcessoViewer.css';

import { processoService } from '../../services/processoService';
import { faseService } from '../../services';


class MovimentacaoProcessoViewer extends Component {
  constructor(props) {
    super(props);    
    this.ocorrencias=0;
    this.state = {  conteudo:'',  
                    mouseX: null,
                    mouseY: null,
                    termoPesquisa:'',
                    menu: props.mostrarPainel === 2 ? 'Consulta' : 'Movimentação',
                    dadosConsulta: props.dadosConsulta,    
                    processoId : props.processoId,                
                }        
    this.onChange=this.onChange.bind(this);
    this.selectedText=this.selectedText.bind(this);
    this.getContentProcessoMovimentacoes=this.getContentProcessoMovimentacoes.bind(this);
    this.fillData=this.fillData.bind(this);
    this.handleScrollTo=this.handleScrollTo.bind(this);
    this.first=this.first.bind(this);
    this.prior=this.prior.bind(this);
    this.next=this.next.bind(this);
    this.last=this.last.bind(this);
    this.handleClick=this.handleClick.bind(this);
    this.handleClose=this.handleClose.bind(this);
    this.handleResponder=this.handleResponder.bind(this);
    this.handleKeyUp=this.handleKeyUp.bind(this);
    this.MenuMovimentacaoConsulta=this.MenuMovimentacaoConsulta.bind(this);
    this.handleMenu=this.handleMenu.bind(this);
    this.getGridHeaderForData=this.getGridHeaderForData.bind(this);
    this.tagPesquisa=this.tagPesquisa.bind(this);
    this.splitMulti=this.splitMulti.bind(this);
    this.atualizaDadosConsulta=this.atualizaDadosConsulta.bind(this);
  }

  componentDidMount(){
    if(this.props.processoId)
      this.fillData();
  }

  componentDidUpdate(){
    if(this.props.processoId){
      this.fillData();  
    }
  }

  fillData(){
    if (  this.props.processoId &&
          (
            this.props.processoId !== this.state.processoId ||
            JSON.stringify( this.props.dadosConsulta ) !== JSON.stringify( this.state.dadosConsulta )
          )  
        ){
          //console.log('Processo diferente:', this.props.processoId !== this.state.processoId);
          //console.log('Dados diferentes:',JSON.stringify( this.props.dadosConsulta ) !== JSON.stringify( this.state.dadosConsulta ))
          processoService.getProcessoMovimentacoes(this.props.processoId).then(response=>{
            this.setState({data:response.result,termoPesquisa:this.props.termoPesquisa, ultimoTermoPesquisa: this.props.termoPesquisa, menu: this.props.mostrarPainel === 2 ? 'Consulta' : 'Movimentação', dadosConsulta:this.props.dadosConsulta},
            ()=>{
              let retorno=this.getContentProcessoMovimentacoes();          
              this.setState({conteudo:retorno.conteudo,processoId:this.props.processoId},()=>{
                this.atualizaDadosConsulta(this.state.termoPesquisa||'');
                })
            });              
          });
        }
      
    if (  this.state.data 
      &&  this.state.ultimoTermoPesquisa !== this.props.termoPesquisa)
      {
        //console.log('Termo de pesquisa diferente:', this.state.ultimoTermoPesquisa !== this.props.termoPesquisa)
        this.setState({termoPesquisa:this.props.termoPesquisa, ultimoTermoPesquisa: this.props.termoPesquisa,menu: this.props.mostrarPainel === 2 ? 'Consulta' : 'Movimentação'},
        ()=>this.onChange({target:{value:this.props.termoPesquisa}}));
        
    }
  }

  tagPesquisa(value,token,frag){
    if (token){
      value=faseService.removeAcentuacao((value||'').toString().toUpperCase());
      token=faseService.removeAcentuacao(token.toUpperCase());

      let tokens=token.match(/(?:(?:".*?")|\S*?(?: | |$))/gim).filter(e=>e.trim()).map(e=>e.split('"').join('').trim());      

      let parts=this.splitMulti(value,tokens);
      let fragments = [];
      if (parts.length===1 && parts[0])
          return <span key={"fr_"+frag++} className='d-inline'n>{parts[0].toString()}</span>;
      
      parts.forEach(part => {
            let tokenFragment='';
            for(let i=0; i<tokens.length; i++){
                if (value.indexOf(faseService.removeAcentuacao(tokens[i]+part))>=0){
                  tokenFragment=tokens[i];
                  break;
                }
            }
            fragments.push(<React.Fragment key={'fragmento_'+frag++}>
                              {tokenFragment && <span id={"oc"+ ++this.ocorrencias} key={"fr_"+frag++} className='MovimentacaoProcessoViewer-Highlight'>{tokenFragment}</span>}
                              <span>{part}</span>
                          </React.Fragment>);
      });
      return fragments;
    }
    else
        return <span key={"fr_"+frag++} className='d-inline'>{value}</span>;
  }


  splitMulti(str, tokens){
    var tempChar = '||'; 
    for(var i = 0; i < tokens.length; i++){
        str = str.split(tokens[i]).join(tempChar);
    }
    return str.split(tempChar);
  }

  getContentProcessoMovimentacoes(pesquisar){
    var frag=1;
    this.ocorrencias=0;
    let row = [];
    let index = 0;
    
    for (const result of this.state.data || []){
        row.push(<div key={"0_"+index} className="col-12"><hr/></div>);
        row.push(<div key={"1_"+index} className="col-4 pad-botton-flex"><div className="expansion-panel-inner-title">Nº</div>: {this.tagPesquisa(result.codigo,pesquisar,frag)}</div>);
        row.push(<div key={"2_"+index} className="col-4 pad-botton-flex"><div className="expansion-panel-inner-title">Disponibilização</div>: {this.tagPesquisa(result.dtDiarioOficial,pesquisar,frag)}</div>);
        row.push(<div key={"3_"+index} className="col-4 pad-botton-flex"><div className="expansion-panel-inner-title">Tribunal</div>: {this.tagPesquisa(result.regiao,pesquisar,frag)}</div>);
        row.push(<div key={"4_"+index}><br/><br/></div>);
        row.push(<div key={"5_"+index} className="col-12 pad-botton detalhamento-ln-break">{this.tagPesquisa(result.detalhamento,pesquisar,frag)}</div>);
    
        if(this.state.data.length!==(index+1)){
            row.push(<div key={"6_"+index}><br/><br/><br/></div>);
        }
        index++;
    }

    if(row.length < 1){
        row.push(<div key={index} className="col-6 pad-botton"><div className="">Sem informações movimentações no processo</div></div>);
    }
    
    return {conteudo:row,ocorrencias:this.ocorrencias};    
  }
  
  onChange(e){
    let retorno=this.getContentProcessoMovimentacoes(e.target.value);
    let token = e.target.value;
    if (retorno.conteudo)               
        this.setState({conteudo:retorno.conteudo, ocorrencia: 100, ocorrencias:retorno.ocorrencias,termoPesquisa:e.target.value},
            ()=>{
              if (this.state.ocorrencias>0)
                this.first();
              else
                this.handleScrollTo('topo_pagina');
            });
            
    this.atualizaDadosConsulta(token);
  }

  atualizaDadosConsulta(token){
    if (token){
      token=faseService.removeAcentuacao(token.toUpperCase());

      let tokens=token.match(/(?:(?:".*?")|\S*?(?: | |$))/gim).filter(e=>e.trim()).map(e=>e.split('"').join('').trim());
      
      this.setState({rowsFiltered: (this.props.dadosConsulta||[]).filter(row=>{
        let found =false;
        let fields=Object.keys(row);
        for (let i=0;i<fields.length; i++){
          let cellValue=faseService.removeAcentuacao( row[fields[i]].toString().toUpperCase() );
          for(let j=0; j<tokens.length; j++){
            if (cellValue.indexOf(faseService.removeAcentuacao(tokens[j]))>=0)
               found=true;
            if (found)
              break;
          }
          if (found)
              break;
        }
        return found;
      })});
    } else {
      this.setState({rowsFiltered: this.props.dadosConsulta || []});
    }
  }

  handleScrollTo = (id) => {
    const el = document.getElementById(id);
    if (el){
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }
  }

  first(){
    if (this.state.ocorrencia!==1)
      this.setState({ocorrencia:1},
        ()=>{
          this.handleScrollTo(`oc${this.state.ocorrencia}`);
        })
  }

  prior(){
    if (this.state.ocorrencia>1)
      this.setState({ocorrencia:this.state.ocorrencia-1},
        ()=>{
          this.handleScrollTo(`oc${this.state.ocorrencia}`);
        })
  }

  next(){
    if (this.state.ocorrencia<this.state.ocorrencias)
      this.setState({ocorrencia:this.state.ocorrencia+1},
        ()=>{
          this.handleScrollTo(`oc${this.state.ocorrencia}`);
        })
  }

  last(){
    if (this.state.ocorrencia!==this.state.ocorrencias)
      this.setState({ocorrencia:this.state.ocorrencias},
        ()=>{
          this.handleScrollTo(`oc${this.state.ocorrencia}`);
        })
  }

  selectedText(e) {
    if (this.props.usarPesquisa)
    { 
        let selectedText='';
        // window.getSelection 
        if (window.getSelection) { 
            selectedText = window.getSelection()+''; 
        } 
        // document.getSelection 
        else if (document.getSelection) { 
            selectedText = document.getSelection()+''; 
        } 
        // document.selection 
        else if (document.selection) { 
            selectedText =  
            document.selection.createRange().text; 
        } else return; 
      this.resposta=selectedText;
      if (this.resposta)
       this.setState({resposta:this.resposta});
        
    }
  }

  handleClick = (event) => {
    event.preventDefault();
    this.setState({
      mouseX: event.clientX + 15,
      mouseY: event.clientY + 15,
    });
  }

  handleClose(){
    this.setState({
      mouseX: null,
      mouseY: null,
    });
  }

  handleResponder(){
    try{
      if (this.state.resposta && this.props.onResponse);
        this.props.onResponse((this.state.resposta || '').trim());
      this.handleClose();
      } catch(e){

    }
  }
  
  handleKeyUp(e){
    e.preventDefault();
    switch(e.keyCode){
      // ENTER
      case 13 : this.handleResponder(); 
                break;
      // Page Up
      case 33 : this.prior();
                break;
      // Page Down
      case 34 : this.next();
                break;
      // Home
      case 36 : this.first();
                break;
      // End
      case 35 : this.last();
                break;
      default : break;
    }
  }

  handleMenu(event, newMenu){
    if (newMenu)
      this.setState({menu:newMenu});
  };

  MenuMovimentacaoConsulta(){
    
    let exibeChaveador = (this.state.dadosConsulta || []).length>0 ? 'block' : 'none';
    return ( 
    
              <ToggleButtonGroup
                value={this.state.menu}
                exclusive              
                size="small"
                onChange={this.handleMenu}
                aria-label="text alignment"
                style={{ position:'absolute', marginLeft:10, display:exibeChaveador}}
              >
                
                  <ToggleButton value="Movimentação" aria-label="Movimentação">
                    <Tooltip title="Movimentação do processo" aria-label="Movimentação do processo" >
                      <Dvr fontSize="small" />
                    </Tooltip>
                  </ToggleButton>

                  <ToggleButton value="Consulta" aria-label="Consulta">
                    <Tooltip title="Consulta" aria-label="Consulta" >
                    <List fontSize="small"/>
                    </Tooltip>
                  </ToggleButton>                              
            </ToggleButtonGroup>
          
    );
  }

  getGridHeaderForData(data){
    if ((data||[]).length>0){
      return Object.keys(data[0]).map(columnName=>{return { "render":"Dynamic","column": columnName,"title": columnName }})
    } else {
      return [];
    }
  }

  render(){
    let data=this.state.rowsFiltered || [];
    let colunas=this.getGridHeaderForData(data);
    //if ((!data || data.length===0 ) && (this.state.menu==="Consulta"))
    //    this.setState({menu:"Movimentação"});
    return (      
        <div className="MovimentacaoProcessoViewer-Container">
            
            {this.props.usarPesquisa &&
            <div className="MovimentacaoProcessoViewer-ContainerHandles">
              <input
                name="pesquisa"
                type="text"
                className="MovimentacaoProcessoViewer-TextForLook"
                placeholder='Pesquisar...'
                value={this.state.termoPesquisa}
                onChange={this.onChange}
              />
              {this.MenuMovimentacaoConsulta()}
                {this.state.menu==="Movimentação"&&
                <div className="MovimentacaoProcessoViewer-ContainerHandleOcorrencias align-top">
                  <FontAwesomeIcon style={{marginRight:10, cursor: 'pointer'}} icon={faFastBackward} onClick={this.first}/>          
                  <FontAwesomeIcon  style={{marginRight:10, cursor: 'pointer'}} icon={faStepBackward} onClick={this.prior}/>
                  {this.state.ocorrencias>0 ?
                    <span  style={{marginRight:10}}>{this.state.ocorrencia} de {this.state.ocorrencias}</span> 
                  : <span  style={{marginRight:10}}> -- </span> 
                  }
                  <FontAwesomeIcon  style={{marginRight:10, cursor: 'pointer'}} icon={faStepForward} onClick={this.next}/>
                  <FontAwesomeIcon  style={{marginRight:10, cursor: 'pointer'}} icon={faFastForward} onClick={this.last}/>
                </div>
                }
              </div>
            }
            
            <div  id='conteudo' 
                  className="MovimentacaoProcessoViewer-Content row align-top" 
                  tabIndex='0' 
                  onMouseUp={this.selectedText} 
                  onKeyUp={(e)=>{this.selectedText(); this.handleKeyUp(e);}}
                  onContextMenu={this.handleClick}  
            >
                    <Menu
                      keepMounted
                      open={this.state.mouseY !== null && this.props.onResponse}
                      onClose={this.handleClose}
                      anchorReference="anchorPosition"
                      anchorPosition={
                        this.state.mouseY !== null && this.state.mouseX !== null
                          ? { top: this.state.mouseY, left: this.state.mouseX }
                          : undefined
                      }
                    >
                      <MenuItem style={{ fontFamily:'Montserrat !important', fontSize:'11px', marginLeft:'5px'}}onClick={this.handleResponder}>Responder com &nbsp;<strong> {this.state.resposta}</strong></MenuItem> 
                    </Menu>

              <div id='topo_pagina'></div>
              {this.state.menu==="Movimentação"&&
                this.state.conteudo
              }
              <div className="col-12"><hr/></div>
              <Grid data={data}
                style={{position:'relative', width: '100%', heigth:'200px', padding: '0px 0px 0px 20px', fontSize: 10,display: this.state.menu==="Consulta" ? 'block':'none' }}
                columns={colunas}
                resizeble={true}
                minPage={1}
                pageSize={data.length}
                getTrProps={(state, rowInfo, instance) => {
                  let colorRow = '#FFFFFF';
                  let colorFont = '#4b4b4d';
                  return {
                      style: {
                          background: colorRow,
                          color: colorFont,
                          cursor: 'pointer'
                      }
                  };
              }}
                              
            />
           </div>             
           <div  id='conteudoGrid' 
                  className="MovimentacaoProcessoViewer-ContentGrid row align-top" >   
           </div>
        </div>              
    );
  }
}

export default MovimentacaoProcessoViewer;