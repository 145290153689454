import React, { Component } from 'react';
import './ContentLogin.css';

export class ContentLogin extends Component {
    render() {
        return (
            <div className={this.props.white ?  "content-login white" : "content-login"}>
                {this.props.children}
            </div>
        );
    }
}