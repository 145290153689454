import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './reducers';
import Env from '../../src/env.json';

const env = Env.NODE_ENV;
const middlewares = [];

// if (env === 'dev') {
//     const loggerMiddleware = createLogger();

//     middlewares.push(loggerMiddleware);
// }

export const store = createStore(
    rootReducer,
    applyMiddleware(
        ...middlewares,
        thunkMiddleware
    )
);