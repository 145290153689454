import React from "react";
import PropTypes from 'prop-types';
import { Container } from "./styles";

function ChipFilter(props) {
    const handleRemoveItem = (index) => {
        let arr = [];
        props.datalist.forEach((e, i) => {
            if (i !== index) {
                arr.push(e);
            }
        });
        props.onReturnList(arr);
    }

    return (
        <Container>
            {props.datalist.map((item, i) => (
                <div key={i} className="filterChip">
                    {item.chave ? <span className="filterChave">{item.chave}:</span> : ''}
                    <span className="filterDesc">{item.valor}</span>
                    <span className="filterChipRemove" onClick={() => handleRemoveItem(i)}>
                        <svg width="18" height="18" viewBox="0 0 24 24">
                            <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
                        </svg>
                    </span>
                </div>
            ))}
        </Container>
    );
}

ChipFilter.propTypes = {
    /** Receba uma lista de objetos de chips. No formato: {chave, valor} */
    datalist: PropTypes.array.isRequired,
    /** Retorna a nova lista sem o item que foi removido */
    onReturnList: PropTypes.func.isRequired,
}

export { ChipFilter };

