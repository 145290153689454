export const columnsDefinition= 
[
    {
        "column": "tipo",
        "title": "Tipo",
        "width":150,
        "headerClassName": "text-left"
    },
    {
        "column": "cedenteCPFCNPJ",
        "title": "Documento",
        "width":90,
        "headerClassName": "text-left"
    },
    {
        "column": "nome",
        "title": "Nome",
        "width":120,
        "height":40,
        "className": "text-center",
        "headerClassName": "text-left"        
    },
    {
        "column": "status",
        "title": "Status",
        "width":50,
        "headerClassName": "text-left"
    },
    {
        "column": "descricao",
        "title": "Descrição",
        "width":530,
        "headerClassName": "text-left"
    },
    {
        "column": "dataFundo",
        "title": "Data",
        "width":110,
        "headerClassName": "text-left"
    }
];
