import React, { Component } from 'react';
import './BackGroundLogin.css';
import ArchImg from '../../assets/elementos_legalflow.png';

export class BackGroundLogin extends Component {
    render() {
        return (
            <div className="background-login">
                {/* <img src={ArchImg} className="img-responsive arch-img"  alt="ArchImg"/> */}
                {this.props.children}
            </div>
        );
    }
}
