import React, { Component } from 'react';
import ModalProcesso  from '../../components/ModalProcesso/ModalProcesso';
export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (
      <div>
        <ModalProcesso ref="modalProcesso" carteiraProcessoHistorico="31418" processoId="524831"/>
        <span>.</span>
        
      </div>
    );
  }
}
