import React from 'react';
import Select from 'react-select';
import chroma from 'chroma-js';

export default function SelectWithColor(props) {

    const colourStyles = {
        container: (provided, state) => {                             
            return {
                ...provided,
                backgroundColor: state.hasValue ? state.getValue()[0].backgroundColor : 'inherit',       
                borderRadius: 5
            }
        },
        control: styles => ({ ...styles, backgroundColor: 'inherit',borderRadius: 5 }),
        option: (provided, { data }) => {                
            return {
                ...provided,
                backgroundColor: data ? data.backgroundColor : 'inherit',
                color: data ? data.fontColor : 'inherit',
            }
        },
        singleValue: (provided, state) => {
            return {
                ...provided,
                color: state.hasValue ? state.getValue()[0].fontColor : 'inherit'
            }
        },
        menu: (provided, state) => {
            if (state.selectProps.index > 4) {
                
                delete provided.top;
                return {
                    ...provided,
                    bottom: provided.height ?  provided.height : 32
                }
            } else {
                return provided
            }
        },
        multiValue: (styles, { data }) => {
          const color = chroma(data.backgroundColor);
          return {
            ...styles,
            backgroundColor: color.alpha(0.1).css(),
          };
        },
        multiValueLabel: (styles, { data }) => ({
          ...styles,
          color: data.fontColor,
        }),
        multiValueRemove: (styles, { data }) => ({
          ...styles,
          color: data.color,
          ':hover': {
            backgroundColor: data.backgroundColor,
            color: data.fontColor,
          },
        }),
      };
    
  return (
    <Select styles={colourStyles} {...props}/>
  );
}
