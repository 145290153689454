import axiosInstance from '../core/httpInterceptor';
import {objectToQueryString} from '../core/util';

export const notificacaoService = {
    getNotificacoes,
    getNotificacoesSemLeitura,
    updateDtLeitura
};

function getNotificacoes(paginacao){
    return axiosInstance.get('/Notificacao/GetNotificacoes?' + objectToQueryString({page:paginacao}));       
};


function getNotificacoesSemLeitura(){
    return axiosInstance.get('/Notificacao/GetNotificacoesSemLeitura');       
};

function updateDtLeitura(notificacaoDtLeitura){
    return axiosInstance.post('/Notificacao/UpdNotificacaoLeitura', notificacaoDtLeitura);       
}