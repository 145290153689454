import React, { Component } from 'react';
import { Grid, Divider, Paper, Button, Backdrop, CircularProgress } from '@material-ui/core';
import _ from "lodash";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withStyles } from '@material-ui/core/styles';
import { ListaRelatorios } from '../../components/ListaRelatorios/ListaRelatorios';
import { RelatorioPivot } from '../../components/RelatorioPivot/RelatorioPivot';
import { RelatorioDataGrid } from '../../components/RelatorioDataGrid/RelatorioDataGrid';
import { relatorioService, perfilService, fonteRelatorioService } from '../../services';
import ModalConfirm from '../../components/ModalConfirm/ModalConfirm';
import { pushWarning } from '../../services/notifierService';
import { PropriedadesRelatorio } from '../../components/PropriedadesRelatorio/PropriedadesRelatorio';
import {getOptionsGrid, getOptionsPivot} from './Utils';

const useStyles = theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    paper: {
        height: "calc(100vh - 110px)",
        padding: 15,
        overflow: 'auto'
    },
    paperRelatorio: {
        height: "calc(100vh - 150px)",
        padding: 15,
        overflow: 'hidden'
    },
    header: {
        margin: 5,
        display: "flex"
    },
    labelHeader: {
        paddingTop: 6,
        marginRight: 5,
        fontSize: 10
    },
    button: {
        fontSize: 10,
        marginLeft: 5,
        fontFamily: 'Montserrat-Medium',
        textTransform: 'none'
    },
    input: {
        fontSize: 10,
        fontFamily: 'Montserrat-Medium',
        marginBottom: 10
    },
    formGroup: {
        marginBottom: 10
    },
    formControlLabel: {
        marginBottom: 10
    }
});

class ConfigurarRelatorio extends Component {
    constructor(props) {
        super(props);

        this.state = {
            abrirListaRelatorios: true,
            editarRelatorio: false,
            listaRelatorios: [],
            abrirCriarRelatorios: false,
            dadosRelatorio: [],
            dadosCampos: [],
            relatorio: {},
            paginacao: {
                page: {
                    number: 1,
                    size: 25
                }
            },
            listaPerfil: [],
            perfilSelecionado: [],
            errorInput: {
                titulo: false,
                descricao: false
            },
            layoutJson: [],
            modalConfirm: {
                abrirModal: false,
                tittle: "",
                message: "",
                buttonSimClick: () => { }
            },
            modalConfirmEncerrar: {
                abrirModal: false,
                tittle: "",
                message: "",
                buttonSimClick: () => { },
                buttonNaoClick: () => { }
            },            
            fields: [],
            options: [],
            gridType: 'pivot',
            summary: [],
            loading: false,
            fonteRelatorio: [],
            fonteRelatorioSelecionada: null
        };
        this.buscaFonteRelatorio = this.buscaFonteRelatorio.bind(this);
        this.buscaPerfil = this.buscaPerfil.bind(this);
        this.criarRelatorio = this.criarRelatorio.bind(this);
        this.salvarRelatorio = this.salvarRelatorio.bind(this);
        this.buscaRelatorios = this.buscaRelatorios.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.selecionarRelatorio = this.selecionarRelatorio.bind(this);
        this.reiniciarTela = this.reiniciarTela.bind(this);
        this.encerrarRelatorio = this.encerrarRelatorio.bind(this);
        this.excluirRelatorio = this.excluirRelatorio.bind(this);
        this.closeModalConfirm = this.closeModalConfirm.bind(this);
        this.closeModalConfirmEncerrar = this.closeModalConfirmEncerrar.bind(this);
        this.confirmarExcluirRelatorio = this.confirmarExcluirRelatorio.bind(this);
        this.handleChangeOptions = this.handleChangeOptions.bind(this);
        this.changeGridType = this.changeGridType.bind(this);
        this.handleCloseBackdrop = this.handleCloseBackdrop.bind(this);
        this.pivotGrid = React.createRef();
        this.tituloRef = React.createRef();
        this.descricaoRef = React.createRef();
        this.perfilRef = React.createRef();
        this.gridRef = React.createRef();
        this.buscaDadosRelatorio = this.buscaDadosRelatorio.bind(this);
    }

    componentDidMount() {
        let options = getOptionsPivot();
        this.setState({ options });
        this.buscaRelatorios();
        this.buscaPerfil();
        this.buscaFonteRelatorio();
    }

    buscaFonteRelatorio(){
        fonteRelatorioService.getFonteRelatorio().then((response) => {
            if (response.result) {
                this.setState({ fonteRelatorio: response.result });
            }
        });
    }

    buscaPerfil() {
        perfilService.getPerfil().then((response) => {
            if (response.result) {
                this.setState({ listaPerfil: response.result });
            }
        });
    }

    buscaRelatorios() {
        this.setState({ loading: true });
        relatorioService.getRelatorios(this.state.paginacao).then((response) => {
            if (response && response.result) {
                this.setState({ listaRelatorios: response.result })
            }
        }).finally(() => {
            this.setState({ loading: false });
        });
    }

    criarRelatorio() {
        // this.setState({ loading: true });
        this.setState({ dadosRelatorio: [], dadosCampos:[], abrirCriarRelatorios: true, abrirListaRelatorios: false, fieelds: [], options: getOptionsPivot(), gridType: 'pivot' });
        // let fonteSelecionada = _.first(this.state.fonteRelatorio);
        // fonteSelecionada.modoConfig = true;
        // relatorioService.getRelatorioDados(fonteSelecionada).then((response) => {
        //     if (response.result) {
        //         let fields = [];
        //         let dadosCampos = _.map(response.result.campos, (obj) => {
        //             if (obj.Formato) {
        //                 obj.Formato = JSON.parse(obj.Formato)
        //             }
        //             return obj;
        //         });
        //         this.setState({ dadosRelatorio: response.result.dados, dadosCampos, abrirCriarRelatorios: true, abrirListaRelatorios: false, fields, options: getOptionsPivot(), gridType: 'pivot' });
        //     }
        // }).finally(() => {
        //     this.setState({ loading: false });
        // });
    }

    salvarRelatorio() {
        let relatorio = this.state.relatorio;
        relatorio.titulo = this.tituloRef.current.value;
        relatorio.descricao = this.descricaoRef.current.value;

        let layoutJson = '';
        if (this.pivotGrid && this.pivotGrid.current && this.pivotGrid.current.instance && relatorio.titulo && relatorio.descricao) {
            let optionFieldPanel = this.pivotGrid.current.instance.option('fieldPanel');
            layoutJson = {
                type: this.state.gridType,
                fields: this.pivotGrid.current.instance.getDataSource().fields(),
                options: {
                    showTotalsPrior: this.pivotGrid.current.instance.option('showTotalsPrior') !== 'none',
                    dataFieldArea: this.pivotGrid.current.instance.option('dataFieldArea') === 'column',
                    rowHeaderLayout: this.pivotGrid.current.instance.option('rowHeaderLayout') === 'tree',
                    showRowTotals: this.pivotGrid.current.instance.option('showRowTotals'),
                    showRowGrandTotals: this.pivotGrid.current.instance.option('showRowGrandTotals'),
                    allowSortingBySummary: this.pivotGrid.current.instance.option('allowSortingBySummary'),
                    allowSorting: this.pivotGrid.current.instance.option('allowSorting'),
                    allowFiltering: this.pivotGrid.current.instance.option('allowFiltering'),
                    showBorders: this.pivotGrid.current.instance.option('showBorders'),
                    showColumnTotals: this.pivotGrid.current.instance.option('showColumnTotals'),
                    showColumnGrandTotals: this.pivotGrid.current.instance.option('showColumnGrandTotals'),
                    fieldPanel: {
                        visible:optionFieldPanel.visible,
                        showColumnFields: optionFieldPanel.showColumnFields,
                        showDataFields: optionFieldPanel.showDataFields,
                        showFilterFields: optionFieldPanel.showFilterFields,
                        showRowFields: optionFieldPanel.showRowFields
                    }
                }
            };

        } else if (this.gridRef && this.gridRef.current && this.gridRef.current.instance && relatorio.titulo && relatorio.descricao) {
            let cols = this.gridRef.current.instance.state().columns;
            _.forEach(_.filter(this.gridRef.current.instance.option('columns'), 'format'), (value) => {
                let col = _.find(cols, { 'dataField': value.dataField });
                col.format = value.format;
            });
            layoutJson = {
                type: this.state.gridType,
                columns: cols,
                options: {
                    showColumnLines: this.gridRef.current.instance.option('showColumnLines'),
                    showRowLines: this.gridRef.current.instance.option('showRowLines'),
                    showBorders: this.gridRef.current.instance.option('showBorders'),
                    rowAlternationEnabled: this.gridRef.current.instance.option('rowAlternationEnabled'),
                },
                summary: this.gridRef.current.instance.option('summary')
            };
        } else {
            pushWarning("Preencha todos os campos para salvar.")
            return;
        }

        relatorio.layoutJson = JSON.stringify(layoutJson);
        relatorio.relatorioPerfils = this.perfilRef.current.value;
        relatorio.idFonteRelatorio = this.state.fonteRelatorioSelecionada;
        if (relatorio.idRelatorio) {
            relatorioService.updRelatorio(relatorio).then(
                (response) => {
                    //this.reiniciarTela();
                }
            )
        } else {
            relatorioService.insRelatorio(relatorio).then(
                (response) => {                    
                    relatorio.idRelatorio = response.result[0].idRelatorio;
                    this.setState({ relatorio });
                    //this.reiniciarTela();
                }
            )
        }

        if(this.state.modalConfirmEncerrar.abrirModal){
            this.setState({modalConfirmEncerrar: {abrirModal: false}});
            this.reiniciarTela();
        }
    }

    handleChange(event) {
        let relatorio = _.cloneDeep(this.state.relatorio);
        relatorio[event.target.name] = event.target.value;
        this.setState({ relatorio });
    }

    selecionarRelatorio(relatorio) {
        this.setState({ loading: true });
        let fonteSelecionada = _.find(this.state.fonteRelatorio, {idFonteRelatorio: relatorio.fonteRelatorio.idFonteRelatorio});
        fonteSelecionada.modoConfig = true;
        relatorioService.getRelatorioDados(fonteSelecionada).then((response) => {
            if (response.result) {
                let dadosCampos = _.map(response.result.campos, (obj) => {
                    if (obj.Formato) {
                        obj.Formato = JSON.parse(obj.Formato)
                    }
                    return obj;
                });
                this.setState({ dadosRelatorio: response.result.dados, dadosCampos });
                relatorioService.getRelatorioPerfil(relatorio.idRelatorio)
                    .then((response) => {
                        let optionSalvo = JSON.parse(relatorio.layoutJson);
                        let options = this.state.options;
                        if (optionSalvo.type === 'pivot') {
                            options = getOptionsPivot(optionSalvo.options);
                        } else {
                            options = getOptionsGrid(optionSalvo.options);
                        }
                        this.setState({ gridType: optionSalvo.type, options: options, relatorio, abrirListaRelatorios: false, editarRelatorio: true, perfilSelecionado: response.result, layoutJson: JSON.parse(relatorio.layoutJson), fonteRelatorioSelecionada: relatorio.fonteRelatorio?.idFonteRelatorio });
                    });
            }
        }).finally(() => {
            this.setState({ loading: false });
        });
    }

    encerrarRelatorio() {
        this.setState({
            modalConfirmEncerrar: {
                abrirModal: true,
                tittle: "Confirmação de encerramento",
                message: "Deseja salvar as alterações realizadas?",
                buttonSimClick: ((this.state.abrirCriarRelatorios || this.state.editarRelatorio) ?  this.salvarRelatorio :  this.criarRelatorio),
                buttonNaoClick: this.reiniciarTela
            }
        })
    }

    reiniciarTela() {
        let perfilSelecionado = _.filter(this.state.listaPerfil, (obj) => { return obj.iD_Perfil === 1 });

        this.setState({
            abrirListaRelatorios: true,
            editarRelatorio: false,
            abrirCriarRelatorios: false,
            relatorio: {},
            paginacao: {
                page: {
                    number: 1,
                    size: 25
                }
            },
            perfilSelecionado,
            errorInput: {
                titulo: false,
                descricao: false
            },
            layoutJson: [],
            fonteRelatorioSelecionada: null
        }, () => {
            this.closeModalConfirm();
            setTimeout(()=>{
                relatorioService.getRelatorios(this.state.paginacao).then((response) => {
                    if (response && response.result) {
                        this.setState({ listaRelatorios: response.result })
                    }
                })                
            },1000)                        
        })
        if(this.state.modalConfirmEncerrar.abrirModal){
            this.setState({modalConfirmEncerrar: {abrirModal: false}});
        }
    }

    excluirRelatorio() {
        relatorioService.delRelatorio(this.state.relatorio.idRelatorio).then((response) => {
            this.reiniciarTela();
        });
    }

    closeModalConfirm() {
        this.setState({ modalConfirm: { ...this.state.modalConfirm, abrirModal: false } });
    }

    closeModalConfirmEncerrar() {
        this.setState({ modalConfirmEncerrar: { ...this.state.modalConfirmEncerrar, abrirModal: false } });
    }

    confirmarExcluirRelatorio() {
        let modalConfirm = this.state.modalConfirm;
        modalConfirm.abrirModal = true;
        modalConfirm.title = 'Confirmar Exclusão'
        modalConfirm.message = 'Tem certeza que deseja excluir o relatório selecionado?'
        modalConfirm.buttonSimClick = this.excluirRelatorio;
        this.setState({ modalConfirm });
    }

    handleChangeOptions(e) {
        switch (e.target.name) {
            case 'showTotalsPrior':
                this.pivotGrid.current.instance.option(e.target.name, e.target.checked ? 'both' : 'none');
                break;
            case 'dataFieldArea':
                this.pivotGrid.current.instance.option(e.target.name, e.target.checked ? 'column' : 'row');
                break;
            case 'rowHeaderLayout':
                this.pivotGrid.current.instance.option(e.target.name, e.target.checked ? 'tree' : 'standard');
                break;
            default:
                if (this.pivotGrid !== null && this.pivotGrid.current !== null) {
                    if(e.target.name.indexOf('fieldPanel.') !== -1){
                        let option = this.pivotGrid.current.instance.option('fieldPanel');
                        option[e.target.name.replace('fieldPanel.', '')] = e.target.checked;
                        this.pivotGrid.current.instance.option('fieldPanel', option);
                    }else{
                        this.pivotGrid.current.instance.option(e.target.name, e.target.checked);
                    }
                }
                if (this.gridRef !== null && this.gridRef.current !== null) {
                    this.gridRef.current.instance.option(e.target.name, e.target.checked);
                }
                break;
        }
        let options = _.map(this.state.options, (option) => {
            if (option.option === e.target.name) {
                option.value = e.target.checked
            }
            return option;
        });
        this.setState(options);
    }

    changeGridType(value) {
        this.setState({ gridType: value });
        if (value === 'pivot') {
            let options = getOptionsPivot();
            this.setState({ options });
        } else {
            let options = getOptionsGrid();
            this.setState({ options });
        }
    }



    handleCloseBackdrop() {
        this.setState({ loading: false });
    }

    buscaDadosRelatorio(idFonteRelatorio) {
        this.setState({ loading: true });
        let fonteSelecionada = _.find(this.state.fonteRelatorio, {idFonteRelatorio: idFonteRelatorio});
        fonteSelecionada.modoConfig = true;
        relatorioService.getRelatorioDados(fonteSelecionada).then((response) => {
            if (response.result) {
                let dadosCampos = _.map(response.result.campos, (obj) => {
                    if (obj.Formato) {
                        obj.Formato = JSON.parse(obj.Formato)
                    }
                    return obj;
                });
                this.setState({ dadosRelatorio: response.result.dados, dadosCampos });
            }
        }).finally(() => {
            this.setState({ loading: false });
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <>
                <Grid container spacing={1}>
                    <Grid item lg={10} md={10} sm={8}>
                        <div className={classes.header}>
                            <label className={classes.labelHeader}>Configurar Relatórios</label>
                            <Divider orientation="vertical" flexItem />
                            <Button
                                size="small"
                                className={classes.button}
                                startIcon={<FontAwesomeIcon icon={['fa', ((this.state.abrirCriarRelatorios || this.state.editarRelatorio) ? 'save' : 'plus')]} />}
                                onClick={((this.state.abrirCriarRelatorios || this.state.editarRelatorio) ? this.salvarRelatorio : this.criarRelatorio)}
                            >
                                {(this.state.abrirCriarRelatorios || this.state.editarRelatorio) ? "Salvar Relatório" : "Novo Relatório"}
                            </Button>
                            {(this.state.editarRelatorio) &&
                                <>
                                    <Divider orientation="vertical" flexItem />
                                    <Button
                                        size="small"
                                        className={classes.button}
                                        startIcon={<FontAwesomeIcon icon={['fa', 'trash']} />}
                                        onClick={this.confirmarExcluirRelatorio}
                                    >
                                        Excluir Relatório
                                </Button>
                                </>
                            }
                            {(this.state.abrirCriarRelatorios || this.state.editarRelatorio) &&
                                <>
                                    <Divider orientation="vertical" flexItem />
                                    <Button
                                        size="small"
                                        className={classes.button}
                                        onClick={this.encerrarRelatorio}
                                    >
                                        Encerrar
                                </Button>
                                </>
                            }

                        </div>
                        {(this.state.abrirCriarRelatorios || this.state.editarRelatorio) &&
                            <Paper className={classes.paperRelatorio}>
                                {this.state.gridType === 'pivot' && this.state.dadosCampos.length > 0 &&
                                    <RelatorioPivot
                                        campos={this.state.dadosCampos}
                                        data={this.state.dadosRelatorio}
                                        configurar={true}
                                        pivotGrid={this.pivotGrid}
                                        layoutJson={this.state.layoutJson}
                                        options={this.state.options}
                                        fields={this.state.fields}
                                    />
                                }
                                {this.state.gridType === 'grid' && this.state.dadosCampos.length > 0 &&
                                    <RelatorioDataGrid
                                        campos={this.state.dadosCampos}
                                        data={this.state.dadosRelatorio}
                                        configurar={true}
                                        gridRef={this.gridRef}
                                        layoutJson={this.state.layoutJson}
                                        optionsDefault={this.state.options}
                                    />
                                }
                            </Paper>
                        }
                    </Grid>
                    {(this.state.abrirCriarRelatorios || this.state.editarRelatorio) &&
                        <Grid item lg={2} md={2} sm={4}>
                            <Paper className={classes.paper}>
                                <PropriedadesRelatorio
                                    perfilSelecionadoEdit={this.state.perfilSelecionado}
                                    relatorioEdit={this.state.relatorio}
                                    tituloRef={this.tituloRef}
                                    descricaoRef={this.descricaoRef}
                                    perfilRef={this.perfilRef}
                                    listaPerfil={this.state.listaPerfil}
                                    options={this.state.options}
                                    handleChangeOptions={this.handleChangeOptions}
                                    changeGridType={this.changeGridType}
                                    gridType={this.state.gridType}
                                    listaFonteRelatorio={this.state.fonteRelatorio}
                                    fonteRelatorioSelecionada={this.state.fonteRelatorioSelecionada}
                                    setFonteRelatorioSelecionada={(value) => {this.buscaDadosRelatorio(value);this.setState({fonteRelatorioSelecionada:value});}}
                                />
                            </Paper>
                        </Grid>
                    }
                    {this.state.abrirListaRelatorios &&
                        <Grid item lg={2} md={2} sm={4}>
                            <Paper className={classes.paper}>
                                <ListaRelatorios
                                    data={this.state.listaRelatorios}
                                    selecionarRelatorio={this.selecionarRelatorio} />
                            </Paper>
                        </Grid>
                    }
                </Grid>
                {this.state.modalConfirm.abrirModal &&
                    <ModalConfirm
                        open={this.state.modalConfirm.abrirModal}
                        modalProps={this.state.modalConfirm}
                        buttonCancelarClick={() => { this.closeModalConfirm() }} />
                }
                {this.state.modalConfirmEncerrar.abrirModal &&
                    <ModalConfirm
                        open={this.state.modalConfirmEncerrar.abrirModal}
                        modalProps={this.state.modalConfirmEncerrar}
                        buttonCancelarClick={() => { this.closeModalConfirmEncerrar() }}
                        btnNaoClick={true}
                         />
                }
                {this.state.loading &&
                    <Backdrop className={classes.backdrop} open={this.state.loading} onClick={this.handleCloseBackDrop}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }
            </>);
    }
}

export default withStyles(useStyles)(ConfigurarRelatorio)
