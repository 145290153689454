import React, {useState} from 'react';

import FaseModal from '../../components/FaseModal/FaseModal';

export function Enriquecimento() {
    const [ aberto,setAberto ] = useState(false);
    return <>
            <button  onClick={()=>setAberto(true)}>Abrir Modal Enriquecimento</button>
            
            <FaseModal  opened={aberto}
                        faseId={1}
                        carteiraProcessoHistoricoId={1} 
                        onClose={()=>setAberto(false)}
            />
           </>;
}
