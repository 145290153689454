import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ProcessosTable } from '../../components/ProcessosTable/ProcessosTable';
import { CarteirasTable } from '../../components/CarteirasTable/CarteirasTable';
import { AcoesProcesso } from '../../components/AcoesProcesso/AcoesProcesso';
import ModalConfirm from '../../components/ModalConfirm/ModalConfirm';
import './WorkFlowPage.css';
import { processoActions, filtroActions } from '../../actions';
import ReprovarProcesso from '../../components/AcoesProcesso/ReprovarProcesso';
import { processoService } from '../../services';
import AdicionarProcessos from '../../components/AdicionarProcessos/AdicionarProcessos';
import FaseModal from '../../components/FaseModal/FaseModal';
import ModalProcesso from '../../components/ModalProcesso/ModalProcesso';
import ModalDossie from '../../components/ModalDossie/ModalDossie';
import ModalMudarResponsavel from '../../components/ModalMudarResponsavel/ModalMudarResponsavel';
import { Comentarios } from '../../components/Comentarios/Comentarios';
import { ProcessoFundo }from '../../components/ProcessoFundo/ProcessoFundo';
import _ from 'lodash';

class WorkFlowPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idCarteira: null,
            statusFluxo: null,
            modalConfirm: {
                abrirModal: false,
                tittle: "",
                message: "",
            },
            modalProcesso: [],
            modalDossie: [],
            processoFundo: {
                open: false
            },
            modalMudarResponsavel: [],
            reprovaModal: false,
            idProcessoAcao: 0,
            observacao: '',
            idMensagem: null,
            motivos: [],
            adicionaProcessos: false,
            faseModal: {
                open: false
            },
            comentarioBox: {
                open: false,
                idCarteiraProcesso: null,
                numeroProcesso: ""
            },
            pesquisaURL:false,
            selecionadosAcao:[],
            labelModal: '',
            idProcessoStatus: null
        };
        this.selecionarCarteira = this.selecionarCarteira.bind(this);
        this.selecionarAcao = this.selecionarAcao.bind(this);
        this.closeAcoes = this.closeAcoes.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.aprovarProcessos = this.aprovarProcessos.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.selectChange = this.selectChange.bind(this);
        this.reprovarProcessos = this.reprovarProcessos.bind(this);
        this.adicionouProcessos = this.adicionouProcessos.bind(this);
        this.incluirProcesso = this.incluirProcesso.bind(this);
        this.abreComentarioFaseModal=this.abreComentarioFaseModal.bind(this);
    }

    

    componentDidUpdate(prevProps){
        const { match: { params } } = this.props;
        if(params.processoNumero && this.props.camposFiltros && !this.state.pesquisaURL){
            let campo = _.find(this.props.camposFiltros, (value) => {
                return value.cod === 'ProcessoNumero';
            });
            if(campo){
                this.props.adicionaFiltro(campo, params.processoNumero);
                this.setState({pesquisaURL:true});
            }
        }
    }

    abreComentarioFaseModal(idCarteiraProcesso,processoNumero){
        this.abrirComentarios(idCarteiraProcesso,processoNumero);
    }

    handleChange(e) {
        let change = {}
        change[e.target.name] = e.target.value
        this.setState(change)
    }

    selectChange(e) {
        this.setState({ idMensagem: e.value });
    }


    selecionarCarteira(idCarteira, statusFluxo) {
        this.setState({ idCarteira });
        this.setState({ statusFluxo });
    }

    incluirProcesso(){
        this.setState({ adicionaProcessos: true });
    }

    selecionarAcao(acao) {
        if(acao.urL_Rota === '/IncluirProcesso'){
            this.props.atualizaSelecionados([], this.props.processos)
            this.incluirProcesso();
            return;
        }
        this.setState({ idProcessoAcao: acao.idProcessoAcao, selecionadosAcao: _.cloneDeep(this.props.selecionados) });
        let selecionado = this.props.processos[this.props.selecionados[0]];
        
        processoService.getPermissao([selecionado.idProcesso], selecionado.idCarteira, acao.idProcessoAcao).then((response) => {
            if (response.messages === null || response.messages.length === 0) {
                if(!acao.gravarAcaoAposSucesso){
                    processoService.insAcoesProcessos([selecionado.idProcesso], selecionado.idCarteira, acao.idProcessoAcao);
                }
                switch (acao.urL_Rota) {
                        case '/Visualizar':
                            this.setState({
                                modalProcesso:
                                {
                                    open: true,
                                    processoId: selecionado.idProcesso,
                                    carteiraProcessoHistoricoId: selecionado.idCarteiraProcessoHistorico,
                                    idCarteiraProcesso: selecionado.idCarteiraProcesso, 
                                    processoNumero: selecionado.processoNumero
                                }
                            });
                            this.closeAcoes();
                            break;
                        case '/MudarResponsavel':
                                const processosSelecionados = [];
                                this.props.selecionados.forEach(checked => {
                                    processosSelecionados.push(this.props.processos[checked].idProcesso)
                                });
                                this.setState({
                                    modalMudarResponsavel:
                                    {
                                        open: true,
                                        processosId: processosSelecionados,
                                        faseId: acao.idFase
                                    }
                                });
                                this.closeAcoes();
                                break;
                        case '/Aprovar':
                            this.setState({
                                modalConfirm: {
                                    abrirModal: true,
                                    tittle: "Aprovar",
                                    message: `Você tem certeza que deseja aprovar ${this.props.selecionados.length} processos?`,
                                    buttonSimClick: this.aprovarProcessos
                                }
                            });
                            break;
                        case '/Reprovar':                           
                            processoService.getMotivos(selecionado.idProcessoStatus).then((response) => {
                                var motivos = [];
                                response.result.forEach(motivo => {
                                    motivos.push({ value: motivo.idMensagem, label: motivo.descricao })
                                });
                                this.setState({ reprovaModal: true, motivos, labelModal: 'Reprovar' });
                            });
                            break;
                        case '/Enriquecimento':
                            this.setState({
                                faseModal:
                                {
                                    open: true,
                                    faseId: acao.idFase,
                                    carteiraProcessoHistoricoId: selecionado.idCarteiraProcessoHistorico,
                                    idCarteiraProcesso: selecionado.idCarteiraProcesso, 
                                    titulo: acao.acaoDescricao,
                                    processoId: selecionado.idProcesso,
                                    processoNumero: selecionado.processoNumero,
                                    idProcessoStatus: selecionado.idProcessoStatus
                                }
                            });
                            this.closeAcoes();
                            this.props.atualizaProcessos();
                            break;
                        case '/Comentario':
                            this.abrirComentarios(selecionado.idCarteiraProcesso, selecionado.processoNumero);
                            this.closeAcoes();
                            break;
                        case '/ProcessoFundo':
                            this.setState({
                                processoFundo:
                                {
                                    open: true,
                                    processoId: selecionado.idProcesso,
                                    idCarteiraProcesso: selecionado.idCarteiraProcesso, 
                                    processoNumero: selecionado.processoNumero
                                }
                            });
                            this.closeAcoes();
                            break;
                        case '/Dossie':
                            this.abrirDossie(selecionado.idProcesso, selecionado.idCarteiraProcessoHistorico, selecionado.idCarteiraProcesso, selecionado.processoNumero);
                            this.closeAcoes();
                            break;
                        case '/Desfazer':
                            processoService.getMotivos(selecionado.idProcessoStatus).then((response) => {
                                var motivos = [];
                                response.result.forEach(motivo => {
                                    motivos.push({ value: motivo.idMensagem, label: motivo.descricao })
                                });
                                this.setState({ reprovaModal: true, motivos, labelModal: 'Desfazer' });
                            });
                            break;
                        case '/Cancelar':
                            processoService.getMotivos(selecionado.idProcessoStatus).then((response) => {
                                var motivos = [];
                                response.result.forEach(motivo => {
                                    motivos.push({ value: motivo.idMensagem, label: motivo.descricao })
                                });                                
                                this.setState({ reprovaModal: true, motivos, labelModal: 'Cancelar'   });
                            });
                            break;
                        default:
                            break;
                    }
                }
                this.props.atualizaSelecionados([], _.map(this.props.processos, (processo) => { return {...processo, selecionado : false, countselecionados: 0}}))
        });
    }

    /**
     * Função responsável por invocar a janela de comentários
     * @param {*} idCarteiraProcesso 
     * @param {*} processoNumero 
     */
    abrirComentarios(idCarteiraProcesso, processoNumero){
        this.setState({ comentarioBox: { open: true, idCarteiraProcesso: idCarteiraProcesso, numeroProcesso: processoNumero } });             
    }

    abrirDossie(idProcesso, idCarteiraProcessoHistorico, idCarteiraProcesso, processoNumero){
        this.setState({
            modalDossie:
            {
                open: true,
                processoId: idProcesso,
                carteiraProcessoHistoricoId: idCarteiraProcessoHistorico,
                idCarteiraProcesso: idCarteiraProcesso, 
                processoNumero: processoNumero
            }
        });
    }

    aprovarProcessos() {
        const processosSelecionados = [];
        const idCarteira = this.state.idCarteira || (this.props.processos.length > 0 ? this.props.processos[this.state.selecionadosAcao[0]].idCarteira : 0);
        this.state.selecionadosAcao.forEach(checked => {
            processosSelecionados.push(this.props.processos[checked].idCarteiraProcesso)
        });

        this.props.aprovarProcessos(processosSelecionados, idCarteira, this.state.idProcessoAcao);
        this.closeModal();
    }

    reprovarModalConfirm() {
        this.setState({
            modalConfirm: {
                abrirModal: true,
                tittle: "Reprovar",
                message: `Você tem certeza que deseja reprovar ${this.state.selecionadosAcao.length} processos?`,
                buttonSimClick: this.reprovarProcessos
            }
        });
    }

    reprovarProcessos() {
        const processosSelecionados = [];
        const idCarteira = this.state.idCarteira || (this.props.processos.length > 0 ? this.props.processos[this.state.selecionadosAcao[0]].idCarteira : 0);
        this.state.selecionadosAcao.forEach(checked => {
            processosSelecionados.push(this.props.processos[checked].idCarteiraProcesso)
        });

        this.props.reprovarProcessos(processosSelecionados, idCarteira, this.state.idProcessoAcao, this.state.observacao, this.state.idMensagem);
        this.closeModal();
    }

    /**
     * Chamada para fechar a visualização do Processo
     */
    closeModalProcesso() {
        this.setState({
            modalProcesso:
            {
                open: false,
                processoId: undefined,
                carteiraProcessoHistoricoId: undefined
            }
        });
    }

     /**
     * Chamada para fechar a visualização do Processo
     */
    closeModalDossie() {
        this.setState({
            modalDossie:
            {
                open: false,
                processoId: undefined,
                carteiraProcessoHistoricoId: undefined
            }
        });
    }

    /**
     * Chamada para fechar a visualização do Processo Fundo
     */
    closeModalProcessoFundo() {
        this.setState({
            processoFundo:
            {
                open: false,
                processoId: undefined
            }
        });
    }

    /**
     * Chamada para fechar o modal de Mudar Responsável
     */
    closeModalMudarResponsavel() {
        this.setState({
            modalMudarResponsavel:
            {
                open: false,
                processosId: undefined,
                faseId: undefined
            }
        });
    }

    closeModal() {
        this.setState({
            adicionaProcessos: false,
            reprovaModal: false,
            faseModal: { ...this.faseModal, open: false},
            modalConfirm: {
                abrirModal: false,
                tittle: "",
                message: ""
            },
            comentarioBox: { open: false }
        });
        this.closeAcoes();
        this.props.recarregaProcessos();
    }

    closeAcoes() {
        this.props.limpaAcoes();
        this.props.atualizaSelecionados([], _.map(this.props.processos, (processo) => { return {...processo, selecionado : false, countselecionados: 0}}))
    }

    adicionouProcessos() {
        this.props.atualizaProcessos();
    }    

    render() {
        const { match: { params } } = this.props;

        return (
            <>
                <div className="mt-3 carteiraBox-right">
                    <div>
                        <ProcessosTable 
                                        idCarteira={this.state.idCarteira || (this.props.carteiras.length > 0 ? this.props.carteiras[0].idCarteira : null)} 
                                        adicionarProcesso={this.incluirProcesso} 
                                        statusFluxo={this.state.statusFluxo || (this.props.carteiras.length > 0 ? this.props.carteiras[0].statusFluxo : null)}/>
                    </div>
                </div>
                <div className="mt-3 pl-3 pr-3 border-right carteiraBox-left">
                    <CarteirasTable selecionarCarteira={this.selecionarCarteira} selecionarAcao={this.selecionarAcao} isFilterSearch={params.isFilterSearch} />
                </div>
                <AcoesProcesso acoes={this.props.acoes} selecionarAcao={this.selecionarAcao} closeAcoes={this.closeAcoes} ></AcoesProcesso>
                {this.state.modalConfirm.abrirModal &&
                    <ModalConfirm open={this.state.modalConfirm.abrirModal} modalProps={this.state.modalConfirm} buttonCancelarClick={() => { this.closeModal() }} ></ModalConfirm>
                }
                {this.state.reprovaModal &&
                    <ReprovarProcesso
                        options={this.state.motivos}
                        open={this.state.reprovaModal}
                        buttonReprovarClick={() => { this.reprovarModalConfirm() }}
                        buttonCancelarClick={() => { this.closeModal() }}
                        handleChange={this.handleChange}
                        selectChange={this.selectChange}
                        disabled={!(this.state.idMensagem && this.state.idMensagem !== '')}
                        label={this.state.labelModal}
                    />
                }
                {this.state.idCarteira && this.state.idCarteira !== 0 && 
                    <AdicionarProcessos
                        idCarteira={this.state.idCarteira || (this.props.carteiras.length > 0 ? this.props.carteiras[0].idCarteira : null)}
                        open={this.state.adicionaProcessos}
                        closeModal={this.closeModal}
                        adicionouProcessos={this.adicionouProcessos} />
                }
                <FaseModal 
                    titulo={this.state.faseModal.titulo}
                    opened={this.state.faseModal.open}
                    faseId={this.state.faseModal.faseId}
                    processoId={this.state.faseModal.processoId}
                    carteiraId={this.state.idCarteira || (this.props.carteiras.length > 0 ? this.props.carteiras[0].idCarteira : null)}
                    carteiraProcessoHistoricoId={this.state.faseModal.carteiraProcessoHistoricoId}
                    idCarteiraProcesso={this.state.faseModal.idCarteiraProcesso}
                    idUsuario={this.props.usuario.iD_Usuario}
                    processoNumero={this.state.faseModal.processoNumero}
                    onClose={() => this.closeModal()}
                    close={()=>{this.closeModal(); this.props.atualizaProcessos();}}
                    openComentarios={(idCarteiraProcesso,processoNumero) => {    this.abreComentarioFaseModal(idCarteiraProcesso,processoNumero); }}
                    idProcessoStatus={this.state.faseModal.idProcessoStatus}
                />
                {this.state.modalProcesso &&
                    <ModalProcesso ref="modalProcesso"
                        carteiraProcessoHistorico={this.state.modalProcesso.carteiraProcessoHistoricoId}
                        processoId={this.state.modalProcesso.processoId}
                        processoNumero={this.state.modalProcesso.processoNumero}
                        onClose={() => this.closeModalProcesso()} 
                        openComentarios={() => this.abrirComentarios(this.state.modalProcesso.idCarteiraProcesso, this.state.modalProcesso.processoNumero)}
                        openDossie={() => this.abrirDossie(this.state.modalProcesso.processoId, this.state.modalProcesso.carteiraProcessoHistoricoId, this.state.modalProcesso.idCarteiraProcesso, this.state.modalProcesso.processoNumero)}/>
                }
                <ProcessoFundo 
                        open={this.state.processoFundo.open}
                        processoNumero={this.state.processoFundo.processoNumero} 
                        processoId={this.state.processoFundo.processoId}
                        idCarteira={this.state.idCarteira || (this.props.carteiras.length > 0 ? this.props.carteiras[0].idCarteira : null)} 
                        idCarteiraProcesso={params.idCarteiraProcesso}
                        onClose={() => this.closeModalProcessoFundo()}/>
                
                {this.state.modalMudarResponsavel &&
                    <ModalMudarResponsavel ref="modalMudarResponsavel"
                        processosId={this.state.modalMudarResponsavel.processosId}
                        faseId={this.state.modalMudarResponsavel.faseId}
                        adicionouProcessos={this.adicionouProcessos}
                        onClose={() => this.closeModalMudarResponsavel()} />
                }

                {this.state.comentarioBox.open &&
                    <Comentarios
                        filtros={this.props.filtros}
                        idCarteiraProcesso={this.state.comentarioBox.idCarteiraProcesso}
                        idCarteira={this.state.idCarteira}
                        numeroProcesso={this.state.comentarioBox.numeroProcesso}
                        idUsuario={this.props.usuario.iD_Usuario}
                        onClose={() => this.closeModal()}
                        statusFlag={this.props.statusFlag}
                    />
                }
                {this.state.modalDossie &&  
                    <ModalDossie ref="modalDossie"
                        carteiraProcessoHistorico={this.state.modalDossie.carteiraProcessoHistoricoId}
                        processoId={this.state.modalDossie.processoId}
                        processoNumero={this.state.modalDossie.processoNumero}
                        onClose={() => this.closeModalDossie()} 
                    />
                }   
            </>
        );
    }
}

function mapState(state) {
    const { carteiras } = state.carteiras;
    const { processos, selecionados, acoes } = state.processos;
    const { usuario } = state.authentication;
    const camposFiltros = state.filtros.campos;
    const { filtros } = state.filtros;
    const { statusFlag } = state.filtros;
    return { carteiras, acoes, selecionados, processos, usuario, camposFiltros, filtros, statusFlag };
}

const actionCreators = {
    limpaAcoes: processoActions.limpaAcoes,
    aprovarProcessos: processoActions.aprovarProcessos,
    reprovarProcessos: processoActions.reprovarProcessos,
    atualizaProcessos: processoActions.atualizaProcessos,
    adicionaFiltro: filtroActions.adicionaFiltro,
    atualizaSelecionados: processoActions.atualizaSelecionados,
    adicionaFiltroStatusFlag: filtroActions.adicionaFiltroStatusFlag,
    recarregaProcessos: processoActions.recarregaProcessos
};

const connectedWorkFlowPage = connect(mapState, actionCreators)(WorkFlowPage);
export { connectedWorkFlowPage as WorkFlowPage };