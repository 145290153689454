import styled from 'styled-components';

const defaultFontFamily='Montserrat !important';

export const FaseProgressoContainer = styled.div(props=>({
    width                   : props.width    || '100%',
    height                  : props.heigth   || '100%',
    fontSize                : props.fontSize || '12px',
    overflowX               : 'hidden',
    overflowY               : 'auto',
    borderBottomRightRadius : '20px',
    fontFamily              : defaultFontFamily,
    textDecoration          : 'none',
}));

export const FaseProgressoList = styled.ul(props=>({
    listStyle               : 'none', 
    fontSize                : '11px',
}));

export const FaseProgressoListItem = styled.li(props=>({    
    position                : 'relative',  
    marginBottom            : props.sessao ? '25px' : '10px',
    marginLeft              : '-25px',
    marginTop               : props.sessao ? '25px' : '10px',
}));

export const FaseProgressoListItemLabel = styled.span(props=>({
    position                : 'relative',
    display                 : 'flex',
    marginLeft              : '26px',
    marginTop               : props.sessao ? '-21px' : '-19px',
    color                   : props.sessao ? props.color : '#5d5d5d',
}));

export const FaseProgressoListItemLineBetween = styled.div(props=>({
    position                : 'absolute',                              
    height                  : props.sessao || props.proximoEhSessao ? 'calc(100% + 8px)':'calc(100% - 4px)',
    width                   : '1px',
    borderLeft              : `1px solid ${props.color}`,
    left                    : '6px',
    top                     : props.sessao ? '17px':'14px'
}));
  
