import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Select from 'react-select';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: "#F5F5F5",
        padding: theme.spacing("15px", "25px"),
        borderRadius: "10px",
        fontSize: "13px",
        outline: 'none'
    },
    buttonSim: {
        backgroundColor: "#E82E59",
        color: "white",
        fontSize: "10px"
    },
    buttonCancelar: {
        backgroundColor: "#FC7575",
        color: "white",
        fontSize: "10px",
        marginRight: "16px"
    },
    contentModal: {
        width:500,
        backgroundColor: "white",
        padding: 16
    }
}));

export default function ReprovarProcesso(props) {
    const classes = useStyles();

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={props.open}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classes.paper}>
                        <React.Fragment>
                            <label>{props.label}</label>
                            <div className={classes.contentModal} >
                                <label>Selecione o motivo</label>
                                <Select 
                                    name="idMensagem" 
                                    options={props.options}
                                    value={props.motivoSelecionado}
                                    disabled={props.motivoSelecionado ? true :false}
                                    onChange={props.selectChange.bind(this)}  
                                    noOptionsMessage={()=>{return "Não encontrado";}}
                                    placeholder="Selecionar"
                                ></Select>
                                <textarea name="observacao" value={props.observacao} style={{marginTop:10}} className="form-control" rows={6} onChange={props.handleChange.bind(this)}></textarea>
                                <div className="text-right mt-2">
                                    <Button
                                        style={{ marginLeft: 10 }}
                                        variant="contained"
                                        className={classes.buttonCancelar}
                                        onClick={(e) => { props.buttonCancelarClick() }}
                                    >
                                        Cancelar
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className={classes.buttonSim}
                                        onClick={(e) => { props.buttonReprovarClick() }}
                                        disabled={props.disabled}
                                    >
                                        {props.label == 'Cancelar' ? 'Confirmar' : props.label}
                                    </Button>
                                </div>
                            </div>
                        </React.Fragment>
                    </div>
                </Fade>
            </Modal>
        </>
    );
}