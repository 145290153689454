import React,{Component} from 'react';
import {toPattern} from 'vanilla-masker';
import './FaseEditor.css';
import { faseService, anexoService } from '../../services/index';
import {pushError,pushInfo,pushWarning} from '../../services/notifierService';
import ModalConfirm from '../../components/ModalConfirm/ModalConfirm';
import ReprovarProcesso from '../../components/AcoesProcesso/ReprovarProcesso';
import { processoService } from '../../services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Backdrop,CircularProgress, Tooltip } from '@material-ui/core';
import { faPaperclip,faSearch } from '@fortawesome/free-solid-svg-icons';
import { withStyles } from "@material-ui/core/styles";
import axiosInstance from '../../core/httpInterceptor';

const useStyles = (theme) => ({    
    backdrop: {        
        color: '#fff',
        zIndex : 1500,
    },
    button: {
        margin: theme.spacing(1),
        color: 'white',
        backgroundColor:'#36C0FB',
        
      },
});

class FaseEditor extends Component {
    constructor(props){
        super(props);
        const {onChange,onAbortChangeField,onChangeDadosConsulta,indiceEdicao,carteiraProcessoHistoricoId,processoId,carteiraId,idUsuario,onIndexChange,close,forcaIndiceEdicao,idProcessoStatus}=props;
        let data = (props.data || []).map((campo,originalIndex)=>{return {...campo,originalIndex}});
        let camposOk = data.filter(campo=>{return campo.estaOK && !campo.idFaseSessaoCampoBuscaValor});
        let indice=0
        if (camposOk.length>0)
            indice = camposOk[camposOk.length-1].originalIndex;
        data=data.map(campo=>{ return {...campo, 
                                    estaOK: ( campo.idFaseSessaoCampoBuscaValor && campo.permiteEdicao ? 
                                               (
                                                   (campo.originalIndex>=indice ) ? 
                                                    false : 
                                                    campo.estaOK 
                                               ) : campo.estaOK 
                                            ),
                                    statusCadastro: 
                                            ( campo.idFaseSessaoCampoBuscaValor && campo.permiteEdicao ? 
                                               (
                                                   (campo.originalIndex>=indice ) ? 
                                                    'Pendente' : 
                                                    campo.statusCadastro 
                                               ) : campo.statusCadastro 
                                             ) }})
        this.state = {
                        // Constante (tipo de campo da tabela tblFaseSessaoCampoTipo)
                        TIPOS_DECIMAIS          : [9,10,11,13,14],
                        VALOR_POSITIVO          : 9,
                        VALOR_NEGATIVO          : 10,
                        VALOR_NEUTRO            : 11,
                        SOMATORIA               : 13,
                        DECIMAL                 : 14,
                        ANEXO                   : 15,
                        FINALIZADOR             : 16,
                        BOTAO_ACAO              : 20,
                        // Constante que indica quando Näo possui valor (o que interrompe a sequencia caso seja campo múltiplo)
                        NAO_POSSUI              : 'Não possui',
                        // Controles de estado
                        campoEdicao             : {},
                        indice                  : indiceEdicao,
                        qualificadores          : [],
                        qualificadorSelecionado : null,
                        reprovaModal            : false,
                        ignoraIndiceRecebido    : false,
                        gravaRespostaRecuperadas: true,
                        atualizaData            : true,
                        estaReplicando          : false,
                        forcaIndiceEdicao,
                        motivos                 : [],
                        ultimoIDFonteAtualizado : 0,
                        modalConfirm            : {
                                                        abrirModal: false,
                                                        tittle: "",
                                                        message: "",
                                                  },
                        loading                 : false,
                        valorCache              : '',
                        // Propriedades
                        data,
                        carteiraProcessoHistoricoId,
                        processoId,
                        carteiraId,
                        idUsuario,
                        // Eventos injetados
                        onIndexChange,
                        onChange,
                        onAbortChangeField,
                        onChangeDadosConsulta,
                        // Método injetado
                        close,
                        idProcessoStatus
        }

        //Bind dos métodos        
        this.AtualizaQualificadores = this.AtualizaQualificadores.bind(this);
        this.VerificarSomatoria = this.VerificarSomatoria.bind(this);
        this.handleRespostaClick = this.handleRespostaClick.bind(this);        
        this.handleQualificadorClick = this.handleQualificadorClick.bind(this);
        this.getValor=this.getValor.bind(this);
        this.getValueForSet=this.getValueForSet.bind(this);        
        this.replicarCamposMultiplos=this.replicarCamposMultiplos.bind(this);
        this.logData=this.logData.bind(this);        
        this.obtemCamposFilho=this.obtemCamposFilho.bind(this);        
        this.proximaOrdemValor=this.proximaOrdemValor.bind(this);
        this.handleChangeValue=this.handleChangeValue.bind(this);
        this.handleKeyUp=this.handleKeyUp.bind(this);
        this.reprovarModalConfirm=this.reprovarModalConfirm.bind(this);
        this.openModalReprovacao=this.openModalReprovacao.bind(this);
        this.closeModalReprovacao=this.closeModalReprovacao.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.selectChange=this.selectChange.bind(this);
        this.reprovaProcesso=this.reprovaProcesso.bind(this);
        this.exibeCampoResposta=this.exibeCampoResposta.bind(this);
        this.tipoInput=this.tipoInput.bind(this);
        this.styleBotaoGravar=this.styleBotaoGravar.bind(this);
        this.handleChangeOpcao=this.handleChangeOpcao.bind(this);
        this.abrirAnexar=this.abrirAnexar.bind(this);
        this.anexar=this.anexar.bind(this);
        this.postFile=this.postFile.bind(this);        
        this.atualizaCampoChave=this.atualizaCampoChave.bind(this);
        this.obtemCampoChave=this.obtemCampoChave.bind(this);
        this.atualizaValorFilho=this.atualizaValorFilho.bind(this);
        this.gravaRespostaRecuperadas=this.gravaRespostaRecuperadas.bind(this);
        this.obterOrdemCampo=this.obterOrdemCampo.bind(this);
        this.atualizaDadosConsulta=this.atualizaDadosConsulta.bind(this);
        this.atualizaDadosApi=this.atualizaDadosApi.bind(this);
        this.reabreCamposPendentes=this.reabreCamposPendentes.bind(this);
        this.reabreCamposPendentesModalConfirm=this.reabreCamposPendentesModalConfirm.bind(this);
        this.handleClickAcao=this.handleClickAcao.bind(this);
        this.requisitarApi=this.requisitarApi.bind(this);        
    }

    componentDidMount(){
        // Carregando dados e populando estado
        const { forcaIndiceEdicao } = this.props;
        const { indiceEdicao} = this.state;        
        
        this.setState({indice : indiceEdicao,                       
                       valorCache  : '',                       
                       forcaIndiceEdicao
                      },
        ()=>this.PreencheValores());
    }

    componentDidUpdate(){
        const props = this.props;
        const { indiceEdicao,resposta,forcaIndiceEdicao } = this.props;
            
        if ((forcaIndiceEdicao && !this.state.forcaIndiceEdicao ) || (resposta && this.state.resposta !== resposta)){   
            
            if (this.state.gravaRespostaRecuperadas)
                this.gravaRespostaRecuperadas();     
            
            this.setState({indice: indiceEdicao, forcaIndiceEdicao, valorCache:resposta, resposta }                    
                ,
                ()=> {
                                                                                                                                 
                        this.PreencheValores();
                        if (resposta) {
                            if (this.state.resposta !== resposta)
                                this.setState({resposta},
                                    ()=>this.handleChangeValue({target:{value:resposta}}));
                        }                                                                                                    
                })            
        }         
    }

    gravaRespostaRecuperadas(){
        this.setState({gravaRespostaRecuperadas:false});        
        let data = this.state.data;
        let recuperados = data.filter(c=>c.idFaseSessaoCampoBuscaValor && !c.permiteEdicao);
        recuperados.forEach(recuperado=>{
            if (recuperado.idFaseSessaoCampoPai && !recuperado.idFaseSessaoCampoValorPai){
                for(let i=recuperado.originalIndex-1; i>=0; i--){
                    if (data[i].idFaseSessaoCampo===recuperado.idFaseSessaoCampoPai)
                    {
                        recuperado.idFaseSessaoCampoValorPai = data[i].idFaseSessaoCampoValor;
                        break;
                    }
                }
            }
            let saveFaseSessaoCampo={   
                idCarteiraProcessoHistorico : this.state.carteiraProcessoHistoricoId,
                idFaseSessaoCampo           : recuperado.idFaseSessaoCampo,
                valor                       : recuperado.valor,
                idAnexo                     : recuperado.idAnexo || 0,
                ordemValor                  : recuperado.ordemValor,
                ordemCampo                  : recuperado.ordemCampo,
                idFaseSessaoCampoValorPai   : recuperado.idFaseSessaoCampoValor,
            }
            faseService.saveFaseSessaoCampo(saveFaseSessaoCampo).then(response=>{
                data[recuperado.originalIndex].idFaseSessaoCampoValor = response.result[0].IDFaseSessaoCampoValor;
            });
        });

        //this.setState({data});
    }

    async AtualizaQualificadores(idFaseSessaoCampoQualificador) {
        if (idFaseSessaoCampoQualificador && idFaseSessaoCampoQualificador> 0)
        {
            let response= await faseService.getQualificador(idFaseSessaoCampoQualificador);            
            return response.result;
        } else {
            return [];
        }        
    }

  
    VerificarSomatoria(campo){        
        const {VALOR_NEGATIVO,VALOR_POSITIVO} = this.state;
        var total=0.00;
        
        this.state.data.filter(campoValor =>{ return   campoValor.idFaseSessaoCampoTipo === 9 || campoValor.idFaseSessaoCampoTipo === 10})
                       .forEach(campoValor=>{
            let multiplicador=0;
            if (campoValor.idFaseSessaoCampoTipo === VALOR_POSITIVO)       //Se tipo Valor Positivo adicionar no objeto somatoria
                multiplicador = 1;
            else if (campoValor.idFaseSessaoCampoTipo === VALOR_NEGATIVO) //Se tipo valor Negativo adicionar no objeto somatoria            
                multiplicador = -1;
            try{
                if (isFinite(faseService.replaceAll(faseService.replaceAll(faseService.replaceAll(faseService.replaceAll(campoValor.valor,'.',''),',','.'),'R$',''),' ',''))){
                    
                    let valor=(multiplicador*parseFloat( faseService.replaceAll(faseService.replaceAll(faseService.replaceAll(faseService.replaceAll(campoValor.valor,'.',''),',','.'),'R$',''),' ','')) );
                    if (!isNaN(valor))
                        total +=valor;
                }
            } catch(error){
                // Nothing to do...
            }
        });
        this.gravaCampo(campo,total.toLocaleString('pt-br', {minimumFractionDigits: 2}))
    }

    logData(){
        this.state.data.forEach(campo=>console.log(`id:${campo.idFaseSessaoCampo} pai:${campo.idFaseSessaoCampoPai} campo:${campo.pergunta} ov: ${campo.ordemValor} oc: ${campo.ordemCampo} valor: ${campo.valor}` ))
    }

    obtemUltimoElementoFilho(campo){
        if (campo){
            if (campo.idFaseSessaoCampoPai===0)
                return campo;
            else {
                let childs = this.state.data.filter(c=> {return c.idFaseSessaoCampoPai===campo.idFaseSessaoCampo });
                if (childs.length===0)
                    return campo;
                else
                    return this.obtemUltimoElementoFilho(childs[childs.length-1]); 
            }
        }
    }

    obtemCamposFilho(campoMultiplo){
        let {data}=this.state;
        let range=data.filter(campo=>{ return (campo.idFaseSessaoCampoValorPai===campoMultiplo.idFaseSessaoCampoValor ||
                                               campo.idFaseSessaoCampoValorPai===campoMultiplo.idFaseSessaoCampo) &&
                                               campo.originalIndex>=campoMultiplo.originalIndex }); 
        if (range.length===0)
            range.push({...campoMultiplo}); 
        else {
            let newRange = [];
            newRange.push({...campoMultiplo});
            range.forEach(c=>{ if (newRange.filter(f=>{return f.idFaseSessaoCampo===c.idFaseSessaoCampo}).length===0) 
                                  newRange.push({...c})
                             })
            range = newRange;                                               
        }
            
        
        return [...range];
    }    

    async replicarCamposMultiplos(campoEdicao){    
        try{ 
            this.setState({estaReplicando:true});
            let novaSessaoCampos=[];
            
            let copiar=this.obtemCamposFilho(campoEdicao);
            
            if(copiar){
                let camposChave=this.state.data.filter(campo=>{ return campo.idFaseSessaoCampo===campoEdicao.idFaseSessaoCampo 
                                                                    && campo.originalIndex>campoEdicao.originalIndex 
                                                                    && (campo.idFaseSessaoCampoValorPai || 0)===(campoEdicao.idFaseSessaoCampoValorPai || 0)
                                                                    && campo.valor !== null
                                                            });

                if (camposChave.length>0 )
                {        
                    this.PreencheValores();        
                    return;
                } else {
                    this.setState({ignoraIndiceRecebido:true});
                }
            }
            if (copiar.length>0){                
                let indiceLimite=copiar[copiar.length-1].originalIndex;
                let i=0;
                while (i<copiar.length){                
                    i=novaSessaoCampos.length;
                    if (i===copiar.length)
                        break;
                    let campo={...copiar[i],idFaseSessaoCampoValor:null, idFaseSessaoCampoValorPai:null};                
                    if (novaSessaoCampos.filter(c=>{return c.idFaseSessaoCampo===campo.idFaseSessaoCampo}).length>1)
                        break;
                    let tmp = novaSessaoCampos.filter(c=>{return c.idFaseSessaoCampo===campo.idFaseSessaoCampoPai});
                    let campoPai = tmp[tmp.length-1];
                    if (campoPai)
                        campo.idFaseSessaoCampoValorPai=campoPai.IDFaseSessaoCampoValor;
            
                    campo=await this.gravaCampo(campo,campo.valor || '');  
                    novaSessaoCampos.push({...campo});                                                      
                }; 

                for (let i=0; i<copiar.length; i++ ){
                    copiar[i]={   ...copiar[i], 
                                idFaseSessaoCampoValor      : null,                                
                            }
                }            

                var k=0;
                novaSessaoCampos = [];
                while (k<copiar.length){
                    k=novaSessaoCampos.length;

                    if (k===copiar.length)
                        break;
                    let campo={...copiar[k]};
                    let tmp = novaSessaoCampos.filter(c=>{return c.idFaseSessaoCampo===campo.idFaseSessaoCampoPai});
                    let campoPai = tmp[tmp.length-1];
                    campo ={   ...campo, 
                        ordemValor                  : this.proximaOrdemValor(campo),                                                 
                        statusCadastro              : 'PENDENTE',
                        idFaseSessaoCampoValor      : null,
                        idFaseSessaoCampoValorPai   : campoPai ? campoPai.idFaseSessaoCampoValor : 0,                        
                        estaOK                      : false,                    
                        valor                       : null };
                    if (k=0)
                        campo.obrigatorio=false;

                    if (novaSessaoCampos.filter(c=>{return c.idFaseSessaoCampo===campo.idFaseSessaoCampo}).length===0){
                        campo=await this.gravaCampo(campo,campo.valor || '');                                        
                        novaSessaoCampos.push({...campo});                 
                    }
                    
                };
                        
                let data = [];
                for (let i=0; i<=indiceLimite; i++){
                    data.push({...this.state.data[i]});
                }            
                if (novaSessaoCampos && novaSessaoCampos.length>0)
                    novaSessaoCampos[0].pergunta=novaSessaoCampos[0].perguntaRecorrencia || novaSessaoCampos[0].pergunta;
                for (let i=0;i<novaSessaoCampos.length; i++){                                               
                    data.push({...novaSessaoCampos[i], originalIndex: indiceLimite+i+1});                
                }
                let l=indiceLimite + novaSessaoCampos.length+1;
                for (let i=indiceLimite+1;i<this.state.data.length; i++){
                    data.push({...this.state.data[i], originalIndex : l++ });
                }                   
                for (let i=0;i<copiar.length; i++){
                    data[copiar[i].originalIndex].ordemValor=copiar[i].ordemValor;
                }                

                data[campoEdicao.originalIndex]={...campoEdicao};
                //console.log('Dados após replicação',data);
                this.setState({data:[...data], estaReplicando:false}, ()=>{                                     
                    if (this.state.onChange){                                
                        this.state.onChange(data,this.state.campoEdicao);       
                    } 
                    this.PreencheValores();                   
                });                       
            }
        } catch(e){
            console.log(e);
        } finally {
            this.setState({estaReplicando:false});
        }
    }
  
    proximaOrdemValor(campo){
        return this.state.data.filter(c=>c.idFaseSessaoCampo===campo.idFaseSessaoCampo).length+1;
    }
    
    postFile(){
        return new Promise((resolve,reject)=>{
            if(this.state.anexo !== null && this.state.anexo !== ""){
                const data = new FormData()
                data.append('file', this.state.anexo)
                anexoService.insAnexo(data).then((response) =>
                    {
                        if(response.result !== null && response.result.IDAnexo && response.result.IDAnexo > 0){
                                this.setState({idAnexo:response.result.IDAnexo,anexo:undefined,campoEdicao:{...this.state.campoEdicao, idAnexo:response.result.IDAnexo}},
                                ()=>{
                                    this.gravaCampo(this.state.campoEdicao,this.state.valorCache).then(()=>{
                                        resolve(true);
                                    })   
                                });                                                
                        }
                    });
            } else {
                resolve(true);
            }
        });
    }
    

    async handleRespostaClick(){
        if (!document.getElementById('FaseEditorGravar').disabled){
            if (!this.state.campoEdicao || !this.state.valorCache)
            return;
            if (!this.state.campoEdicao.idFaseSessaoCampo)
                return;
                        
            if (this.state.campoEdicao.idFaseSessaoCampoQualificador>0 && !this.state.qualificadorSelecionado){
                pushWarning('Seleciona uma opção');
                return;
            }
            if (this.state.campoEdicao.valor === this.state.valorCache && this.state.campoEdicao.originalIndex===this.state.data.length-1)
                return;
        
            if (this.state.campoEdicao.validacaoFormula) // Validar o campo usando uma fórmula
            {
                let data = this.state.data;
                data[this.state.campoEdicao.originalIndex]={...this.state.campoEdicao, valor:this.state.valorCache};
                let validou = faseService.CalculaFormula(this.state.campoEdicao.validacaoFormula, data);
                if (!validou)
                {
                    pushError(this.state.campoEdicao.validacaoMensagem);
                    if (this.state.campoEdicao.idMensagem)
                        this.openModalReprovacao();
                    
                    return;
                }
            }

            if (this.state.campoEdicao.regexValidacao &&  this.state.valorCache!==this.state.NAO_POSSUI && this.state.campoEdicao.componenteTela !== 'Button'){
                let regex=new RegExp(this.state.campoEdicao.regexValidacao);
                let validou= regex.test(this.getValor( this.state.valorCache ));
                if (!validou){
                    pushError(this.state.campoEdicao.mensagemValidacaoFormato || 'Formato inválido!');
                    return;
                }
            }
            
            if (this.state.campoEdicao.idFaseSessaoCampoTipo===this.state.ANEXO && this.state.anexo){
                try{
                    this.setState({loading:true});
                    await this.postFile();        
                } catch(erro){
                    console.log(erro);
                } finally {
                    this.setState({loading:false});                               
                }
            }
            
            this.gravaCampo(this.state.campoEdicao,this.getValor({...this.state.campoEdicao}, this.state.valorCache ))
                .then(campoGravado=>{
                    this.state.campoEdicao=campoGravado;
                    let data = this.state.data;
                    data[this.state.campoEdicao.originalIndex]=this.state.campoEdicao;
                    if (!this.state.campoEdicao.campoMultiplo){
                        data.filter(c=>{return c.idFaseSessaoCampoPai === this.state.campoEdicao.idFaseSessaoCampo })
                            .forEach(async campo=>{
                                campo.idFaseSessaoCampoValorPai = this.state.campoEdicao.idFaseSessaoCampoValor;
                                campo = await this.gravaCampo(campo,campo.valor || '');
                                data[campo.originalIndex]={...campo};
                            });
                    }
                    if (this.state.InicioPreenchimento){                        
                         faseService.updateTempoPreenchimento({ idCarteiraProcessoHistorico : this.state.carteiraProcessoHistoricoId, 
                                                                segundos                    : Math.round( Math.abs((new Date().getTime() - this.state.InicioPreenchimento.getTime()) / 1000))
                                                              });
                    }
                        
                    this.setState({data,InicioPreenchimento:undefined},()=>{
                        if (this.state.onChange){                                
                            this.state.onChange(data,this.state.campoEdicao);       
                        }                    
                        if (this.state.campoEdicao.campoMultiplo && this.state.valorCache !==this.state.NAO_POSSUI &&
                            !this.state.estaReplicando
                        ){
                            this.replicarCamposMultiplos(this.state.campoEdicao);
                        } else {
                            this.PreencheValores();
                        }
                        
                    });
                    
                });        
        }
    }

    handleQualificadorClick(event,qualificadorSelecionado){    
        if (this.exibeCampoResposta())
            document.getElementById('Resposta').style.display = 'none';
        this.setState({  qualificadorSelecionado,
                         valorCache : qualificadorSelecionado.opcao
                    },
                    ()=>{                        
                            this.handleRespostaClick();
                    });
    }

    atualizaCampoChave(campo,valor){
        return new Promise((resolve,reject)=>{
            if (!valor)
                resolve();
        faseService.updateReferenciaCampoChave({
            idFaseSessaoCampoChave      : campo.idFaseSessaoCampoChave,
            idCarteiraProcessoHistorico : this.state.carteiraProcessoHistoricoId,
            valor                       : valor,
            valoresFilho                : undefined,
        }).then(response=>{
            let campoChave = response.result;                 
            faseService.getValoresCampoChaveFilho(campo.idFaseSessaoCampoChave,valor)
            .then(response=>{
                let data = this.state.data;
                campo = {...campo, campoChave, valoresFilho:response.result};
                data[campo.originalIndex]=campo;
                this.setState({data},()=>resolve(campo));
            }).catch(erro=>reject(erro));
        
        });   
       });
    }

    obterOrdemCampo(campo){
      let data=  this.state.data
                .filter(c=>{return c.idFaseSessaoCampo===campo.idFaseSessaoCampo
                                && c.ordemCampo <= campo.ordemCampo});
                                
      return data.length;
    }

    obtemCampoChave(campo){
        return new Promise((resolve,reject)=>{
            let campoPaiChave = this.state.data.filter(c=>c.idFaseSessaoCampo===campo.idFaseSessaoCampoPai && c.idFaseSessaoCampoChave && c.estaOK);
            if (campoPaiChave.length>0){
                let ultimo = campoPaiChave[campoPaiChave.length-1];
                if (ultimo)
                    this.atualizaCampoChave(ultimo,ultimo.valor)
                    .then(campoChave=>
                        resolve(campoChave)
                    );
            }
        });        
    }

    atualizaValorFilho(campoChave,campo,valor){
        if (valor){
            faseService.updateReferenciaCampoChaveFilho({
                idFaseSessaoCampoChaveValor: campoChave.idFaseSessaoCampoChaveValor,
                idFaseSessaoCampoChaveFilho: campo.idFaseSessaoCampoFilho,
                ordem: this.obterOrdemCampo(campo),
                valor: valor 
            });
        }
    }

    gravaCampo(campo,valor){
        if (campo.idFaseSessaoCampoValor===campo.idFaseSessaoCampo)
            campo.idFaseSessaoCampoValor=null;
        if (campo.idFaseSessaoCampoValorPai===campo.idFaseSessaoCampoPai)
            campo.idFaseSessaoCampoValorPai=null;
        return new Promise(async (resolve,reject)=>{
            if (this && campo)
                try{
                    let data = this.state.data;
                    if (campo.idFaseSessaoCampoPai && !campo.idFaseSessaoCampoValorPai){
                        for(let i=campo.originalIndex-1; i>=0; i--){
                            if (this.state.data[i].idFaseSessaoCampo===campo.idFaseSessaoCampoPai)
                            {
                                let campoPai = this.state.data[i];   
                                if (!campoPai.idFaseSessaoCampoValor)                             
                                {
                                    campoPai = await this.gravaCampo( campoPai, campoPai.valor );
                                    data[campoPai.originalIndex]={...campoPai};
                                }
                                campo.idFaseSessaoCampoValorPai = campoPai.idFaseSessaoCampoValor;
                                break;
                            }
                        }
                    }
                    let saveFaseSessaoCampo={   
                        idCarteiraProcessoHistorico : this.state.carteiraProcessoHistoricoId,
                        idFaseSessaoCampo           : campo.idFaseSessaoCampo,
                        valor                       : valor,
                        idAnexo                     : campo.idAnexo || 0,
                        ordemValor                  : campo.ordemValor,
                        ordemCampo                  : campo.ordemCampo,
                        idFaseSessaoCampoValorPai   : campo.idFaseSessaoCampoValorPai || 0,
                    }
                    let response=await faseService.saveFaseSessaoCampo(saveFaseSessaoCampo);
                    if (response && response.result && response.result.length>0)
                        campo.idFaseSessaoCampoValor = response.result[0].IDFaseSessaoCampoValor;          
                    else
                        console.log(response);
                    
                    if (valor !==''){
                        campo.valor = valor; 
                        campo.estaOK=true;
                        campo.statusCadastro='Cadastrado';            
                    }
                    
                    data[campo.originalIndex]={...campo};
                    
                    if (valor===this.state.NAO_POSSUI){                        
                        if (campo.campoMultiplo && valor === this.state.NAO_POSSUI && campo.ordemCampo.toString().substring(5,5)==="00000"){
                            this.state.data.filter(c=>{return c.ordemCampo.toString().substring(0,5)===campo.ordemCampo.substring(0,5)})
                            .forEach(c=>{
                                if (c.campoMultiplo){
                                    c.valor=this.state.NAO_POSSUI;
                                    data[c.originalIndex].valor=this.state.NAO_POSSUI;
                                    data[c.originalIndex].estaOK=true;
                                    data[c.originalIndex].statusCadastro='Cadastrado';
                                    this.gravaCampo(c,c.valor);
                                }                        
                            })
                            
                        }
                        for(let j=campo.originalIndex+1; j<data.length; j++)
                            if (data[j].idFaseSessaoCampoPai===campo.idFaseSessaoCampo){
                                data[j].valor=''
                                data[j].estaOK=false;
                                data[j].statusCadastro='Pendente';   
                            } else {
                                break;
                            }
                    }                        
                    if (campo.idFaseSessaoCampoChave){
                        if (valor && valor !== campo.valor)
                            this.atualizaCampoChave(campo,valor);
                    }
                    if (campo.idFaseSessaoCampoFilho){
                        this.obtemCampoChave(campo).then(campoPai=>{
                            this.atualizaValorFilho(campoPai.campoChave,campo,valor);
                        });                                                    
                    }                    
                    if (JSON.stringify(this.state.data[campo.originalIndex])!==JSON.stringify(campo)){
                        this.setState({data,lastSendData:[...campo]},()=>{
                            if (this.state.onChange){                                
                                this.state.onChange(this.state.data);       
                            }
                            resolve(campo);
                        });
                    } else {
                        resolve(campo);
                    }                  
                } catch(error){
                    console.log(error);
                    reject(error);
                }
            });
    }    

    async atualizaDadosApi(campo,data){
        return new Promise(async(resolve,reject)=>{
            let queryWithApi = campo.endPointApi;
            if (campo.originalIndex===this.state.campoEdicao.originalIndex){
                if (!this.state.valorCache){
                    pushWarning('Favor preencher o campo antes da consulta!');
                    return;
                }
                if (campo.validacaoFormula) // Validar o campo usando uma fórmula
                {
                    let data = this.state.data;
                    data[campo.originalIndex]={...campo, valor:this.state.valorCache};
                    let validou = faseService.CalculaFormula(this.state.campoEdicao.validacaoFormula, data);
                    if (!validou)
                    {
                        pushError(this.state.campoEdicao.validacaoMensagem);
                        return;
                    }
                }

                if (campo.regexValidacao &&  this.state.valorCache!==this.state.NAO_POSSUI && campo.componenteTela !== 'Button'){
                    let regex=new RegExp(campo.regexValidacao);
                    let validou= regex.test(this.getValor( this.state.valorCache ));
                    if (!validou){
                        pushError(campo.mensagemValidacaoFormato || 'Formato inválido!');
                        return;
                    }
                }
                queryWithApi =faseService.replaceAll(queryWithApi, `$${campo.idFaseSessaoCampo}$`, encodeURIComponent(this.state.valorCache || '')); 
            }
            for (let i=campo.originalIndex; i>=0; i--){
                queryWithApi= faseService.replaceAll(queryWithApi, `$${data[i].idFaseSessaoCampo}$`, encodeURIComponent(data[i].valor || ''));
            }
            
            queryWithApi =       faseService.replaceAll(
                                 faseService.replaceAll(queryWithApi,'$IDCarteiraProcessoHistorico$', this.state.carteiraProcessoHistoricoId.toString() ),
                                                                     '$IDUsuario$', this.state.idUsuario.toString() );
                        
            if (queryWithApi!==campo.queryWithApi){
                try{
                    this.setState({loading:true});
                    let dadosConsulta= await axiosInstance.get(queryWithApi);
                    campo.dadosConsulta=dadosConsulta.result;
                    campo.queryWithApi=queryWithApi;
                    if (data)
                        data[campo.originalIndex]={...campo};
                } catch(e){
                    console.log(e);
                }
                finally{
                    this.setState({loading:false});
                }
            }
            resolve(campo);
        })
    }

    async atualizaDadosConsulta(campo,data){
        return new Promise(async(resolve,reject)=>{
            let queryWithProcedure = campo.storedProcedure;
            for (let i=campo.originalIndex; i>=0; i--){
                queryWithProcedure = faseService.replaceAll(queryWithProcedure, `$${data[i].idFaseSessaoCampo}$`, data[i].valor || '');
            }
            
            queryWithProcedure = faseService.replaceAll(
                                 faseService.replaceAll(queryWithProcedure,'$IDCarteiraProcessoHistorico$', this.state.carteiraProcessoHistoricoId),
                                                                           '$IDUsuario$', this.state.idUsuario );
            
            console.log('Encontrou parametro:',queryWithProcedure.indexOf("$"));
            queryWithProcedure=faseService.replaceAll(queryWithProcedure,'===','=');
            if (queryWithProcedure!==campo.queryWithProcedure && queryWithProcedure.indexOf("$") === -1){
                try{
                    this.setState({loading:true});
                    let dadosConsulta= await faseService.listData({queryWithProcedure});
                    if (dadosConsulta.success && (dadosConsulta.messages || []).length===0){
                        campo.dadosConsulta=dadosConsulta.result;
                        campo.dadosConsulta=dadosConsulta.result;
                        campo.queryWithProcedure=queryWithProcedure;
                        if (data)
                            data[campo.originalIndex]={...campo};
                        resolve(campo);
                    } else {
                        reject('Falha na execução do procedimento');
                    }
                    
                } catch(e){
                    console.log(e);
                }
                finally{
                    this.setState({loading:false});
                }
            } else {
                resolve(campo)
            }  
        })        
    }

    async requisitarApi (){
        let data=[];
        let campo=this.state.campoEdicao;
        if (campo.idFaseSessaoCampo===campo.idFaseSessaoCampoFontePainel){
            if (campo.endPointApi){
                campo=await this.atualizaDadosApi(campo,this.state.data);
                data=campo.dadosConsulta||[];
            }
        }
        else {
            let origem;
            for (let index=campo.originalIndex; index>=0; index--){
                if (this.state.data[index].idFaseSessaoCampo===campo.idFaseSessaoCampoFontePainel)
                {
                    origem=this.state.data[index];
                    break;
                }    
            }
            if (origem){
                if (origem.endPointApi){
                    origem=await this.atualizaDadosApi(origem,this.state.data);
                    data=origem.dadosConsulta||[];
                } 
                
            }  
        }
        this.state.onChangeDadosConsulta(data, campo.mostrarPainel);        
    }

    async obtemDadosConsulta(campo,data){
        return new Promise(async (resolve,reject)=>{
            if (campo.idFaseSessaoCampo===campo.idFaseSessaoCampoFontePainel){
                if (campo.storedProcedure){
                    campo=await this.atualizaDadosConsulta(campo,data);
                    resolve(campo.dadosConsulta||[]);
                } else {
                    resolve(campo.dadosConsulta||[]);
                }            
            }
            else {
                let origem;
                for (let index=campo.originalIndex; index>=0; index--){
                    if (data[index].idFaseSessaoCampo===campo.idFaseSessaoCampoFontePainel)
                    {
                        origem=data[index];
                        break;
                    }    
                }
                if (origem){
                    if (origem.storedProcedure){
                        origem=await this.atualizaDadosConsulta(origem,data);
                        resolve(origem.dadosConsulta||[]);
                    } 
                    else {
                        resolve(origem.dadosConsulta||[]);
                    }
                }  else { 
                    resolve([]);
                }
            }
        });        
    }

    async PreencheValores(){    
    
    if (this.state.modalConfirm.abrirModal)
        return;    
    let {indice,data,ultimoIDFonteAtualizado,SOMATORIA,FINALIZADOR}=this.state;
    let posicaoEncontrada= this.state.forcaIndiceEdicao ? this.state.indice : faseService.PosicaoAtual(data);
    if (this.state.campoEdicao && this.state.campoEdicao.originalIndex===posicaoEncontrada)
        return;
    
    if ( this.state.forcaIndiceEdicao ){
        this.reabreCamposPendentesModalConfirm(data[posicaoEncontrada]);
    } else {
        indice = posicaoEncontrada;
    }

    let campoSomatorio=data.find(campo=>{return campo.idFaseSessaoCampoTipo===SOMATORIA && campo.originalIndex<=indice });                    
    if (campoSomatorio)
        this.VerificarSomatoria(campoSomatorio);

    data.filter(campo=>{return campo.formula && campo.originalIndex<=indice})
        .forEach(campo=>{            
            let oldValue=campo.valor;
            campo.valor = faseService.CalculaFormula(campo.formula,this.state.data);
            if (oldValue!==campo.valor)
                this.gravaCampo(campo,campo.valor);                            
        });

    if (indice>=data.length){
        indice=data.length-1;        
    }
    let campoEdicao=data[indice];
    if (!campoEdicao.questionaCampoMultiplo){        
        if (campoEdicao.idFaseSessaoCampoTipo===FINALIZADOR){
            if (!campoEdicao.estaOK){
               this.gravaCampo(campoEdicao,'Concluído!')
                    .then(ok=>{ if (!this.jaFinalizou){ this.jaFinalizou=true; pushInfo("Fim do preenchimento");}});       
            }
        }
    }
    
    if (campoEdicao){        
        if (!this.state.InicioPreenchimento)
            await this.setState({InicioPreenchimento:new Date()});
        let qualificadores=await this.AtualizaQualificadores(campoEdicao.idFaseSessaoCampoQualificador);
        
        if (!campoEdicao.questionaCampoMultiplo){            
            if (indice >= data.length)
                return;                
            
            if (!campoEdicao.valor && campoEdicao.valorPadrao){                    
                campoEdicao.valor = faseService.CalculaFormula(campoEdicao.valorPadrao,this.state.data);            
            }      

            if (campoEdicao.idFaseSessaoCampoFontePainel){    
                ultimoIDFonteAtualizado=campoEdicao.idFaseSessaoCampoFontePainel;
                let dadosConsulta=await this.obtemDadosConsulta(campoEdicao,data)
                this.state.onChangeDadosConsulta(dadosConsulta, campoEdicao.mostrarPainel);
            } else {
                this.state.onChangeDadosConsulta([], 1);
            }

            if (campoEdicao.idFaseSessaoCampoFilho){
                this.obtemCampoChave(campoEdicao)
                .then(campoPai=>{
                    if (!campoPai){
                        campoPai= this.state.data.find(c=>{return c.idFaseSessaoCampo===campoEdicao.idFaseSessaoCampoPai && c.idFaseSessaoCampoChave}) 
                    }
                    if(campoPai && campoPai.valoresFilho){
                        let valorEncontrado=campoPai.valoresFilho.find(v=>v.idFaseSessaoCampoFilho===campoEdicao.idFaseSessaoCampoFilho && v.ordem===this.obterOrdemCampo(this.state.campoEdicao));
                        if (valorEncontrado){
                            campoEdicao.valor=valorEncontrado.valor;
                            this.setState({valorCache:valorEncontrado.valor},()=>{document.getElementById('FaseEditorGravar').disabled=false;}); 
                        } 
                    }
                });      
            }
        }    

        if (!campoEdicao.valor)
            campoEdicao.valor='';
        
        this.setState({indice,campoEdicao, forcaIndiceEdicao:false, valorCache: campoEdicao.valor,ignoraIndiceRecebido:false, ultimoIDFonteAtualizado, idAnexo:campoEdicao.idAnexo||0, qualificadores}, 
        ()=>{
            if (campoEdicao.palavrasPesquisa && this.props.onSearchChange)
                this.props.onSearchChange(campoEdicao.palavrasPesquisa)
            else if (this.props.onSearchChange)
                this.props.onSearchChange('');
            if (this.exibeCampoResposta()){
                let resposta=document.getElementById('Resposta');
                resposta.style.display = 'block';
                resposta.focus();
            } else if (this.state.qualificadores && this.state.qualificadores.length>0 && this.state.qualificadores.filter(q=>{return q.tipo==='combo'}).length>0){
                document.getElementById('comboResposta').focus();
            }
                
        });
        
    }
}

getValor(){
    try{
    if (this.state.campoEdicao.componenteTela==='DatePicker'){
       let parts = (this.state.valorCache||'').split('-');
       if (parts.length===3){
           let dateValue = parts[2]+'/'+parts[1]+'/'+parts[0];           
           return dateValue;
       } else {
           return this.state.valorCache;
       }
    } else {
        return this.state.valorCache ? this.state.valorCache : '';
    }
    } catch(e){
        return '';
    }
}

getValueForSet(campo,value){    
    if (campo && campo.componenteTela){
        if (campo.componenteTela==='DatePicker'){
            let parts = (value||'').split('/');
            if (parts.length===3){
                let dateValue = parts[2]+'-'+parts[1]+'-'+parts[0];           
                return dateValue;
            } else {
                return value;
            }
         } else {
             return value ? ( value===this.state.NAO_POSSUI ? '' : value ) : '';
         }
    
    } else  {
        return value ? ( value===this.state.NAO_POSSUI ? '' : value ) : '';
    }
}

handleChangeValue(event){
    const {TIPOS_DECIMAIS}=this.state;
    var value = event.target.value;
    if (this.state.campoEdicao.mascara){
        value = value.replace(/[^A-Za-z0-9]/g, '');
        let masks=this.state.campoEdicao.mascara.split('|');
        let indexMask=masks.length-1;
        for(let i=0; i<masks.length-1;i++){
            if (value.length<=masks[i].replace(/[^A-Za-z0-9]/g, '').length)
            {
                indexMask=i;
                break;
            } 
        }
        let pattern = masks[indexMask];
        this.setState({ valorCache : toPattern(value,{pattern}) });
    }else if ( TIPOS_DECIMAIS.includes(this.state.campoEdicao.idFaseSessaoCampoTipo) ) {  
        value=('000'+value).replace(/[^0-9]/g, '');
        value=value.substring(0,value.length-2)+'.'+value.substring(value.length-2);
        this.setState({ valorCache : parseFloat( value ).toLocaleString('pt-br', {minimumFractionDigits: 2})})
    } else {
        this.setState({ valorCache : value})
    }
}

handleKeyUp(event){
    if (event.keyCode===13/* ENTER */){
        const {valorCache,campoEdicao}=this.state;
        event.preventDefault();
        if (campoEdicao.obrigatorio && !valorCache)
            pushWarning('Campo obrigatório!');
        else {
            this.handleRespostaClick();
        }
    }
}

reabreCamposPendentes(campoReferencia){
    let {data}=this.state;
    for(let i=campoReferencia.originalIndex; i<data.length; i++){
        data[i].statusCadastro = 'Pendente';
        data[i].estaOK         = false;
        if (data[i].exibeCampoFormula){
            
            if (!faseService.CalculaFormula(data[i].exibeCampoFormula,data)){
                data[i].valor='';
                this.gravaCampo(data[i],'');
            }
        }
        if (data[i].idFaseSessaoCampoTipo!==this.state.SOMATORIA)
            faseService.updateCamposPendentes({ idCarteiraProcessoHistorico : this.state.carteiraProcessoHistoricoId, 
                                                ordemValor                  : data[i].ordemValor,
                                                idFaseSessaoCampo           : data[i].idFaseSessaoCampo});
    }
    this.setState({
        modalConfirm: {
            abrirModal: false,
            tittle: "",
            message: ""
        },
        data
    },()=>{
        if (this.state.onChange){                                
            this.state.onChange(data,campoReferencia);       
        }
    });
    
    
    
}

reabreCamposPendentesModalConfirm(campoReferencia) {
    if (campoReferencia && campoReferencia.campo)
    this.setState({
        ignoraIndiceRecebido:false,
        forcaIndiceEdicao: false,
        modalConfirm: {
            abrirModal: true,
            tittle: "Reabrir edição",
            message: `Deseja retomar edição a partir do campo [${campoReferencia.campo}]? Os campos já preenchidos na sequencia precisarão ser confirmados.`,
            buttonSimClick: ()=> this.reabreCamposPendentes(campoReferencia),            
        }
    });
}

reprovarModalConfirm() {
    this.setState({
        modalConfirm: {
            abrirModal: true,
            tittle: "Reprovar",
            message: `Você tem certeza que deseja reprovar o processo?`,
            buttonSimClick: this.reprovaProcesso
        }
    });
}

openModalReprovacao(){
    processoService.getMotivos(this.props.idProcessoStatus).then(response=>{
        var motivos = [];
        var motivoSelecionado = {}
        let motivo=response.result.find(m=>{return m.idMensagem===this.state.campoEdicao.idMensagem});
        motivoSelecionado={ value: motivo.idMensagem, label: motivo.descricao };            
        motivos.push({ value: motivo.idMensagem, label: motivo.descricao });
        
        this.setState({
            motivos,
            reprovaModal: true,
            motivoSelecionado,
            modalConfirm: {
                abrirModal: false,
                tittle: "",
                message: ""
            },
        });
    });
        
}

closeModalReprovacao() {
    if (!this.state.reprovaModal){
        this.state.onAbortChangeField();
        this.setState({ ignoraIndiceRecebido : true,  
                        modalConfirm: {
                        abrirModal: false,
                        tittle: "",
                        message: ""
                    }});
    } 
    this.setState({
        reprovaModal: false,
        modalConfirm: {
            abrirModal: false,
            tittle: "",
            message: ""
        },
    });    
}

reprovaProcesso() {
    const {processoId,carteiraId}=this.props;
    const {observacao,campoEdicao}=this.state;
    let processos = [processoId];
    processoService.reprovarProcessos(processos, carteiraId, 2, observacao||'', campoEdicao.idMensagem)
    .then(()=>{
        let campoFinalizador = this.state.data.find(campo=>{return campo.idFaseSessaoCampoTipo===this.state.data.FINALIZADOR});
        if (campoFinalizador)
            this.gravaCampo(campoFinalizador,'Concluído!');
        this.closeModalReprovacao();
        this.state.close();
    })
    .catch(error=>{
        console.log(error);
    })
    this.closeModalReprovacao();
}

handleChange(e) {
    let change = {}
    change[e.target.name] = e.target.value
    this.setState(change)
}

selectChange(e) {
    this.setState({ idMensagem: e.value });
}

exibeCampoResposta(){
    return (this.state.campoEdicao.componenteTela 
        && this.state.campoEdicao.componenteTela!=='Button'
        && this.state.campoEdicao.componenteTela!=='Finalizador'
        && (this.state.qualificadores || []).length<2 )
}

tipoInput(){
    return  this.state.campoEdicao.componenteTela === 'DatePicker' ? 'date' : 
            this.state.campoEdicao.componenteTela === 'Spin' ? 'number' : 
            'text';
}

styleBotaoGravar(){
    if (this.state.qualificadores && this.state.qualificadores.length>0)
        return { display :  (this.state.qualificadores || []).find(q=>{return q.tipo==='button'}) || 
                            this.state.campoEdicao.componenteTela==='Button' || 
                            this.state.campoEdicao.componenteTela==='Finalizador' ?'none':'block'};
    else if (this.state.campoEdicao.componenteTela==='Button' || this.state.campoEdicao.componenteTela==='Finalizador')
        return { display:'none'}
    else
        return { display:'block'}
}

handleChangeOpcao(){
    let opcao=document.getElementById("comboResposta").value
    this.setState({valorCache:opcao, qualificadorSelecionado:this.state.qualificadores.find(q=>{return q.opcao===opcao})});
}

abrirAnexar(){
  let upload = document.getElementById('upload');
  upload.click();
}

anexar(e){
    this.state.setState({ anexo:e, valorCache:e.name });
}

async handleClickAcao(){
    const {campoEdicao,data}=this.state;
    try{ 
        if (campoEdicao.storedProcedure){
            this.setState({ignoraIndiceRecebido:true});
            await this.atualizaDadosConsulta(campoEdicao,data);
        }
        this.gravaCampo(campoEdicao,'Processado!').then(async ()=>{   
            this.setState({ignoraIndiceRecebido:true},()=>{
                if (this.props.onRequestRefresh)
                    this.props.onRequestRefresh();
                this.PreencheValores();
            });
            
        });
    } catch(e){
        pushError(e);
    }
}

render(){    
  const { classes } = this.props;
  let qualificadoresBotoes=[];
  let qualificadoresOpcoes=[];
  let habilitarInput=false;
  if (this.state.campoEdicao && this.state.qualificadores ){    
    if (this.state.qualificadores.length>0)
        this.state.qualificadores.filter(q=>{return q.tipo==='button'}).forEach(q=>{
            if (!qualificadoresBotoes.find(qb=>{return q.opcao===qb.opcao}))
                qualificadoresBotoes.push(q);
        })

    if (this.state.qualificadores.length>0)
        this.state.qualificadores.filter(q=>{return q.tipo==='combo'}).forEach(q=>{
            if (!qualificadoresOpcoes.find(qo=>{return q.opcao===qo.opcao}))
            qualificadoresOpcoes.push(q);
        });
    
    if ( !this.state.campoEdicao.obrigatorio || (this.state.campoEdicao.ordemValor>1 && this.state.campoEdicao.campoMultiplo) ){    
        qualificadoresBotoes=[{opcao: this.state.NAO_POSSUI},...qualificadoresBotoes];
        if (qualificadoresOpcoes.length>0)
            qualificadoresOpcoes=[{opcao:this.state.NAO_POSSUI},...qualificadoresOpcoes];
    }    

    habilitarInput = this.state.campoEdicao.idFaseSessaoCampoTipo===this.state.ANEXO &&
                        this.state.campoEdicao.permiteEdicao ?  {disabled:'disabled'} : {};
  }
  
  return (
        <>
        <Backdrop 
            open={this.state.loading} 
            className={classes.backdrop}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        <div className='FaseEditor-Conteiner'>     
            <label>
                {this.state.campoEdicao.idFaseSessaoCampoTipo!==this.state.BOTAO_ACAO &&
                    
                    this.state.campoEdicao.pergunta    
                }
                {this.state.campoEdicao.idFaseSessaoCampoTipo===this.state.BOTAO_ACAO &&
                    <button 
                        className="FaseEditor-BotaoAcao"
                        onClick={this.handleClickAcao}  >
                        {this.state.campoEdicao.pergunta}
                    </button>  
                }       
                    <div className='FaseEditor-Qualificador-Conteiner' >                        
                        {qualificadoresOpcoes.length>0 &&                                                        
                            <select 
                                className='FaseEditor-Qualificador-Select'                                
                                id='comboResposta'  
                                name="comboResposta"  
                                onBlur={this.handleChangeOpcao}                            
                                onChange={this.handleChangeOpcao}
                                value={this.state.valorCache}
                            >
                                {qualificadoresOpcoes.map((q,i)=>{
                                    return ( <option key={i} className='FaseEditor-Qualificador-Select'   value={q.opcao} >{q.opcao}</option>)
                                })}
                            </select>
                            
                        }               
                        {qualificadoresBotoes.map((qualificador,index)=>{          
                                return (<button 
                                            className={qualificador.opcao===this.state.campoEdicao.valor?'FaseEditor-Qualificador-Selecionado':'FaseEditor-Qualificador'}
                                            onClick={(e)=>this.handleQualificadorClick(e,qualificador)} 
                                            key={`btnQualificador${index}`}  >
                                                {qualificador.opcao}
                                        </button>);
                            })
                        }
                    </div>
                    { this.exibeCampoResposta() &&
                    <input className='FaseEditor-Qualificador-Input' 
                            id='Resposta'
                            type={this.tipoInput()}                
                            value={this.getValueForSet(this.state.campoEdicao,this.state.valorCache || '')}             
                            onChange={this.handleChangeValue} 
                            onKeyUp={this.handleKeyUp}
                            {...habilitarInput}
                    />
                    }
      
                    
            </label>
            <button className='FaseEditor-Gravar'
                    id='FaseEditorGravar'
                    style={this.styleBotaoGravar()}
                    onClick={this.handleRespostaClick}  >
                Gravar
            </button>
            
            {this.state.modalConfirm.abrirModal &&
            <ModalConfirm open={this.state.modalConfirm.abrirModal} modalProps={this.state.modalConfirm} buttonCancelarClick={() => { this.closeModalReprovacao() }} ></ModalConfirm>
            }
            
            {this.state.campoEdicao.idFaseSessaoCampoTipo===this.state.ANEXO &&
            <>
            <input 
                id='upload'                
                style={{display:'none'}}                
                type="file" 
                name="file"
                onChange={(e) =>{
                    let files = e.target.files;
                    if(files.length > 0){
                        var FileSize = e.target.files[0].size / 1024 / 1024; // in MB
                        if (FileSize > this.state.campoEdicao.tamanhoMaximo) {
                            pushWarning(this.state.campoEdicao.mensagemValidacaoFormato);
                        } else {
                            this.setState({anexo:e.target.files[0],valorCache:e.target.files[0].name});
                        }                        
                    }
                }}                 
            />
                <Tooltip title="Selecionar anexo" aria-label="Selecionar anexo" >        
                    <button  className='FaseEditor-AnexarConteiner'onClick={()=>{this.abrirAnexar()}}>
                        <FontAwesomeIcon
                            icon={faPaperclip}
                            size="lg"
                            color='white'
                        />              
                    </button>
                </Tooltip>
            </>
            }
            {this.state.campoEdicao.endPointApi &&
            <Tooltip title={this.state.campoEdicao.hintBotaoApi || "Executar consulta externa"} aria-label={this.state.campoEdicao.hintBotaoApi || "Executar consulta externa"} >        
                <button  className='FaseEditor-ExecutaRequisicaoApi'onClick={()=>{this.requisitarApi()}}>
                    <FontAwesomeIcon
                        icon={faSearch}
                        size="lg"
                        color='white'
                    />              
                </button>
            </Tooltip>
            }
            {this.state.reprovaModal &&
                <ReprovarProcesso
                    options={this.state.motivos}
                    motivoSelecionado={this.state.motivoSelecionado}
                    open={this.state.reprovaModal}
                    buttonReprovarClick={() => { this.reprovarModalConfirm() }}
                    buttonCancelarClick={() => { this.closeModalReprovacao() }}
                    handleChange={this.handleChange}
                    selectChange={this.selectChange}
                    disabled={false}
                    label='Reprovar'                    
                />
            }                     
        </div>
        </>);
    }
}

export default withStyles(useStyles, { withTheme: true })(FaseEditor);