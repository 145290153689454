import { processosConstants } from '../redux/constants';
import { processoService } from '../services';
import { filtroActions } from './index';

export const processoActions = {
    getProcessos,
    proximaPaginaProcessos,
    atualizaSelecionados,
    getAcoesProcessos,
    insAcoesProcessos,
    limpaAcoes,
    aprovarProcessos,
    reprovarProcessos,
    getMotivos,
    atualizaProcessos,
    getProcessoFundo,
    proximaPaginaProcessoFundo,
    recarregaProcessos
}

function getProcessos(props) {
    return dispatch => {
        dispatch(request());
        processoService.getProcessos(props.carteiraId, props.idProcessoStatus, props.filtro, props.paginacao, props.filtroPesquisa)
            .then(
                response => {
                    var data = response.result;
                    if (data && data.length > 0) {
                        var paginacao = response.paginacao;
                        dispatch(success(data, paginacao ? paginacao.records.count : null, response.filtro, true));                        
                    } else {
                        dispatch(inicialState(response.filtro ?? null))
                    }
                    if (response.filtro && response.filtro.campos) {
                        dispatch(filtroActions.adicionarCampos(response.filtro.campos));
                    }
                }
            )                      
    };

    function request() { return { type: processosConstants.LISTA } }
    function success(processos, count, filtro) { return { type: processosConstants.SUCESSO, processos, count, filtro, loading: false } }
    function inicialState(filtro) { return { type: processosConstants.INICIA_STATE, filtro } }    
}

function proximaPaginaProcessos(props) {
    return dispatch => {
        dispatch(request());
        processoService.getProcessos(props.carteiraId, props.idProcessoStatus, props.filtro, props.paginacao)
            .then(
                response => {
                    var data = response.result;
                    if (data && data.length > 0) {
                        var paginacao = response.paginacao;
                        dispatch(success(data, paginacao ? paginacao.records.count : null, response.filtro));
                    }
                }
            ).catch(
                () => {
                    dispatch(inicialState(null))
                }
            );
    };

    function request() { return { type: processosConstants.LISTA } }
    function success(processos) { return { type: processosConstants.PROXIMA_PAGINA, processos } }
    function inicialState(filtro) { return { type: processosConstants.INICIA_STATE, filtro } }
}

function atualizaSelecionados(selecionados, processos) {
    return dispatch => {
        dispatch(success(selecionados, processos));
    };

    function success(selecionados, processos) { return { type: processosConstants.ATUALIZA_PROCESSOS_SELECIONADOS, selecionados, processos } }
}

function getAcoesProcessos(idCarteira, selecionados) {
    return dispatch => {
        processoService.getAcoesProcessos(idCarteira, selecionados)
            .then(response => {
                var data = response.result;
                if (data) {
                    dispatch(success(data));
                }
                else {
                    dispatch(error());
                }
            }).catch(() => {
                dispatch(error());
            });
    };

    function success(acoes) { return { type: processosConstants.SUCESSO_ACOES_PROCESSO, acoes } }
    function error() { return { type: processosConstants.FALHA_ACOES_PROCESSO } }
}

function insAcoesProcessos(processos, idCarteira, idProcessoAcao) {
    return dispatch => {
        processoService.insAcoesProcessos(processos, idCarteira, idProcessoAcao)
            .then(response => {
                dispatch(success([]))
            }).catch(() => {
                dispatch(error());
            });
    };

    function success(acoes) { return { type: processosConstants.SUCESSO_ACOES_PROCESSO, acoes } }
    function error() { return { type: processosConstants.FALHA_ACOES_PROCESSO } }
}

function limpaAcoes() {
    return dispatch => {
        dispatch(close());
    };

    function close() { return { type: processosConstants.FALHA_ACOES_PROCESSO } }
}

function aprovarProcessos(processos, idCarteira, idProcessoAcao) {
    return dispatch => {
        processoService.aprovarProcessos(processos, idCarteira, idProcessoAcao)
            .then(response => {
                dispatch(success([]));
                dispatch(atualiza());
            }).catch(() => {
                dispatch(error());
            });
    };

    function atualiza() { return { type: processosConstants.ATUALIZA_PROCESSOS } }
    function success(acoes) { return { type: processosConstants.SUCESSO_ACOES_PROCESSO, acoes } }
    function error() { return { type: processosConstants.FALHA_ACOES_PROCESSO } }
}

function reprovarProcessos(processos, idCarteira, idProcessoAcao, observacao, idMensagem) {
    return dispatch => {
        processoService.reprovarProcessos(processos, idCarteira, idProcessoAcao, observacao, idMensagem)
            .then(response => {
                dispatch(success([]))
                dispatch(atualiza());
            }).catch(() => {
                dispatch(error());
            });
    };

    function atualiza() { return { type: processosConstants.ATUALIZA_PROCESSOS } }
    function success(acoes) { return { type: processosConstants.SUCESSO_ACOES_PROCESSO, acoes } }
    function error() { return { type: processosConstants.FALHA_ACOES_PROCESSO } }
}

function getMotivos() {
    return processoService.getMotivos();
}

function atualizaProcessos() {
    return dispatch => {
        dispatch(atualiza());
    };
    function atualiza() { return { type: processosConstants.ATUALIZA_PROCESSOS } }
}

function getProcessoFundo(props) {
    return dispatch => {
        dispatch(request());
        processoService.getProcessoFundo(props.carteiraId, props.idProcesso, props.paginacao)
            .then(
                response => {
                    var data = response.result;
                    if (data && data.length > 0) {
                        var paginacao = response.paginacao;
                        dispatch(success(data, paginacao ? paginacao.records.count : null));
                    }
                }
            );
    };

    function request() { return { type: processosConstants.LISTA } }
    function success(processoFundo, count) { return { type: processosConstants.SUCESSO_PROCESSO_FUNDO, processoFundo, count } }
}

function proximaPaginaProcessoFundo(props) {
    return dispatch => {
        dispatch(request());
        processoService.getProcessoFundo(props.carteiraId, props.idProcesso, props.paginacao)
            .then(
                response => {
                    var data = response.result;
                    if (data && data.length > 0) {
                        var paginacao = response.paginacao;
                        dispatch(success(data, paginacao ? paginacao.records.count : null));
                    }
                }
            );
    };

    function request() { return { type: processosConstants.LISTA } }
    function success(processoFundo) { return { type: processosConstants.PROXIMA_PAGINA_FUNDO, processoFundo } }
}

function recarregaProcessos() {
    return dispatch => {
        dispatch(recarrega());
    };
    function recarrega() { return { type: processosConstants.RECARREGA_PROCESSOS } }
}