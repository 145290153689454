import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grafico } from '../../components/Grafico/Grafico';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { Responsive, WidthProvider } from "react-grid-layout";
import { dashboardService } from '../../services';
import { AdicionarGrafico } from '../../components/AdicionarGrafico/AdicionarGrafico';
import _ from "lodash";
import Grid from '@material-ui/core/Grid';
import './Dashboard.css';
import { AppBar, Tabs, Tab, Box, Typography, IconButton } from '@material-ui/core';
import { TabEditavel } from '../../components/TabEditavel/TabEditavel'
import { pushWarning } from '../../services/notifierService';
import ModalConfirm from '../../components/ModalConfirm/ModalConfirm';
import { connect } from 'react-redux';
import { filtroActions } from '../../actions/index';
import SelectInput from '@material-ui/core/Select/SelectInput';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Typography style={{ fontFamily: 'Montserrat-Medium', fontSize: '10px' }}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = theme => ({
    header: {
        margin: 5,
        display: "flex"
    },
    labelHeader: {
        paddingTop: 16,
        marginRight: 5,
        fontSize: 10
    },
    button: {
        fontSize: 10,
        marginLeft: 5,
        fontFamily: 'Montserrat-Medium',
        textTransform: 'none',
        minWidth: '115px'
    },
    buttonFocused: {
        outline: 'none'
    },
    paper: {
        padding: 5,
        height: 'calc(100vh - 175px)',
        overflow: 'auto'
    },
    dashboard: {
        transform: "translate(1px, 1px)",
        minWidth: "1024px",
        overflowX: "auto",
        overflowY: "hidden"
    },
    removeStyle: {
        position: "absolute",
        right: "2px",
        top: 0,
        cursor: "pointer",
        zIndex: 2
    },
    noStaticDrag: {
        cursor: "move",
    },
    nenhumDashboard: {
        width: "100%",
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        lineHeight: 10
    },
    tabDiv:
    {
        width: 'calc(100% - 180px)'
    },
    appBar: {
        boxShadow: "none"
    },
    adicionarGrafico: {
        height: 'calc(100vh - 120px)',
    },
})

class Dashboard extends Component {
    static defaultProps = {
        className: "layout",
        rowHeight: 100
    };

    constructor(props) {
        super(props);
        this.state = {
            adicionarGraficos: false,
            graficos: [],
            currentBreakpoint: "lg",
            compactType: "vertical",
            mounted: false,
            layouts: [],
            graficosUsuario: [],
            layoutModificado: [],
            dados: [],
            intervals: [],
            reload: 1,
            abaAtual: 0,
            abas: [],
            editarAba: null,
            modalConfirm: {
                abrirModal: false,
                tittle: "",
                message: "",
                buttonSimClick: () => { }
            },
        };
        this.editarDashboard = this.editarDashboard.bind(this);
        this.adicionarGrafico = this.adicionarGrafico.bind(this);
        this.onLayoutChange = this.onLayoutChange.bind(this);
        this.onRemoveItem = this.onRemoveItem.bind(this);
        this.onAddItem = this.onAddItem.bind(this);
        this.salvarDashboard = this.salvarDashboard.bind(this);
        this.getDados = this.getDados.bind(this);
        this.clearIntervals = this.clearIntervals.bind(this);
        this.closeAdicionarGrafico = this.closeAdicionarGrafico.bind(this);
        this.selecionaAba = this.selecionaAba.bind(this);
        this.adicionarAba = this.adicionarAba.bind(this);
        this.renderEditableTab = this.renderEditableTab.bind(this);
        this.salvarAba = this.salvarAba.bind(this);
        this.getDashboardUsuario = this.getDashboardUsuario.bind(this);
        this.closeModalConfirm = this.closeModalConfirm.bind(this);
        this.confirmDelDashboardAbaUsuario = this.confirmDelDashboardAbaUsuario.bind(this);
        this.delDashboardAbaUsuario = this.delDashboardAbaUsuario.bind(this);
        this.carregarDashboardAbas = this.carregarDashboardAbas.bind(this);
        this.onChangeProps = this.onChangeProps.bind(this);
    }

    componentDidMount() {
        dashboardService.getDashboardAbaUsuario().then((response) => {
            let idDashboardAbaUsuario = 0;
            if (response.result && response.result.length > 0) {
                idDashboardAbaUsuario = response.result[0].idDashboardAbaUsuario;
                this.setState({ abas: response.result });
            } else {

                this.setState({ abas: [{ aba: "PADRÃO", idDashboardAbaUsuario: -2, ordem: 1 }] })
                this.salvarAba("PADRÃO", -2);
            }
            this.getDashboardUsuario(idDashboardAbaUsuario);
        });
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props.filtros, prevProps.filtros)  || this.props.btnPesquisarClick !== prevProps.btnPesquisarClick) {
            const intervals = this.state.intervals;
            intervals.forEach((value, index) => {
                let grafico = _.find(this.state.graficosUsuario, { 'indexLayout': index.toString() });
                this.getDados({ grafico, index });
            });
        }
    }

    componentWillUnmount() {
        this.clearIntervals(null);
    }

    clearIntervals(i) {
        const intervals = this.state.intervals;
        intervals.forEach((value, index) => {
            if (value.idDashboard === i) {
                clearInterval(value.interval);
                intervals.splice(index, 1);
            }
            if (i === null) {
                clearInterval(value.interval);
            }
        });
        if (i !== null) {
            this.setState({ intervals });
        }
    }

    editarDashboard() {
        dashboardService.getGraficos().then((response) => {
            if (response.result) {
                let graficos = response.result;
                this.setState({ adicionarGraficos: true, graficos }, () => {
                    window.dispatchEvent(new Event('resize'));
                    this.setState({ reload: this.state.reload * -1 });
                });
            }
        });
    }

    getDados(args) {
        const grafico = args.grafico;
        dashboardService.getGraficoDados(grafico, this.props.filtros).then((response) => {
            if (response.result) {
                const dados = this.state.dados;
                const grafico = args.grafico;
                let index = _.findKey(dados, ['idDashboard', grafico.idDashboard]);
                if (!index) {
                    dados.push({ result: response.result, idDashboard: grafico.idDashboard })
                } else {
                    dados[index] = { result: response.result, idDashboard: grafico.idDashboard };
                }
                this.setState({ dados });
            }
            if (response.filtro && response.filtro.campos) {
                this.props.adicionarCampos(response.filtro.campos);
            }
        });
    }

    closeAdicionarGrafico() {
        this.setState({ adicionarGraficos: false }, () => {
            window.dispatchEvent(new Event('resize'));
        });
    }

    adicionarGrafico(grafico) {
        grafico.dashboardUsuario = {
            PosicaoEsquerda: 0,
            PosicaoTopo: 0,
            Altura: grafico.altura,
            Largura: grafico.largura,
            TempoAtualizacaoSegundos: 60,
            IDDashboardAbaUsuario: this.state.abas[this.state.abaAtual].idDashboardAbaUsuario,
            propriedadesGrafico: grafico.propriedadesGrafico
        }
        dashboardService.insGraficoUsuario(grafico).then((response) => {
            if (response.result && response.result.IDDashboardUsuario) {
                grafico.dashboardUsuario.iDDashboardUsuario = response.result.IDDashboardUsuario;
                this.onAddItem(grafico);
                this.editarDashboard();
            }
        });
    }

    onLayoutChange(layout, layouts) {
        let graficos = this.state.graficosUsuario;
        let graficosUsuario = _.map(graficos, (grafico) => {
            let index = _.findIndex(layout, function (o) { return o.i === grafico.indexLayout; });
            if (index !== -1) {
                grafico.dashboardUsuario.altura = layout[index].h;
            }
            return grafico;
        });

        this.setState({ layoutModificado: layout, layouts: layout, reload: this.state.reload * -1, graficosUsuario });
    }

    onRemoveItem(i) {
        const graficosUsuario = this.state.graficosUsuario;
        dashboardService.delGraficoUsuario(graficosUsuario[i]).then((response) => {
            if (response.result) {
                const layouts = this.state.layouts;
                var newLayouts = _.remove(layouts, function (n) {
                    return n.i !== graficosUsuario[i].indexLayout.toString();
                });
                this.clearIntervals(graficosUsuario[i].idDashboard);
                graficosUsuario.splice(i, 1)
                this.setState({ layouts: newLayouts, graficosUsuario });
                this.editarDashboard()
            }
        });
    }

    generateDOM(thisReference) {
        const staticDrag = thisReference.state.adicionarGraficos;
        const { classes } = thisReference.props;
        return _.map(thisReference.state.graficosUsuario, function (l, i) {
            let dados = _.filter(thisReference.state.dados, ['idDashboard', l.idDashboard]);
            return (
                <Paper key={i.toString()} data-grid={thisReference.state.layouts[i]} className={staticDrag ? classes.noStaticDrag : ''}>
                    <FontAwesomeIcon
                        icon={['fa', 'times']}
                        tyle={{ cursor: "pointer" }}
                        onClick={thisReference.onRemoveItem.bind(this, i)}
                        className={classes.removeStyle}
                        style={{ display: staticDrag ? '' : 'none' }} />
                    {dados && dados.length > 0 &&
                        <Grafico
                            reload={thisReference.state.reload}
                            grafico={l}
                            data={dados[0].result}
                            onChangeProps={thisReference.onChangeProps}
                            editing={staticDrag}
                        />
                    }
                </Paper>
            );
        });
    }

    onChangeProps(grafico, type, options, tempoAtualizacaoSegundos) {
        let graficosUsuario = _.cloneDeep(this.state.graficosUsuario);
        let graficoUsuario = _.find(graficosUsuario, { 'idDashboard': grafico.idDashboard });
        delete options.width;
        delete options.height;
        options.tipoGrafico = type;
        if (options) {
            graficoUsuario.dashboardUsuario.propriedadesGrafico = JSON.stringify(options);
        }
        if (tempoAtualizacaoSegundos !== graficoUsuario.dashboardUsuario.tempoAtualizacaoSegundos) {
            graficoUsuario.dashboardUsuario.tempoAtualizacaoSegundos = parseInt(tempoAtualizacaoSegundos);
            this.clearIntervals(graficoUsuario.idDashboard);
            let intervals = _.cloneDeep(this.state.intervals);
            _.forEach(intervals, (value, index) => {
                if (value.idDashboard === graficoUsuario.idDashboard) {
                    clearInterval(value.interval);
                    intervals.splice(index, 1);
                }
            });
            intervals.push({ idDashboard: graficoUsuario.idDashboard, interval: setInterval(this.getDados, tempoAtualizacaoSegundos * 1000, { grafico: graficoUsuario, index: graficoUsuario.indexLayout }) });
            this.setState({ intervals });
        }
        this.setState({ graficosUsuario });
    }

    onAddItem(grafico) {
        let layouts = this.state.layouts;
        let index = (this.state.layouts.length).toString();
        let intervals = this.state.intervals;
        layouts.push({
            i: index,
            x: (layouts.length * 2) % (this.state.cols || 12),
            y: Infinity, // puts it at the bottom
            w: grafico.largura,
            h: grafico.altura
        });
        grafico.indexLayout = index;
        this.getDados({ grafico, index });
        intervals.push({ idDashboard: grafico.idDashboard, interval: setInterval(this.getDados, 60 * 1000, { grafico, index }) });
        this.setState({
            layouts,
            graficosUsuario: this.state.graficosUsuario.concat(grafico),
            intervals
        });
    }

    salvarDashboard() {
        const graficosUsuario = this.state.graficosUsuario;
        if (this.state.layoutModificado && this.state.layoutModificado.length > 0) {
            this.state.layoutModificado.forEach((value, index) => {
                if (graficosUsuario[index].dashboardUsuario === null) {
                    graficosUsuario[index].dashboardUsuario = [];
                }
                graficosUsuario[index].dashboardUsuario.posicaoEsquerda = value.x;
                graficosUsuario[index].dashboardUsuario.posicaoTopo = value.y;
                graficosUsuario[index].dashboardUsuario.altura = value.h;
                graficosUsuario[index].dashboardUsuario.largura = value.w;
            });
            dashboardService.updGraficoUsuario(graficosUsuario);
        }
        this.closeAdicionarGrafico();
    }

    selecionaAba(event, newValue) {
        if (this.state.abaAtual !== newValue) {
            if (this.state.adicionarGraficos) {
                let modalConfirm = this.state.modalConfirm;
                modalConfirm.abrirModal = true;
                modalConfirm.title = 'Confirmar Alterações';
                modalConfirm.message = 'As alterações realizadas serão perdida, tem certeza que deseja continuar?';
                modalConfirm.buttonSimClick = () => {
                    this.setState({ modalConfirm: { ...this.state.modalConfirm, abrirModal: false }, adicionarGraficos: false })
                    this.carregarDashboardAbas(newValue)
                };
                this.setState({ modalConfirm });
            } else {
                this.carregarDashboardAbas(newValue);
            }
        }
    }

    carregarDashboardAbas(newValue) {
        this.setState({
            abaAtual: newValue
        });
        let abas = this.state.abas;
        this.getDashboardUsuario(abas[newValue].idDashboardAbaUsuario);
    }

    adicionarAba() {
        let abas = _.cloneDeep(this.state.abas);
        let aba = {
            idDashboardAbaUsuario: ((abas.length + 2) * -1),
            aba: 'Aba ' + (abas.length + 1),
            ordem: abas.length + 1
        };
        abas.push(aba);
        this.setState({ abas }, () => {
            this.salvarAba(aba.aba, aba.idDashboardAbaUsuario);
        });
    }

    salvarAba(nomeAba, i) {
        let abas = _.cloneDeep(this.state.abas);
        let index = _.findIndex(abas, (aba) => { return aba.idDashboardAbaUsuario === i });
        if (index !== -1) {
            abas[index].aba = nomeAba;
            abas[index].novo = false;
            let thisAtual = this;
            if (i < 0) {
                dashboardService.insDashboardAbaUsuario(abas[index]).then((response) => {
                    if (response.result.IDDashboardAbaUsuario) {
                        abas[index].idDashboardAbaUsuario = response.result.IDDashboardAbaUsuario;
                        thisAtual.setState({ abas });
                    }
                })
            } else {
                dashboardService.updDashboardAbaUsuario(abas[index]).then((response) => {
                    thisAtual.setState({ abas });
                })
            }
        }
    }

    renderTab() {
        let classes = this.props.classes;
        let thisAtual = this;
        return _.map(this.state.abas, function (l, i) {
            return (
                <TabPanel key={i} value={thisAtual.state.abaAtual} index={i}>
                    <div className={classes.dashboard}>
                        {thisAtual.state.layouts && thisAtual.state.layouts.length === 0 &&
                            <div className={classes.nenhumDashboard}>
                                <p>Nenhum dashboard encontrado, para adicionar clique no botão Editar</p>
                            </div>
                        }
                        {thisAtual.state.layouts &&
                            <ResponsiveReactGridLayout
                                {...thisAtual.props}
                                cols={{ lg: 12, md: 12, sm: 12, xs: 4, xxs: 2 }}
                                layouts={{ lg: thisAtual.state.layouts }}
                                onBreakpointChange={thisAtual.onBreakpointChange}
                                onLayoutChange={thisAtual.onLayoutChange}
                                measureBeforeMount={true}
                                useCSSTransforms={thisAtual.state.mounted}
                                compactType={thisAtual.state.compactType}
                                isDraggable={thisAtual.state.adicionarGraficos}
                                isResizable={thisAtual.state.adicionarGraficos}
                            >
                                {thisAtual.generateDOM(thisAtual)}
                            </ResponsiveReactGridLayout>
                        }
                    </div>
                </TabPanel>
            );
        });
    }

    renderEditableTab(aba) {
        let thisAtual = this;
        return (
            _.map(this.state.abas, function (l, i) {
                return (
                    <Tab key={i} label={<TabEditavel aba={l} abaAtual={thisAtual.state.abaAtual === i} salvarAba={thisAtual.salvarAba} deletarAba={thisAtual.confirmDelDashboardAbaUsuario} />} {...a11yProps(i)} />
                )
            })
        );
    }

    getDashboardUsuario(idDashboardAbaUsuario) {
        this.clearIntervals(null);
        dashboardService.getGraficosUsuario(idDashboardAbaUsuario).then((response) => {
            if (response.result && response.result.length > 0) {
                const layouts = [];
                const intervals = [];
                response.result.forEach((grafico, index) => {
                    layouts.push({
                        i: index.toString(),
                        x: grafico.dashboardUsuario.posicaoEsquerda,
                        y: grafico.dashboardUsuario.posicaoTopo,
                        w: grafico.dashboardUsuario.largura,
                        h: grafico.dashboardUsuario.altura,
                    });
                    this.getDados({ grafico });
                    grafico.indexLayout = index.toString();
                    intervals.push({ idDashboard: grafico.idDashboard, interval: setInterval((args) => { setTimeout(this.getDados(args), (100 + Math.floor(400 * Math.random()))); }, grafico.dashboardUsuario.tempoAtualizacaoSegundos * 1000, { grafico, index }) });
                });
                this.setState({ layouts, graficosUsuario: response.result, intervals });
            } else {
                this.setState({ layouts: [], graficosUsuario: [] })
            }
        });
    }

    criaIntervalos() {

    }

    confirmDelDashboardAbaUsuario(nomeAba) {
        let abas = this.state.abas;
        if (abas.length > 1) {
            let modalConfirm = this.state.modalConfirm;
            modalConfirm.abrirModal = true;
            modalConfirm.title = 'Confirmar Exclusão';
            modalConfirm.message = 'Tem certeza que deseja excluir a aba ' + nomeAba + ' ?';
            modalConfirm.buttonSimClick = this.delDashboardAbaUsuario;
            this.setState({ modalConfirm });
        } else {
            pushWarning("Não é possivel excluir todas as abas.");
        }
    }

    delDashboardAbaUsuario() {
        let thisAtual = this;
        let abas = _.cloneDeep(this.state.abas);
        dashboardService.delDashboardAbaUsuario(abas[this.state.abaAtual]).then((response) => {
            _.remove(abas, (aba, index) => { return index === thisAtual.state.abaAtual });
            thisAtual.setState({ abaAtual: 0, abas, modalConfirm: { ...this.state.modalConfirm, abrirModal: false } });
            thisAtual.getDashboardUsuario(abas[0].idDashboardAbaUsuario);

        });
    }

    closeModalConfirm() {
        this.setState({ modalConfirm: { ...this.state.modalConfirm, abrirModal: false } });
    }

    render() {
        const { classes } = this.props;
        return (
            <>
                <Grid container spacing={1}>
                    <Grid item lg={this.state.adicionarGraficos ? 10 : 12} md={this.state.adicionarGraficos ? 9 : 12} sm={this.state.adicionarGraficos ? 8 : 12}>
                        <div className={classes.header}>
                            <label className={classes.labelHeader}>Dashboard</label>
                            <Divider orientation="vertical" flexItem />
                            <Button
                                size="small"
                                className={classes.button}
                                startIcon={<FontAwesomeIcon icon={['fa', (this.state.adicionarGraficos ? 'save' : 'pencil')]} />}
                                onClick={(this.state.adicionarGraficos ? this.salvarDashboard : this.editarDashboard)}
                            >
                                {this.state.adicionarGraficos ? "Encerrar Edição" : "Editar"}
                            </Button>
                            <Divider orientation="vertical" flexItem />
                            <div className={classes.tabDiv}>
                                <AppBar position="static" color="default" className={classes.appBar}>
                                    <Tabs
                                        value={this.state.abaAtual}
                                        onChange={this.selecionaAba}
                                        variant="scrollable"
                                        scrollButtons="on"
                                        indicatorColor="primary"
                                        textColor="primary"
                                        aria-label="scrollable force tabs example"
                                    >
                                        {this.renderEditableTab()}
                                        <IconButton aria-label="Adicionar" onClick={this.adicionarAba} >
                                            <FontAwesomeIcon icon={['fa', 'plus']} />
                                        </IconButton>
                                    </Tabs>
                                </AppBar>
                            </div>
                        </div>
                        <div className={classes.paper} >

                            {this.renderTab()}
                        </div>
                    </Grid>
                    {this.state.adicionarGraficos &&
                        <Grid item lg={2} md={3} sm={4}>
                            <div className={classes.adicionarGrafico} >
                                <AdicionarGrafico data={this.state.graficos} onClose={() => { this.closeAdicionarGrafico(); }} adicionarGrafico={this.adicionarGrafico} />
                            </div>
                        </Grid>
                    }
                </Grid>
                {this.state.modalConfirm.abrirModal &&
                    <ModalConfirm open={this.state.modalConfirm.abrirModal} modalProps={this.state.modalConfirm} buttonCancelarClick={() => { this.closeModalConfirm() }} ></ModalConfirm>
                }
            </>
        );
    }
}

function mapState(state) {
    const { filtros, btnPesquisarClick } = state.filtros;
    return { filtros, btnPesquisarClick };
}

const actionCreators = {
    adicionarCampos: filtroActions.adicionarCampos
};

export default connect(mapState, actionCreators)(withStyles(useStyles)(Dashboard));