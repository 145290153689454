import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import history from './services/history';
import Routes from './routes';
import './custom.css'
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faSearch as falSearch, faEdit, faSitemap, faTasks, faEye, faListAlt, faThumbsUp, faThumbsDown, faArrowRight, faFilter, faTrashAlt, faPlus, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { faAngleRight, faBars, faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';
import Notifier from './components/Notifier/Notifier';

library.add( fab, fas, faSearch, faBars, faTimes, faAngleRight, falSearch, faTasks, faEdit, faSitemap, faEye, faListAlt, faThumbsUp, faThumbsDown, faArrowRight, faFilter, faTrashAlt, faPlus, faCommentDots);
export default class App extends Component {
  render() {
    return (
      <>
        {alert.message &&
          <div className={`alert ${alert.type}`}>{alert.message}</div>
        }
        <Notifier />
        <Router history={history}>
          <Routes />
        </Router>
      </>
    );
  }
}