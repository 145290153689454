import axios from 'axios';
import Env from '../../src/env.json';
import history from '../services/history';
import { pushError, pushWarning, pushInfo, pushSuccess } from '../services/notifierService';

const axiosInstance = axios.create({
    baseURL: Env.API.Backend,
    crossDomain: true,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
    },
});

axiosInstance.interceptors.request.use(
    config => {
        const authentication = JSON.parse(localStorage.getItem('authentication') || localStorage.getItem('authenticationOnlyApi'));
        if (authentication != null && authentication.accessToken != null) {
            config.headers.authorization = `Bearer ${authentication.accessToken}`;
        }
        return config;
    }
);

axiosInstance.interceptors.response.use(
    function (response) {
        let ignoreMessages = false;
        if (!response.config.data instanceof FormData && response.config.data) {
            let dados = JSON.parse(response.config.data);
            ignoreMessages = dados.ignoreMessages;
        }

        if (!ignoreMessages) {
            (response.data.messages || []).forEach(resultMessage => {
                switch (resultMessage.type) {
                    case 0: pushError(resultMessage.message); break;
                    case 1: pushWarning(resultMessage.message); break;
                    case 2: pushInfo(resultMessage.message); break;
                    case 3: pushSuccess(resultMessage.message); break;
                    default: pushSuccess(resultMessage.message); break;
                }
            });
        }
        return response.data;
    }, function (error) {
        if (error.response && error.response.status === 401) {
            pushError("Sua sessão expirou! Favor realize o login.");
            history.push('/login');
        } else {
            pushError("Ocorreu um erro inesperado, favor contate o administrador do sistema!");
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;