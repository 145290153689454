import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Typography from '@material-ui/core/Typography';
import FasesLoader from '../FaseLoader/FasesLoader';
import FaseViewer from '../FaseViewer/FaseViewer';
import MovimentacaoProcessoViewer from '../MovimentacaoProcessoViewer/MovimentacaoProcessoViewer';
import { processoService } from '../../services/processoService';
import { withStyles } from "@material-ui/core/styles";
import './ModalProcesso.css';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { Backdrop, CircularProgress } from '@material-ui/core';

/**
 * Objeto style para ser consumido pelo Dialog
 */
const styles = (theme) => ({
    rootStyle: {
        borderRadius: 15,
        zIndex: 1200,
    },
    exampleWrapper: {
        position: 'absolute',
        bottom: 0,
        right: '45px'
    },
    speedDial: {
        position: 'absolute',
        '&.MuiSpeedDial-directionUp': {
            bottom: 25,
            right: 25,
        },
    },
    content: {
        maxHeight: 'calc(100vh - 200px)'
    },
    fabButton: {
        backgroundColor: '#0091c7'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});

/**
 * Componente que realiza a abertura das informações do proceso dentro de um Modal responsivo
 * @param {int} processoId
 * @since 29/04/2020
 */
class ModalProcesso extends Component {

    /**
     * Construtor do componente
     */
    constructor(props) {
        super(props);
        this.state = {
            partes: '',
            detalhes: '',
            collapses: [],
            dadosCampos: [],
            documento: '',
            open: props.abrirAberto,
            openMaisOpcoes: false,
            loading: false
        };
        this.handleOpen = this.handleOpen.bind(this);
        this.handleOpenMaisOpcoes = this.handleOpenMaisOpcoes.bind(this);
        this.handleCloseMaisOpcoes = this.handleCloseMaisOpcoes.bind(this);
        this.handleCloseBackdrop = this.handleCloseBackdrop.bind(this);
        this.loadData = this.loadData.bind(this);

        if (props.processoId)
            this.loadData();
    }

    /**
     * Realiza a abertura do Modal e chama o load para as informações básicas
     */
    handleOpen = () => {
        this.setState({ open: true });
    }

    handleCloseBackdrop() {
        this.setState({ loading: false });
    }

    /**
     * Realiza a abertura do Modal e chama o load para as informações básicas
     */
    handleOpenMaisOpcoes = () => {
        this.setState({ openMaisOpcoes: true });
    }

    handleCloseMaisOpcoes = () => {
        this.setState({ openMaisOpcoes: false });
    }

    componentDidMount = () => {
        // VAZIO
    }

    /**
     * Método responsável por carregar as informações iniciais
     * do processo
     */
    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if ((prevProps.processoId === undefined && prevProps.processoId !== this.props.processoId) && !prevState.open) {
            this.loadData();
        }
    }

    loadData() {
        //deixando o modal visível
        this.setState({ open: true });
        this.setState({ loading: true });

        //Pegando e preenchendo o cabeçalho do processo
        processoService.getProcessoPartes(this.props.processoId).then(response => {
            //this.state.collapses = response.result;
            let rowProc = [];
            let rowPart = [];
            let iPartes = 0;
            let iProces = 0;

            let chaveAgrupador = '';
            if (response.result.length > 0) {
                rowProc.push(<div key={iProces} className="col-12 pad-botton"><div className="expansion-panel-inner-title">Processo:</div> <div>{response.result[iProces].processoNumero}</div></div>);
            }
            for (const result of response.result) {
                let innerRow = [];
                if (result.sessao === "PARTES") {
                    let colWidth = (result.agrupadorRelacionado !== "" ? 6 : 12);
                    if (colWidth === 12) {
                        innerRow.push(<div key={"col1" + iPartes} className={"col-" + colWidth + " pad-botton"}><div className="expansion-panel-inner-title">{result.agrupador}:</div> <div>{result.valor}</div></div>);

                    } else if (chaveAgrupador !== result.agrupador) { //Novo agrupamento
                        chaveAgrupador = result.agrupador;
                        innerRow.push(<div key={"col1" + iPartes} className={"col-" + colWidth + " pad-botton"}><div className="expansion-panel-inner-title">{result.agrupador}:</div> <div>{result.valor}</div></div>);
                        innerRow.push(<div key={"col2" + iPartes} className={"col-" + colWidth + " pad-botton"}><div className="expansion-panel-inner-title">{result.agrupadorRelacionado}:</div> <div>{result.valorRelacionado}</div></div>);
                    } else { // Atualizando agrupamento
                        innerRow.push(<div key={"col1" + iPartes} className={"col-" + colWidth + " pad-botton"}></div>);
                        innerRow.push(<div key={"col2" + iPartes} className={"col-" + colWidth + " pad-botton"}><div className="expansion-panel-inner-title">{result.agrupadorRelacionado}:</div> <div>{result.valorRelacionado}</div></div>);

                    }
                    iPartes++; //tratando o parametro Key do react
                    rowPart.push(innerRow);
                } else if (result.sessao === "PROCESSO") {
                    iProces++; //tratando o parametr Key do react
                    rowProc.push(<div key={iProces} className="col-6 pad-botton"><div className="expansion-panel-inner-title">{result.agrupador}:</div> <div>{result.valor}</div></div>);
                }
            }

            if (rowProc.length < 1) {
                rowProc.push(<div key={iProces} className="col-6 pad-botton"><div className="">Sem informações do processo</div></div>);
            }

            if (rowPart.length < 1) {
                rowPart.push(<div key={iProces} className="col-6 pad-botton"><div className="">Sem informações das partes do processo</div></div>);
            }

            this.setState({ partes: rowPart });
            this.setState({ detalhes: rowProc });
        });

        //Pegando as Fases que o processo já percorreu
        processoService.getProcessoFases(this.props.carteiraProcessoHistorico).then(response => {
            let customCollapses = [];            
            for (const result of response.result) {
                if (result.idFase !== 0 && (result.exibeNoLinkExterno || this.props.openComentarios)) {
                    customCollapses.push(result);
                }
            }
            this.setState({ collapses: customCollapses });
        }).finally(() => {
            setTimeout(() => {
                this.setState({ loading: false });
            }, 2600);
        });
    }

    componentWillUnmount = () => {
        //VAZIO
    }

    render() {
        const { classes, onClose, openComentarios, openDossie } = this.props;

        /**
         * Abre o comentário para o processo visualizado
         */
        const showComentarios = () => {
            openComentarios();
        }

        const showDossie = () => {
            openDossie();
        }

        /**
         * Finaliza o modal, liberando os objetos instanciados
         */
        const handleClose = () => {
            this.setState({
                partes: '',
                detalhes: '',
                collapses: [],
                dadosCampos: [],
                documento: '',
                open: false
            }, () => onClose());
        };

        /**
         * Função responsável por atualizar o conteúdo do CustomCollapse
         * @param {*} _target 
         * @param {*} faseId 
         * @param {*} carteiraProcessoHistoricoId 
         */
        async function expandCollapseCustom(target, faseId, carteiraProcessoHistoricoId, index) {

            if (target.state.dadosCampos[index] === undefined) {

                let loader = new FasesLoader(faseId, carteiraProcessoHistoricoId);
                loader.getData().then((retorno) => {
                    if (retorno.dataCampos == null) {
                        target.state.dadosCampos[index] = [];
                    }
                    else {
                        target.state.dadosCampos[index] = retorno.dadosCampos;
                    }

                    target.setState({ dadosCampos: target.state.dadosCampos });
                });
            }

        }

        /**
         * Função que verifica se os dados da fase estão preenchidos
         * @param {*} dadosCampos 
         */
        function validateCollapseData(dadosCampos, index) {
            for (const dados of dadosCampos[index]) {
                if (dados.valor != null) {
                    return true;
                }
            }
            return false;
        }
        return (
            <>
                <div>
                    <Dialog
                        disableEnforceFocus
                        fullWidth={true}
                        maxWidth={"md"}
                        open={this.state.open || false}
                        onClose={handleClose}
                        scroll={"body"}
                        aria-labelledby="responsive-dialog-title"
                        classes={{
                            paper: classes.rootStyle
                        }}
                    >
                        <div className="modal-grey-background">
                            <DialogTitle id="responsive-dialog-title" className="modal-title" onClick={e => handleClose()}>
                                <ArrowBackIosIcon fontSize="small" />
                            Visualização - {this.props.processoNumero}
                            </DialogTitle>
                            <div className="teste">
                                <DialogContent className={classes.content}>
                                    {this.state.loading &&
                                        <Backdrop className={classes.backdrop} open={this.state.loading} onClick={this.handleCloseBackDrop}>
                                            <CircularProgress color="inherit" />
                                        </Backdrop>
                                    }
                                    <div className="expansion-container">
                                        {this.props.openComentarios &&
                                            <div>
                                                <ExpansionPanel defaultExpanded={true} className="expansion-panel">
                                                    <ExpansionPanelSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography className="expansion-panel-title">
                                                            {'Dados do Processo'}
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails className="custom-painel-details">
                                                        <div className="row">
                                                            {this.state.detalhes}
                                                        </div>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            </div>}
                                            
                                        {/*this.props.openComentarios &&
                                            <div>
                                                <ExpansionPanel defaultExpanded={true} className="expansion-panel">
                                                    <ExpansionPanelSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography className="expansion-panel-title">
                                                            {'Partes do Processo'}
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails className="custom-painel-details">
                                                        <div className="row">
                                                            {this.state.partes}
                                                        </div>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                        </div>*/}
                                        {/*this.props.openComentarios &&
                                            <div>
                                                <ExpansionPanel defaultExpanded={false} className="expansion-panel" >
                                                    <ExpansionPanelSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography className="expansion-panel-title">
                                                            {'Movimentações'}
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails className="custom-painel-details">
                                                        <MovimentacaoProcessoViewer
                                                            processoId={this.props.processoId}
                                                            usarPesquisa={true} />
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            </div>
                                        */}
                                        {
                                            //Inject dos collapses customizados (conforme as fases executadas do Processo)
                                            this.state.collapses.map((result, index) =>

                                                <div key={"collapse_" + index}>
                                                    <ExpansionPanel defaultExpanded={false} className="expansion-panel" onClick={e => expandCollapseCustom(this, result.idFase, result.idCarteiraProcessoHistorico, index)}>
                                                        <ExpansionPanelSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            <Typography className="expansion-panel-title-enriquecido">
                                                                {result.fase}
                                                            </Typography>
                                                        </ExpansionPanelSummary>
                                                        <ExpansionPanelDetails className="custom-painel-details">
                                                            {
                                                                (this.state.dadosCampos !== undefined && this.state.dadosCampos[index] !== undefined && this.state.dadosCampos[index].length > 0) ?
                                                                    (validateCollapseData(this.state.dadosCampos, index)) ?
                                                                        <FaseViewer data={this.state.dadosCampos[index]} style={{ fontFamily: 'Montserrat', color: '#5d5d5d' }} />
                                                                        : result.fase + " não preenchido"
                                                                    :
                                                                    "Carregando..."
                                                            }
                                                        </ExpansionPanelDetails>
                                                    </ExpansionPanel>
                                                </div>
                                            )
                                        }
                                        <SpeedDial
                                            ariaLabel="Mais Opções"
                                            className={classes.speedDial}
                                            icon={<FontAwesomeIcon icon={['fa', 'bars']} color="#FFFFFF" />}
                                            onClose={this.handleCloseMaisOpcoes}
                                            onOpen={this.handleOpenMaisOpcoes}
                                            open={this.state.openMaisOpcoes}
                                            direction={'up'}
                                            FabProps={{ className: classes.fabButton, size: 'small' }}
                                        >
                                            {this.props.openComentarios &&
                                                <SpeedDialAction
                                                    tooltipTitle={'Abrir Comentários'}
                                                    icon={<FontAwesomeIcon icon={['fa', 'comment-dots']} color="#0091C7" />}
                                                    onClick={() => { showComentarios() }}
                                                />
                                            }
                                            <SpeedDialAction
                                                icon={<FontAwesomeIcon icon={['fa', 'file-pdf']} color="#0091C7" />}
                                                onClick={() => { showDossie() }}
                                                tooltipTitle={'Exportar Dossiê'}
                                            />
                                        </SpeedDial>
                                    </div>
                                </DialogContent>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </>
        );
    }
}

/**
 * Export do Componente construído utilizando o style customizado
 */
export default withStyles(styles)(ModalProcesso);
