import React, { Component } from 'react';
import Select from 'react-select';
import { components } from 'react-select';
import { Avatar } from "../Avatar/Avatar";
import { withStyles } from "@material-ui/core/styles";

/**
 * Componente que realiza a abertura das informações do proceso dentro de um Modal responsivo
 * @param {int} processoId
 * @since 24/06/2020
 */
class SelectWithImage extends Component {

    /**
     * Construtor do componente
     */
    constructor(props) {
        super(props);
    }

    /**
     * Função que trata a alteração de valor do Select com Avatar
     * @param {*} e 
     */
    handleInputChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            responsavel: value
        });
        //Retorna o ID do objeto selecionado
        this.props.onChange(value);
    }

    render() {
        const selectStyles = {
            valueContainer: base => ({
                ...base,
                height: 35,
                minHeight: 35
              })
        }

        const OptionItem = (props) => 
          (
            <components.Option {... props}>
                <div className="option-with-image">
                    <Avatar nome={props.data.label} imagem_usuario_compra={props.data.image}/>
                    <div className="option-with-image-text">
                        {props.data.label}
                    </div>
                </div>
            </components.Option>
          );

          const SingleValueItem = (props) => 
          (
            <components.SingleValue {... props}>
                <div className="option-with-image single-value">
                    <Avatar nome={props.data.label} imagem_usuario_compra={props.data.image}/>
                    <div className="option-with-image-text">
                        {props.data.label}
                    </div>
                </div>
            </components.SingleValue>
          );

        return(
            <Select 
                options={this.props.options} 
                onChange={(val) => {this.handleInputChange({target: { name:'fluxo', value: val.value }})}} 
                noOptionsMessage={()=>{return this.props.noOptionsMessage;}}
                placeholder={this.props.placeholder}
                styles={{...selectStyles,spacing:{controlHeight:105}}}
                components={{SingleValue: SingleValueItem, Option: OptionItem}}
            />
        );
    }
}

/**
 * Export do Componente construído utilizando o style customizado
 */
export default SelectWithImage;