import React, { Component } from 'react';
import Env from '../../env.json';
import ModalProcesso from '../../components/ModalProcesso/ModalProcesso';
import ModalDossie from '../../components/ModalDossie/ModalDossie';
import { pushWarning } from '../../services/notifierService';
import { usuarioService } from '../../services/usuarioService';

const apiLogin=Env.API.ApiLogin;
const apiSenha=Env.API.ApiSenha;

 export default class Processo extends Component {    
    
    constructor(props){
        super(props);
        this.state= { 
            modalProcesso : {},
            modalDossie   : {}
        };
        
       
        this.closeModalProcesso=this.closeModalProcesso.bind(this);
        this.abrirDossie=this.abrirDossie.bind(this);
        this.lazyLoad=this.lazyLoad.bind(this);

        this.lazyLoad();
    }

    componentDidUpdate(prevProps){
        const { match: { params } } = this.props;
    }

    lazyLoad(){
        usuarioService.login(apiLogin, apiSenha)
            .then(
                response => {
                    var data = response.result;
                    let dataReceived;
                    if (data.authenticated) {
                        // store user details and jwt token in local storage to keep user logged only in api requests
                        localStorage.setItem('authenticationOnlyApi', JSON.stringify(data));
                        let decryptedData={}
                        let jsonContent='';
                        try{
                            dataReceived=decodeURIComponent(this.props.match.params.data).split("").reverse().join("");                            
                            jsonContent=atob(dataReceived);
                            decryptedData = JSON.parse(jsonContent);
                        } catch(e){                            
                            pushWarning('Chave inválida! Nenhum processo localizado')
                        }
                        this.setState({ 
                            modalProcesso : decryptedData || {}
                        });                                                        
                    }
                });
    }
      /**
     * Chamada para fechar a visualização do Processo
     */
    closeModalProcesso() {
        this.setState({
            modalProcesso:
            {                
                processoId: undefined,
                carteiraProcessoHistoricoId: undefined
            }
        });
    }

    abrirDossie(idProcesso, idCarteiraProcessoHistorico, idCarteiraProcesso, processoNumero){
        this.setState({
            modalDossie:
            {
                open                        : true,
                processoId                  : idProcesso,
                carteiraProcessoHistoricoId : idCarteiraProcessoHistorico,
                idCarteiraProcesso          : idCarteiraProcesso, 
                processoNumero              : processoNumero
            }
        });
    }

    closeModalDossie() {
        this.setState({
            modalDossie:
            {
                open: false,
                processoId: undefined,
                carteiraProcessoHistoricoId: undefined
            }
        });
    }
    
    render(){
        return (
                <>
                    {this.state.modalProcesso.carteiraProcessoHistoricoId&&
                    <ModalProcesso ref="modalProcesso"
                                carteiraProcessoHistorico={this.state.modalProcesso.carteiraProcessoHistoricoId}
                                processoId={this.state.modalProcesso.processoId}
                                onClose={() => { return }}     
                                abrirAberto={true}                 
                                openDossie={() => this.abrirDossie(this.state.modalProcesso.processoId, this.state.modalProcesso.carteiraProcessoHistoricoId, this.state.modalProcesso.idCarteiraProcesso, this.state.modalProcesso.processoNumero)}/>                               
                    }
                    {this.state.modalProcesso.carteiraProcessoHistoricoId && this.state.modalDossie &&  
                    <ModalDossie ref="modalDossie"
                                carteiraProcessoHistorico={this.state.modalDossie.carteiraProcessoHistoricoId}
                                processoId={this.state.modalDossie.processoId}
                                processoNumero={this.state.modalDossie.processoNumero}
                                onClose={() => this.closeModalDossie()} 
                    />
                    }                    
            </>
        
        );
    }
    
}