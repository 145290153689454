import React, { Component } from 'react';
import { usuarioService } from '../../services/index';
import { BackGroundLogin } from '../../components/BackGroundLogin/BackGroundLogin';
import { ContentLogin } from '../../components/ContentLogin/ContentLogin';
import './EsqueceuSenha.css';
import { pushWarning,pushError } from '../../services/notifierService';
//import ArchImg from '../../assets/elementos_legalflow.png';
import InputMask from 'react-input-mask';
import ToggleableLink  from '../../components/ToggleableLink/ToggleableLink';

export class EsqueceuSenha extends Component {
    static displayName = EsqueceuSenha.name;
    
    constructor(props) {
        super(props);
        this.state = { login: '', loading: false };
        this.handleChangeLogin = this.handleChangeLogin.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChangeLogin(event) {
        this.setState({ login: event.target.value.replace(/[^0-9.-]/g, '') });
    }

     handleSubmit(event) {
        event.preventDefault();

        if(this.state.login === "" || this.state.login.length < 14){
            pushWarning("CPF inválido. Digite um CPF válido.");
            return;
        }
        this.setState({ loading: true });
        usuarioService.esqueceuSenha(this.state.login).then(response => {
            let resp = response.result;
            if (!resp.hasOwnProperty('error')) {
                pushWarning(resp.message);
                this.props.history.push('/Login')
            } else {
                pushWarning(resp.message);
            }
        }).catch(err => {
            console.log(err);
            pushError("Desculpe, ocorreu um erro inesperado, por favor tente novamente!")
        }).finally(() =>{
            this.setState({ loading: false });
        });
    }

    render() {
        return (
            <BackGroundLogin>
                <ContentLogin>
                    <div className="content-esqueceu-senha row align-items-center">
                        <div className="col-12">
                            <p>Esqueceu a senha</p>
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-group text-left">
                                    <label htmlFor="cpf">CPF</label>
                                    <InputMask mask="999.999.999-99" id="cpf" className="form-control text-center" disabled={this.state.loading} value={this.state.login} onChange={this.handleChangeLogin}></InputMask>
                                </div>
                                <div className="row">
                                    <div className="col-7 text-left">
                                        <ToggleableLink id="lnkEsqueceuSenha" to="/Login" disabled={this.state.loading}>Ir para Login</ToggleableLink>
                                    </div>
                                    <div className="col-5 text-right pl-0">
                                        <button id="btnEsqueceuSenha" className="btn btn-primary" type="submit" disabled={this.state.loading} >
                                            <span role="status" aria-hidden="true" className={this.state.loading ? "spinner-border spinner-border-sm" : "d-none"}></span>
                                            Enviar
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </ContentLogin>
            </BackGroundLogin>
        );
    }
}
