import React, { Component } from 'react';
import './Avatar.css';
import { Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';

class Avatar extends Component {
    trataIniciais = (nome) => {
        if(nome != null && nome !== undefined){
            let nomeSplit = nome.split(' ');
            let nomeReturn = '';
            if (nomeSplit.length > 1) {
                nomeReturn = nomeSplit[0].charAt(0).toUpperCase() + nomeSplit[nomeSplit.length - 1].charAt(0).toUpperCase();
            } else {
                nomeReturn = nome.substr(0, 2).toUpperCase();
            }
            return nomeReturn;
        }else{
            return "-";
        }
    }

    componentDidMount(){
        //console.log(this.props.imagemUsuarioCompra)
    }

    render() {
        const iniciais = this.trataIniciais(this.props.nome);
        return (
            <Tooltip title={this.props.tooltip || this.props.nome} aria-label={this.props.tooltip || this.props.nome} >
                <div className={"avatar-circle " + this.props.className} style={this.props.style}>
                    {this.props.imagem_usuario_compra !== null ?
                        <img src={this.props.imagem_usuario_compra} onClick={this.props.onClick} alt="boohoo" className="avatar-circle"/>
                    :
                        <span className="initials" onClick={this.props.onClick}>{iniciais}</span>
                    }
                    {this.props.children}
                </div>
            </Tooltip>
        );
    }
}

function mapState(state) {
    const { usuario } = state.authentication;

    return { usuario };
}

const connectedAvatar = connect(mapState)(Avatar);
export { connectedAvatar as Avatar };
