import React, { Component } from 'react';
import { Grid, Divider, Paper, Button, Backdrop, CircularProgress } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withStyles } from '@material-ui/core/styles';
import { ListaRelatorios } from '../../components/ListaRelatorios/ListaRelatorios';
import { RelatorioPivot } from '../../components/RelatorioPivot/RelatorioPivot';
import { RelatorioDataGrid } from '../../components/RelatorioDataGrid/RelatorioDataGrid';
import { relatorioService } from '../../services';
import _ from 'lodash';
import {getOptionsGrid, getOptionsPivot} from './Utils';
import { filtroActions } from '../../actions/index';
import { connect } from 'react-redux';

const useStyles = theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    paper: {
        height: "calc(100vh - 110px)",
        padding: 15,        
        overflow: 'auto'
    },
    paperRelatorio: {
        height: "calc(100vh - 150px)",
        padding: 15,
        overflow: 'hidden'
    },
    header: {
        margin: 5,
        display: "flex"
    },
    labelHeader: {
        paddingTop: 9,
        marginRight: 5,
        fontSize: 10,
        marginBottom: 0
    },
    button: {
        fontSize: 10,
        marginLeft: 5,
        fontFamily: 'Montserrat-Medium',
        textTransform: 'none'
    },
    nenhumRelatorio:{
        width: "100%",
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        lineHeight: 10
    }
});

class VisualizarRelatorio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            abrirListaRelatorios: true,
            listaRelatorios: [],
            dadosRelatorio: [],
            dadosCampos: [],
            relatorio: {},
            paginacao: {
                page: {
                    number: 1,
                    size: 25
                }
            },
            layoutJson: [],
            visualizarRelatorio: false,
            loading: false,
            options: []
        };
        this.buscaRelatorios = this.buscaRelatorios.bind(this);
        this.selecionarRelatorio = this.selecionarRelatorio.bind(this);
        this.reiniciarTela = this.reiniciarTela.bind(this);
        this.recarregaRelatorio = this.recarregaRelatorio.bind(this);
        this.pivotGrid = React.createRef();
        this.gridRef = React.createRef();
    }

    componentDidMount() {
        this.buscaRelatorios();
    }

    componentDidUpdate(prevProps) {              
        if(this.props.btnPesquisarClick !== prevProps.btnPesquisarClick){
            this.selecionarRelatorio(this.state.relatorio);
        }
    }

    buscaRelatorios() {
        relatorioService.getRelatorios(this.state.paginacao).then((response) => {
            if (response && response.result) {
                this.setState({ listaRelatorios: response.result })
            }
        });
    }

    selecionarRelatorio(relatorio) {
        this.setState({ loading: true });
        relatorio.fonteRelatorio.filtroPesquisa = this.props.filtros;
        relatorioService.getRelatorioDados(relatorio.fonteRelatorio).then((response) => {
            if (response.result) {
                let dadosCampos = _.map(response.result.campos, (obj) => {
                    if (obj.Formato) {
                        obj.Formato = JSON.parse(obj.Formato)
                    }
                    return obj;
                });
                this.setState({ dadosRelatorio: response.result.dados, dadosCampos });
                if (response && response.filtro && response.filtro.campos) {
                    this.props.adicionarCampos(response.filtro.campos);
                }
                relatorioService.getRelatorioPerfil(relatorio.idRelatorio)
                    .then((response) => {
                        let optionSalvo = JSON.parse(relatorio.layoutJson);
                        let options = this.state.options;
                        if (optionSalvo.type === 'pivot') {
                            options = getOptionsPivot(optionSalvo.options);
                        } else {
                            options = getOptionsGrid(optionSalvo.options);
                        }
                        this.setState({ relatorio, abrirListaRelatorios: false, visualizarRelatorio: true, layoutJson: JSON.parse(relatorio.layoutJson), options });
                    });
            }
        }).finally(() => {
            this.setState({ loading: false });
        });
    }

    recarregaRelatorio() {
        this.setState({ loading: true });
        relatorioService.getRelatorios(this.state.paginacao).then((response) => {            
            if (response && response.result) {
                this.setState({ listaRelatorios: response.result })
            }
        }).finally(() => {
            this.setState({ loading: false });
        });
    }

    reiniciarTela() {
        this.props.limparTudo();
        this.setState({
            abrirListaRelatorios: true,
            relatorio: {},
            paginacao: {
                page: {
                    number: 1,
                    size: 25
                }
            },
            layoutJson: [],
            visualizarRelatorio: false
        }, () => {
            this.buscaRelatorios();
        })
    }


    render() {
        const { classes } = this.props;
        return (
            <>
                <Grid container alignItems="center" spacing={1}>
                    <Grid item lg={this.state.abrirListaRelatorios ? 10 : 12} md={this.state.abrirListaRelatorios ? 10 : 12} sm={this.state.abrirListaRelatorios ? 8 : 12}>
                        <div className={classes.header}>
                            <label className={classes.labelHeader}>Visualizar Relatórios</label>
                            <Divider orientation="vertical" flexItem />
                            <Button
                                className={classes.button}
                                startIcon={<FontAwesomeIcon icon={['fa', 'times']} />}
                                onClick={this.reiniciarTela}
                                disabled={this.state.abrirListaRelatorios}
                            >
                                Fechar Relatório
                                </Button>
                                {this.state.visualizarRelatorio && 
                                <Button
                                className={classes.button}
                                startIcon={<FontAwesomeIcon icon={['fa', 'sync-alt']} />}
                                onClick={this.recarregaRelatorio}
                                >
                                Atualizar Dados
                                </Button>
                                }
                                
                        </div>
                        <Paper className={classes.paperRelatorio}>
                            {!this.state.visualizarRelatorio &&
                                <div className={classes.nenhumRelatorio}>
                                    <p>Selecione na lista ao lado um relatório</p>
                                </div>
                            }
                            {this.state.visualizarRelatorio && this.state.layoutJson.type === 'pivot' &&
                                <>
                                    {this.state.relatorio.titulo}
                                    <RelatorioPivot
                                        campos={this.state.dadosCampos}
                                        data={this.state.dadosRelatorio}
                                        configurar={false}
                                        pivotGrid={this.pivotGrid}
                                        layoutJson={this.state.layoutJson}
                                        name={this.state.relatorio.titulo}
                                        options={this.state.options}
                                    />
                                </>
                            }
                            {this.state.visualizarRelatorio && this.state.layoutJson.type === 'grid' &&
                                <>
                                    {this.state.relatorio.titulo}
                                    <RelatorioDataGrid
                                        campos={this.state.dadosCampos}
                                        data={this.state.dadosRelatorio}
                                        configurar={false}
                                        gridRef={this.gridRef}
                                        layoutJson={this.state.layoutJson}
                                        fields={this.state.fields}
                                        name={this.state.relatorio.titulo}
                                        optionsDefault={this.state.options}
                                    />
                                </>
                            }
                        </Paper>
                    </Grid>
                    {this.state.abrirListaRelatorios &&
                        <Grid item lg={2} md={2} sm={4}>
                            <Paper className={classes.paper}>
                                <ListaRelatorios 
                                    data={this.state.listaRelatorios} 
                                    selecionarRelatorio={this.selecionarRelatorio}/>
                            </Paper>
                        </Grid>
                    }
                </Grid>
                {this.state.loading &&
                    <Backdrop className={classes.backdrop} open={this.state.loading} onClick={this.handleCloseBackDrop}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }
            </>);
    }
}

function mapState(state) {
    const { filtros, btnPesquisarClick } = state.filtros;
    return { filtros, btnPesquisarClick };
}

const actionCreators = {
    limparTudo: filtroActions.limparTudo,
    adicionarCampos: filtroActions.adicionarCampos
};

export default connect(mapState, actionCreators)(withStyles(useStyles)(VisualizarRelatorio));