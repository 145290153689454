import React, { useState, useEffect } from 'react';
import { Input, Grid, IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formGroup: {
        marginTop: 15
    },
    input: {
        width: '80px',
        fontFamily: 'Montserrat-Medium',
        fontSize: '10px'
    },
    font: {
        fontFamily: 'Montserrat-Medium',
        fontSize: '10px'
    }
}));

export const TabEditavel = (props) => {
    const { aba, salvarAba, deletarAba, abaAtual } = props;
    const [editando, setEditando] = useState(false);
    const [nomeAba, setNomeAba] = useState(aba.aba);
    const classes = useStyles();

    useEffect(() => {
        setEditando(false);
    }, [abaAtual]);

    function editarAba() {
        setEditando(true);
    }

    function handleChangeNome(e) {
        setNomeAba(e.target.value);
    }

    function salvar() {
        setEditando(false);
        salvarAba(nomeAba, aba.idDashboardAbaUsuario);
    }

    function deletar() {
        deletarAba(nomeAba);
    }

    return (
        <>
            {!editando && !aba.novo &&
                <span className={classes.font} onDoubleClick={() => { editarAba() }}>{aba.aba}</span>
            }
            {(editando || aba.novo) && abaAtual &&
                <>
                    <Grid container>
                        <Grid item>
                            <Input value={nomeAba} onChange={handleChangeNome} className={classes.input} />
                        </Grid>
                        <Grid item>
                            <IconButton aria-label="Salvar" onClick={salvar} >
                                <FontAwesomeIcon icon={['fa', 'save']} />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton aria-label="Excluir" onClick={deletar} >
                                <FontAwesomeIcon icon={['fa', 'trash']} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </>
            }
        </>
    )
}