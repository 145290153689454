import axiosInstance from '../core/httpInterceptor';
import {objectToQueryString} from '../core/util';

export const comentarioService = {
    getComentarios,
    insComentario,
    getComentariosVisualizacoes,
    insComentarioVisualizacao
};

function getComentarios(paginacao, idCarteiraProcesso){
    return axiosInstance.get('/Comentario/GetComentarios/' + idCarteiraProcesso +  '?' + objectToQueryString({page:paginacao}));       
};

function insComentario(comentario){
    return axiosInstance.post('/Comentario/InsComentario', comentario);       
}

function getComentariosVisualizacoes(paginacao, idCarteiraProcessoComentario){
    return axiosInstance.get('/Comentario/GetComentariosVisualizacoes/' + idCarteiraProcessoComentario + '?' + objectToQueryString({page:paginacao}));       
}

function insComentarioVisualizacao(comentarios){
    return axiosInstance.post('/Comentario/InsComentarioVisualizacao', comentarios)
}