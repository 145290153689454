import axiosInstance from '../core/httpInterceptor';

export const dashboardService = {
    getGraficos,
    getGraficosUsuario,
    insGraficoUsuario,
    updGraficoUsuario,
    delGraficoUsuario,
    getGraficoDados,
    getDashboardAbaUsuario,
    insDashboardAbaUsuario,
    updDashboardAbaUsuario,
    delDashboardAbaUsuario
};

function getGraficos(){
    return axiosInstance.get('/Dashboard/GetGraficos');       
};

function getGraficosUsuario(idDashboardAbaUsuario){
    return axiosInstance.get('/Dashboard/GetGraficosUsuario/' + idDashboardAbaUsuario);       
};

function insGraficoUsuario(grafico){
    return axiosInstance.post('/Dashboard/InsGraficoUsuario', grafico);       
};

function updGraficoUsuario(grafico){
    return axiosInstance.post('/Dashboard/UpdGraficoUsuario', grafico);       
}

function delGraficoUsuario(grafico){
    return axiosInstance.post('/Dashboard/DelGraficoUsuario', grafico);       
    // return axiosInstance.delete('/Dashboard/DelGraficoUsuario', {data:grafico});       
}

function getGraficoDados(grafico, filtros){
    return axiosInstance.post('/Dashboard/GetGraficoDados', {dashboard: grafico, filtroPesquisa: filtros});       
}

function getDashboardAbaUsuario(){
    return axiosInstance.get('/Dashboard/GetDashboardAbaUsuario');       
}

function insDashboardAbaUsuario(dashboardAbaUsuario){
    return axiosInstance.post('/Dashboard/InsDashboardAbaUsuario', dashboardAbaUsuario)
}

function updDashboardAbaUsuario(dashboardAbaUsuario){
    return axiosInstance.post('/Dashboard/UpdDashboardAbaUsuario', dashboardAbaUsuario)
}

function delDashboardAbaUsuario(dashboardAbaUsuario){
    return axiosInstance.post('/Dashboard/DelDashboardAbaUsuario', dashboardAbaUsuario)
}