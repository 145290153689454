import { store } from '../redux/store'
import { enqueueSnackbar } from '../actions/notifier.actions';

const dispachMessage=(message,variant)=>{
    store.dispatch(enqueueSnackbar({
        key: new Date().getTime() + Math.random(),
        message,                
        options: {        
            variant    
        },
    }));
}

export const pushError = (message) => dispachMessage(message,"error");
export const pushSuccess = (message) => dispachMessage(message,"success");
export const pushInfo = (message) => dispachMessage(message,"info");
export const pushWarning = (message) => dispachMessage(message,"warning");