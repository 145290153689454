export const usuariosConstants = {
    LOGIN: 'LOGIN',
    LOGIN_SUCESSO: 'LOGIN_SUCESSO',
    LOGIN_FALHA: 'LOGIN_FALHA',

    NOVA_SENHA: 'NOVA_SENHA',
    NOVA_SENHA_SUCESSO: 'NOVA_SENHA_SUCESSO',
    NOVA_SENHA_FALHA: 'NOVA_SENHA_FALHA',
    
    LOGOUT: 'LOGOUT',
    ATUALIZAR_USUARIO: 'ATUALIZAR_USUARIO',
    ATUALIZAR_USUARIO_SUCESSO: 'ATUALIZAR_USUARIO_SUCESSO',
};