import { carteirasConstants } from '../constants';

const carteiraState = { loading: false, carteiras: [], count: 0, filtro: { FlagTodos: 2, inativaChecked: false }, idCarteira: 0, selecionado: false }; //2 - Recentes / 1 - Todas

const carteiras = (state = carteiraState, action) => {
    switch (action.type) {
        case carteirasConstants.SUCESSO:
            return {
                ...state,
                loading: false,
                carteiras: action.carteiras,
                count: action.count,
                filtro: (action.filtro ?? state.filtro)
            };
        case carteirasConstants.FALHA:
            return {
                ...state,
                loading: false,
            };
        case carteirasConstants.LISTA:
            return {
                ...state,
                loading: true
            };
        case carteirasConstants.PROXIMA_PAGINA:
            return {
                ...state,
                loading: false,
                carteiras: [...state.carteiras, ...action.carteiras]
            };
        case carteirasConstants.SUCESSO_SELECIONADO:
            return {
                ...state,
                idCarteira: action.idCarteira,
                selecionado: action.selecionado
            };
        case carteirasConstants.ALTERA_FILTRO:
            return {
                ...state,
                filtro: action.filtro
            };
        default:
            return state
    }
}

export default carteiras;