import React, { Component } from 'react';
import { connect } from 'react-redux';
import PainelProcessoService from '../../services/PainelProcessoService';
import Kanban from '../../components/Kanban/Kanban';
import { filtroActions } from '../../actions';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import UpdateIcon from '@material-ui/icons/Update';
import { withStyles } from '@material-ui/core/styles';
import ReportIcon from '@material-ui/icons/Report';
import Tooltip from '@material-ui/core/Tooltip';
import ModalConfirm from '../../components/ModalConfirm/ModalConfirm';
import _ from 'lodash';

import './PainelProcesso.css';

const useStyles = theme => ({
    button: {
        fontSize: 10,
        marginLeft: 5,
        fontFamily: 'Montserrat-Medium',
        textTransform: 'none',
        minWidth: '115px',
    },

    prioridadeAlta: {
        cursor: 'pointer',
        textAlign: 'right',
        color: 'red'
    },

    prioridade: {
        opacity: 0.5,
        cursor: 'pointer',
    }
});


class PainelProcesso extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timeAtualizacao: 1,
            dataUltimaAtualizao: new Date(),
            atualizando: false,
            filtros: {},
            inativaChecked: false,
            board: { columns: [] },
            modalConfirm: {
                abrirModal: false,
                tittle: "",
                message: "",
                buttonSimClick: () => { }
            },
        };

        this.loadData = this.loadData.bind(this);
        this.iniciarAtualizacao = this.iniciarAtualizacao.bind(this);
        this.pararAtualizacao = this.pararAtualizacao.bind(this);
        this.proximaPagina = this.proximaPagina.bind(this);
        this.buildCard = this.buildCard.bind(this);
        this.updtProcessoPrioridade = this.updtProcessoPrioridade.bind(this);
        this.closeModalConfirm = this.closeModalConfirm.bind(this);

        PainelProcessoService.getPainelProcessoColumns().then(response => {
            let columns = response.result.map(column => {
                return {
                    id: column.idProcessoStatus, title: "", nome: column.nome
                    , cards: [], paginacao: {
                        page: {
                            number: 1,
                            size: 5
                        }
                    },
                }
            });
            this.state.board.columns = columns;
            this.setState({ ...this.state }, () => {
                this.loadData();
            })
        }).catch(error => {
            console.log(error);
        });
    }

    formatDateTime(dat, time) { //caso queira que apareca o time so mandar como true e vai concatenar
        let d = new Date(dat);
        let retorno = "";
        let date = d.toLocaleDateString("pt-BR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        });
        retorno = date;

        if (time) {
            let time = d.toLocaleTimeString("pt-BR", {
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit"
            });
            retorno = date + ' ' + time;
        }
        return retorno;
    }

    buildCard(row, columnId) {
        const { classes } = this.props;
        const permissaoPriorizar = _.findIndex(this.props.permissoes, (p) => { return p.chave === "priorizar" && p.url_Rota === '/PainelProcesso'});
        return {
            id: row.idProcesso, title:
                <>
                    <span className="btnProcesso" onClick={() => { this.visualizarProcesso(row.processoNumero) }}>{row.processoNumero}</span>
                    <Tooltip
                        title={
                            permissaoPriorizar !== -1 ? (row.prioridade == 100 ? "Priorizar" : "Despriorizar") : "Usuário sem permissão para esta ação"
                        }
                    >
                        <ReportIcon
                            className={row.prioridade == 50 ? classes.prioridadeAlta : classes.prioridade}
                            onClick={() => {
                                if(permissaoPriorizar !== -1){
                                    this.updtProcessoPrioridade(
                                        row.idProcesso,
                                        row.prioridade == 50 ? 100 : 50,
                                        columnId
                                    )
                                }
                            }}></ReportIcon>
                    </Tooltip>
                </>, description:
                <>
                    {row.reclamante != null && row.reclamada != null ? <p>{row.reclamante} X {row.reclamada}  </p> : ""}
                    <p>CARTEIRA: {row.nomeCarteira}</p>
                    <p>STATUS: {row.statusExecucao}</p>
                    {row.esteira != null ? <p>PROTOCOLO: {row.esteira}</p> : ""}
                    {row.deadline != null ? this.checkDate(row.deadline) : ""}
                </>

        }
    }

    visualizarProcesso(processoNumero) {
        let regex = /\d*\-\d*\.\d*\.\d*.\d*.\d*/;
        if (regex.test(processoNumero)) {
            this.setState({
                modalConfirm: {
                    abrirModal: true,
                    tittle: "Confirmação de redirecionamento",
                    message: "Deseja visualizar o processo " + processoNumero + "?",
                    buttonSimClick: async () => {
                        window.open('WorkFlow/' + processoNumero, "_blank");
                        this.closeModalConfirm();
                    }
                }
            })
        }
    }

    closeModalConfirm() {
        this.setState({ modalConfirm: { ...this.state.modalConfirm, abrirModal: false } });
    }

    checkDate(date) {
        let today = new Date();
        let deadline = new Date(date);
        if (deadline > today)
            return <p style={{ color: "green" }}>DEADLINE: {this.formatDateTime(deadline)}</p>
        else
            return <p style={{ color: "red" }}>DEADLINE: {this.formatDateTime(deadline)} - ATRASADO</p>
    }

    async updtProcessoPrioridade(idProcesso, prioridade, columnId) {
        await PainelProcessoService.postUpdProcessoPrioridade(idProcesso, prioridade);
        let column = this.state.board.columns.find(c => c.id === columnId);
        column.cards = [];
        let pagina = await PainelProcessoService.listPainelProcessos(0, column.id, { FlagTodos: 1, inativoChecked: 0 },
            column.paginacao, this.props.filtros);
        column.cards = pagina.result.map(row => {
            return this.buildCard(row, column.id);
        });
        this.setState({ ...this.state, board: this.state.board });
    }


    loadData(columnId = null) {
        this.state.board.columns.forEach((columns, idx) => {
            if (columnId == null || columnId == columns.id) {
                this.state.board.columns.find(c => c.id === columns.id).cards = [];
                PainelProcessoService.listPainelProcessos(0, columns.id, { FlagTodos: 1, inativoChecked: 0 },
                    { page: columns.paginacao.page }
                    , this.props.filtros).then(response => {
                        this.props.adicionarCampos(response.filtro.campos);
                        response.result.forEach((row, index) => {
                            this.state.board.columns.find(c => c.id === columns.id).cards.push(
                                this.buildCard(row, columns.id)
                            )
                        });
                        this.setState({ ...this.state, board: this.state.board, dataUltimaAtualizao: new Date() });
                    }).catch(error => {
                        console.log(error);
                    })
            }
        });
    }

    componentDidUpdate() {
        if (JSON.stringify(this.props.filtros) !== JSON.stringify(this.state.filtros)) {
            this.setState({ ...this.state, filtros: this.props.filtros }, () => {
                this.loadData();
            });
        }
    }

    componentDidMount() {
        PainelProcessoService.getTimeAtualizacao().then(response => {
            this.setState({ ...this.state, timeAtualizacao: parseInt(response.result.timeAtualizacao) })
        }).catch(error => {
            console.log(error);
        })
    }

    componentWillUnmount() {
        this.setState({ ...this.state, atualizando: false });
        clearInterval(this.interval);
    }

    async iniciarAtualizacao() {
        this.setState({ ...this.state, atualizando: true });
        this.interval = await setInterval(() => {
            this.loadData()
        }, this.state.timeAtualizacao);
    }

    pararAtualizacao() {
        this.setState({ ...this.state, atualizando: false });
        clearInterval(this.interval);
    }

    async proximaPagina(column) {
        column.paginacao.page.size += 5;
        this.loadData(column.id);
    }

    render() {
        const { classes } = this.props;
        return (
            <div className="PainelProcesso">
                <Grid container alignItems="center">
                    <label>Painel de controle</label>
                    <Divider orientation="vertical" flexItem></Divider>
                    <Button
                        className={classes.button}
                        startIcon={<UpdateIcon></UpdateIcon>}
                        onClick={this.state.atualizando ? this.pararAtualizacao : this.iniciarAtualizacao}
                    >
                        {this.state.atualizando ? "Parar Atualização Automática" : "Iniciar Atualização Automática"}
                    </Button>
                    <Divider orientation="vertical" flexItem></Divider>
                    <label>{`Data da ultima atualzação: ${this.formatDateTime(this.state.dataUltimaAtualizao, true)}`}</label>

                </Grid>
                <div className="kanban">
                    <Kanban data={this.state.board} proximaPagina={this.proximaPagina} >
                    </Kanban>
                </div>
                {this.state.modalConfirm.abrirModal &&
                    <ModalConfirm open={this.state.modalConfirm.abrirModal} modalProps={this.state.modalConfirm} buttonCancelarClick={() => { this.closeModalConfirm() }} ></ModalConfirm>
                }
            </div>
        );
    }
}

function mapState(state) {
    const { usuario, permissoes } = state.authentication;
    const camposFiltros = state.filtros.campos;
    const { filtros } = state.filtros;
    const { statusFlag } = state.filtros;
    return { usuario, camposFiltros, filtros, statusFlag, permissoes };
}

const actionCreators = {
    adicionarCampos: filtroActions.adicionarCampos,
};

const connectedPainelProcesso = connect(mapState, actionCreators)(withStyles(useStyles)(PainelProcesso));
export { connectedPainelProcesso as PainelProcesso };