import React, { Component } from 'react';
import { pushInfo, pushError } from '../../services/index';
import { BackGroundLogin } from '../../components/BackGroundLogin/BackGroundLogin';
import { ContentLogin } from '../../components/ContentLogin/ContentLogin';
import './NovaSenhaPage.css';
import InputMask from 'react-input-mask';
import ToggleableLink  from '../../components/ToggleableLink/ToggleableLink';
import { usuarioActions } from '../../actions';
import { connect } from 'react-redux';

class NovaSenhaPage extends Component {
    constructor(props) {
        super(props);

        this.state = { senha: "", confirmarSenha: "" };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

     handleSubmit(event) {
        event.preventDefault();
        const {senha, confirmarSenha} = this.state;
        if(senha === "" || confirmarSenha ===  "")
        {
            pushInfo("Informe uma nova senha.");
        }else if(senha !== confirmarSenha){
            pushError("As senhas não coincidem.");
        }else{
            this.setState({ loading: true });
            this.props.usuario.senha = senha;
            this.props.novaSenha(this.props.usuario);
            this.setState({ loading: false });
        }
    }

    render() {
        const {  usuario, loading } = this.props;
        const {senha, confirmarSenha} = this.state;
        return (
            <BackGroundLogin>
                <ContentLogin>
                    <div className="content-nova-senha row align-items-center">
                        <div className="col-12 mb-5 mt-5">
                            <p>Cadastrar senha</p>
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-group text-left">
                                    <label htmlFor="nome">Nome do Usuário</label>
                                    <input id="nome" className="form-control text-center" disabled={loading} value={usuario.nome} />
                                </div>
                                <div className="form-group text-left">
                                    <label htmlFor="cpf">CPF do Usuário</label>
                                    <InputMask mask="999.999.999-99" id="cpf" className="form-control text-center" value={usuario.cpf} disabled  ></InputMask>
                                </div>
                                <div className="form-group text-left">
                                    <label htmlFor="senha">Nova Senha</label>
                                    <input  id="senha" type="password" name="senha" className="form-control text-center" value={senha} disabled={loading} onChange={this.handleChange} />
                                </div>
                                <div className="form-group text-left">
                                    <label htmlFor="confirmarSenha">Confirmar Senha</label>
                                    <input id="confirmarSenha" type="password" name="confirmarSenha" className="form-control text-center" disabled={loading} value={confirmarSenha} onChange={this.handleChange} />
                                </div>
                                <div className="row">
                                    <div className="col-7 text-left">
                                        <ToggleableLink id="lnkEsqueceuSenha" to="/Login" disabled={loading} className="btn btn-outline-primary" >Cancelar</ToggleableLink>
                                    </div>
                                    <div className="col-5 text-right pl-0">
                                        <button id="btnEsqueceuSenha" className="btn btn-primary" type="submit" disabled={this.state.loading} >
                                            <span role="status" aria-hidden="true" className={loading ? "spinner-border spinner-border-sm" : "d-none"}></span>
                                            Enviar
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </ContentLogin>
            </BackGroundLogin>
        );
    }
}


function mapState(state) {
    const { authentication, usuarios } = state;
    const { usuario } = authentication;
    const { loading } = usuarios;
    return { usuario, loading };
}

const actionCreators = {
    novaSenha: usuarioActions.novaSenha
};

const connectedNovaSenhaPage = connect(mapState, actionCreators)(NovaSenhaPage);
export { connectedNovaSenhaPage as NovaSenhaPage };