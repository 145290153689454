import React, { Component } from 'react';
import Input from '@material-ui/core/Input';
import { Container, SearchDiv, TextoFiltro, Sugestoes } from './styles';
import PropTypes from 'prop-types';
import $ from 'jquery';
import MaskedInput from 'react-text-mask';

class Autocomplete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idFiltroAtivo: 0,
            textoFiltro: "",
            listaFiltro: [],
            filtroExibir: false,
            textoEntrada: "",
            idSugestaoAtiva: -1,
            listaSugestoes: [],
            listaSugestoesDefault: [],
            sugestoesExibir: false,
            widthFiltro: 0,
            isDate: false
        }
        this.myRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.filtroFixo === true) {
            this.setFiltroFixo();
        }
    }

    componentDidUpdate(prevProps) {
        const { listaSugestoes, filtroFixo } = this.props;

        if (listaSugestoes !== prevProps.listaSugestoes) {
            if (this.state.textoEntrada !== "") {
                const newState = Object.assign(this.state, {});
                newState.listaSugestoesDefault = listaSugestoes;
                this.setState(newState);
                this.setSugestoes();
            }

            const newState = Object.assign(this.state, {});
            newState.widthFiltro = this.myRef.current !== null ? this.myRef.current.offsetWidth : 0
            this.setState(newState);
        }

        /* verifica se o filtro eh fixo, se for seta o filtro fixo */
        if (filtroFixo !== prevProps.filtroFixo && filtroFixo) {
            this.setFiltroFixo();
        }

        if (this.props.LimpaClickButton) {
            this.limpaFiltros();
            this.props.limpaClickButtonRetorno();
        }
    }

    setFiltroFixo = () => {
        const obj = this.props.filtroFixoObj;
        this.setFiltroSelecionado(obj);
        const newState = Object.assign(this.state, {});
        newState.listaFiltro = [obj];
        this.setState(newState);
    }

    /* Seta sugestoes */
    setSugestoes = () => {

        const newState = Object.assign(this.state, {});
        newState.idSugestaoAtiva = -1;
        newState.filtroExibir = false;
        newState.sugestoesExibir = true;

        let listaFiltro = [];
        const { textoEntrada } = this.state;
        this.state.listaSugestoesDefault.forEach((element) => {
            if (element.desc.toLowerCase().indexOf(textoEntrada.toLowerCase()) > -1) {
                listaFiltro.push(element);
            }
        });

        newState.listaSugestoes = listaFiltro;
        this.setState(newState);
    }

    /* Limpa filtro,, volta na configuracao de inicio */
    limpaFiltros = () => {
        const newState = Object.assign(this.state, {});
        newState.idFiltroAtivo = 0;
        newState.textoFiltro = "";
        newState.listaFiltro = [];
        newState.listaSugestoesDefault = [];
        newState.filtroExibir = false;
        newState.sugestoesExibir = false;
        newState.textoEntrada = "";
        newState.isDate = false;
        this.setState(newState);
    }

    limpaSugestoes = () => {
        const newState = Object.assign(this.state, {});
        newState.idSugestaoAtiva = -1;
        newState.listaSugestoes = [];
        newState.listaSugestoesDefault = [];
        newState.sugestoesExibir = false;
        this.setState(newState);
    }

    onClickSugestao = (sugestao) => {
        const newState = Object.assign(this.state, {});
        newState.idSugestaoAtiva = sugestao.cod;
        newState.sugestoesExibir = false;

        if (this.props.exibirSugestaoCod) {
            newState.textoEntrada = sugestao.cod;
        } else {
            newState.textoEntrada = sugestao.desc;
        }

        this.setState(newState);
        this.inputFocus.focus();
    }

    /* Busca lista de sugestoes na api */
    buscaSugestoes = (textoEntrada = '') => {
        this.setState({ textoEntrada });

        //pega o filtro selecionado
        let obj = this.state.listaFiltro.find((e) => {
            return e.cod === this.state.idFiltroAtivo;
        });

        //se tiver um objeto e autocomplete estiver ativado
        if (obj !== undefined && obj.autocomplete) {
            if (textoEntrada.length >= obj.contDeCaracter) {
                if (this.timeout) {
                    clearTimeout(this.timeout);
                }
                this.timeout = setTimeout(() => {
                    let objRetorno = {
                        cod: obj.cod,
                        textoEntrada
                    }
                    if (this.props.onRetornoInput)
                        this.props.onRetornoInput(objRetorno);
                }, obj.contDeTempo * 1000);
            }
        }
    }

    /* Retorna o resultado com a chave e o valor da sugestao selecionada */
    retornaResultado = () => {
        const {
            textoFiltro,
            textoEntrada,
            listaSugestoesDefault,
            idFiltroAtivo,
        } = this.state;

        if (textoFiltro && textoEntrada) {
            const id_sugestao = listaSugestoesDefault.find(e => {
                return e.desc === textoEntrada;
            });

            const retorno = {
                chave_id: idFiltroAtivo,
                chave_desc: textoFiltro,
                valor_id: id_sugestao ? id_sugestao.cod : null,
                valor_desc: textoEntrada
            }

            const newState = Object.assign(this.state, {});
            newState.textoEntrada = "";
            this.setState(newState);

            if (this.props.limpaFiltroAoBuscar) {
                this.limpaFiltros();
            } else {
                this.limpaSugestoes();
            }

            this.props.onRetornoBusca(retorno);
        }
    }

    onChange = e => {
        let textoEntrada = e.currentTarget.value;
        const { limpaInputEnter } = this.props;

        const { listaFiltro, delimit } = this.props;
        const delimitUsuario = e.currentTarget.value[0];
        const { textoFiltro } = this.state;


        if (!textoFiltro) { // se nao tem filtro selecionado
            const newState = Object.assign(this.state, {});
            let newListaFiltro = [];

            if (delimitUsuario === delimit) {
                textoEntrada = textoEntrada.substr(1, textoEntrada.length);
                listaFiltro.forEach(element => {
                    if (element.desc.toLowerCase().indexOf(textoEntrada.toLowerCase()) > -1) {
                        newListaFiltro.push(element);
                    }
                });
                newState.idFiltroAtivo = 0;
                newState.filtroExibir = true;
            }
            //seta lista de filtro;
            newState.listaFiltro = newListaFiltro;
            newState.textoEntrada = e.currentTarget.value;
            this.setState(newState);
        } else {
            if (!limpaInputEnter) {
                this.limpaSugestoes();
            }
            this.buscaSugestoes(textoEntrada);
        }
    }

    /* seta um filtro selecionado */
    setFiltroSelecionado = (filtro) => {
        const newState = Object.assign(this.state, {});
        newState.idFiltroAtivo = filtro.cod;
        newState.filtroExibir = false;
        newState.textoEntrada = "";
        newState.textoFiltro = filtro.desc;
        if (filtro.tipo === "date") {
            newState.isDate = true;
        } else {
            newState.isDate = false;
        }
        this.setState(newState);
        this.inputFocus.focus();
        this.buscaSugestoes();
    }

    onKeyDown = e => {
        const {
            idFiltroAtivo,
            listaFiltro,
            textoEntrada,
            textoFiltro,
            idSugestaoAtiva,
            sugestoesExibir,
            listaSugestoes,
        } = this.state;

        //scroll da lista
        const $scrollable = $(".sugestoes");
        const scrollTop = $scrollable.scrollTop();
        const liHeight = $(".suggestion-active").height();
        const constNum = parseInt((26 * liHeight) / 14.66); //calculo de proporcao
        const up = scrollTop - constNum;
        const down = scrollTop + constNum;
        const { filtroFixo, limpaInputEnter } = this.props;

        //se o usuario pressiona enter
        if (e.keyCode === 13) {
            //se nao tem um filtro selecionado
            if (!textoFiltro) {
                const obj = {};
                obj.cod = listaFiltro[idFiltroAtivo] !== undefined ? listaFiltro[idFiltroAtivo].cod : 0;
                obj.desc = listaFiltro[idFiltroAtivo] !== undefined ? listaFiltro[idFiltroAtivo].desc : "";
                this.setFiltroSelecionado(obj);
                //tem um filtro selecionado
            } else {
                const newState = Object.assign(this.state, {});

                //se nao tem entrada no input e nao tem nenhuma sugestao selecionada, retorna
                if (textoEntrada === "" && idSugestaoAtiva === -1) {
                    return;
                }

                //se tem uma sugestao ativa, eh pra exibir as sugestoes e a lista de sugestoes tem elementos, entao seleciona o elemento
                else if (idSugestaoAtiva !== -1 && sugestoesExibir && listaSugestoes.length !== 0) {
                    newState.sugestoesExibir = false;
                    newState.textoEntrada = listaSugestoes.length === 0 ? textoEntrada : (
                        this.props.exibirSugestaoCod ? listaSugestoes[idSugestaoAtiva].cod : listaSugestoes[idSugestaoAtiva].desc
                    )
                    this.setState(newState);
                    this.inputFocus.focus();
                }

                //nao tem nenhuma sugestao ativa
                else {
                    if (limpaInputEnter) {
                        this.retornaResultado();
                    }
                }
            }
        }

        //usuario pressiona tecla up
        else if (e.keyCode === 38) {
            //nao tem nenhum filtro selecionado e a tecla up eh apertada
            if (textoFiltro === "") {
                //esta no extremo superior, nao faca nada
                if (idFiltroAtivo === 0) return;
                else {
                    if (idFiltroAtivo > 2) {
                        $scrollable.scrollTop(up);
                    }
                    this.setState({ idFiltroAtivo: idFiltroAtivo - 1 });
                }
            }
            // se nao tem um filtro selecionado
            else {
                //ja esta no extremo superior
                if (idSugestaoAtiva === -1) return;
                else {
                    if (idSugestaoAtiva > 2) {
                        $scrollable.scrollTop(up);
                    }
                    this.setState({ idSugestaoAtiva: idSugestaoAtiva - 1 });
                }
            }
        }

        //usuario pressiona tecla down
        else if (e.keyCode === 40) {
            //se nao tem nenhum filtro selecionado e tecla down eh apertada
            if (textoFiltro === "") {
                if (idFiltroAtivo + 1 === listaFiltro.length) {
                    return;
                } else {
                    if (idFiltroAtivo > 2) {
                        $scrollable.scrollTop(down);
                    }
                    this.setState({ idFiltroAtivo: idFiltroAtivo + 1 });
                }
            }
            //tem um filtro selecionado
            else {
                //ja esta no extremo inferior
                if (idSugestaoAtiva + 1 === listaSugestoes.length) {
                    return;
                }
                //anda para baixo
                else {
                    if (idSugestaoAtiva > 2) {
                        $scrollable.scrollTop(down);
                    }
                    this.setState({ idSugestaoAtiva: idSugestaoAtiva + 1 });
                }
            }
        }

        //se usuario pressionou o backspace e nao tem nenhum valor digitado e tem um filtro selecionado e nao eh filtro fixo, entao remove filtro
        else if (e.keyCode === 8 && textoEntrada.length === 0 && textoFiltro !== "" && !filtroFixo) {
            if (filtroFixo === false) {
                this.limpaFiltros();
            }
        }
    }

    onMouseOver = (idOperacao, idElement) => {
        //filtro
        if (idOperacao === 0) {
            this.setState({ idFiltroAtivo: idElement });
            //sugestao
        } else {
            this.setState({ idSugestaoAtiva: idElement });
        }
    }

    setTextos = () => {
        const { placeholder, delimit } = this.props;
        let textoPlaceholder;
        let textoFiltro;

        //se o filtro esta setado
        if (this.state.textoFiltro) {
            textoFiltro = <TextoFiltro ref={this.myRef}>{this.state.textoFiltro}</TextoFiltro>
            textoPlaceholder = "";
            //filtro nao esta setado
        } else {
            textoFiltro = "";
            textoPlaceholder = placeholder ? placeholder : ("Digite " + delimit + " para obter uma lista de filtros");
        }

        return {
            textoPlaceholder,
            textoFiltro
        }
    }

    exibirListas = () => {
        const {
            filtroExibir,
            textoEntrada,
            listaFiltro,
            sugestoesExibir,
            listaSugestoes,
            widthFiltro
        } = this.state;

        const { delimit } = this.props;
        let listaComponente;

        //se eh para exibir o filtro e o usuario entrou com algum texto no input
        if (filtroExibir && textoEntrada) {
            //se tem elementos no filtro
            if (listaFiltro.length) {
                const styles = {
                    marginLeft: "15px",
                    width: "calc(100% - 30px)"
                };
                const { idFiltroAtivo } = this.state;

                listaComponente = (
                    <Sugestoes style={styles}>
                        {
                            listaFiltro.map((filtro, index) => {
                                let className;
                                if (index === idFiltroAtivo) {
                                    className = "suggestion-active";
                                }
                                return (
                                    <li
                                        key={index}
                                        className={className}
                                        onClick={() => this.setFiltroSelecionado(filtro)}
                                        onMouseOver={() => this.onMouseOver(0, index)}
                                    >
                                        {delimit + filtro.desc}
                                    </li>
                                );
                            })
                        }
                    </Sugestoes>
                );
            }
            return listaComponente;
        } else if (textoEntrada && sugestoesExibir && listaSugestoes.length > 0) { // exibe lista de sugestoes
            const styles = {
                marginLeft: widthFiltro,
                width: `calc(100% - ${widthFiltro + 16}px)`,
            }
            const { idSugestaoAtiva } = this.state;

            listaComponente = (
                <Sugestoes style={styles}>
                    {
                        listaSugestoes.map((sugestao, index) => {
                            let className;
                            if (index === idSugestaoAtiva) {
                                className = "suggestion-active";
                            }
                            return (
                                <li
                                    key={sugestao.desc}
                                    className={className}
                                    onClick={() => this.onClickSugestao(sugestao)}
                                    onMouseOver={() => this.onMouseOver(1, index)}
                                >
                                    {sugestao.desc}
                                </li>
                            )
                        })
                    }
                </Sugestoes>
            );
            return listaComponente;
        }

        return "";
    }

    TextMaskCustom(props) {
        const { inputRef, ...other } = props;
      
        return (
          <MaskedInput
            {...other}
            ref={(ref) => {
              inputRef(ref ? ref.inputElement : null);
            }}
            mask={['dd/MM/yyyy']}
            showMask
          />
        );
      }

    render() {
        const { textoPlaceholder, textoFiltro } = this.setTextos();
        const { textoEntrada } = this.state;
        const listaComponente = this.exibirListas();
        const inputStyle = textoFiltro ? '' : 'inputLimpo';

        return (
            <Container>
                <SearchDiv>
                    {textoFiltro}
                    {!this.state.isDate &&
                        <input
                            type="text"
                            className={inputStyle}
                            placeholder={textoPlaceholder}
                            onChange={this.onChange}
                            onKeyDown={this.onKeyDown}
                            ref={e => this.inputFocus = e}
                            value={textoEntrada}
                        />
                    }
                    {this.state.isDate &&
                        <MaskedInput
                        type="text"

                            className={inputStyle}
                            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                            onChange={this.onChange}
                            ref={e => this.inputFocus = e}
                            className={inputStyle}
                            onKeyDown={this.onKeyDown}
                            ref={e => this.inputFocus = e}
                            value={textoEntrada}
                        />
                    }
                </SearchDiv>
                {listaComponente}
            </Container>
        );
    }
}

Autocomplete.propTypes = {
    listaFiltro: PropTypes.array.isRequired,
    listaSugestoes: PropTypes.array,
    onRetornoInput: PropTypes.func.isRequired,
    onRetornoBusca: PropTypes.func.isRequired,
    delimit: PropTypes.string,
    exibirSugestaoCod: PropTypes.bool,
    filtroFixo: PropTypes.bool,
    filtroFixoObj: PropTypes.object,
    limpaFiltroAoBuscar: PropTypes.bool,
}

Autocomplete.defaultProps = {
    delimit: '@',
    exibirSugestaoCod: false,
    filtroFixo: false,
    filtroFixoObj: {},
    limpaFiltroAoBuscar: false,
    limpaInputEnter: true
}

export { Autocomplete };