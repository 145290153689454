import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { removeSnackbar } from '../../actions/notifier.actions';

let displayed = [];

const Notifier = () => {
    const dispatch = useDispatch();    
    const notifications = useSelector(store=> store.notifications || []);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const storeDisplayed = (id) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id) => {
        displayed = [...displayed.filter(key => id !== key)];
    };

    React.useEffect(() => {
        let notifierData=JSON.parse(localStorage.getItem('notifier'));
        (notifierData ? notifierData.notifications || [] : []).forEach(({ key, date, message, options = {}, dismissed = false, teste }) => {
            let diffTime = Math.abs(new Date() - new Date(date));
            if(!date || Math.ceil(diffTime / 1000) > 30)
            {
                return;
            }
            
            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(key);
                return;
            }

            // do nothing if snackbar is already displayed
            if (displayed.includes(key)) return;

            // display snackbar using notistack
            enqueueSnackbar(message, {
                key,
                ...options,
                autoHideDuration: options.variant === 'error' ? 10000 : 4000,
                preventDuplicate: true,
                anchorOrigin : {
                    horizontal: "right", vertical: "top" 
                },
                onClose: (event, reason, myKey) => {
                    if (options.onClose) {
                        options.onClose(event, reason, myKey);
                    }
                },
                onExited: (event, myKey) => {
                    // removen this snackbar from redux store
                    dispatch(removeSnackbar(myKey));
                    removeDisplayed(myKey);
                },
            });

            // keep track of snackbars that we've displayed
            storeDisplayed(key);
        });
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

    return null;
};

export default Notifier;