import axiosInstance from '../core/httpInterceptor';

export const configCarteiraAutomaticaService = {
    getConfigCarteiraAutomatica,
    delConfigCarteiraAutomatica,
    updConfigCarteiraAutomatica,
    getConfigCarteiraAutomaticaCategoria,
    InsConfigCarteiraAutomatica,
    InsConfigCarteiraAutomaticaCSV
};

function getConfigCarteiraAutomatica(paginacao, filtroPesquisa, idChave){
    return axiosInstance.post('/ConfigCarteiraAutomatica/GetConfigCarteiraAutomatica/' + idChave, {paginacao, filtroPesquisa});       
};

function delConfigCarteiraAutomatica(listaConfigCarteira){
    return axiosInstance.post('/ConfigCarteiraAutomatica/DelConfigCarteiraAutomatica', listaConfigCarteira);       
};

function updConfigCarteiraAutomatica(configCarteiraAutomatica){
    return axiosInstance.post('/ConfigCarteiraAutomatica/UpdConfigCarteiraAutomatica', configCarteiraAutomatica);       
};


function getConfigCarteiraAutomaticaCategoria(idChave){
    return axiosInstance.get('/ConfigCarteiraAutomatica/GetConfigCarteiraAutomaticaCategoria/' + idChave);       
};

function InsConfigCarteiraAutomatica(configCarteiraAutomatica){
    return axiosInstance.post('/ConfigCarteiraAutomatica/InsConfigCarteiraAutomatica', configCarteiraAutomatica);       
};

function InsConfigCarteiraAutomaticaCSV(fileCSV){
    return axiosInstance.post('/ConfigCarteiraAutomatica/InsConfigCarteiraAutomaticaCSV', fileCSV);       
};