import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import InfiniteScroll from "react-infinite-scroll-component";
import Grid from '../Grid/Grid';
import { columnsDefinition } from './Columns';
import { processoActions } from '../../actions';
import { connect } from 'react-redux';

const useStyles = theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: "#F5F5F5",
        padding: theme.spacing("15px", "20px", "20px", "20px"),
        borderRadius: "10px",
        fontSize: "13px",
        outline: 'none'
    },
    contentModal: {
        width: 1100,
        backgroundColor: "#F5F5F5",
        padding: 16
    },
    header: {
        marginLeft: 24
    },
    iconButton: {
        padding: 10,
    },
});


class ProcessoFundo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            partes: '',
            detalhes:'',
            movimentacoes: '',
            collapses: [],
            dadosCampos: [],
            documento: '',
            open: false
        };
        this.handleOpen = this.handleOpen.bind(this);
        this.state = { page: { number: 1, size: 25 }, idProcesso: null, idCarteiraProcesso: null };
    }
    
    componentDidUpdate(prevProps) {
        if (this.props.processoId !== prevProps.processoId && this.props.processoId) {
            let paginacao = this.paginacaoInicial();
            let props = {
                carteiraId: this.props.idCarteira,
                paginacao: paginacao,
                idProcesso: this.props.processoId,
            };
            if((!prevProps.idCarteira && this.props.idCarteiraProcesso) || this.props.idCarteiraProcesso !== prevProps.idCarteiraProcesso){
                this.setState({idCarteiraProcesso:this.props.idCarteiraProcesso});
            }
            this.setState({paginacao});
            this.props.getProcessoFundo(props)
        }

        
    }

    proximaPagina() {
        let page = this.state.page;
        page.number++;
        this.setState({ page })
        this.props.proximaPaginaProcessoFundo({carteiraId: this.props.idCarteira, paginacao: page, idProcesso: this.state.idProcesso});
    }

    paginacaoInicial() {
        return { page: { number: 1, size: 25 } };
    }

    handleOpen = () => {
        this.setState({ open: true });
    }

    handleClose(){
        this.setState({open:false},()=>this.props.onClose());
    }

    render() {
        const { classes } = this.props;
        const [data] = [this.props.processoFundo];
        
        return (
            <>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={this.props.open}
                    onClose={e => this.handleClose()}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.props.open}>
                        <div className={classes.paper}>
                            <React.Fragment>
                                <FontAwesomeIcon icon={faChevronLeft} onClick={e => this.handleClose()} style={{ cursor: "pointer" }} />
                                <label className={classes.header}>Acompanhamento Integração {this.props.processoNumero}</label>
                                <div className={classes.contentModal} >
                                    <InfiniteScroll
                                        style={{ width: '100%', fontSize: 10 }}
                                        dataLength={this.props.processoFundo.length}
                                        next={() => { this.proximaPagina() }}
                                        hasMore={this.props.processoFundo.length !== this.props.count}
                                        height="100%"
                                    >
                                        <Grid data={data}
                                            style={{ width: '100%', fontSize: 10, position: 'static' }}
                                            columns={columnsDefinition}
                                            getTrProps={(state, rowInfo) => ({                                                
                                                style: {
                                                    background: '#FFFFFF',
                                                },
                                            })}
                                            loading={this.props.loading.processos}
                                            minPage={1}
                                            pageSize={this.props.processoFundo.length}                                            
                                        />
                                    </InfiniteScroll>
                                </div>
                            </React.Fragment>
                        </div>
                    </Fade>
                </Modal>
            </>
        );
    }
}

function mapState(state) {
    const { processoFundo, loading, count, selecionados, atualizacao } = state.processos;

    return { processoFundo, loading, count, selecionados, atualizacao };
}

const actionCreators = {
    getProcessoFundo: processoActions.getProcessoFundo,
    proximaPaginaProcessoFundo: processoActions.proximaPaginaProcessoFundo
};

const connectedProcessoFundo = connect(mapState, actionCreators)(withStyles(useStyles)(ProcessoFundo));
export { connectedProcessoFundo as ProcessoFundo };
