import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Divider } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles((theme) => ({
    header: {
        fontSize: "12px",
        marginLeft: "26px"
    },
    grid: {
        paddingTop: 15,
        cursor: "pointer"
    },
    titulo: {
        fontWeight: "bold"
    },
    vazio: {
        margin: "auto",
        padding: 10,
        textAlign:'center'
    }
}));

export const ListaRelatorios = (props) => {
    const {data, selecionarRelatorio} = props;
    const classes = useStyles();

    return (<>
        <div>
            <FontAwesomeIcon icon={['fa', 'chart-bar']} color="#0091C7" />
            <label className={classes.header}>Lista de Relatórios</label>
        </div>
        <div>
            {data.length === 0 &&
                <div className={classes.vazio}>
                    Nenhum relatório encontrado
                </div>
            }
            {data.map((relatorio, index) => {
                return (
                    <div key={index}>
                        <Grid container wrap="nowrap" spacing={2} alignItems="left" className={classes.grid} onClick={() => { selecionarRelatorio(relatorio) }} direction="column">
                            <Grid>
                                <label className={classes.titulo}>
                                    {relatorio.titulo}
                                </label>
                            </Grid>
                            <Grid>
                                <label className={classes.descricao}>
                                    {relatorio.descricao}
                                </label>
                            </Grid>
                        </Grid>
                        <Divider variant="fullWidth" style={{ marginTop: 15 }} />
                    </div>
                );
            })
            }
        </div>
    </>);
}