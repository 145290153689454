import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { usuarios } from './usuarios.reducer';
import notifier from './notifier.reducer';
import { workFlows } from './workFlow.reducer';
import carteiras from './carteiras.reducer';
import processos from './processos.reducer';
import filtros from './filtros.reducer';

const rootReducer = combineReducers({
  authentication,
  usuarios,
  notifier,
  workFlows,
  carteiras,
  processos,
  filtros
});

export default rootReducer;