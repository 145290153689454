import React, { Component } from 'react';
import { Grid as GridTable } from '../../components/Grid/Grid';
import InfiniteScroll from "react-infinite-scroll-component";
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usuarioService } from '../../services/usuarioService';
import { perfilService } from '../../services';
import _ from 'lodash';
import ModalConfirm from '../../components/ModalConfirm/ModalConfirm';
import { pushWarning, pushError } from '../../services/notifierService';
import { Input, MenuItem, Select } from '@material-ui/core';
import { faseService } from '../../services/index';
import { filtroActions } from '../../actions/index';
import { connect } from 'react-redux';

const columnsDefinition = [
    {
        "column": null,
        "title": "CPF",
        "render": "ColumnWithColorAndCheckboxOrText",
        "props": {
            "color": "rgb(245, 114, 114)",
            "colorsecondary": "#0091c7",
            "text_column": "cpf",
            "check_column": "idCpf",
            "onupdate": (props) => {
            },
            editable: () => {
                return true;
            }
        },
        "filterable": true,
        "style": {
            "padding": "0px",
            "height": "100%",
            "minHeight": "40px"
        },
        "height": 20,
        "width": 220,
        "headerClassName": "text-left pl-3",
    },
    {
        "column": "nome",
        "title": "Nome",
        "headerClassName": "text-left",
        "render": "InputWithNoFocus",
        "props": {
            editable: () => {
                return true;
            }
        },
        "filterable": true
    },
    {
        "column": "email",
        "title": "E-mail",
        "headerClassName": "text-left",
        "render": "InputWithNoFocus",
        "props": {
            editable: () => {
                return true;
            }
        },
        "filterable": true
    },
    {
        "column": "nome",
        "title": "Imagem",
        "render": "Avatar",
        "width": 60,
        "height": 40,
        "className": "text-center",
        "headerClassName": "text-left",
        "props": {
            "tooltip_empty": "Sem Responsável",
            "imagem_usuario_compra": "imagem"
        },
    },
    {
        "column": "status",
        "title": "Status",
        "headerClassName": "text-left",
        "render": "SelectInput",
        "props": {
            "text_column": "status",
            editable: () => {
                return true;
            },
            onchange: () => {

            },
            getoptions: () => {

            }
        },
        "filterable": true,
        Filter: () => { return null },
        "className": "rt-rd-select-custom"
    },
    {
        "column": "descPerfis",
        "title": "Perfil",
        "headerClassName": "text-left",
        "render": "SelectCheck",
        "props": {
            "text_column": "descPerfis",
            "value_column": "perfis",
            editable: () => {
                return true;
            },
            onchange: () => {

            },
            getoptions: () => {

            }
        },
        "filterable": true,
        Filter: () => { return null },
        "className": "rt-rd-select-custom"
    }
];

const useStyles = theme => ({
    header: {
        margin: 5,
        display: "-webkit-box"
    },
    labelHeader: {
        paddingTop: 6,
        marginRight: 5,
        fontSize: 10
    },
    button: {
        fontSize: 10,
        marginLeft: 5,
        fontFamily: 'Montserrat-Medium',
        textTransform: 'none'
    },
    lastButton: {
        fontSize: 10,
        marginLeft: 5,
        fontFamily: 'Montserrat-Medium',
        textTransform: 'none',
        display: "-webkit-box"
    },
    buttonFocused: {
        outline: 'none'
    },
})

class Usuario extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listaUsuario: [],
            listaPerfil: [],
            loading: false,
            totalUsuario: 0,
            paginacao: {
                page: {
                    number: 1,
                    size: 25
                }
            },
            selecionados: [],
            modalConfirm: {
                abrirModal: false,
                tittle: "",
                message: "",
                buttonSimClick: () => { }
            },
            processoTipo: [],
            status: [],
            editing: false,
            filtered: []
        }

        this.proximaPagina = this.proximaPagina.bind(this);
        this.selecionaUsuario = this.selecionaUsuario.bind(this);
        this.closeModalConfirm = this.closeModalConfirm.bind(this);
        this.getStatusOptions = this.getStatusOptions.bind(this);
        this.getPerfilOptions = this.getPerfilOptions.bind(this);
        this.adicionarClick = this.adicionarClick.bind(this);
        this.editarClick = this.editarClick.bind(this);
        this.cancelarClick = this.cancelarClick.bind(this);
        this.getEditable = this.getEditable.bind(this);
        this.getEditableCPF = this.getEditableCPF.bind(this);
        this.cpfMask = this.cpfMask.bind(this);
        this.changeCPF = this.changeCPF.bind(this);
        this.changeEmail = this.changeEmail.bind(this);
        this.changeNome = this.changeNome.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.changePerfil = this.changePerfil.bind(this);
        this.editarUsuarios = this.editarUsuarios.bind(this);
        this.fileInput = React.createRef();
        this.carregaLista = this.carregaLista.bind(this);
        this.filterChange = this.filterChange.bind(this);

        this.timer = null;
    }

    componentDidMount() {
        this.setState({ loading: true })
        this.setState({ status: ['Ativo', 'Inativo'] })
        this.carregaLista();

        columnsDefinition[0].props.onupdate = this.selecionaUsuario;
        columnsDefinition[4].props.getoptions = this.getStatusOptions;
        columnsDefinition[5].props.getoptions = this.getPerfilOptions;
        columnsDefinition[0].props.editable = this.getEditableCPF;
        columnsDefinition[1].props.editable = this.getEditable;
        columnsDefinition[2].props.editable = this.getEditable;
        columnsDefinition[4].props.editable = this.getEditable;
        columnsDefinition[5].props.editable = this.getEditable;
        columnsDefinition[0].props.onchange = this.changeCPF;
        columnsDefinition[1].props.onchange = this.changeNome;
        columnsDefinition[2].props.onchange = this.changeEmail;
        columnsDefinition[4].props.onchange = this.changeStatus;
        columnsDefinition[5].props.onchange = this.changePerfil;

        columnsDefinition[0].Filter = () => {
            return <Input
                style={{ fontSize: "10px" }}
                fullWidth={true}
                onChange={(e) => {
                    clearTimeout(this.timer)
                    this.timer = setTimeout(this.filterChange('CPF', '@CPF', 'CPF', '', 'string', e.target.value), 3000)
                }
                }
                placeholder="Pesquise por CPF"
            />
        }
        columnsDefinition[1].Filter = () => {
            return <Input
                style={{ fontSize: "10px" }}
                fullWidth={true}
                onChange={(e) => {
                    clearTimeout(this.timer)
                    this.timer = setTimeout(this.filterChange('Nome', '@Nome', 'Nome', '', 'string', e.target.value), 3000)
                }
                }
                placeholder="Pesquise por Nome"
            />
        }
        columnsDefinition[2].Filter = () => {
            return <Input
                style={{ fontSize: "10px" }}
                fullWidth={true}
                onChange={(e) => {
                    clearTimeout(this.timer)
                    this.timer = setTimeout(this.filterChange('email', '@email', 'email', '', 'string', e.target.value), 3000)
                }
                }
                placeholder="Pesquise por E-mail"
            />
        }
        columnsDefinition[4].Filter = () => {
            let options = this.getStatusOptions();

            return (
                <Select
                    style={{ fontSize: "10px" }}
                    fullWidth={true}
                    inputProps={{ 'aria-label': 'Without label' }}
                    displayEmpty
                    onChange={(e) => { this.filterChange('Status', '@Status', 'Status', '', 'string', e.target.value); }}
                >
                    <MenuItem style={{ fontSize: "10px" }}>Pesquise por Status</MenuItem>
                    <MenuItem key='TODOS' value='' style={{ fontSize: "10px" }}>TODOS</MenuItem>
                    {options.map((value, index) => {
                        return (<MenuItem key={index} value={value.value}
                            style={{ fontSize: "10px" }}
                        >{value.label}</MenuItem>);
                    })}
                </Select>);
        }
        columnsDefinition[5].Filter = () => {
            let options = this.getPerfilOptions();

            return (
                <Select
                    style={{ fontSize: "10px" }}
                    fullWidth={true}
                    inputProps={{ 'aria-label': 'Without label' }}
                    displayEmpty
                    onChange={(e) => { this.filterChange('Perfil', '@Perfil', 'Perfil', '', 'string', e.currentTarget.dataset.name); }}
                >
                    <MenuItem style={{ fontSize: "10px" }}>Pesquise por Perfil</MenuItem>
                    <MenuItem key='TODOS' value='' data-name='' style={{ fontSize: "10px" }}>TODOS</MenuItem>
                    {options.map((value, index) => {
                        return (<MenuItem key={index} value={value.id} data-name={value.label}
                            style={{ fontSize: "10px" }}
                        >{value.label}</MenuItem>);
                    })}
                </Select>);
        }

    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(this.props.filtros, prevProps.filtros) ) {
            this.carregaLista();
        }
    }

    carregaLista() {
        perfilService.getPerfil().then((response) => {
            if (response.result) {
                this.setState({ listaPerfil: response.result });
            }
        });

        let paginacao = {
            page: {
                number: 1,
                size: 25
            }
        };
        this.setState({ paginacao })
        usuarioService.getUsuarios(paginacao, [...this.state.filtered, ...this.props.filtros])
            .then((response) => {
                if (response.result && response.paginacao && response.paginacao.records) {
                    for (var item in response.result) {
                        response.result[item].cpf = this.cpfMask(response.result[item].cpf);
                    }
                    this.setState({
                        listaUsuario: response.result,
                        totalUsuario: response.paginacao.records.count
                    })
                }
            }).finally(() => {
                this.setState({ loading: false });
            });


        let filtro = [];
        filtro.push({ id: "CPF", tipo: "string", dsc: "@CPF", sprauto: "" })
        filtro.push({ id: "Nome", tipo: "string", dsc: "@Nome", sprauto: "" })
        filtro.push({ id: "email", tipo: "string", dsc: "@email", sprauto: "" })
        filtro.push({ id: "Status", tipo: "string", dsc: "@Status", sprauto: "" })
        filtro.push({ id: "Perfil", tipo: "string", dsc: "@Perfil", sprauto: "" })
        this.props.adicionarCampos(filtro);
    }

    proximaPagina() {
        this.setState({ loading: true })
        let paginacao = this.state.paginacao;
        paginacao.page.number++;
        usuarioService.getUsuarios(paginacao)
            .then((response) => {
                let listaUsuarioResponse = _.map(response.result, (value) => {
                    value.countselecionados = this.state.selecionados.length;
                    value.selecionado = false;
                    return value;
                });
                let listaUsuario = _.union(this.state.listaUsuario, listaUsuarioResponse);
                this.setState({
                    listaUsuario,
                    totalUsuario: response.paginacao.records.count
                })
            }).finally(() => {
                this.setState({ loading: false });
            });
        this.setState({ paginacao });
    }

    selecionaUsuario(row, lineClick = false) {
        if (!this.state.editing && !lineClick && (this.state.selecionados.length === 0 || (this.state.selecionados.length === 1 && this.state.selecionados[0].iD_Usuario === row.original.iD_Usuario))) {
            return;
        }
        let checkeds = this.state.selecionados;
        let usuario = row.original;
        let indexSelecionados = _.findIndex(checkeds, function (o) { return o.iD_Usuario === usuario.iD_Usuario; })
        let cancelarSelecao = indexSelecionados === -1 ? null : _.clone(checkeds[indexSelecionados]);
        if (indexSelecionados === -1) {
            if (this.state.editing) {
                pushWarning("Encerre a edição antes de selecionar novos registros.");
                return;
            }
            let checked = _.clone(usuario);
            checkeds.push(checked);
        } else {
            _.remove(checkeds, (o) => { return o.iD_Usuario === usuario.iD_Usuario });
        }
        let listaUsuario = _.map(this.state.listaUsuario, (value) => {
            if (indexSelecionados !== -1 && value.iD_Usuario === cancelarSelecao.iD_Usuario) {
                value = cancelarSelecao;
            }
            value.countselecionados = checkeds.length;
            value.selecionado = _.findIndex(checkeds, function (o) { return o.iD_Usuario === value.iD_Usuario; }) !== -1;
            return value;
        });
        if (this.state.editing && checkeds.length === 0) {
            this.setState({ editing: false });
            this.carregaLista();
        }
        this.setState({ selecionados: checkeds, listaUsuario });
    }

    closeModalConfirm() {
        this.setState({ modalConfirm: { ...this.state.modalConfirm, abrirModal: false } });
    }

    getStatusOptions() {
        return _.map(this.state.status, (value) => {
            return { value: value, label: value };
        });
    }

    getPerfilOptions() {
        return _.map(this.state.listaPerfil, (value) => {
            return { id: value.iD_Perfil, label: value.descricao };
        });
    }

    adicionarClick() {
        let { editing, listaUsuario, selecionados } = this.state;
        var newObj = {
            iD_Usuario: null,
            nome: " ",
            email: "",
            imagem: null,
            status: "Ativo",
            selecionado: true,
            countselecionados: 1,
            senha: "Mudar123",
            fl_MudarSenha: true,
            fl_Status: true
        }
        this.state.selecionados.unshift(newObj);
        this.state.listaUsuario.unshift(newObj);
        this.setState({
            editing: !editing,
            selecionados: selecionados,
            listaUsuario: listaUsuario
        });
    }

    editarClick() {
        let editing = this.state.editing;
        let lstDescNOk;
        if (editing && this.state.selecionados.length > 0) {
            let { listaUsuario, selecionados } = this.state;
            lstDescNOk = _.filter(listaUsuario, (value) => { return (_.findIndex(selecionados, (v) => { return v.iD_Usuario === value.iD_Usuario && value.nome !== '' && value.email !== '' && value.fl_status !== '' && value.perfis.length !== 0 }) !== -1) });

            if (lstDescNOk.length === 0) {
                pushWarning("Por favor, preencher todos os campos.");
            } else {
                this.setState({
                    modalConfirm: {
                        abrirModal: true,
                        tittle: "Confirmação de edição",
                        message: "Tem certeza que deseja alterar " + this.state.selecionados.length + " usuário?",
                        buttonSimClick: this.editarUsuarios
                    }
                })
            }
        } else {
            this.setState({ editing: !editing });
        }
    }

    cancelarClick() {
        this.setState({ editing: false, selecionados: [] });  
        this.carregaLista();      
    }

    getEditable(row) {
        let retorno = false;
        if (this.state.editing && _.findIndex(this.state.selecionados, (value) => { return value.iD_Usuario === row.original.iD_Usuario }) !== -1) {
            retorno = true;
        }
        return retorno;
    }

    getEditableCPF(row) {
        let selecionados = this.state.selecionados[0];
        let retorno = false;
        if (this.state.editing && _.findIndex(this.state.selecionados, (value) => { return value.iD_Usuario === row.original.iD_Usuario }) !== -1 && !selecionados.iD_Usuario) {
            retorno = true;
        }
        return retorno;
    }

    cpfMask(value) {
        return value
            .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
            .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
    }

    changeCPF(value, row) {
        let listaUsuario = this.state.listaUsuario;
        let index = _.findIndex(this.state.listaUsuario, (value) => { return value.iD_Usuario === row.original.iD_Usuario });
        if (index !== -1) {
            listaUsuario[index].cpf = this.cpfMask(value);
            this.setState({ listaUsuario });
        }
    }

    changeEmail(value, row) {
        let listaUsuario = this.state.listaUsuario;
        let index = _.findIndex(this.state.listaUsuario, (value) => { return value.iD_Usuario === row.original.iD_Usuario });
        if (index !== -1) {
            listaUsuario[index].email = value;
            this.setState({ listaUsuario });
        }
    }

    changeNome(value, row) {
        let listaUsuario = this.state.listaUsuario;
        let index = _.findIndex(this.state.listaUsuario, (value) => { return value.iD_Usuario === row.original.iD_Usuario });
        if (index !== -1) {
            listaUsuario[index].nome = value;
            this.setState({ listaUsuario });
        }
    }

    changeStatus(input, row) {
        if (input && input.value) {
            let listaUsuario = this.state.listaUsuario;
            let index = _.findIndex(this.state.listaUsuario, (value) => { return value.iD_Usuario === row.original.iD_Usuario });
            if (index !== -1) {
                listaUsuario[index].Status = input.value;
                this.setState({ listaUsuario });
            }
        }
    }

    changePerfil(input, row) {
        if (input) {
            let listaUsuario = this.state.listaUsuario;
            let index = _.findIndex(this.state.listaUsuario, (value) => { return value.iD_Usuario === row.original.iD_Usuario });
            
            if (index !== -1) {
                let lst = _.head(input) ? _.head(input) : [];
                if (_.find(lst, { 'iD_Perfil': input[1].id })) {
                    lst = _.remove(lst, function (n) {
                        return n.iD_Perfil !== input[1].id;
                    });
                } else {
                    lst = [...lst,{iD_Perfil: input[1].id, descricao: input[1].label}];
                }

                listaUsuario[index].perfis = lst;
                this.setState({ listaUsuario });
            }
        }
    }

    editarUsuarios() {
        let { listaUsuario, selecionados } = this.state;
        let editUsuario = _.filter(listaUsuario, (value) => { return (_.findIndex(selecionados, (v) => { return v.iD_Usuario === value.iD_Usuario }) !== -1) });
        if (!editUsuario[0].iD_Usuario) {
            if (!faseService.ValidaCPF(editUsuario[0].cpf)) {
                pushWarning("CPF inválido.");
                this.setState({ modalConfirm: { abrirModal: false }, loading: false });
            } else if (!faseService.ValidaEmail(editUsuario[0].email)) {
                pushWarning("E-mail inválido.");
                this.setState({ modalConfirm: { abrirModal: false }, loading: false });
            }
            else {
                this.setState({ modalConfirm: { abrirModal: false }, loading: true });

                usuarioService.InsUsuario(editUsuario[0]).then((response)=>{
                    var msg = response.messages[0] ? response.messages[0].message : '';
                    if(!msg.includes('CPF') && !msg.includes('E-mail')){
                        this.setState({ selecionados: [], editing: false });
                        this.carregaLista();
                    }               
                }            
                ).catch(err => {
                    pushError(err);
                }).finally(() => {
                    this.setState({ loading: false });
                });
            }
        } else {
            this.setState({ modalConfirm: { abrirModal: false }, loading: true });
            usuarioService.atualizarUsuarios(editUsuario).then((response)=>{
                var msg = response.messages[0] ? response.messages[0].message : '';
                if(!msg.includes('CPF') && !msg.includes('E-mail')){
                    this.setState({ selecionados: [], editing: false });
                    this.carregaLista();
                }  
            }            
            ).catch(err => {
                pushError(err);
            }).finally(() => {
                this.setState({ loading: false });                
            });
        }
    }

    filterChange(chave, dsc, nomeCampo, sp, tipo, valor) {
        let filtered = this.state.filtered;
        let id = chave;
        let index = _.findIndex(filtered, (value) => { return value.chave === chave });
        if (valor !== undefined) {
            if (index !== -1) {
                filtered[index].valor = valor.toString();
            } else {
                filtered.push({ id, chave, dsc, nomeCampo, sp, tipo, valor: valor.toString() })
            }
        }

        this.setState({ filtered }, () => {
            this.carregaLista();
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <>
                <Grid container spacing={1}>
                    <Grid item lg={this.state.adicionarGraficos ? 10 : 12} md={this.state.adicionarGraficos ? 9 : 12} sm={this.state.adicionarGraficos ? 8 : 12}>
                        <div className={classes.paper} >
                            <div className={classes.header}>
                                <Button
                                    size="small"
                                    className={classes.button}
                                    startIcon={<FontAwesomeIcon icon={['fa', 'plus']} />}
                                    onClick={this.adicionarClick}
                                    disabled={this.state.selecionados.length !== 0}
                                >
                                    Adicionar
                                </Button>
                                <Button
                                    size="small"
                                    className={classes.button}
                                    startIcon={<FontAwesomeIcon icon={['fa', (this.state.editing ? 'save' : 'pencil')]} />}
                                    onClick={this.editarClick}
                                    disabled={this.state.selecionados.length === 0}
                                >
                                    {this.state.editing ? "Encerrar Edição" : "Editar"}
                                </Button>
                                {this.state.editing && 
                                <Button
                                size="small"
                                className={classes.button}
                                startIcon={<FontAwesomeIcon icon={['fa', 'window-close']} />}
                                onClick={this.cancelarClick}
                                >
                                Cancelar
                                </Button>
                                }
                            </div>
                            <div>
                                <InfiniteScroll
                                    style={{ width: '100%', fontSize: 10 }}
                                    dataLength={this.state.listaUsuario.length}
                                    next={() => { this.proximaPagina() }}
                                    hasMore={this.state.listaUsuario.length !== this.state.totalUsuario}
                                    height="100%"
                                >
                                    <GridTable
                                        selecionados={this.state.selecionados}
                                        data={this.state.listaUsuario}
                                        style={{ width: '100%', fontSize: 10, position: 'static' }}
                                        columns={columnsDefinition}
                                        loading={this.state.loading}
                                        minPage={1}
                                        pageSize={this.state.listaUsuario.length}
                                        getTrProps={(state, rowInfo, instance) => {
                                            let colorRow = '#FFFFFF';
                                            let colorFont = '#4b4b4d';
                                            let cursor = '';
                                            let onClick = () => { };

                                            if (rowInfo && (this.state.selecionados.length === 0 || (this.state.selecionados.length === 1 && this.state.selecionados[0].iD_Usuario === rowInfo.original.iD_Usuario)) && this.state.editing === false) {
                                                cursor = 'pointer';
                                                onClick = () => { this.selecionaUsuario(rowInfo, true) };
                                            }

                                            return {
                                                style: {
                                                    background: colorRow,
                                                    color: colorFont,
                                                    cursor: cursor,
                                                },
                                                onClick: onClick
                                            };
                                        }}
                                        getTdProps={(state, rowInfo, instance) => {

                                            return {
                                                style: {
                                                    overflow: 'visible'
                                                },
                                            };
                                        }}
                                        onFilteredChange={this.filterChange}
                                    />
                                </InfiniteScroll>
                            </div>

                        </div>
                    </Grid>
                </Grid>
                {this.state.modalConfirm.abrirModal &&
                    <ModalConfirm open={this.state.modalConfirm.abrirModal} modalProps={this.state.modalConfirm} buttonCancelarClick={() => { this.closeModalConfirm() }} ></ModalConfirm>
                }
            </>
        );
    }
}

function mapState(state) {
    const { filtros, btnPesquisarClick } = state.filtros;
    return { filtros, btnPesquisarClick };
}

const actionCreators = {
    adicionarCampos: filtroActions.adicionarCampos
};

export default connect(mapState, actionCreators)(withStyles(useStyles)(Usuario));