import React, { Component } from 'react';
import { connect } from 'react-redux';
import { workFlowActions, carteiraActions, processoActions } from '../../actions';
import Grid from '../Grid/Grid';
import columnsDefinition from './Columns.json';
import InfiniteScroll from "react-infinite-scroll-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdicionarCarteira from '../../components/AdicionarCarteira/AdicionarCarteira';
import ModalConfirm from '../../components/ModalConfirm/ModalConfirm';
import Switch from "react-switch";
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import _ from 'lodash';

const useStyles = theme => ({
    reactSwitch: {
        marginTop: '5px',
        float: 'left'
    }
});

class CarteirasTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idCarteira: null,
            statusFluxo: "",
            modalConfirm: {
                abrirModal: false,
                tittle: "",
                message: "",
            },
            paginacao: {
                page:
                {
                    number: 1,
                    size: 25
                }
            },
            selected: 0,
            modalAdicionarCarteira: false,
            inativaChecked: false
        };
        this.changeFilter = this.changeFilter.bind(this);
        this.checkInativa = this.checkInativa.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.criouCarteira = this.criouCarteira.bind(this);
        this.selecionarCarteira = this.selecionarCarteira.bind(this);
        this.adicionarProcessos = this.adicionarProcessos.bind(this);
    }

    

    componentDidMount() {
        if (this.props.idCarteiraProcesso && this.props.idCarteiraProcesso !== '0') {
            var filtro = this.props.filtro;
            filtro.FlagTodos = 1;
            this.props.getCarteiras(this.state.paginacao, this.props.filtro, this.props.idCarteiraProcesso, this.props.filtros, this.props.idCarteira === 0 ? true : false);
        } else {
            if (!this.props.isFilterSearch) {
                this.props.getCarteiras(this.state.paginacao, this.props.filtro, null, this.props.filtros, this.props.idCarteira === 0 ? true : false);
            }
        }
    }

    componentDidUpdate(prevProps) {      
        if (this.props.idCarteira !== prevProps.idCarteira) {
            this.selecionarCarteira(this.props.idCarteira, null);
        }
        if(!_.isEqual(this.props.filtros, prevProps.filtros) || this.props.btnPesquisarClick !== prevProps.btnPesquisarClick){
            let pagina = { page: { number: 1, size: 25 } };
            this.setState({ paginacao: pagina });
            var filtro = this.props.filtro;
            filtro.inativaChecked = this.state.inativaChecked;
            this.props.getCarteiras(pagina, filtro, this.props.idCarteiraProcesso, this.props.filtros, true);
            if(this.props.idCarteira === 0){
                this.props.atualizaProcessos();
            }
        }
    }

    proximaPagina() {
        let pagina = this.state.paginacao;
        pagina.page.number = pagina.page.number + 1;
        this.setState({ paginacao: pagina });
        var filtro = this.props.filtro;
        filtro.inativaChecked = this.state.inativaChecked;
        this.props.proximaPagina(pagina, filtro, this.props.idCarteiraProcesso, this.props.filtros);
    }

    changeFilter(valor) {
        var filtro = this.props.filtro;
        filtro.FlagTodos = valor;
        filtro.inativaChecked = this.state.inativaChecked;
        this.setState({ ...this.state, paginacao: { page: { number: 1, size: 25 } }, selected: 0 });
        this.props.getCarteiras({ page: { number: 1, size: 25 } }, filtro, this.props.idCarteiraProcesso, this.props.filtros);
        this.props.selecionarCarteira(null);
    }

    checkInativa(inativaChecked) {
        var filtro = this.props.filtro;
        //filtro.FlagTodos = valor;
        filtro.inativaChecked = inativaChecked;
        this.setState({ ...this.state, paginacao: { page: { number: 1, size: 25 } }, selected: 0, inativaChecked });
        this.props.getCarteiras({ page: { number: 1, size: 25 } }, filtro, this.props.idCarteiraProcesso, this.props.filtros);
        this.props.selecionarCarteira(null);
      }

    closeModal() {
        this.setState({
            modalAdicionarCarteira: false,
            modalConfirm: {
                abrirModal: false,
                tittle: "",
                message: "",
            }
        });
    }

    criouCarteira(idCarteira) {
        let pagina = { page: { number: 1, size: 25 } };
        this.setState({ paginacao: pagina, idCarteira });
        this.props.getCarteiras(pagina, this.props.filtro);
        this.setState({
            idCarteira: idCarteira,
            modalConfirm: {
                abrirModal: true,
                tittle: "Incluir Processos",
                message: "Deseja incluir processos nesta carteira?",
                buttonSimClick: this.adicionarProcessos
            }
        });
    }

    adicionarProcessos() {
        this.setState({
            modalConfirm: {
                abrirModal: false,
                tittle: "",
                message: "",
            },
        });
        this.selecionarCarteira(this.state.idCarteira, null);
        this.props.selecionarAcao({ idProcessoAcao: 0, urL_Rota: '/IncluirProcesso' })
    }

    selecionarCarteira(idCarteira, index, statusFluxo) {
        if (index === null) {
            this.props.carteiras.forEach((carteira, i) => {
                if (carteira.idCarteira === idCarteira) {
                    index = i;
                }
            });
        }
        this.props.getPassos(idCarteira);
        this.props.selecionarCarteira(idCarteira, statusFluxo);
        this.setState({ selected: index });
        this.props.idCarteiraSelecionado(idCarteira);
    }

    render() {
        const [data] = [this.props.carteiras];
        const filtro = this.props.filtro;
        const { classes } = this.props;
        return (
            <>
                <div className="text-center mb-1" style={{ padding: '8px' }}>
                    <Switch onChange={this.checkInativa} height={10} width={20} className={classes.reactSwitch} uncheckedIcon={false} checkedIcon={false} checked={this.state.inativaChecked} />
                    <Tooltip title="Exibir Carteiras Inativas" aria-label="Exibir Carteiras Inativas" >
                        <label style={{ marginLeft: "0.15rem", marginTop:"3px", float: "left" }} className={"tab mr-3 cursor-pointer "} >Inativa</label>
                    </Tooltip>                    
                    <label className={"tab ml-3 mr-3 cursor-pointer " + (filtro ? (filtro.flagTodos === 2 ? "active" : "") : "")} onClick={(e) => { this.changeFilter(2) }}>Recentes</label>
                    <label className={"tab ml-3 mr-3 cursor-pointer " + (filtro ? (filtro.flagTodos === 1 ? "active" : "") : "")} onClick={(e) => { this.changeFilter(1) }}>Todas</label>
                    <label onClick={() => { this.setState({ modalAdicionarCarteira: true }) }}><FontAwesomeIcon icon={['fa', 'plus']} style={{ marginLeft: 2, cursor: "pointer" }} /></label>
                </div>
                <InfiniteScroll
                    style={{ "width": "100%" }}
                    dataLength={this.props.carteiras.length}
                    next={() => { this.proximaPagina() }}
                    hasMore={this.props.carteiras.length !== this.props.count}
                    height="100%"
                >
                    <Grid data={data}
                        style={{ width: '100%', fontSize: 10 }}
                        columns={columnsDefinition}
                        getTrProps={(state, rowInfo) => ({
                            onClick: () => {
                                this.selecionarCarteira(rowInfo.original.idCarteira, rowInfo.index, rowInfo.original.statusFluxo)
                            },
                            style: {
                                background: (rowInfo ? (this.state.selected === rowInfo.index ? '#0091C7' : '#FFFFFF') : '#FFFFFF'),
                                color: (rowInfo ? (this.state.selected === rowInfo.index ? '#FFFFFF' : '') : '')
                            },
                        })}
                        loading={this.props.loading.carteiras}
                        minPage={1}
                        pageSize={this.props.carteiras.length}
                    />
                </InfiniteScroll>
                {this.state.modalAdicionarCarteira &&
                    <AdicionarCarteira open={this.state.modalAdicionarCarteira} closeModal={this.closeModal} criouCarteira={this.criouCarteira} />
                }
                <ModalConfirm open={this.state.modalConfirm.abrirModal} modalProps={this.state.modalConfirm} buttonCancelarClick={() => { this.closeModal() }} ></ModalConfirm>
            </>
        );
    }
}

function mapState(state) {
    const { carteiras, loading, count, filtro } = state.carteiras;
    const idCarteira = state.carteiras.idCarteira;
    const selecionado = state.carteiras.selecionado;
    const {filtros, btnPesquisarClick} = state.filtros;
    return { carteiras, loading, count, filtro, idCarteira, selecionado, filtros, btnPesquisarClick };
}

const actionCreators = {
    getCarteiras: carteiraActions.getCarteiras,
    getPassos: workFlowActions.getPassos,
    proximaPagina: carteiraActions.proximaPagina,
    atualizaProcessos: processoActions.atualizaProcessos,
    idCarteiraSelecionado: carteiraActions.idCarteiraSelecionado,
};

const connectedCarteirasTable = connect(mapState, actionCreators)(withStyles(useStyles)(CarteirasTable));
export { connectedCarteirasTable as CarteirasTable };
