import styled from 'styled-components';

let alturaFaseEditor='140px'
export const FaseModalContainer = styled.div`
  & {
  position: absolute;
  display: grid;
  font-family:Montserrat !important;
	text-decoration:none;
  grid-template-columns: 1fr 4fr ${props=> props.exibeMovimentacao ? '4fr':''};
  grid-template-rows: 40px calc(100% - 40px - ${alturaFaseEditor}) ${props=> props.exibeMovimentacao ? alturaFaseEditor : ''};
  grid-template-areas: "Breadcrumb  Breadcrumb    ${props=> props.exibeMovimentacao ? 'Breadcrumb': ''}"
                       "Etapa       Visualizacao  ${props=> props.exibeMovimentacao ? 'Documento' : ''}" 
                       "Etapa       AreaEdicao    ${props=> props.exibeMovimentacao ? 'Documento' : ''}";
  height: 100%;
  width: 100%;
  border-radius:20px;
  }
`

export const FaseModalTitulo = styled.span`
  position: relative;
  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 16px !important;
  cursor: pointer;
  top: 12px;
  left : 35px;
`

export const FaseModalArrowBack = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  height: 10px;
  cursor: pointer;
  font-size:small;
`
export const FaseModalCloseIcon= styled.div`
position: absolute;
top: 10px;
right: 10px;
height: 10px;
cursor: pointer;
font-size:small;
`

export const FaseModalBreadcrumb = styled.div` 
  grid-area: Breadcrumb; 
  background-color: #F5F5F5;
  width: 100%;
  border-top-left-radius:20px;
  border-top-right-radius:20px;
`

export const FaseModalVisualizacao = styled.div`
  grid-area: Visualizacao;
  position: relative;
  width: 100%;
  height: 100%;
  background-color:'#F5F5F5';  
`

export const FaseModalEtapa = styled.div`
  grid-area: Etapa; 
  background-color: #F5F5F5;
  border-bottom-left-radius: 20px;
`

export const FaseModalAreaEdicao = styled.div`
  grid-area: AreaEdicao;
  position: relative; 
  background-color: white;
  border-bottom-right-radius: 20px;
  height: 100%;
  width: 100%;
`

export const FaseModalNumeroProcesso = styled.span`
  padding-top: 10px;
`

export const FaseModalDocumento = styled.div`
  grid-area: Documento; 
  position:relative;
  border-bottom-right-radius: 20px;
  background-color: #F5F5F5;
  height: 100%;
  width: 100%;
`
