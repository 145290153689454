import { workFlowConstants } from '../constants';

const initialState = { loading: { passos: false }, passos: [], count: 0 }
export function workFlows(state = initialState, action) {
  switch (action.type) {
    case workFlowConstants.LISTAPASSOS:
      return {
        ...state,
        loading: {
          ...state.loading,
          passos: true
        }
      };
    case workFlowConstants.SUCESSO_PASSOS:
      return {
        ...state,
        loading: {
          ...state.loading,
          passos: false
        },
        passos: action.passos,
      };
    default:
      return state
  }
}