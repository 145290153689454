import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Grid, Paper } from "@material-ui/core";
import { Avatar } from "../Avatar/Avatar";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { anexoService } from '../../services/anexoService';
import Link from '@material-ui/core/Link';
import { Box, Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: "15px",
        width: "100%",
        boxShadow: "0px 0px 0px 0px",
        color: "rgba(60, 60, 67, 0.6);"
    },
    headerName: {
        fontSize: "12px",
        whiteSpace: "nowrap"
    },
    headerDate: {
        fontSize: "12px",
        whiteSpace: "nowrap"
    },

}));

export const ComentarioBox = (props) => {
    const { id, nome, data, mensagem, direita, anexos, imagem, icone, onLoad, onFinishLoad, notificacoes, qtdvisualizacao, onLoadTooltip, visualizacoes, onCloseTooltip } = props;
    const classes = useStyles();

    function download(idAnexo) {
        onLoad();
        anexoService.getAnexo(idAnexo).then((response) => {
            const base64 = "data:" + response.result[0].tipoAnexo + ";base64," + response.result[0].binAnexo; //data:image/png;base64,
            const url = base64;
            // const url = window.URL.createObjectURL(new Blob([response.result[0].binAnexo],{type: response.result[0].tipoAnexo}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response.result[0].nmAnexo);
            document.body.appendChild(link);
            link.click();
        }).finally(() => {
            onFinishLoad();
        });
    }

    return (
        <>
            <Paper className={classes.paper}>
                <Grid container wrap="nowrap" spacing={2} alignItems="center">
                    <Grid item>
                        {icone &&
                            <FontAwesomeIcon icon={['fa', icone]} style={{ fontSize: 20, color: "#0091C7" }} />
                        }
                    </Grid>
                    {!direita &&
                        <Grid item>
                            <Avatar nome={nome} imagem_usuario_compra={imagem} />
                        </Grid>
                    }
                    <Grid container >
                        <Grid item xs={direita === true ? 4 : 8}>
                            <label className={classes.headerName}>
                                {direita === true ? moment(data).format('DD/MM/YY HH:mm') : nome}
                            </label>
                        </Grid>
                        <Grid item xs={direita === true ? 8 : 4} style={{ textAlign: "right" }}>
                            <label className={classes.headerDate}>
                                {!direita === true ? moment(data).format('DD/MM/YY HH:mm') : nome}
                            </label>
                        </Grid>
                        <p style={{ textAlign: "justify", fontSize: 12, color: "rgba(60, 60, 67, 0.6)" }} dangerouslySetInnerHTML={{ __html: mensagem }}></p>
                        <Grid container style={{ marginBottom: 5 }}>
                            <Tooltip arrow={true} placement="top" enterDelay={1000} onOpen={() => { onLoadTooltip(id) }} onClose={() => {onCloseTooltip()}} title={
                                <Box component="div">
                                    Visto via web 
                                    <Box component="div" display="flex" style={{alignItens: "center", justifyContent: "center"}}>
                                        {visualizacoes && visualizacoes.lista && visualizacoes.lista.map((visualizacao, index) => {
                                            return (
                                                <Box key={index} component="div" display="inline" margin="5px">
                                                    <Avatar nome={visualizacao.Nome} imagem_usuario_compra={visualizacao.Imagem} />
                                                </Box>
                                            )
                                        })}
                                        {visualizacoes.total && visualizacoes.total > 3 && 
                                            <Box component="div" display="flex" alignItems="center">
                                                + {visualizacoes.total ? visualizacoes.total - 3 : 0}
                                            </Box>
                                        }
                                    </Box>
                                </Box>
                            } style={{ cursor: "pointer" }} >
                                <span>
                                    <FontAwesomeIcon icon={['fa', 'eye']} style={{ marginRight: 4 }} /> Visto por {qtdvisualizacao}
                                </span>
                            </Tooltip>
                        </Grid>
                        {notificacoes && notificacoes.length > 0 &&
                            <Grid container style={{ marginBottom: 5 }}>
                                <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: 4 }} />{moment(notificacoes[0].dtExibicao).format("DD/MM/YY HH:mm")}
                            </Grid>
                        }
                        {anexos &&
                            anexos.map((anexo, index) => {
                                if (anexo !== null) {
                                    return (
                                        <Grid container >
                                            <Link key={index} component="button" onClick={() => { download(anexo.idAnexo) }}>
                                                <FontAwesomeIcon icon={faPaperclip} style={{ marginRight: 4 }} />{anexo.nmAnexo}
                                            </Link>
                                        </Grid>
                                    )
                                }
                                return null;
                            })
                        }
                    </Grid>
                    {direita &&
                        <Grid item>
                            <Avatar nome={nome} imagem_usuario_compra={imagem} />
                        </Grid>
                    }
                </Grid>

                <Divider variant="fullWidth" style={{ marginTop: 15 }} />
            </Paper>
        </>
    )
}