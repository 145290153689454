import React, { useState } from 'react';
import { Modal, Fade, Button, Paper, Select, MenuItem, Grid, FormControl, Checkbox, Backdrop, makeStyles } from '@material-ui/core';
import _ from 'lodash';


const useStyles = makeStyles((theme) => ({
    input: {
        fontSize: 10,
        fontFamily: 'Montserrat-Medium',
        marginBottom: 10
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: "#F5F5F5",
        padding: theme.spacing("25px", "25px"),
        borderRadius: "10px",
        fontSize: "13px",
        outline: 'none',
        minWidth: '300px'
    },
    buttonSim: {
        backgroundColor: "#0091C7",
        color: "white",
        fontSize: "10px"
    },
    buttonCancelar: {
        backgroundColor: "rgba(0, 145, 199, 0.65)",
        color: "white",
        fontSize: "10px",
        marginRight: "16px"
    },
    contentModal: {
        backgroundColor: "white",
        padding: 16
    },
    formControl: {
        width: '120px',
        fontSize: 10,
        fontFamily: 'Montserrat-Medium',
        margin: 5
    },
    label: {
        fontSize: 10,
        fontFamily: 'Montserrat-Medium'
    },
    formGroup: {
        marginTop: 15
    },
    grid: {
        fontSize: 10,
        fontFamily: 'Montserrat-Medium'
    },
    lista: {
        maxHeight: '400px',
        overflow: 'auto'
    }
}));


export const ConfiguracaoDataGrid = (props) => {
    const { gridRef, modalOpen, buttonOkClick } = props;
    const [reloadState, setReloadState] = useState(1);
    const classes = useStyles();
    const groupIndex = _.countBy(gridRef.current.instance.state().columns, 'groupIndex')[0];

    function onChange(event, data) {
        gridRef.current.instance.beginUpdate();
        let newSummary = gridRef.current.instance.option('summary');
        let columns = gridRef.current.instance.state().columns;
        _.forEach(_.filter(gridRef.current.instance.option('columns'), 'format'), (value) => {
            let col = _.find(columns, { 'dataField': value.dataField });
            col.format = value.format;
        });
        let chkFind = _.find(newSummary.totalItems, (value) => { return value.column === data.dataField; });
        let groupFind = _.find(newSummary.groupItems, (value) => { return value.column === data.dataField; });
        let columnFind = _.find(columns, (value) => { return value.dataField === data.dataField; });
        let format = columnFind.format;



        if (event.target.name === 'chkSummary') {
            if (!newSummary || newSummary.length === 0) {
                newSummary = { totalItems: [], groupItems: [] };
            } else if (!newSummary.totalItems) {
                newSummary.totalItems = []
                newSummary.groupItems = []
            }
            if (event.target.checked === false && chkFind) {
                _.remove(newSummary.totalItems, (value) => { return value.column === columnFind.dataField; })
                _.remove(newSummary.groupItems, (value) => { return value.column === columnFind.dataField; })
            } else if (!chkFind) {
                newSummary['totalItems'].push({
                    column: columnFind.dataField,
                    summaryType: "count"
                });
            }
        } else if (event.target.name === 'dataType') {
            if (event.target.value === 'currency') {
                format = { style: 'currency', currency: 'BRL' };
            } else if (event.target.value === 'int') {
                format = { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 };
            } else if (event.target.value === 'date') {
                chkFind.summaryType = 'count';
                chkFind.valueFormat = null;
                format = 'dd/MM/yyyy';
            } else {
                if (chkFind) {
                    chkFind.summaryType = 'count';
                }
                format = null;
            }
            columnFind.dataType = event.target.value;
            columnFind.format = format;
        } else if (chkFind) {
            chkFind.summaryType = event.target.value;
            if (groupFind) {
                groupFind.summaryType = event.target.value;
            }
        }
        if (chkFind) {
            if (chkFind.summaryType !== 'count') {
                chkFind.valueFormat = format;
                if (groupFind) {
                    groupFind.valueFormat = format;
                }
            } else if (chkFind.summaryType === 'count') {
                chkFind.valueFormat = null;
                if (groupFind) {
                    groupFind.valueFormat = null;
                }
            }
        }
        gridRef.current.instance.option('summary', newSummary);
        gridRef.current.instance.option('columns', columns);
        gridRef.current.instance.endUpdate();
        setReloadState(reloadState * -1);
    }

    function onChangeSummaryGroup(event, data) {
        gridRef.current.instance.beginUpdate();
        let newSummary = gridRef.current.instance.option('summary');
        let chkFind = _.find(newSummary.groupItems, (value) => { return value.column === data.dataField; });
        let chkTotalItemsFind = _.find(newSummary.totalItems, (value) => { return value.column === data.dataField; });
        if (!newSummary['groupItems']) {
            newSummary['groupItems'] = [];
        }
        if (event.target.value === 's') {
            newSummary['groupItems'].push({
                column: chkTotalItemsFind.column,
                summaryType: chkTotalItemsFind.summaryType,
                valueFormat: chkTotalItemsFind.valueFormat,
                displayFormat: chkTotalItemsFind.column + ': {0}',
                showInGroupFooter: true
            });
        } else if (chkFind) {
            _.remove(newSummary.groupItems, (value) => { return value.column === data.dataField; })
        }
        gridRef.current.instance.option('summary', newSummary);
        gridRef.current.instance.endUpdate();
    }

    return (<>
        <Modal
            open={modalOpen}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            className={classes.modal}
            disableBackdropClick={true}
            disableEscapeKeyDown={true}
        >
            <Fade in={modalOpen}>
                <Paper className={classes.paper}>
                    <div className={classes.contentModal}>
                        <div>Configuração de Dados</div>
                        <div className={classes.lista}>
                            {_.map(_.orderBy(gridRef.current.instance.getVisibleColumns(), 'dataField'), (column) => {
                                let summary = gridRef.current.instance.option('summary');
                                let summaryField = _.find((summary ? summary.totalItems : []), (value) => { return value.column === column.dataField });
                                return (<>
                                    <Grid container alignItems="center" justify="flex-start">
                                        <Grid item width='245px'>
                                            <Checkbox
                                                name="chkSummary"
                                                onChange={(e) => { onChange(e, column) }}
                                                checked={summaryField !== undefined}
                                            />
                                            {column.caption}:
                                            </Grid>
                                        <Grid item>
                                            <FormControl className={classes.formControl}>
                                                <Select
                                                    labelId="dataTypeLabel"
                                                    name="dataType"
                                                    fullWidth
                                                    className={classes.input}
                                                    placeholder="Tipo do Campo"
                                                    value={column.dataType}
                                                    onChange={(e) => { onChange(e, column) }}
                                                >
                                                    <MenuItem className={classes.label} value='string' >Texto</MenuItem>
                                                    <MenuItem className={classes.label} value='date' >Data</MenuItem>
                                                    <MenuItem className={classes.label} value='int' >Numérico</MenuItem>
                                                    <MenuItem className={classes.label} value='currency'>Valor em R$</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl className={classes.formControl} disabled={summaryField === undefined || (column.dataType !== 'int' && column.dataType !== 'currency')}>
                                                <Select
                                                    name="summaryType"
                                                    labelId="summaryLabel"
                                                    fullWidth
                                                    displayEmpty
                                                    className={classes.input}
                                                    placeholder="Tipo de agregação"
                                                    onChange={(e) => { onChange(e, column) }}
                                                    value={summaryField ? summaryField.summaryType : ''}
                                                >
                                                    <MenuItem className={classes.label} value='' disabled>
                                                        Tipo de agregação
                                                            </MenuItem>
                                                    <MenuItem className={classes.label} value='sum'>Soma</MenuItem>
                                                    <MenuItem className={classes.label} value='min' >Mínimo</MenuItem>
                                                    <MenuItem className={classes.label} value='max' >Máximo</MenuItem>
                                                    <MenuItem className={classes.label} value='avg'>Média</MenuItem>
                                                    <MenuItem className={classes.label} value='count'>Contagem</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <FormControl className={classes.formControl} disabled={groupIndex === undefined || summaryField === undefined}>
                                                <Select
                                                    name="summaryGroup"
                                                    fullWidth
                                                    displayEmpty
                                                    className={classes.input}
                                                    placeholder=""
                                                    onChange={(e) => { onChangeSummaryGroup(e, column) }}
                                                >
                                                    <MenuItem className={classes.label} value='' disabled>Mostrar em Grupo</MenuItem>
                                                    <MenuItem className={classes.label} value='s'>Sim</MenuItem>
                                                    <MenuItem className={classes.label} value='n' >Não</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </>)
                            })}
                        </div>
                        <div className="text-right mt-2">
                            <Button
                                variant="contained"
                                className={classes.buttonSim}
                                onClick={buttonOkClick}
                            >
                                OK
                                    </Button>
                        </div>
                    </div>
                </Paper>
            </Fade>
        </Modal>
    </>);
}