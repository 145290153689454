import React, { Component } from 'react';
import { Grid as GridTable } from '../../components/Grid/Grid';
import InfiniteScroll from "react-infinite-scroll-component";
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { configCarteiraAutomaticaService } from '../../services/configCarteiraAutomaticaService';
import { processoTipoService } from '../../services/processoTipoService';
import _ from 'lodash';
import ModalConfirm from '../../components/ModalConfirm/ModalConfirm';
import { pushWarning } from '../../services/notifierService';
import { Upload } from '../../components/Upload/Upload';
import CsvDownloader from 'react-csv-downloader';
import { Select, MenuItem, Input } from '@material-ui/core';
import { filtroActions } from '../../actions/index';
import { connect } from 'react-redux';

const columnsDefinition = [
    {
        "column": null,
        "title": "Chave",
        "render": "ColumnWithColorAndCheckbox",
        "props": {
            "color": "rgb(245, 114, 114)",
            "colorsecondary": "#0091c7",
            "text_column": "idChave",
            "check_column": "idConfigCarteira",
            "onupdate": (props) => {
            }
        },
        "style": {
            "padding": "0px",
            "height": "100%",
            "minHeight": "40px"
        },
        "height": 20,
        "width": 220,
        "headerClassName": "text-left pl-3",
    },
    {
        "column": "descricao",
        "title": "Descrição",
        "headerClassName": "text-left",
        "render": "Input",
        "props": {
            editable: () => {
                return true;
            }
        },
        "filterable": true
    },
    {
        "column": "categoria",
        "title": "Categoria",
        "headerClassName": "text-left",
        "render": "SelectInput",
        "props": {
            "text_column": "categoria",
            editable: () => {
                return true;
            },
            onchange: () => {

            },
            getoptions: () => {

            }
        },
        "filterable": true,
        Filter: () => {
            return null
        },
        "className": "rt-rd-select-custom"
    },
    {
        "column": "processoTipo.idProcessoTipo",
        "title": "Tipo Processo",
        "headerClassName": "text-left",
        "render": "Select",
        "props": {
            "text_column": "processoTipo.descricao",
            editable: () => {
                return true;
            },
            getoptions: () => {

            }
        },
        "filterable": true,
        Filter: () => { return null }
    }
];

const useStyles = theme => ({
    header: {
        margin: 5,
        display: "-webkit-box"
    },
    labelHeader: {
        paddingTop: 6,
        marginRight: 5,
        fontSize: 10
    },
    button: {
        fontSize: 10,
        marginLeft: 5,
        fontFamily: 'Montserrat-Medium',
        textTransform: 'none'
    },
    lastButton: {
        fontSize: 10,
        marginLeft: 5,
        fontFamily: 'Montserrat-Medium',
        textTransform: 'none',
        display: "-webkit-box"
    },
    buttonFocused: {
        outline: 'none'
    },
    paper: {
        padding: 10,
        height: 'calc(100vh - 110px)',
        overflow: 'auto'
    },
    dashboard: {
        transform: "translate(1px, 1px)",
        minWidth: "1024px",
        overflowX: "auto",
        overflowY: "hidden"
    },
    removeStyle: {
        position: "absolute",
        right: "2px",
        top: 0,
        cursor: "pointer",
        zIndex: 2
    },
    noStaticDrag: {
        cursor: "move",
    },
    nenhumDashboard: {
        width: "100%",
        height: "100%",
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        lineHeight: 10
    }
})

class ConfigCarteiraAutomatica extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listaConfigCarteiraAutomatica: [],
            lstCsvConfigCarteiraAutomatica: [],
            loading: false,
            totalConfiCarteiraAutomatica: 0,
            paginacao: {
                page: {
                    number: 1,
                    size: 25
                }
            },
            selecionados: [],
            modalConfirm: {
                abrirModal: false,
                tittle: "",
                message: "",
                buttonSimClick: () => { }
            },
            processoTipo: [],
            categorias: [],
            editing: false,
            arquivoCSV: null,
            filtered: []
        }
        this.proximaPagina = this.proximaPagina.bind(this);
        this.selecionaConfigCarteiraAutomatica = this.selecionaConfigCarteiraAutomatica.bind(this);
        this.closeModalConfirm = this.closeModalConfirm.bind(this);
        this.confirmarExcluirSelecionados = this.confirmarExcluirSelecionados.bind(this);
        this.excluirSelecionados = this.excluirSelecionados.bind(this);
        this.getCategoriasOptions = this.getCategoriasOptions.bind(this);
        this.getProcessoTipoOptions = this.getProcessoTipoOptions.bind(this);
        this.adicionarClick = this.adicionarClick.bind(this);
        this.editarClick = this.editarClick.bind(this);
        this.getEditable = this.getEditable.bind(this);
        this.changeCategoria = this.changeCategoria.bind(this);
        this.changeDescricao = this.changeDescricao.bind(this);
        this.changeProcessoTipo = this.changeProcessoTipo.bind(this);
        this.editarConfiguracoes = this.editarConfiguracoes.bind(this);
        this.abrirImportarCSV = this.abrirImportarCSV.bind(this);
        this.importarCSV = this.importarCSV.bind(this);
        this.fileInput = React.createRef();
        this.enviarCSV = this.enviarCSV.bind(this);
        this.carregaLista = this.carregaLista.bind(this);
        this.carregaListaCsv = this.carregaListaCsv.bind(this);
        this.filterChange = this.filterChange.bind(this);

        this.timer = null;
    }

    componentDidMount() {
        this.setState({ loading: true })

        this.carregaLista();
        this.carregaListaCsv();

        configCarteiraAutomaticaService.getConfigCarteiraAutomaticaCategoria(this.props.idChave)
            .then((response) => {
                this.setState({
                    categorias: response.result
                })
            });

        processoTipoService.getProcessoTipo()
            .then((response) => {
                this.setState({
                    processoTipo: response.result
                })
            });
        columnsDefinition[0].props.onupdate = this.selecionaConfigCarteiraAutomatica;
        columnsDefinition[2].props.getoptions = this.getCategoriasOptions;
        columnsDefinition[3].props.getoptions = this.getProcessoTipoOptions;
        columnsDefinition[1].props.editable = this.getEditable;
        columnsDefinition[2].props.editable = this.props.categoria ? () => {return false;} : this.getEditable;
        columnsDefinition[3].props.editable = this.getEditable;
        columnsDefinition[1].props.onchange = this.changeDescricao;
        columnsDefinition[2].props.onchange = this.changeCategoria;
        columnsDefinition[3].props.onchange = this.changeProcessoTipo;

        columnsDefinition[1].Filter = () => {
            return <Input
                style={{ fontSize: "10px" }}
                fullWidth={true}
                onChange={(e) => {
                    clearTimeout(this.timer)
                    this.timer = setTimeout(this.filterChange('Descricao', '@Descricao', 'Descricao', '', 'string', e.target.value), 3000)
                }
                }
                placeholder="Pesquise por descrição"
            />
        }


        columnsDefinition[2].Filter = () => {
            let options = this.getCategoriasOptions();

            return (
                <Select
                    style={{ fontSize: "10px" }}
                    fullWidth={true}
                    inputProps={{ 'aria-label': 'Without label' }}
                    displayEmpty
                    onChange={(e) => { this.filterChange('Categoria', '@Categoria', 'Categoria', '', 'string', e.target.value); }}
                >
                    <MenuItem style={{ fontSize: "10px" }}>Pesquise por Categoria</MenuItem>
                    <MenuItem key='TODOS' value='' style={{ fontSize: "10px" }}>TODOS</MenuItem>
                    {options.map((value, index) => {
                        return (<MenuItem key={index} value={value.value}
                            style={{ fontSize: "10px" }}
                        >{value.label}</MenuItem>);
                    })}
                </Select>);
        }

        columnsDefinition[3].Filter = () => {
            let options = this.getProcessoTipoOptions();

            return (
                <Select
                    style={{ fontSize: "10px" }}
                    fullWidth={true}
                    inputProps={{ 'aria-label': 'Without label' }}
                    displayEmpty
                    onChange={(e) => { this.filterChange('ProcessoTipo', '@ProcessoTipo', 'ProcessoTipo', '', 'string', e.target.value ? e.target.value == 2 ? 'cível' : 'trabalhista' : ''); }}
                >
                    <MenuItem style={{ fontSize: "10px" }}>Pesquise por Tipo de Processo</MenuItem>
                    <MenuItem key='TODOS' value='' style={{ fontSize: "10px" }}>TODOS</MenuItem>
                    {options.map((value, index) => {
                        return (<MenuItem key={index} value={value.id}
                            style={{ fontSize: "10px" }}
                        >{value.name}</MenuItem>);
                    })}
                </Select>);
        }
    }

    componentDidUpdate(prevProps) {              
        if(!_.isEqual(this.props.filtros, prevProps.filtros)){
            this.carregaLista();
        }
    }

    carregaLista() {
        let paginacao = {
            page: {
                number: 1,
                size: 25
            }
        };
        this.setState({ paginacao })
        configCarteiraAutomaticaService.getConfigCarteiraAutomatica(paginacao, [...this.state.filtered,...this.props.filtros], this.props.idChave)
            .then((response) => {
                if (response.result && response.paginacao && response.paginacao.records) {
                    this.setState({
                        listaConfigCarteiraAutomatica: response.result,
                        totalConfiCarteiraAutomatica: response.paginacao.records.count
                    })  
                }
                if (response.filtro && response.filtro.campos) {
                    this.props.adicionarCampos(response.filtro.campos);
                }
            }).finally(() => {
                this.setState({ loading: false });
            });
    }

    carregaListaCsv() {
        let paginacao = {
                page: {
                    number: 1,
                    size: 9999
                }
            };
        configCarteiraAutomaticaService.getConfigCarteiraAutomatica(paginacao, [...this.state.filtered,...this.props.filtros], this.props.idChave)
            .then((response) => {
                if (response.result && response.paginacao && response.paginacao.records) {
                    var csvDados = [];
                    response.result.forEach(item => {
                        csvDados.push({chave: item.idChave, descricao: item.descricao, categoria: item.categoria, processoTipo: item.processoTipo.descricao})
                    });       
                    this.setState({lstCsvConfigCarteiraAutomatica: csvDados})        
                }
            }).finally(() => {
                this.setState({ loading: false });
            });
    }

    proximaPagina() {
        this.setState({ loading: true })
        let paginacao = this.state.paginacao;
        paginacao.page.number++;
        configCarteiraAutomaticaService.getConfigCarteiraAutomatica(paginacao, [...this.state.filtered,...this.props.filtros], this.props.idChave)
            .then((response) => {
                let listaConfigCarteiraAutomaticaResponse = _.map(response.result, (value) => {
                    value.countselecionados = this.state.selecionados.length;
                    value.selecionado = false;
                    return value;
                });
                let listaConfigCarteiraAutomatica = _.union(this.state.listaConfigCarteiraAutomatica, listaConfigCarteiraAutomaticaResponse);
                this.setState({
                    listaConfigCarteiraAutomatica,
                    totalConfiCarteiraAutomatica: response.paginacao.records.count
                })
            }).finally(() => {
                this.setState({ loading: false });
            });
        this.setState({ paginacao });
    }

    selecionaConfigCarteiraAutomatica(row, lineClick = false) {
        if (!this.state.editing && !lineClick && (this.state.selecionados.length === 0 || (this.state.selecionados.length === 1 && this.state.selecionados[0].idConfigCarteira === row.original.idConfigCarteira))) {
            return;
        }
        let checkeds = this.state.selecionados;
        let configCarteiraAutomatica = row.original;
        let indexSelecionados = _.findIndex(checkeds, function (o) { return o.idConfigCarteira === configCarteiraAutomatica.idConfigCarteira; })
        let cancelarSelecao = indexSelecionados === -1 ? null : _.clone(checkeds[indexSelecionados]);
        if (indexSelecionados === -1) {
            if (this.state.editing) {
                pushWarning("Encerre a edição antes de selecionar novos registros.");
                return;
            }
            let checked = _.clone(configCarteiraAutomatica);
            checked.processoTipo = _.clone(configCarteiraAutomatica.processoTipo);
            checkeds.push(checked);
        } else {
            _.remove(checkeds, (o) => { return o.idConfigCarteira === configCarteiraAutomatica.idConfigCarteira });
        }
        let listaConfigCarteiraAutomatica = _.map(this.state.listaConfigCarteiraAutomatica, (value) => {
            if (indexSelecionados !== -1 && value.idConfigCarteira === cancelarSelecao.idConfigCarteira) {
                value = cancelarSelecao;
            }
            value.countselecionados = checkeds.length;
            value.selecionado = _.findIndex(checkeds, function (o) { return o.idConfigCarteira === value.idConfigCarteira; }) !== -1;
            return value;
        });
        if (this.state.editing && checkeds.length === 0) {
            this.setState({ editing: false });
        }
        this.setState({ selecionados: checkeds, listaConfigCarteiraAutomatica });
    }

    closeModalConfirm() {
        this.setState({ modalConfirm: { ...this.state.modalConfirm, abrirModal: false } });
    }

    confirmarExcluirSelecionados() {
        let modalConfirm = this.state.modalConfirm;
        modalConfirm.abrirModal = true;
        modalConfirm.title = 'Confirmar Exclusão'
        modalConfirm.message = 'Tem certeza que deseja excluir ' + this.state.selecionados.length + ' configuração(ões)?'
        modalConfirm.buttonSimClick = this.excluirSelecionados;
        this.setState({ modalConfirm });
    }

    excluirSelecionados() {
        let listaConfigCarteiraAutomaticaExcluir = _.map(this.state.selecionados, (value) => {
            return { idConfigCarteira: value.idConfigCarteira };
        });
        let thisAtual = this;
        configCarteiraAutomaticaService.delConfigCarteiraAutomatica(listaConfigCarteiraAutomaticaExcluir).then((response) => {
            if (response.result) {
                let listaConfigCarteiraAutomatica = thisAtual.state.listaConfigCarteiraAutomatica;
                _.remove(listaConfigCarteiraAutomatica, function (n) {
                    return _.findIndex(thisAtual.state.selecionados, function (o) { return o.idConfigCarteira === n.idConfigCarteira; }) !== -1;
                });

                thisAtual.setState({ listaConfigCarteiraAutomatica })
            }
        }
        ).finally(() => {
            thisAtual.setState({ modalConfirm: { abrirModal: false }, selecionados: [] });
        })
    }

    getCategoriasOptions() {
        return _.map(this.state.categorias, (value) => {
            return { value: value, label: value };
        });
    }

    getProcessoTipoOptions() {
        return _.map(this.state.processoTipo, (value) => {
            return { id: value.idProcessoTipo, name: value.descricao };
        });
    }

    adicionarClick() {
        let { editing, listaConfigCarteiraAutomatica, selecionados } = this.state;
        var newObj = {
            categoria: (this.props.categoria ?? ""),
            descricao: "",
            flagDesejo: true,
            idChave: this.props.idChave,
            idConfigCarteira: 0,
            idProcessoTipo:0,
            processoTipo: {idProcessoTipo:0, descricao: ''},
            obs: "",
            selecionado: true,
            countselecionados: 1
        }        
        this.state.selecionados.unshift(newObj);
        this.state.listaConfigCarteiraAutomatica.unshift(newObj);
        this.setState({ 
            editing: !editing,
            selecionados: selecionados,
            listaConfigCarteiraAutomatica: listaConfigCarteiraAutomatica
        });
    }

    editarClick() {
        let editing = this.state.editing;
        let lstDescNOk = [];
        if (editing && this.state.selecionados.length > 0) {
            let { listaConfigCarteiraAutomatica, selecionados } = this.state;
            lstDescNOk = _.filter(listaConfigCarteiraAutomatica, (value) => { return (_.findIndex(selecionados, (v) => { return v.idConfigCarteira === value.idConfigCarteira && value.descricao !== '' && value.categoria !== '' && value.idProcessoTipo !== 0}) !== -1) });
                    
            if (lstDescNOk.length === 0) {
                pushWarning("Por favor, preencher todos os campos.");
            } else {
                let lstDescRegex = false;
                if(this.props.regexDescricao){
                    _.forEach(selecionados, (value) => {
                        let config = _.find(listaConfigCarteiraAutomatica, {idConfigCarteira : value.idConfigCarteira});
                        if(!config.descricao.match(this.props.regexDescricao)){
                            lstDescRegex = true;
                        }    
                    });
                }
                if(lstDescRegex){
                    pushWarning(this.props.regexMsg);
                }else{
                    this.setState({
                        modalConfirm: {
                            abrirModal: true,
                            tittle: "Confirmação de edição",
                            message: "Tem certeza que deseja alterar " + this.state.selecionados.length + " configurações?",
                            buttonSimClick: this.editarConfiguracoes
                        }
                    })
                }
            }
        } else {
            this.setState({ editing: !editing });
        }
    }

    getEditable(row) {
        let retorno = false;
        if (this.state.editing && _.findIndex(this.state.selecionados, (value) => { return value.idConfigCarteira === row.original.idConfigCarteira }) !== -1) {
            retorno = true;
        }
        return retorno;
    }

    changeCategoria(input, row) {
        if (input && input.value) {
            let listaConfigCarteiraAutomatica = this.state.listaConfigCarteiraAutomatica;
            let index = _.findIndex(this.state.listaConfigCarteiraAutomatica, (value) => { return value.idConfigCarteira === row.original.idConfigCarteira });
            if (index !== -1) {
                listaConfigCarteiraAutomatica[index].categoria = input.value;
                this.setState({ listaConfigCarteiraAutomatica });
            }
        }
    }

    changeDescricao(value, row) {
        let listaConfigCarteiraAutomatica = this.state.listaConfigCarteiraAutomatica;
        let index = _.findIndex(this.state.listaConfigCarteiraAutomatica, (value) => { return value.idConfigCarteira === row.original.idConfigCarteira });
        if (index !== -1) {
            listaConfigCarteiraAutomatica[index].descricao = value;
            this.setState({ listaConfigCarteiraAutomatica });
        }
    }

    changeProcessoTipo(value, text, row) {
        let listaConfigCarteiraAutomatica = this.state.listaConfigCarteiraAutomatica;
        let index = _.findIndex(this.state.listaConfigCarteiraAutomatica, (value) => { return value.idConfigCarteira === row.original.idConfigCarteira });
        if (index !== -1) {
            listaConfigCarteiraAutomatica[index].idProcessoTipo = value;
            listaConfigCarteiraAutomatica[index].processoTipo.idProcessoTipo = value;
            listaConfigCarteiraAutomatica[index].processoTipo.descricao = text;
            this.setState({ listaConfigCarteiraAutomatica });
        }
    }

    editarConfiguracoes() {
        this.setState({ modalConfirm: { abrirModal: false }, loading: true, editing: false });
        let { listaConfigCarteiraAutomatica, selecionados } = this.state;
        let editConfigCarteiraAutomatica = _.filter(listaConfigCarteiraAutomatica, (value) => { return (_.findIndex(selecionados, (v) => { return v.idConfigCarteira === value.idConfigCarteira }) !== -1) });
        if(editConfigCarteiraAutomatica[0].idConfigCarteira === 0){
            configCarteiraAutomaticaService.InsConfigCarteiraAutomatica(editConfigCarteiraAutomatica).finally(() => {
                this.setState({ loading: false, selecionados: [] });
                this.carregaLista();
            });
        }else{
            configCarteiraAutomaticaService.updConfigCarteiraAutomatica(editConfigCarteiraAutomatica).finally(() => {
                this.setState({ loading: false, selecionados: [] });
                this.carregaLista();
            });
        }
    }

    importarCSV(file) {
        this.setState({ loading: true, arquivoCSV: file });
        let reader = new FileReader();
        let thisAtual = this;
        this.fileInput.current.value = null;
        reader.onload = function (e) {
            let csvValido = true;
            let linhas = reader.result.split(/\r\n|\n/);
            _.each(linhas, (linha, index) => {
                if (!csvValido) {
                    return false;
                }
                
                let campos = linha.split(';');
                if (campos.length !== 4 && linha.includes(';')) {
                    pushWarning("Arquivo CSV esta no formato inválido. Favor revisar o formato: CHAVE, Descrição, Categoria, Tipo de Processo");
                    csvValido = false;
                    return false;
                }
                _.each(campos, (campo) => {
                    if (campo.trim() === '' && linha.includes(';')) {
                        pushWarning("Existem campos que não estão preenchidos na linha " + (index + 1) + ". Por favor revise o seu arquivo CSV.");
                        csvValido = false;
                        return false;
                    }
                });
                if(index > 0  && linha.includes(';') && thisAtual.props.regexDescricao && !campos[1].match(thisAtual.props.regexDescricao)){
                    pushWarning(thisAtual.props.regexMsg);
                    csvValido = false;
                }
                if(index > 0  && linha.includes(';') && campos[0] !== thisAtual.props.idChave){
                    pushWarning("Existem valores inválidos para o campo CHAVE.")
                    csvValido = false;
                }
                if(thisAtual.props.categoria && index > 0 && linha.includes(';') && campos[2] !== thisAtual.props.categoria  ){
                    pushWarning("Existem valores inválidos para o campo CHAVE.")
                    csvValido = false;
                }
            });
            if (csvValido) {
                let modalConfirm = thisAtual.state.modalConfirm;
                modalConfirm.abrirModal = true;
                modalConfirm.title = 'Confirmar importação de CSV'
                modalConfirm.message = 'Tem certeza que deseja importar ' + (linhas.length - 2) + ' configuração(ões)?'
                modalConfirm.buttonSimClick = thisAtual.enviarCSV;
                thisAtual.setState({ modalConfirm });
            }
            thisAtual.setState({ loading: false });
        }
        reader.readAsText(file);
    }

    abrirImportarCSV() {
        this.fileInput.current.click();
    }

    enviarCSV() {
        const data = new FormData();
        data.append('file', this.state.arquivoCSV);
        configCarteiraAutomaticaService.InsConfigCarteiraAutomaticaCSV(data).finally((response) => {
            this.fileInput.current.value = null;
            this.setState({ modalConfirm: { abrirModal: false }, arquivoCSV: null })
            this.carregaLista();
        });
    }

    filterChange(chave, dsc, nomeCampo, sp, tipo, valor) {
        
        let filtered = this.state.filtered;
        let id = chave;
        let index = _.findIndex(filtered, (value) => { return value.chave === chave });
        
        if(valor !== undefined){
            if (index !== -1) {
                filtered[index].valor = valor.toString();
            } else {
                filtered.push({ id, chave, dsc, nomeCampo, sp, tipo, valor: valor.toString() })
            }
        }
        
        this.setState({ filtered }, () => {
            this.carregaLista();
        });
    }

    render() {
        const { classes } = this.props;
        const headers = [
            { displayName: "CHAVE", id: "chave" },
            { displayName: "DESCRICAO", id: "descricao" },
            { displayName: "CATEGORIA", id: "categoria" },
            { displayName: "Processo Tipo", id: "processoTipo" }
        ];

        const csvPadrao = [
            { chave: this.props.idChave, descricao: 'DESCRIÇÃO PADRÃO TRABALHISTA', categoria: this.props.categoria ?? "NOVOS", processoTipo: 'TRABALHISTA' },
            { chave: this.props.idChave, descricao: 'DESCRIÇÃO PADRÃO CÍVEL', categoria: this.props.categoria ?? "NOVOS", processoTipo: 'CÍVEL' }
        ];
        return (
            <>
                <Grid container spacing={1}>
                    <Grid item lg={this.state.adicionarGraficos ? 10 : 12} md={this.state.adicionarGraficos ? 9 : 12} sm={this.state.adicionarGraficos ? 8 : 12}>
                        <div className={classes.paper} >
                            <div className={classes.header}>
                                <Button
                                    size="small"
                                    className={classes.button}
                                    startIcon={<FontAwesomeIcon icon={['fa', 'plus']} />}
                                    onClick={this.adicionarClick}
                                    disabled={this.state.selecionados.length !== 0}
                                >
                                    Adicionar
                                </Button>
                                <Button
                                    size="small"
                                    className={classes.button}
                                    startIcon={<FontAwesomeIcon icon={['fa', (this.state.editing ? 'save' : 'pencil')]} />}
                                    onClick={this.editarClick}
                                    disabled={this.state.selecionados.length === 0}
                                >
                                    {this.state.editing ? "Encerrar Edição" : "Editar"}
                                </Button>
                                <Button
                                    size="small"
                                    className={classes.button}
                                    startIcon={<FontAwesomeIcon icon={['fa', 'trash-alt']} />}
                                    onClick={this.confirmarExcluirSelecionados}
                                    disabled={this.state.selecionados.length === 0 || this.state.editing}
                                >Excluir
                                </Button>
                                <CsvDownloader
                                    filename="Config Carteira Aut"
                                    separator=";"
                                    wrapColumnChar=""
                                    columns={headers}
                                    datas={csvPadrao}>
                                    <Button
                                        size="small"
                                        className={classes.button}
                                        startIcon={<FontAwesomeIcon icon={['fa', 'file-download']} />}
                                    >
                                        Download Padrão
                                        </Button>
                                </CsvDownloader>
                                <Button
                                    size="small"
                                    className={classes.lastButton}
                                    startIcon={<FontAwesomeIcon icon={['fa', 'file-csv']} />}
                                    onClick={this.abrirImportarCSV}
                                    disabled={this.state.selecionados.length !== 0 || this.state.editing}
                                >
                                    Importar CSV
                                </Button>
                                <CsvDownloader
                                    filename="Config Carteira Aut Dados"
                                    separator=";"
                                    wrapColumnChar=""
                                    columns={headers}
                                    datas={this.state.lstCsvConfigCarteiraAutomatica}>
                                    <Button
                                        size="small"
                                        className={classes.button}
                                        startIcon={<FontAwesomeIcon icon={['fa', 'file-export']} />}
                                    >
                                        Exportar
                                        </Button>
                                </CsvDownloader>
                                <Upload onChange={this.importarCSV} accept=".csv" inputRef={this.fileInput}></Upload>
                            </div>
                            <div>
                                <InfiniteScroll
                                    style={{ width: '100%', fontSize: 10 }}
                                    dataLength={this.state.listaConfigCarteiraAutomatica.length}
                                    next={() => { this.proximaPagina() }}
                                    hasMore={this.state.listaConfigCarteiraAutomatica.length !== this.state.totalConfiCarteiraAutomatica}
                                    height="100%"
                                >
                                    <GridTable
                                        selecionados={this.state.selecionados}
                                        data={this.state.listaConfigCarteiraAutomatica}
                                        style={{ width: '100%', fontSize: 10, position: 'static' }}
                                        columns={columnsDefinition}
                                        loading={this.state.loading}
                                        minPage={1}
                                        pageSize={this.state.listaConfigCarteiraAutomatica.length}
                                        getTrProps={(state, rowInfo, instance) => {
                                            let colorRow = '#FFFFFF';
                                            let colorFont = '#4b4b4d';
                                            let cursor = '';
                                            let onClick = () => { };

                                            if (rowInfo && (this.state.selecionados.length === 0 || (this.state.selecionados.length === 1 && this.state.selecionados[0].idConfigCarteira === rowInfo.original.idConfigCarteira)) && this.state.editing === false) {
                                                cursor = 'pointer';
                                                onClick = () => { this.selecionaConfigCarteiraAutomatica(rowInfo, true) };
                                            }

                                            return {
                                                style: {
                                                    background: colorRow,
                                                    color: colorFont,
                                                    cursor: cursor,
                                                },
                                                onClick: onClick
                                            };
                                        }}
                                        getTdProps={(state, rowInfo, instance) => {

                                            return {
                                                style: {
                                                    overflow: 'visible'
                                                },
                                            };
                                        }}
                                        onFilteredChange={this.filterChange}
                                    />
                                </InfiniteScroll>
                            </div>

                        </div>
                    </Grid>
                </Grid>
                {this.state.modalConfirm.abrirModal &&
                    <ModalConfirm open={this.state.modalConfirm.abrirModal} modalProps={this.state.modalConfirm} buttonCancelarClick={() => { this.closeModalConfirm() }} ></ModalConfirm>
                }
            </>
        );
    }
}


function mapState(state) {
    const { filtros, btnPesquisarClick } = state.filtros;
    return { filtros, btnPesquisarClick };
}

const actionCreators = {
    adicionarCampos: filtroActions.adicionarCampos
};

export default connect(mapState, actionCreators)(withStyles(useStyles)(ConfigCarteiraAutomatica));