import React, { Component }  from 'react';
import BpmnModeler from 'bpmn-js/dist/bpmn-viewer.development';
import 'bpmn-js/dist/assets/diagram-js.css';
import './BpmnDiagram.css';

class BpmnModelerComponent extends Component {
    
    constructor(props) {
        super(props);
        this.modeler = null;
    }
    
    
    componentDidMount = () => {
        this.modeler = new BpmnModeler({
            container: '#bpmnview',
            keyboard: {
                bindTo: window
            },
            propertiesPanel: {
                parent: '#propview'
            },
        });
        
        this.newBpmnDiagram();
    }

    newBpmnDiagram = () => {
        this.openBpmnDiagram(`<?xml version="1.0" encoding="UTF-8"?>
        <bpmn2:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" id="sample-diagram" targetNamespace="http://bpmn.io/schema/bpmn" xsi:schemaLocation="http://www.omg.org/spec/BPMN/20100524/MODEL BPMN20.xsd">
            <bpmn2:collaboration id="Collaboration_0czsqyr">
                <bpmn2:participant id="Participant_0cyhvx8" processRef="Process_1" />
            </bpmn2:collaboration>
            <bpmn2:process id="Process_1" isExecutable="false">
                <bpmn2:startEvent id="StartEvent_1" />
            </bpmn2:process>
            <bpmndi:BPMNDiagram id="BPMNDiagram_1">
                <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Collaboration_0czsqyr">
                    <bpmndi:BPMNShape id="Participant_0cyhvx8_di" bpmnElement="Participant_0cyhvx8">
                        <dc:Bounds x="130" y="220" width="600" height="250" />
                    </bpmndi:BPMNShape>
                    <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
                        <dc:Bounds x="412" y="240" width="36" height="36" />
                    </bpmndi:BPMNShape>
                </bpmndi:BPMNPlane>
            </bpmndi:BPMNDiagram>
        </bpmn2:definitions>`);
    }

    openBpmnDiagram = (xml) => {
        var diagramXml = '';
        var bpmnInicio = '<?xml version="1.0" encoding="UTF-8"?>\r\n<bpmn:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" id="Definitions_130ybgm" targetNamespace="http://bpmn.io/schema/bpmn" exporter="bpmn-js (https://demo.bpmn.io)" exporterVersion="6.5.1">\r\n<bpmn:process id="Process_0b6dxld" isExecutable="false">\r\n<bpmn:startEvent id="StartEvent_048wt7e">\r\n<bpmn:outgoing>Flow_11az1zj</bpmn:outgoing>\r\n</bpmn:startEvent>\r\n';
        var bpmnMeio = '';
        var bpmnFim = '';
        let idProcessoStatusOK = 1;
        //Primeiro loop para construção do bpmn:process
        // eslint-disable-next-line
        {this.props.passos.map((passo) => {
            if(passo.idProcessoStatus === 1){
                idProcessoStatusOK = passo.idProcessoStatusOK;
                bpmnMeio = `<bpmn:task id="Activity_`+passo.idProcessoStatus+`" name="`+passo.dsProcessoStatus+`">\r\n<bpmn:incoming>Flow_11az1zj</bpmn:incoming>\r\n`;
                if(passo.idProcessoStatusOK !== 0){
                    bpmnMeio+=`<bpmn:outgoing>Flow_`+passo.idProcessoStatusOK+passo.idProcessoStatus+`</bpmn:outgoing>\r\n</bpmn:task>\r\n`;
                    bpmnMeio+=`<bpmn:sequenceFlow id="Flow_`+passo.idProcessoStatusOK+passo.idProcessoStatus+`" sourceRef="Activity_`+passo.idProcessoStatus+`" targetRef="Activity_`+passo.idProcessoStatusOK+`" />\r\n`;    
                }
                bpmnMeio+=`<bpmn:sequenceFlow id="Flow_11az1zj" sourceRef="StartEvent_048wt7e" targetRef="Activity_`+passo.idProcessoStatus+`" />\r\n`;    
                              
            }else if(passo.idProcessoStatus !== 1 && idProcessoStatusOK !== 0){
                idProcessoStatusOK = passo.idProcessoStatusOK;
                bpmnMeio+=`<bpmn:task id="Activity_`+passo.idProcessoStatus+`" name="`+passo.dsProcessoStatus+`">\r\n`;
                
                //Loop para pegar casos de incoming de status OK
                // eslint-disable-next-line
                {this.props.passos.map((passo1) => {
                    if(passo1.idProcessoStatusOK === passo.idProcessoStatus){
                        bpmnMeio+=`<bpmn:incoming>Flow_`+passo.idProcessoStatus+passo1.idProcessoStatus+`</bpmn:incoming>\r\n`;                        
                    }                        
                })}                
                //Loop para pegar casos de incoming de status NOK
                // eslint-disable-next-line
                {this.props.passos.map((passo2) => {
                    if(passo2.idProcessoStatusNOK === passo.idProcessoStatus)
                        bpmnMeio+=`<bpmn:incoming>Flow_`+passo.idProcessoStatus+passo2.idProcessoStatus+`</bpmn:incoming>\r\n`;
                })}
                //Loop para pegar casos de outgoing OK
                // eslint-disable-next-line
                {this.props.passos.map((passo3) => {
                    if(passo.idProcessoStatusOK === passo3.idProcessoStatus && passo3.idProcessoStatus !== 0)
                        bpmnMeio+=`<bpmn:outgoing>Flow_`+passo3.idProcessoStatus+passo.idProcessoStatus +`</bpmn:outgoing>\r\n`;
                })}
                //Loop para pegar casos de outgoing NOK
                var idNokFlow = '';
                // eslint-disable-next-line
                {this.props.passos.map((passo4) => {
                    if(passo.idProcessoStatusNOK === passo4.idProcessoStatus && passo4.idProcessoStatus !== 0){
                        bpmnMeio+=`<bpmn:outgoing>Flow_`+passo4.idProcessoStatus+passo.idProcessoStatus +`</bpmn:outgoing>\r\n`;
                        idNokFlow = passo4.idProcessoStatus+''+passo.idProcessoStatus;
                    }                        
                })}        
                
                //Se for final adiciona outgoing final
                if(passo.idProcessoStatusOK === 0){
                    bpmnMeio+=`<bpmn:outgoing>Flow_final</bpmn:outgoing>\r\n`;
                }
                
                //close </bpmn:task>
                bpmnMeio+=`</bpmn:task>\r\n`;  
                //Adiciono ligação entre fluxos OK
                if(passo.idProcessoStatusOK !== 0){
                    bpmnMeio+=`<bpmn:sequenceFlow id="Flow_`+passo.idProcessoStatusOK+passo.idProcessoStatus+`" sourceRef="Activity_`+passo.idProcessoStatus+`" targetRef="Activity_`+passo.idProcessoStatusOK+`" />\r\n`;
                }                    
                    
                //Adiciono ligação entre fluxos NOK
                if(passo.idProcessoStatusNOK !== 0){
                    bpmnMeio+=`<bpmn:sequenceFlow id="Flow_`+idNokFlow+`" name="Reprovado" sourceRef="Activity_`+passo.idProcessoStatus+`" targetRef="Activity_`+passo.idProcessoStatusNOK+`" />\r\n`;
                }                    
                 
                //Se for fluxo final
                if(passo.idProcessoStatusOK === 0){
                    bpmnMeio+= '<bpmn:endEvent id="Event_Final">\r\n<bpmn:incoming>Flow_final</bpmn:incoming>\r\n</bpmn:endEvent>\r\n';
                    bpmnMeio+= `<bpmn:sequenceFlow id="Flow_final" sourceRef="Activity_`+passo.idProcessoStatus+`" targetRef="Event_Final" />\r\n`;
                }
            }
            
        })}
        bpmnFim = '</bpmn:process>\r\n';

        var bpmnDiInicio = '<bpmndi:BPMNDiagram id="BPMNDiagram_1">\r\n<bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_0b6dxld">\r\n';
        bpmnDiInicio += '<bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_048wt7e" bioc:stroke="#0091C7" bioc:fill="#0091C7">\r\n<dc:Bounds x="162" y="142" width="36" height="36" />\r\n</bpmndi:BPMNShape>\r\n';
        var bpmnDiMeio = '';
        var bpmnDiFim = '';
        idProcessoStatusOK = 1;
        var BPMNEdgeX1 = 320;
        var BPMNEdgeX2 = 340;
        var BPMNShapeX = 220;
        var BPMNShapeXNOK = 0;
        var BPMNEdgeXNOK = 0;
        var BPMNEdgeXNOK2 = 0;
        var BPMNShapeXNOKLabel = 0;
        var lstIdProcessoStatusOK = [];
        var lstAbaixoNOK = [];
        var isTopElement = true;
        var lstPosicao = [];     
        var NokTop = true;   
        //Segundo loop para construção do bpmndi:BPMNDiagram
        // eslint-disable-next-line
        {this.props.passos.map((passo) => {
            BPMNShapeXNOK = 0;
            BPMNEdgeXNOK = 0;
            BPMNEdgeXNOK2 = 0;
            BPMNShapeXNOKLabel = 0;
            isTopElement = false;
            if(passo.idProcessoStatus === 1){
                idProcessoStatusOK = passo.idProcessoStatusOK;
                bpmnDiMeio = `<bpmndi:BPMNShape id="Activity_0ajps3u_di" bpmnElement="Activity_`+passo.idProcessoStatus+`" bioc:stroke="`+passo.corHexadecimal+`" bioc:fill="`+passo.corHexadecimal+`">\r\n`;
                bpmnDiMeio+=`<dc:Bounds x="220" y="120" width="100" height="80" />\r\n</bpmndi:BPMNShape>\r\n`;
                bpmnDiMeio+=`<bpmndi:BPMNEdge id="Flow_11az1zj_di" bpmnElement="Flow_11az1zj">\r\n<di:waypoint x="198" y="160" />\r\n<di:waypoint x="220" y="160" />\r\n</bpmndi:BPMNEdge>\r\n`;
                if(passo.idProcessoStatusOK !== 0){
                    bpmnDiMeio+=`<bpmndi:BPMNEdge id="Flow_`+passo.idProcessoStatusOK+passo.idProcessoStatus+`_di" bpmnElement="Flow_`+passo.idProcessoStatusOK+passo.idProcessoStatus+`">\r\n<di:waypoint x="320" y="160" />\r\n<di:waypoint x="340" y="160" />\r\n</bpmndi:BPMNEdge>\r\n`;
                }
                lstIdProcessoStatusOK.push(passo.idProcessoStatusOK);   
                // eslint-disable-next-line
                var objPosicao = {};
                objPosicao.IdProcessoStatus = passo.idProcessoStatus;
                objPosicao.BPMNShapeY = 120;
                objPosicao.BPMNShapeX = 220;                
                lstPosicao.push(objPosicao);
            }else if(passo.idProcessoStatus !== 1 && idProcessoStatusOK !== 0){
                idProcessoStatusOK = passo.idProcessoStatusOK;
                var contemElemento = true;
                //Se o idprocessostatus estiver nos idprocessostatusOK já incluidos faço a soma
                if(lstIdProcessoStatusOK.includes(passo.idProcessoStatus))
                {
                    BPMNShapeX += 120;
                    contemElemento = true;
                }else{
                    contemElemento = false;
                }     
                
                if(lstAbaixoNOK.includes(passo.idProcessoStatusNOK))
                {
                    isTopElement = false;
                }else{
                    isTopElement = true;
                }
                                
                bpmnDiMeio+=`<bpmndi:BPMNShape id="Activity_`+passo.idProcessoStatus+`_di" bpmnElement="Activity_`+passo.idProcessoStatus+`" bioc:stroke="`+passo.corHexadecimal+`" bioc:fill="`+passo.corHexadecimal+`">\r\n`;
                // eslint-disable-next-line
                var objPosicao = {};
                objPosicao.IdProcessoStatus = passo.idProcessoStatus;
                //Se tiver elemento adiciono novo a direita, caso não tenha elemento, é pq é status NOK para um novo elemento adiciono embaixo
                if(contemElemento){
                    bpmnDiMeio+=`<dc:Bounds x="`+BPMNShapeX+`" y="120" width="100" height="80" />\r\n</bpmndi:BPMNShape>\r\n`;
                    objPosicao.BPMNShapeY = 120;
                }else{
                    //Adiciono diagrama embaixo de outro elemento
                    bpmnDiMeio+=`<dc:Bounds x="`+BPMNShapeX+`" y="220" width="100" height="80" />\r\n</bpmndi:BPMNShape>\r\n`;
                    objPosicao.BPMNShapeY = 220;
                    //Loop para pegar casos status NOK
                    var i=0;
                    // eslint-disable-next-line
                    {this.props.passos.map((passo2) => {
                        if(passo2.idProcessoStatusNOK === passo.idProcessoStatus && i === 0){
                            bpmnDiMeio+=`<bpmndi:BPMNEdge id="Flow_`+passo.idProcessoStatus+passo2.idProcessoStatus+`_di" bpmnElement="Flow_`+passo.idProcessoStatus+passo2.idProcessoStatus+`" bioc:stroke="#FF0000" bioc:fill="#FF0000">\r\n`;
                            i++;
                        }                            
                    })}                    
                    BPMNShapeXNOK = BPMNShapeX + 50;
                    BPMNShapeXNOKLabel = BPMNShapeXNOK + 13;
                    //Posição flecha q sai do elemento anterior para o atual
                    bpmnDiMeio+=`<di:waypoint x="`+BPMNShapeXNOK+`" y="200" />\r\n<di:waypoint x="`+BPMNShapeXNOK+`" y="220" />\r\n`;
                    bpmnDiMeio+=`<bpmndi:BPMNLabel> <dc:Bounds x="`+BPMNShapeXNOKLabel+`" y="207" width="54" height="14" />\r\n</bpmndi:BPMNLabel>\r\n`;
                    bpmnDiMeio+=`</bpmndi:BPMNEdge>\r\n`

                    //Adiciono flecha saindo a esquerda do elemento atual para o elemento destino
                    if(passo.idProcessoStatusOK !== 0){
                        //pego a posição x do elemento q vai a seta
                        // eslint-disable-next-line
                        {lstPosicao.map((posicao) => {
                            if(posicao.IdProcessoStatus === passo.idProcessoStatusOK)
                                BPMNEdgeXNOK = posicao.BPMNShapeX + 50;
                        })} 
                        bpmnDiMeio+=`<bpmndi:BPMNEdge id="Flow_`+passo.idProcessoStatusOK+passo.idProcessoStatus+`_di" bpmnElement="Flow_`+passo.idProcessoStatusOK+passo.idProcessoStatus+`" >\r\n`;
                        bpmnDiMeio+=`<di:waypoint x="`+BPMNShapeX+`" y="260" />\r\n`;
                        bpmnDiMeio+=`<di:waypoint x="`+BPMNEdgeXNOK+`" y="260" />\r\n`;
                        bpmnDiMeio+=`<di:waypoint x="`+BPMNEdgeXNOK+`" y="200" />\r\n</bpmndi:BPMNEdge>\r\n`;
                    }
                    lstAbaixoNOK.push(passo.idProcessoStatus);
                }     
                
                objPosicao.BPMNShapeX = BPMNShapeX;
                lstPosicao.push(objPosicao);

                //Adiciono flechas com status NOK e verifico também se esta na lista de status ok
                if(passo.idProcessoStatusNOK !== 0 && lstIdProcessoStatusOK.includes(passo.idProcessoStatusNOK)){
                    BPMNEdgeXNOK = BPMNShapeX + 30;
                    // eslint-disable-next-line
                    {lstPosicao.map((posicao) => {
                        if(posicao.IdProcessoStatus === passo.idProcessoStatusNOK)
                            BPMNEdgeXNOK2 = posicao.BPMNShapeX + 70;
                    })} 
                    if(NokTop){
                        bpmnDiMeio+=`<bpmndi:BPMNEdge id="Flow_`+passo.idProcessoStatusNOK+passo.idProcessoStatus+`_di" bpmnElement="Flow_`+passo.idProcessoStatusNOK+passo.idProcessoStatus+`" bioc:stroke="#FF0000" bioc:fill="#FF0000">\r\n`;
                        bpmnDiMeio+=`<di:waypoint x="`+BPMNEdgeXNOK+`" y="200" />\r\n`;
                        bpmnDiMeio+=`<di:waypoint x="`+BPMNEdgeXNOK+`" y="230" />\r\n`;
                        bpmnDiMeio+=`<di:waypoint x="`+BPMNEdgeXNOK2+`" y="230" />\r\n`;
                        bpmnDiMeio+=`<di:waypoint x="`+BPMNEdgeXNOK2+`" y="200" />\r\n</bpmndi:BPMNEdge>\r\n`;
                        NokTop = false;
                    }else{
                        bpmnDiMeio+=`<bpmndi:BPMNEdge id="Flow_`+passo.idProcessoStatusNOK+passo.idProcessoStatus+`_di" bpmnElement="Flow_`+passo.idProcessoStatusNOK+passo.idProcessoStatus+`" bioc:stroke="#FF0000" bioc:fill="#FF0000">\r\n`;
                        bpmnDiMeio+=`<di:waypoint x="`+BPMNEdgeXNOK+`" y="120" />\r\n`;
                        bpmnDiMeio+=`<di:waypoint x="`+BPMNEdgeXNOK+`" y="90" />\r\n`;
                        bpmnDiMeio+=`<di:waypoint x="`+BPMNEdgeXNOK2+`" y="90" />\r\n`;
                        bpmnDiMeio+=`<di:waypoint x="`+BPMNEdgeXNOK2+`" y="120" />\r\n</bpmndi:BPMNEdge>\r\n`;
                        NokTop = true;
                    }                    
                }

                //Adiciono flechas com status NOK e verifico também se não esta lista de status ok e não é o elemento acima 
                if(passo.idProcessoStatusNOK !== 0 && !lstIdProcessoStatusOK.includes(passo.idProcessoStatusNOK) && !isTopElement){
                    BPMNEdgeXNOK = BPMNShapeX + 30;
                    // eslint-disable-next-line
                    {lstPosicao.map((posicao) => {
                        if(posicao.IdProcessoStatus === passo.idProcessoStatusNOK)
                            BPMNEdgeXNOK2 = posicao.BPMNShapeX + 100;
                    })} 
                    BPMNShapeXNOKLabel = BPMNShapeX + 53;
                        bpmnDiMeio+=`<bpmndi:BPMNEdge id="Flow_`+passo.idProcessoStatusNOK+passo.idProcessoStatus+`_di" bpmnElement="Flow_`+passo.idProcessoStatusNOK+passo.idProcessoStatus+`"  bioc:stroke="#FF0000" bioc:fill="#FF0000">\r\n`;
                        bpmnDiMeio+=`<di:waypoint x="`+BPMNEdgeXNOK+`" y="200" />\r\n`;
                        bpmnDiMeio+=`<di:waypoint x="`+BPMNEdgeXNOK+`" y="260" />\r\n`;
                        bpmnDiMeio+=`<di:waypoint x="`+BPMNEdgeXNOK2+`" y="260" />\r\n`;
                        bpmnDiMeio+=`<bpmndi:BPMNLabel> <dc:Bounds x="`+BPMNShapeXNOKLabel+`" y="207" width="54" height="14" />\r\n</bpmndi:BPMNLabel>\r\n`;
                        bpmnDiMeio+=`</bpmndi:BPMNEdge>\r\n`;
                }
                
                //Adiciono flecha para status OK
                if(passo.idProcessoStatusOK !== 0 && contemElemento){
                    BPMNEdgeX1+=120;
                    BPMNEdgeX2+=120;
                    bpmnDiMeio+=`<bpmndi:BPMNEdge id="Flow_`+passo.idProcessoStatusOK+passo.idProcessoStatus+`_di" bpmnElement="Flow_`+passo.idProcessoStatusOK+passo.idProcessoStatus+`">\r\n`;
                    bpmnDiMeio+=`<di:waypoint x="`+BPMNEdgeX1+`" y="160" />\r\n<di:waypoint x="`+BPMNEdgeX2+`" y="160" />\r\n</bpmndi:BPMNEdge>\r\n`;
                }     
                //Se for fluxo final
                if(passo.idProcessoStatusOK === 0){
                    BPMNEdgeX1+=120;
                    BPMNEdgeX2+=120;
                    bpmnDiMeio+=`<bpmndi:BPMNEdge id="Flow_final_di" bpmnElement="Flow_final">\r\n`;
                    bpmnDiMeio+=`<di:waypoint x="`+BPMNEdgeX1+`" y="160" />\r\n<di:waypoint x="`+BPMNEdgeX2+`" y="160" />\r\n</bpmndi:BPMNEdge>\r\n`;
                    BPMNShapeX += 122;
                    bpmnDiMeio+= `<bpmndi:BPMNShape id="Event_Final_di" bpmnElement="Event_Final" bioc:stroke="`+passo.corHexadecimal+`" bioc:fill="`+passo.corHexadecimal+`">\r\n`;
                    bpmnDiMeio+= `<dc:Bounds x="`+BPMNShapeX+`" y="142" width="36" height="36" />\r\n</bpmndi:BPMNShape>\r\n`;
                }
                lstIdProcessoStatusOK.push(passo.idProcessoStatusOK);
            }
            
        })}
        bpmnDiFim = '</bpmndi:BPMNPlane>\r\n</bpmndi:BPMNDiagram>\r\n</bpmn:definitions>\r\n';

        diagramXml = bpmnInicio+ bpmnMeio+ bpmnFim + bpmnDiInicio+ bpmnDiMeio+ bpmnDiFim;
        
        this.modeler.importXML(diagramXml, (error) => {
            if (error) {
                return console.log('fail import xml');
            }

            var canvas = this.modeler.get('canvas');

            canvas.zoom('fit-viewport');
        });
    }

    render = () => {
        return(
            <div id="bpmncontainer">
                <div id="bpmnview" style={{ width: '1000px', height: '220px', alignContent: 'center', textAlign: 'center' }}></div>
            </div>
        )
    }
}

export default BpmnModelerComponent;
